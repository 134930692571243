import manager from '../manager'
import * as t from '../types'
import { API_KEY, createBody, dyFetch } from '../utils'

export default function reportEngagement(evt: t.EngagementEvent) {
  return manager.getContext(
    (ctx) => {
      const body = createBody(ctx)
      /** add events */
      body.engagements = [evt]

      return dyFetch(
        'https://direct-collect.dy-api.eu/v2/collect/user/engagement',
        {
          method: 'POST',
          headers: { 'DY-API-Key': API_KEY },
          body: JSON.stringify(body)
        }
      )
    },
    (ctx) =>
      ctx._dyid_server &&
      ctx._dyjsession &&
      ctx.consentStatus !== 'UNKNOWN' &&
      ctx.pageViewSend &&
      ctx.isChannelSet
  )
}
