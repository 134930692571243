import * as React from 'react'
import { addRule } from 'redux-ruleset'
import ProductReviews from 'theme/partials/ProductReviews'
import * as Partials from 'containers/Partials'
import { SHOW_REVIEWS } from 'modules/ui/const'
import { useInitializer } from 'modules/productReviews'

/**
 * By a click, this rule will get triggered,
 * which will trigger a hook to open up the ProductReview drawer by itself.
 */

addRule({
  id: 'feature/SHOW_REVIEWS',
  target: SHOW_REVIEWS,
  output: '#partial-mount',
  consequence: (action) => {
    const remove = () => {
      Partials.remove('feature/SHOW_REVIEWS')
    }
    Partials.add('feature/SHOW_REVIEWS', () => {
      useInitializer(action.meta.containerID)

      return (
        <ProductReviews remove={remove} containerID={action.meta.containerID} />
      )
    })
  }
})
