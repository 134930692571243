import * as React from 'react'
import { addRule } from 'redux-ruleset'
import FlyoutCart from 'theme/partials/FlyoutCart'
import * as Partials from 'containers/Partials'
import getPageInfo from 'utils/getPageInfo'

/**
 * Wenn ich als Kunde etwas in den Warenkorb lege oder die Menge ändere,
 * Und ich nicht im Cart/Checkout bin
 * Dann soll auf der rechten Seite der Flyout Warenkorb erscheinen (ausfahren).
 */
addRule({
  id: 'feature/SHOW_FLYOUTCART',
  target: ['CART_ADD_ITEM', 'CART_UPDATE_ITEM'],
  output: '#partial-mount',
  condition: () => {
    const info = getPageInfo(window.location.pathname)
    switch (info.pageType) {
      case 'Checkout':
      case 'Cart':
        return false
      default:
        return true
    }
  },
  consequence: (action, { getState }) => {
    const state = getState()
    const remove = () => {
      Partials.remove('feature/SHOW_FLYOUTCART')
    }
    Partials.add('feature/SHOW_FLYOUTCART', () => {
      if (
        state.productDetail &&
        state.productDetail.data.length &&
        state.productDetail.data[0].related.optionalAdditions.length
      ) {
        return (
          <FlyoutCart
            optionalAdditions={
              state.productDetail?.data[0].related.optionalAdditions || []
            }
            remove={remove}
            latestCartItemId={action.cartItemId}
          />
        )
      } else {
        return (
          <FlyoutCart remove={remove} latestCartItemId={action.cartItemId} />
        )
      }
    })
  }
})
