import * as a from '../actions'
import { State } from '../reducer'
import useConnect, { Config } from 'hooks/useConnect'

export type Input = {}

export type Output = {
  visible: boolean
  setIntentVisible: (source?: string) => void
  hideIntentLayer: (source?: string) => void
  registerNewsletterNL: typeof a.registerNewsletterNL
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useIntentLayer',
  createCacheKey: () => '',
  mapState: (state) => ({
    visible: state.newsletterIntentVisible
  }),
  mapDispatch: {
    setIntentVisible: (source) =>
      a.setIntentVisible({
        visible: true,
        source: source || 'unknown'
      }),
    hideIntentLayer: (source) =>
      a.setIntentVisible({ visible: false, source: source || 'unknown' }),
    registerNewsletterNL: a.registerNewsletterNL
  }
}

/** @firescoutMockFn hooks.useIntentLayer */
export default function useIntentLayer(): Output {
  const input = {}
  const hook = useConnect<Input, Output, State, any>(input, config)
  return hook
}
