import { addRule } from 'redux-ruleset'

addRule({
  id: 'feature/track-chip-navigation',
  target: 'listingCategoryTree/chipClick',
  output: '#dl-event',
  consequence: (action) => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'navigation_lists_chipsclick',
      category: 'Engagement',
      action: 'navigation chips click',
      label: action.payload.label,
      click_text: action.payload.label,
      value: 0,
      nonInteraction: 'false'
    })
  }
})
