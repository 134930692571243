import * as React from 'react'
import styled from 'styled-components'
import { default as CheckoutLayout } from 'containers/CheckoutLayout'
import Layout from 'containers/Layout'
import Container from 'theme/atoms/Container'
import Seo from 'theme/atoms/SEO'
import theme from 'theme'
import GlobalStyle from '../../../containers/GlobalStyle'

type Props = {
  location: {
    pathname: string
    origin: string
  }
  checkoutLayout?: boolean
  renderWithoutCart?: boolean
}
import withTranslations from './withTranslations'
import useTranslations from 'hooks/useTranslations'

/* 
We render this error-atom in case if we catch an error with errorboundary  
We have two diffrent types return-types
renderWithoutCart: the error is catched in the `AccountProviderErrorBoundary`, 
useAccount-hook (cart) is not available -> but we still want to render layout (without cart) AND
we can not use translation-hook in this case -> static text is used 
default case: normal layout is rendered
*/
export function Error(props: Props) {
  const t = useTranslations<'error_500'>()

  if (props.checkoutLayout) {
    return (
      <CheckoutLayout>
        <GlobalStyle />
        <Seo
          location={props.location}
          title="500 - Something went wrong!"
          description="500 - Something went wrong!"
          type="website"
        />
        <CheckoutWrapper>
          <div className="headline">{t('headline')}</div>
          <div className="subline">{t('subline')}</div>
        </CheckoutWrapper>
      </CheckoutLayout>
    )
  } else if (props.renderWithoutCart) {
    return (
      <Layout dontRenderCartIcon>
        <GlobalStyle />
        <Seo
          location={props.location}
          title="500 - Something went wrong!"
          description="500 - Something went wrong!"
          type="website"
        />
        <Container>
          <Wrapper className="Error">
            <div className="headline">Something went wrong</div>
            <div className="subline">
              Sorry, the page you are looking for was not found.
            </div>
          </Wrapper>
        </Container>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <GlobalStyle />
        <Seo
          location={props.location}
          title="500 - Something went wrong!"
          description="500 - Something went wrong!"
          type="website"
        />
        <Container>
          <Wrapper className="Error">
            <div className="headline">{t('headline')}</div>
            <div className="subline">{t('subline')}</div>
          </Wrapper>
        </Container>
      </Layout>
    )
  }
}
export default withTranslations(Error)

const Wrapper = styled.div`
  margin-top: ${theme.spacing('xxl')};
  color: ${theme.colors.b0};
  text-align: center;

  > .headline {
    ${theme._ty([28, 0, 36], theme.fontSpecial, '400')};
    margin-bottom: ${theme.spacing('xs')};
  }
  > .subline {
    ${theme._ty([16, 0, 24], theme.fontSpecial, '400')};
  }
`

const CheckoutWrapper = styled.div`
  margin-top: ${theme.spacing('xxl')};
  color: ${theme.colors.b0};
  text-align: center;
  background-color: ${theme.colors.white};
  padding: ${theme.spacing('m')};
  width: 40rem;
  margin: 0 auto;

  > .headline {
    ${theme._ty([28, 0, 36], theme.fontSpecial, '400')};
    margin-bottom: ${theme.spacing('xs')};
  }
  > .subline {
    ${theme._ty([16, 0, 24], theme.fontSpecial, '400')};
  }
`
