import config from 'config'
import { Review } from 'modules/productReviews/types'
import React from 'react'

type Result = {
  showTranslationBtn: boolean
  translate: boolean
  title: string
  comment: string
  reply?: string
  toggleTranslation: () => void
}

function getComment(
  review: Review,
  locale: string
): { title: string; comment: string } {
  const title =
    review.review[locale]?.title || review.originalReview.title || ''
  const comment =
    review.review[locale]?.comment || review.originalReview.comment || ''
  return { title, comment }
}

function getReply(review: Review, locale: string): string | undefined {
  return (
    review.reply?.[locale]?.reply || review.originalReply?.comment || undefined
  )
}

export default function useReview(review: Review): Result {
  const [translate, setTranslate] = React.useState<boolean>(true)
  return React.useMemo(() => {
    const locale = config.locale.split('-')[0]
    const reviewLocale = review.locale.split('-')[0]
    const isSameLocale = locale.localeCompare(reviewLocale) === 0 ? true : false
    const translationExists = !!review.review?.[locale]

    const { title: title, comment: comment } =
      !isSameLocale && translate && translationExists
        ? getComment(review, locale)
        : {
            title: review.originalReview.title,
            comment: review.originalReview.comment
          }

    const reply =
      !isSameLocale && translate && translationExists
        ? getReply(review, locale)
        : review.originalReply.comment

    return {
      showTranslationBtn: !isSameLocale && translationExists,
      translate: translate,
      title: title || '',
      comment: comment || '',
      reply: reply || undefined,
      toggleTranslation: () => {
        setTranslate((translate) => !translate)
      }
    }
  }, [review, translate])
}
