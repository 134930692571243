import './rules'
import { default as reducer, State } from './reducer'
import { injectReducer } from 'store/rootReducer'
import store from 'store'
import { Action } from './actions'

injectReducer(store, 'snackbar', reducer)

declare global {
  interface RootState {
    snackbar: State
  }
  interface RootReducers {
    snackbar: typeof reducer
  }
  interface ModuleActions {
    snackbar: Action
  }
}

export { default as useSnackbar } from './hooks/useSnackbar'
