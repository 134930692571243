import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import CheckRadio from 'theme/atoms/CheckRadio'

type Props = {
  onClick: () => void
  active: boolean
  label: string
}

export default function SwitchItem({ onClick, active, label }: Props) {
  return (
    <Wrapper
      data-cy-handle="lang-switch-item"
      className="SwitchItem"
      onClick={onClick}
    >
      <CheckRadio
        variation="radio"
        radiogroup="lang-switch"
        label={label}
        checked={active}
      >
        {label}
      </CheckRadio>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: ${theme.spacing('xxs')};
  text-transform: capitalize;
  > .CheckRadio {
    > svg {
      > g > g > g {
        > path {
          fill: revert-layer;
        }
      }
    }
  }
`
