import React from 'react'
import { StyleSheetManager } from 'styled-components'
import { GatsbyBrowser } from 'gatsby'

interface WrapRootElementArgs {
  element: React.ReactNode
  pluginOptions?: {
    disableVendorPrefixes?: boolean
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
  pluginOptions
}: WrapRootElementArgs): JSX.Element => (
  <StyleSheetManager
    disableVendorPrefixes={pluginOptions?.disableVendorPrefixes === true}
  >
    {element}
  </StyleSheetManager>
)
