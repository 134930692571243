import { graphql, useStaticQuery } from 'gatsby'

export type NavigationQuery = {
  navigation: {
    sidebar: NavigationItem[]
  }
}

type NavigationItem = {
  label: string
  link: string
}

export default function useStaticNavi(): NavigationItem[] {
  /** @firescoutMockVar app-header.gq-headerNavigationQuery */
  const data = useStaticQuery<NavigationQuery>(graphql`
    query HeaderNavigationQuery {
      navigation {
        sidebar {
          label
          link
        }
      }
    }
  `)

  return data.navigation.sidebar
}
