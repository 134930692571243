import { LOCATION_CHANGED } from 'modules/navigation/const'
import { addRule } from 'redux-ruleset'
import config from 'config'
import { isChannelB2B, isChannelSet } from 'utils/channel'

addRule({
  id: 'leadinfo/INIT',
  target: LOCATION_CHANGED,
  output: '#leadinfo-init',
  condition: () =>
    Boolean(config.features.leadinfo) && isChannelSet() && isChannelB2B(),
  consequence: () => {
    setTimeout(() => {
      if (window['leadinfo']) {
        if (!window['leadinfo'].initialized) {
          window['leadinfo'].init()
        }
      }
    }, 500)
  }
})
