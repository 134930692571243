import 'intersection-observer-polyfill/index.global'
import * as React from 'react'
import GlobalLayout from './src/containers/GlobalLayout'

export function wrapPageElement({ element, props }) {
  return <GlobalLayout {...props}>{element}</GlobalLayout>
}

export function disableCorePrefetching() {
  return true
}

// export function onPrefetchPathname({ pathname, loadPage }) {
//   // if(pathname.includes('/pdp/')) return
//   // loadPage(pathname)
//   // console.log('Prefetching', pathname, loadPage)
// }
