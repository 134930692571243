const blackList = [
  'cart',
  'checkout',
  'account',
  'online-service',
  'newsletter'
]

export default function checkUrl(url: string) {
  return blackList.some((item) => url.includes(item))
}
