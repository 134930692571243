import * as React from 'react'
import config from 'config'
import useAccount from './account/useAccount'
import lusiniFunctionsFetch from 'utils/lusiniFunctionsFetch'

const hydration = {
  optIn: false,
  setup: false
}

export type GetOptInStatusResponse = {
  status: 'success' | 'failure'
  mode: 'OPTIN_IN_FALSE' | 'OPTIN_IN_TRUE' | string
}

export type CreateContactResponse = {
  status: 'success' | 'failure'
  mode: 'NO_MAIL_TRIGGER' | 'MAIL_TRIGGER' | 'ALREADY_OPTIN' | string
  meta: unknown
}

export type SetOptInStatusResponse = {
  status: 'success' | 'failure'
  mode: 'OPTIN_SET' | string
  meta: unknown
}

export default function useNewsletter() {
  const account = useAccount()
  const [optIn, setOptIn] = React.useState<boolean>(hydration.optIn)
  const [setup, setSetup] = React.useState(hydration.setup)

  React.useEffect(() => {
    if (!account.context) return
    if (hydration.setup) return
    if (!account.context.loggedIn) return
    lusiniFunctionsFetch<GetOptInStatusResponse>(
      'https://functions.lusini.com/.netlify/functions/newsletter-getOptInStatus',
      {
        method: 'POST',
        body: JSON.stringify({
          email: account.context.email,
          location: config.locale
        })
      }
    )
      .then((res) => {
        const status: 'success' | 'failure' | string = res.status
        if (status === 'success') {
          if (res.mode === 'OPTIN_IN_TRUE') {
            setOptIn(true)
            hydration.optIn = true
          } else if (res.mode === 'OPTIN_IN_FALSE') {
            setOptIn(false)
            hydration.optIn = false
          } else {
            setOptIn(false)
            hydration.optIn = false
          }
        }
      })
      .finally(() => {
        setSetup(true)
        hydration.setup = true
      })
  }, [account.context])

  /** clear hydration when email changes */
  React.useEffect(() => {
    account._.onEvent((evt) => {
      switch (evt.type) {
        case 'LOGOUT':
        case 'CHANGE_EMAIL': {
          setOptIn(false)
          hydration.optIn = false
          hydration.setup = false
          break
        }
      }
    })
  }, [])

  return {
    /** initially false. true when we known if optIn status is true/false */
    setup,

    /** true when user has subscripted to newsletter */
    optInStatus: optIn,

    /** fetch optIn status to get current optInStatus*/
    fetchOptInStatus: async () => {
      if (account.context?.email) {
        lusiniFunctionsFetch<GetOptInStatusResponse>(
          'https://functions.lusini.com/.netlify/functions/newsletter-getOptInStatus',
          {
            method: 'POST',
            body: JSON.stringify({
              email: account.context.email,
              location: config.locale
            })
          }
        ).then((res) => {
          const status: 'success' | 'failure' | string = res.status
          if (status === 'success') {
            if (res.mode === 'OPTIN_IN_TRUE') {
              setOptIn(true)
              hydration.optIn = true
            } else if (res.mode === 'OPTIN_IN_FALSE') {
              setOptIn(false)
              hydration.optIn = false
            } else {
              setOptIn(false)
              hydration.optIn = false
            }
          }
        })
      }
    },
    /**
     * @param email
     * @returns status
     *
     * check if email has optin '1' --> skip following steps
     * update or create contact
     * set the optin Status depended on country config and channel (b2c|b2b)
     * if optIn already on "1", dont update
     * next step: triggers double Opt In Mail depended on country config and channel (b2c|b2b)
     */
    createContactWithOptIn: async (props: {
      channel?: string
      email?: string
      subscriptionSource:
        | 'footer'
        | 'account'
        | 'order_guest'
        | 'order_account'
        //WDV-3313 NewsletterIntentLayer
        | 'Newsletterlayer'
        | string
    }) => {
      // setOptIn(true)
      // hydration.optIn = true
      return await lusiniFunctionsFetch<CreateContactResponse>(
        'https://functions.lusini.com/.netlify/functions/newsletter-createContactWithOptIn',
        {
          method: 'POST',
          body: JSON.stringify({
            email: props.email || account.context?.email || '',
            location: config.locale,
            channel: props.channel || account.channel,
            subscriptionSource: props.subscriptionSource
          })
        }
      ).then((res) => {
        const status: 'success' | 'failure' | string = res.status
        if (status !== 'success') {
          setOptIn(false)
          hydration.optIn = false
        } else {
          account._.sendEvent({
            type: 'NEWSLETTER_REGISTER',
            email: props.email || account.context?.email || ''
          })
          if (res.mode === 'NO_MAIL_TRIGGER') {
            setOptIn(true)
            hydration.optIn = true
          } else if (res.mode === 'MAIL_TRIGGER') {
            setOptIn(false)
            hydration.optIn = false
          } else if (res.mode === 'ALREADY_OPTIN') {
            setOptIn(true)
            hydration.optIn = true
          }
        }

        return { status, mode: res.mode, meta: res.meta || {} }
      })
    },

    /**
     * @param email string
     * @returns status Boolean
     *
     * unsubscribes the user from newsletter
     */
    clearOptInStatus: async (email?: string) => {
      setOptIn(false)
      hydration.optIn = false
      return await lusiniFunctionsFetch<SetOptInStatusResponse>(
        'https://functions.lusini.com/.netlify/functions/newsletter-setOptInStatus',
        {
          method: 'POST',
          body: JSON.stringify({
            email: email || account.context?.email || '',
            location: config.locale,
            payload: 'OptInFalse',
            channel: account.channel
            // "subscriptionSource" if used for set optin true
          })
        }
      ).then((res) => {
        const status: 'success' | 'failure' | string = res.status

        if (status !== 'success') {
          setOptIn(true)
          hydration.optIn = true
        } else {
          account._.sendEvent({
            type: 'NEWSLETTER_UNREGISTER',
            email: email || account.context?.email || ''
          })
        }
        return { status, mode: res.mode, meta: res.meta || {} }
      })
    }
  }
}
