import * as React from 'react'
import styled from 'styled-components'
import IconTemplate from './IconTemplate'
import UserSvg from 'assets/user.svg'
import PhoneSvg from 'assets/phone-call.svg'
import CartSvg from 'assets/cart.svg'
import WishlistIcon from './WishlistIcon'
import theme from 'theme'
import useAccount from 'hooks/account/useAccount'
import useAddressRequireDict from 'hooks/account/lists/useAddressRequireDict'
import useTranslations from 'hooks/useTranslations'
import config from 'config'
import LanguageSwitch from './LanguageSwitch'

export default function IconList() {
  const account = useAccount()
  const requireDict = useAddressRequireDict()
  const t = useTranslations<'app-Header'>()

  const userLink = React.useMemo(() => {
    let userLink = '/account/login/'
    if (!account.context || !account.loggedIn) return userLink

    const { activeBillingAddress, activeShippingAddress } = account.context
    const billingValid = requireDict.isBillingValid(activeBillingAddress)
    const shippingValid = requireDict.isShippingValid(activeShippingAddress)

    if (account.context.isGuest) userLink = '/account/login/'
    else if (!billingValid || !shippingValid) userLink = '/account/address/'
    else userLink = '/account/'

    return userLink
  }, [
    account.loggedIn,
    account.context,
    requireDict.isBillingValid,
    requireDict.isShippingValid
  ])

  return (
    <Wrapper className="IconList">
      {config.langShop && (
        <IconTemplate
          data-cy-state="language-switch-icon"
          className="language-switch"
          label={config.langShop.current.locale.slice(0, 2).toLocaleUpperCase()}
          icon={<LanguageSwitch />}
        />
      )}
      <IconTemplate
        className="wishlist"
        label={t('iconList_bookmarks')}
        icon={<WishlistIcon />}
      />
      <IconTemplate
        to={config.links.contact}
        className="contact"
        label={t('iconList_contact')}
        icon={<PhoneSvg />}
      />
      <IconTemplate
        to={userLink}
        className="account"
        label={t('iconList_account')}
        icon={<UserSvg />}
      />
      <IconTemplate
        to="/cart/"
        className="cart"
        label={t('iconList_cart')}
        icon={<CartSvg />}
        counter={account.cart.data.items.length}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${theme.flex('center', 'center')}
`
