import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import config from 'config'
import useAccount from 'hooks/account/useAccount/useAccount'
import useTranslations from 'hooks/useTranslations'
import CheckmarkIcon from 'assets/checkmark.svg'
import ArrowDown from 'assets/arrow-down.svg'
import ArrowUp from 'assets/arrow-up.svg'

export default function B2XDropdown() {
  const [open, setOpen] = React.useState(false)
  const { channel, setChannel, loggedIn } = useAccount()
  const [isB2cChannelActive, setIsB2cChannelActive] = React.useState(
    channel === 'b2c'
  )
  const t = useTranslations<'app-Header'>()

  React.useEffect(() => {
    channel === 'b2c'
      ? setIsB2cChannelActive(true)
      : setIsB2cChannelActive(false)
  }, [channel])

  const handleSwitchInteraction = (type: 'b2b' | 'b2c') => {
    switch (type) {
      case 'b2b':
        setChannel('b2b', 'navigation_b2b2c_switch')
        break
      case 'b2c':
        setChannel('b2c', 'navigation_b2b2c_switch')
        break
    }
  }

  return (
    <Wrapper
      className="B2XDropdown"
      $isOpen={open}
      $isB2cChannelActive={isB2cChannelActive}
      data-cy-state={open ? 'show-dropdown-content' : undefined}
    >
      {!loggedIn && config.features.b2c && (
        <div className="dropdown" data-cy-state="show-b2x:dropdown">
          <div
            className="button-dropdown border-box"
            onClick={() => setOpen(!open)}
            data-cy-handle="open-b2x-dropdown"
          >
            <div className="active-Selection">
              {channel === 'b2b' && t('b2x_selection_company')}
              {channel === 'b2c' && t('b2x_selection_private')}
            </div>
            <div className="dropdown-arrow">
              {open ? (
                <ArrowUp data-cy-state="show-b2x:arrowup" />
              ) : (
                <ArrowDown data-cy-state="show-b2x:arrowdown" />
              )}
            </div>
          </div>

          <div className="dropdown-content">
            <div className="border-box">
              <div
                className="option option-company"
                onClick={() => handleSwitchInteraction('b2b')}
                data-cy-handle="dropdown-option-company"
              >
                <div
                  className="business-label"
                  data-cy-handle="choose-company-as-customer"
                  onClick={() => setOpen(!open)}
                >
                  <div className="b2x_label b2b_label">
                    {t('b2x_dropdown_company', {
                      TAX: '-',
                      __transformers: {
                        TAX: (_, vars) => (
                          <div className="business-tax">{vars.TAX_label}</div>
                        )
                      }
                    })}
                  </div>

                  <div className="b2x_icon">
                    {!isB2cChannelActive && (
                      <CheckmarkIcon
                        className="icon"
                        data-cy-state="showb2bcheckmark"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="option option-private"
                onClick={() => handleSwitchInteraction('b2c')}
                data-cy-handle="dropdown-option-private"
              >
                <div
                  className="business-label"
                  data-cy-handle="choose-private-as-customer"
                  onClick={() => setOpen(!open)}
                >
                  <div className="b2x_label b2c_label">
                    {t('b2x_dropdown_private', {
                      TAX: '-',
                      __transformers: {
                        TAX: (_, vars) => (
                          <div className="business-tax">{vars.TAX_label}</div>
                        )
                      }
                    })}
                  </div>

                  <div className="b2x_icon">
                    {isB2cChannelActive && (
                      <CheckmarkIcon
                        className="icon"
                        data-cy-state="showb2ccheckmark"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isOpen: boolean; $isB2cChannelActive: boolean }>`
  display: block;
  > .dropdown {
    > .border-box {
      > .active-Selection {
        ${theme.ty('rc-s')}
      }
    }

    > .button-dropdown.border-box {
      border: 0.125rem solid ${theme.colors.primary};
      border-radius: 0.25rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      height: 2.5rem;
      width: 10.625rem;
      color: ${theme.colors.b0};
      font-size: ${theme.ty('r-base')};
      padding: ${theme.spacing('xs')};

      > .dropdown-arrow {
        width: ${theme.spacing('m')};
        height: ${theme.spacing('m')};
        margin-left: auto;
        align-self: end;
      }
    }

    > .dropdown-content {
      display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
      z-index: ${theme.zIndex.app_header_b2xdropdown_dropdown_content};
      width: 10.625rem;
      position: absolute;
      flex-direction: column;
    }

    > .dropdown-content::before {
      content: '';
      height: 0.625rem;
    }

    > .dropdown-content > .border-box {
      border: 0.125rem solid ${theme.colors.primary};
      border-radius: 0.25rem;
      background-color: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      > .option {
        padding: ${theme.spacing('xs')};
        > .business-label {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: baseline;
          cursor: pointer;

          > span {
            font-size: 1rem;
          }

          > .b2x_label {
            justify-self: start;
            .business-tax {
              font-size: ${theme.ty('r-s')};
              color: ${theme.colors.shade.b2};
            }
          }

          > .b2x_icon {
            align-self: baseline;
            justify-self: end;
          }

          > .b2c_label {
            ${(props) =>
              props.$isB2cChannelActive
                ? theme.ty('rc-s', 'bold')
                : theme.ty('rc-s')};
          }
          > .b2b_label {
            ${(props) =>
              !props.$isB2cChannelActive
                ? theme.ty('rc-s', 'bold')
                : theme.ty('rc-s')};
          }
        }
      }

      > .option-company {
        background-color: ${(props) =>
          !props.$isB2cChannelActive
            ? theme.colors.shade.primaryUltraBright
            : ''};
      }

      > .option-private {
        background-color: ${(props) =>
          props.$isB2cChannelActive
            ? theme.colors.shade.primaryUltraBright
            : ''};
      }
    }
  }
`
