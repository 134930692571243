import * as React from 'react'
import createAlgoliaHelper from 'utils/createAlgoliaHelper'
import config from 'config'
import slugify from 'utils/slugify'
import * as searchHistory from './search-history'

type Output = {
  data: Suggestion[]
  index: null | number
  removeSuggestion: (index: number) => void
  fetchInitialSuggestions: () => void
}

export type Suggestion = {
  label: string
  type: 'search' | 'category'
  historic: boolean
  urlPath: string
}

export function toSuggestion(urlPath: string, label = ''): Suggestion {
  return {
    label: label,
    urlPath: urlPath,
    historic: false,
    type: 'search'
  }
}

export default function useSuggestions(
  query: string,
  setQuery: (query: string) => void,
  focused: boolean
): Output {
  const [initSuggestions, setInitSuggestions] = React.useState<Suggestion[]>([])
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([])
  const [active, _setActive] = React.useState<null | number>(null)
  const activeSuggestion = React.useRef<string | null>(null)
  const setActive = React.useCallback(
    (n) => {
      _setActive(n)
      setQuery(suggestions[n].label)
      activeSuggestion.current = suggestions[n].label
    },
    [suggestions, setQuery]
  )

  // handle keyup and keydown
  React.useEffect(() => {
    if (!focused) return
    const listener = (e: KeyboardEvent) => {
      switch (e.keyCode) {
        case 40: {
          // down
          if (active === null) return setActive(0)
          if (active + 1 >= suggestions.length) return setActive(0)
          if (active >= 9) return setActive(0)
          return setActive(active + 1)
        }
        case 38: {
          // up
          if (active === null) return
          if (active === 0) return _setActive(null)
          return setActive(active - 1)
        }
        default:
          return
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [suggestions, active, setActive, focused])

  // fetch
  React.useEffect(() => {
    if (query.length === 0) return setSuggestions(initSuggestions)
    if (query.length < 3) return
    if (activeSuggestion.current === query) return
    fetchSuggestions(query).then((result) => {
      _setActive(null)
      // if(activeSuggestion.current !== query) return
      setSuggestions(result)
    })
  }, [query])

  const fetchInitialSuggestions = async () => {
    let updateSuggestions = initSuggestions
    if (updateSuggestions.length === 0) {
      updateSuggestions = await fetchProductSuggestions('')
    }
    updateSuggestions = updateSuggestions.filter((row) => !row.historic)
    const historic = await fetchHistoricSuggestions('')
    updateSuggestions.unshift(...historic)
    setInitSuggestions(updateSuggestions)
    if (suggestions.length === 0) setSuggestions(updateSuggestions)
  }

  const removeSuggestion = (index: number) => {
    const label = suggestions[index].label
    setSuggestions((l) => l.filter((_, i) => i !== index))
    setInitSuggestions((l) => l.filter((s) => s.label !== label))
    searchHistory.remove(label)
  }

  return {
    data: suggestions,
    index: active,
    removeSuggestion,
    fetchInitialSuggestions
  }
}

/** @firescoutMockFn app-Header.fetchSuggestions */
async function fetchSuggestions(query: string): Promise<Suggestion[]> {
  const [historic, products, categories] = await Promise.all([
    fetchHistoricSuggestions(query),
    fetchProductSuggestions(query),
    fetchCategorySuggestions(query)
  ])
  const used = new Set<string>()
  return [...historic, ...categories, ...products].filter((hit) => {
    if (used.has(hit.label.toLowerCase())) return false
    used.add(hit.label.toLowerCase())
    return true
  })
}

type SuggestionHit = {
  objectID: string
  query: string
}

async function fetchProductSuggestions(query: string): Promise<Suggestion[]> {
  const helper = await createAlgoliaHelper(config.index.productsSuggestion, {})
  helper.setQuery(query)
  const result: any = await helper.searchOnce({ hitsPerPage: 15 })
  const hits: SuggestionHit[] = result.content.hits
  return hits.map((hit) => ({
    label: hit.query,
    type: 'search',
    historic: false,
    urlPath: '/search/#q=' + hit.query
  }))
}

async function fetchCategorySuggestions(query: string): Promise<Suggestion[]> {
  const helper = await createAlgoliaHelper(config.index.categories, {
    attributesToRetrieve: ['label', 'path'],
    facets: ['active']
  })
  helper.addTag('base-category')
  helper.setQuery(query)
  helper.addFacetRefinement('active', 'true')
  const result: any = await helper.searchOnce({ hitsPerPage: 5 })
  const hits: any[] = result.content.hits
  return hits.map((hit) => ({
    label: hit.label,
    type: 'category',
    historic: false,
    urlPath: '/category/' + slugify(hit.path)
  }))
}

async function fetchHistoricSuggestions(query: string): Promise<Suggestion[]> {
  const hits = searchHistory.query(query).slice(0, 5)
  return hits.map((hit) => ({
    ...hit,
    historic: true
  }))
}
