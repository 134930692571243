import Component from './NewsletterIntentLayer'
import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'app-NewsletterIntentLayer': {
      b2b_benefits_headline_label: void
      b2b_benefit_text1: {
        MONEY_VALUE: any
        GET: any
      }
      b2b_benefit_text2: { CURRENT_NEWS_TIPS: any }
      b2b_benefit_text3: { EXCLUSIVE_OFFERS: any }
      b2b_benefits_submit_btn_label: void
      b2b_benefits_info_text: { PRIVACY: any }
      b2c_benefits_headline_label: void
      b2c_benefit_text1: {
        MONEY_VALUE: any
        GET: any
      }
      b2c_benefit_text2: { CURRENT_NEWS_TIPS: any }
      b2c_benefit_text3: { EXCLUSIVE_OFFERS: any }
      b2c_benefits_submit_btn_label: void
      b2c_benefits_info_text: { PRIVACY: any }
      mail_placeholder_text: void
      mail_error_text: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query app_NewsletterIntentLayer_translations {
      b2b_benefits_headline_label: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefits_headline_label"
      )
      b2b_benefit_text1: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefit_text1"
      )
      b2b_benefit_text2: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefit_text2"
      )
      b2b_benefit_text3: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefit_text3"
      )
      b2b_benefits_submit_btn_label: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefits_submit_btn_label"
      )
      b2b_benefits_info_text: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2b_benefits_info_text"
      )
      b2c_benefits_headline_label: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefits_headline_label"
      )
      b2c_benefit_text1: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefit_text1"
      )
      b2c_benefit_text2: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefit_text2"
      )
      b2c_benefit_text3: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefit_text3"
      )
      b2c_benefits_submit_btn_label: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefits_submit_btn_label"
      )
      b2c_benefits_info_text: snippet(
        group: "app-NewsletterIntentLayer"
        name: "b2c_benefits_info_text"
      )
      mail_placeholder_text: snippet(
        group: "app-NewsletterIntentLayer"
        name: "mail_placeholder_text"
      )
      mail_error_text: snippet(
        group: "app-NewsletterIntentLayer"
        name: "mail_error_text"
      )
    }
  `)
)
