import * as React from 'react'
import styled from 'styled-components'
import Search from 'assets/search.svg'
import theme from 'theme'
import useSuggestions, { Suggestion, toSuggestion } from './useSuggestions'
import Close from 'assets/menu-close.svg'
import * as events from './events'
import useTranslations from 'hooks/useTranslations'
import { navigate } from 'gatsby'
import * as searchHistory from './search-history'
import SuggestionItem from './Suggestion'
import { isValidContainerID, isValidSku } from './pdp-route-check'
import getPageInfo from 'utils/getPageInfo'

export default function SearchField() {
  const initialValue = React.useMemo(() => {
    if (typeof window === 'undefined') return ''
    const value = window.location.hash.match(/#q=([^&#]+)/)
    if (value) return decodeURIComponent(value[1])
    return ''
  }, [])
  const [search, setSearch] = React.useState(initialValue)
  const [focused, setFocused] = React.useState(false)
  const suggestions = useSuggestions(search, setSearch, focused)
  const $input = React.useRef<any>()
  const $searchButton = React.useRef<any>()
  const $clearSearchButton = React.useRef<any>()
  const t = useTranslations<'app-Header'>()

  React.useEffect(() => {
    if (!focused) return
    events.open()
  }, [focused])

  const onNavigateToPdp = () => {
    if (typeof window !== 'undefined') {
      events.viewSearchResultPDP(
        search,
        window.location.href,
        getPageInfo(window.location.pathname).pageType
      )
    }
  }
  const navigateToSearchRoute = (suggestion: Suggestion, track = true) => {
    events.search(search, suggestion.label || '')
    setTimeout(() => setFocused(false), 500)
    navigate(suggestion.urlPath)
    if (track) searchHistory.add(suggestion)
  }

  const handleSubmit = async (e?: any) => {
    e && e.preventDefault()
    if (e) {
      events.inputTypeEnter(search)
    } else {
      events.searchInputClick(search)
    }
    setFocused(false)
    const match = search.match(/([0-9]{6,8})/)
    if (suggestions.index !== null && suggestions.data[suggestions.index]) {
      navigateToSearchRoute(suggestions.data[suggestions.index])
    }
    // navigate to pdp with productNumber
    else if (match && match[1].length === 6) {
      const productNumber = match[1]
      const isValid = await isValidContainerID(productNumber)
      if (isValid) {
        onNavigateToPdp()
        navigate('/pdp/' + productNumber)
      } else navigateToSearchRoute(toSuggestion('/search/#q=' + search, search))
    }
    // navigate to pdp with sku
    else if (match && match[1].length === 8) {
      const sku = match[1]
      const isValid = await isValidSku(sku)
      if (isValid) {
        onNavigateToPdp()
        navigate('/pdp/sku-' + sku)
      } else navigateToSearchRoute(toSuggestion('/search/#q=' + search, search))
    } else {
      navigateToSearchRoute(toSuggestion('/search/#q=' + search, search))
    }
    return false
  }

  return (
    <>
      {/* eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state, @kaminrunde/firescout/onclick-needs-handle */}
      {focused && <ClickArea onClick={() => setFocused(false)} />}
      <Wrapper
        className="SearchField"
        $active={focused}
        onClick={() => setFocused(true)}
        data-cy-collection="SearchField"
      >
        <div className="overlay">
          <form autoComplete="off" onSubmit={handleSubmit} action="#">
            <input
              type="search"
              ref={$input}
              id="search-input"
              title={t.asText($input, 'header_search_input_a11y_title')}
              data-cy-handle="search-input"
              onFocus={suggestions.fetchInitialSuggestions}
              placeholder={t.asText($input, 'header_search_input_placeholder')}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            {search && (
              <button
                className="close"
                type="button"
                ref={$clearSearchButton}
                title={t.asText(
                  $clearSearchButton,
                  'header_clear_search_button_a11y_title'
                )}
                data-cy-handle="clear-search-button"
                data-cy-state="show-clear-button"
                onClick={() => setSearch('')}
              >
                <Close />
              </button>
            )}
            <button
              className="search"
              type="button"
              ref={$searchButton}
              title={t.asText($searchButton, 'header_search_button_a11y_title')}
              data-cy-handle="search-icon"
              onClick={() => handleSubmit()}
            >
              <Search />
            </button>
          </form>
        </div>

        {focused && (
          <div className="suggestions" data-cy-state="suggestions-visible">
            {suggestions.data.slice(0, 10).map((item, i) => (
              <SuggestionItem
                key={item.label}
                active={i === suggestions.index}
                item={item}
                onItemClick={() => navigateToSearchRoute(item)}
                onRemoveClick={() => suggestions.removeSuggestion(i)}
                onSelectItem={() => setSearch(item.label)}
              />
            ))}
          </div>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ $active: boolean }>`
  width: 100%;
  height: 40px;
  position: relative;

  > .overlay {
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    margin-right: 0;
    height: 60px;
    background: ${(props) => (props.$active ? '#FAF5EC' : 'transparent')};
    border: ${(props) =>
      props.$active ? '1px solid ' + theme.colors.primary : 'none'};
  }

  > .overlay > form {
    display: flex;
    background: ${(props) => (props.$active ? 'white' : '#FAF5EC')};

    > .close {
      position: absolute;
      right: 3.125rem;
    }

    > .search {
      position: absolute;
      right: 0.625rem;
      height: 40px;
      width: 40px;
      ${theme.flex('center', 'center')};
      background: ${theme.colors.primary};
      border-radius: 0 5px 5px 0;

      svg {
        g {
          fill: ${theme.colors.white};
        }
      }
    }

    > .close {
      height: 40px;
      padding: 0 ${theme.spacing('s')};
      ${theme.flex('center', 'center')}

      svg {
        width: 10px;
        g {
          stroke: ${theme.colors.b0};
        }
      }
    }

    > input {
      flex: 1;
      height: 40px;
      font-size: 16px;
      box-sizing: border-box;
      border: none;
      background: transparent;
      padding-left: ${theme.spacing('s')};
      &:focus {
        border: none;
        outline: none;
      }
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }

  > .suggestions {
    position: absolute;
    left: -10px;
    top: 100%;
    right: 0;
    background: #faf5ec;
    z-index: ${theme.zIndex.app_header_search_field};
    border: 1px solid ${theme.colors.primary};
    border-top: none;
    padding: 10px 0;
  }
`

const ClickArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`
