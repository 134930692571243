import { dispatchEvent } from 'redux-ruleset'
import * as at from 'modules/ui/const'
import checkUrl from './checkUrl'

export default function registerLeavePage() {
  if (typeof document !== 'undefined') {
    const html = document.querySelector('html')

    const dispatch = () => {
      const isIntentLayerExecuted =
        localStorage.getItem('intentLayerExecuted') === 'true'
      const isBlacklisted = checkUrl(window.location.pathname)
      if (!isIntentLayerExecuted && !isBlacklisted)
        dispatchEvent({
          type: at.SET_INTENT_VISIBLE,
          payload: { visible: true, source: 'exit_intent' }
        })
    }

    if (html && localStorage.getItem('intentLayerExecuted') !== 'true') {
      html.removeEventListener('mouseleave', dispatch, false)
      html.addEventListener('mouseleave', dispatch)
    }
  }
}
