import { addRule } from 'redux-ruleset'
import { OPEN, CLOSE } from 'theme/templates/PDP/FilterDrawer/events'
import { SET_FILTER_VALUE } from 'modules/productDetail/const'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'pdp/OPEN_FILTER_DRAWER',
  target: OPEN,
  output: '#dl-event',
  consequence: () => {
    dlPush({
      event: 'genericEvent',
      eventname: 'off_canvas',
      action: 'open'
    })
  }
})

addRule({
  id: 'pdp/CLOSE_FILTER_DRAWER',
  target: CLOSE,
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'off_canvas',
      action: 'close',
      close_method: action.payload
    })
  }
})

addRule({
  id: 'pdp/SET_FILTER_VALUE',
  target: SET_FILTER_VALUE,
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'off_canvas',
      action: 'select',
      selected_category: action.meta.filterKey,
      selected_value: action.payload
    })
  }
})
