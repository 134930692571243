import { isChannelSet } from '../../utils/channel'
import getCookieByName from '../../utils/getCookieByName'
import * as t from './types'

declare global {
  interface Window {
    isStorybook?: boolean
  }
}

if (
  typeof window !== 'undefined' &&
  location.hostname.includes('netlify.app')
) {
  document.cookie =
    'OptanonConsent=isGpcEnabled=0&datestamp=Wed+May+10+2023+10%3A51%3A57+GMT%2B0200+(Mitteleurop%C3%A4ische+Sommerzeit)&version=6.30.0&isIABGlobal=false&hosts=&consentId=fe5b328f-da46-4da2-a2e8-4dc819816177&interactionCount=1&landingPath=NotLandingPage&groups=C0001%3A1%2CC0002%3A1%2CC0003%3A1%2CC0004:1&geolocation=%3B&AwaitingReconsent=false;'
  document.cookie = 'OptanonAlertBoxClosed=2025-04-27T07:55:56.426Z;'
}

let _dyid_server: string | null = null
let _dyjsession: string | null = null
let _dy_cs_gcg: string | null = null
let returningUser: 'New' | 'Returning' = 'New'
if (typeof window !== 'undefined') {
  _dyid_server = localStorage.getItem('_dyid_server') || null
  _dyjsession = localStorage.getItem('_dyjsession') || null
  _dy_cs_gcg = localStorage.getItem('_dy_cs_gcg') || null
  returningUser = _dyid_server && _dyjsession ? 'Returning' : 'New'
}

let dyContext: t.DyContext = {
  _dyid_server: _dyid_server,
  _dyjsession: _dyjsession,
  _dy_cs_gcg: _dy_cs_gcg,
  consentStatus: 'UNKNOWN',
  pageViewSend: false,
  isChannelSet: isChannelSet(),
  isRetuningUser: returningUser
}

const buffer = new Set<{
  cb: () => void
  validation: (ctx: t.DyContext) => boolean
}>()

function flushBuffer() {
  for (const row of buffer) {
    if (row.validation(dyContext)) {
      row.cb()
      buffer.delete(row)
    }
  }
}

const manager = {
  /** only used for rejecting consent. DO NOT USE */
  _unsaveGetContext: () => dyContext,
  bufferClear: () => buffer.clear(),
  isControlGroup: () => dyContext._dy_cs_gcg?.toLowerCase() === 'control',
  updateContext: (cb: (ctx: t.DyContext) => Partial<t.DyContext>) => {
    const updated = {
      ...dyContext,
      ...cb(dyContext)
    }

    /** save tokens in local-storage */
    if (
      updated._dyid_server !== dyContext._dyid_server ||
      updated._dyjsession !== dyContext._dyjsession ||
      updated._dy_cs_gcg !== dyContext._dy_cs_gcg
    ) {
      if (updated._dyid_server)
        localStorage.setItem('_dyid_server', updated._dyid_server)
      if (updated._dyjsession)
        localStorage.setItem('_dyjsession', updated._dyjsession)
      if (updated._dy_cs_gcg)
        localStorage.setItem('_dy_cs_gcg', updated._dy_cs_gcg)
    }

    dyContext = updated
    flushBuffer()
  },
  getContext: async <T>(
    cb: (ctx: t.DyContext) => T,
    validation: (ctx: t.DyContext) => any
  ): Promise<T> => {
    if (window.isStorybook) return cb(dyContext)
    if (validation(dyContext)) return cb(dyContext)
    return new Promise((resolve) => {
      buffer.add({
        cb: () => resolve(cb(dyContext)),
        validation: validation
      })
    })
  },
  getConsentStatus: getConsentStatus
}

export function getConsentStatus(): t.ConsentStatus {
  if (getCookieByName('OptanonAlertBoxClosed') === undefined) return 'UNKNOWN'
  return getCookieByName('OptanonConsent')?.includes('C0004:1')
    ? 'ACCEPTED'
    : 'REJECTED'
}

/**
 * wait for consent status. we request every 100ms until we know for shure
 */
if (typeof window !== 'undefined') {
  const status = getConsentStatus()
  if (status !== 'UNKNOWN') dyContext.consentStatus = status
  else {
    const iId = setInterval(() => {
      const status = getConsentStatus()
      if (status === 'UNKNOWN') return
      manager.updateContext(() => ({ consentStatus: status }))
      clearInterval(iId)
    }, 100)
  }
}

declare global {
  interface Window {
    __module_navigation_location_pre_change: (
      location: Location,
      prev: Location | null
    ) => void
  }
}

/**
 * disable all events that are not pageview events
 */
if (typeof window !== 'undefined') {
  window.__module_navigation_location_pre_change = () => {
    dyContext.pageViewSend = false
  }
}

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.manager = manager
}

export default manager
