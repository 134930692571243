const poplarSkuMap = [
  '30099948',
  '30003038',
  '30099897',
  '10092500',
  '10091691',
  '10070532',
  '30099983',
  '30041389',
  '10095062',
  '10094680',
  '10094293',
  '10008268',
  '30040179',
  '30040080',
  '10080359',
  '30101889',
  '30040109',
  '10094305',
  '10094779',
  '30003095',
  '30002036',
  '30002842',
  '10089906',
  '10097696',
  '10080351',
  '30024824',
  '10092323',
  '10008108',
  '30046949',
  '30046758',
  '30023915',
  '30003036',
  '30043691',
  '10091426',
  '10095275',
  '10094448',
  '30038981',
  '10089586',
  '30002031',
  '30014352',
  '10094106',
  '30107951',
  '10092846',
  '10095061',
  '30107480',
  '10091197',
  '30002414',
  '10089444',
  '10094374',
  '30044714',
  '10097700',
  '30040083',
  '30025555',
  '30045440',
  '10093998',
  '10008270',
  '10094356',
  '30051260',
  '30068759',
  '10091110',
  '30107482',
  '10089653',
  '30038978',
  '30040097',
  '10070533',
  '30002415',
  '10008119',
  '30045124',
  '10095301',
  '10094373',
  '30003527',
  '30099947',
  '30025554',
  '10089555',
  '30024597',
  '30045122',
  '10097702',
  '10090295',
  '30022742',
  '30053524',
  '30101888',
  '30039960',
  '10094294',
  '30014356',
  '30046757',
  '30022743',
  '30044295',
  '30003037',
  '30045123',
  '30125314',
  '30127691',
  '30107541',
  '30125588',
  '30046953',
  '10094886',
  '10089585',
  '30046954',
  '10089903',
  '10094883',
  '10008118',
  '10093368',
  '30002404',
  '10008116',
  '10094882',
  '10089587',
  '10093958',
  '10008486',
  '30053872',
  '30066978',
  '10008107',
  '10089908',
  '10093959',
  '10089589',
  '30053516',
  '30040090',
  '10094885',
  '10095276',
  '10008105',
  '10089392',
  '10095048',
  '30024596',
  '30099898',
  '10008446',
  '10080717',
  '10095059',
  '30099966',
  '30054570',
  '30003039',
  '10080547',
  '10093885',
  '30038982',
  '10070530',
  '30014355',
  '30038983',
  '10094306',
  '30102281',
  '30039963',
  '10094449',
  '10094884'
]

export default function isPoplarSku(sku) {
  return poplarSkuMap.indexOf(sku) !== -1
}
