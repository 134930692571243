import Component from './ProductSlider'
import { graphql, useStaticQuery } from 'gatsby'
import { withTranslations } from 'hooks/useTranslations'
export { ProductSliderLoading } from './ProductSlider'

declare global {
  interface Snippets {
    'molecules-ProductSlider': {
      molecules_productslider_a11y_prev_button: void
      molecules_productslider_a11y_next_button: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query molecules_ProductSlider_translations {
      molecules_productslider_a11y_prev_button: snippet(
        group: "molecules-ProductSlider"
        name: "molecules_productslider_a11y_prev_button"
      )
      molecules_productslider_a11y_next_button: snippet(
        group: "molecules-ProductSlider"
        name: "molecules_productslider_a11y_next_button"
      )
    }
  `)
)
