import * as React from 'react'
import getPageInfo from 'utils/getPageInfo'

type PageType = ReturnType<typeof getPageInfo>['pageType']

export type PageContext =
  | null
  | {
      type: Extract<PageType, 'Category'>
      dyPath: string
      ready: boolean
      filteredCategories: {
        urlPath: string
        title: string
      }[]
    }
  | {
      type: Extract<PageType, 'Series'>
      path: string
      ready: boolean
    }
  | {
      type: Extract<PageType, 'Product'>
      ready: boolean
      sku: string
    }
  | {
      type: Extract<PageType, 'Cart'>
      ready: boolean
      skus: string[]
    }
  | {
      type: Extract<PageType, 'FlyoutCart'>
      ready: boolean
      skus: string[]
    }
  | {
      type: 'Homepage'
      ready: boolean
    }
  | {
      type: Extract<PageType, 'Other'>
      path?: string
      ready: boolean
    }

const Context = React.createContext<PageContext>(null)

export default function usePageContext() {
  return React.useContext(Context)
}

export function PageContextProvider(props: {
  children: any
  pageContext: PageContext
}) {
  return (
    <Context.Provider value={props.pageContext}>
      {props.children}
    </Context.Provider>
  )
}
