import { addRule } from 'redux-ruleset'
import {
  INCREMENT_LOCATION_CHANGE_COUNTER,
  SET_INTENT_VISIBLE,
  REGISTER_NEWSLETTER_NL
} from 'modules/ui/const'
import { incrementLocationChangeCounter } from 'modules/ui/actions'
import store from 'store'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import { push } from './tracking/datalayer'
import registerLeavePage from 'theme/app/NewsletterIntentLayer/leavePage'
import setupInactivityDetection from 'theme/app/NewsletterIntentLayer/setupInactivityDetection'
import checkUrl from 'theme/app/NewsletterIntentLayer/checkUrl'

registerLeavePage()
setupInactivityDetection(60)

addRule({
  id: 'feature/INCREMENT_PAGE_COUNTER',
  target: LOCATION_CHANGED,
  output: INCREMENT_LOCATION_CHANGE_COUNTER,
  condition: (action, { getState }) => {
    const isBlacklisted = checkUrl(action.payload.pathname)
    const state = getState()
    return !state.ui.registerNewsletterNL && !isBlacklisted
  },
  consequence: (_, { getState }: any) => {
    const state = getState()
    const counter = state.ui.locationChangeCounter
    if (counter < 2 && !localStorage.getItem('intentLayerFirstpage')) {
      const randomId = Math.random().toString()
      localStorage.setItem('intentLayerFirstpage', randomId)
      sessionStorage.setItem('intentLayerFirstpage', randomId)
    }
    store.dispatch({
      type: INCREMENT_LOCATION_CHANGE_COUNTER
    })
  }
})
// only dl-event is used in the consequence
addRule({
  id: 'feature/SET_INTENT_VISIBLE',
  target: SET_INTENT_VISIBLE,
  output: '#dl-event',
  addOnce: true,
  condition: (_, { getState }) => {
    const state = getState()
    const isExecuted =
      localStorage.getItem('intentLayerExecuted') === 'true' || false

    const isFirstPage =
      sessionStorage.getItem('intentLayerFirstpage') ===
      localStorage.getItem('intentLayerFirstpage')

    return (
      !state.ui.registerNewsletterNL &&
      !isExecuted &&
      isFirstPage &&
      state.ui.locationChangeCounter > 2
    )
  },
  consequence: (action) => {
    store.dispatch({ type: SET_INTENT_VISIBLE, payload: action.payload })
    localStorage.setItem('intentLayerExecuted', 'true')
    push({
      event: 'genericEvent',
      eventname: 'newsletter_layer',
      action: 'open',
      'trigger-type': action.payload.source
    })
  }
})

addRule({
  id: 'feature/REGISTER_NEWSLETTER_NL',
  target: REGISTER_NEWSLETTER_NL,
  output: '#dl-event',

  addUntil: function* (next, { context }) {
    yield next([SET_INTENT_VISIBLE], (action) => {
      context.set('triggerType', action.payload.source)
    })
    return 'RECREATE_RULE'
  },

  consequence: (_, { context }) => {
    push({
      event: 'genericEvent',
      eventname: 'newsletter_layer',
      action: 'subscribed',
      'trigger-type': context.get('triggerType') // mögliche Werte "5_pageviews","inactivity","exit_intent"
    })
    store.dispatch({
      type: SET_INTENT_VISIBLE,
      payload: { visible: false, source: context.get('triggerType') }
    })
  }
})

addRule({
  id: 'feature/INVALID_MAIL',
  target: 'NewsletterIntentContent/INVALID_MAIL',
  output: '#dl-event',
  consequence: () => {
    push({
      event: 'genericEvent',
      eventname: 'newsletter_layer',
      action: 'error',
      message: 'InvalidMail'
    })
  }
})
