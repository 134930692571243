import { addRule } from 'redux-ruleset'
import * as filterEvents from 'theme/templates/PDP/FilterDrawer/Filter/events'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'dl/SIZE_CHART_LINK_CLICK',
  target: [filterEvents.SIZE_CHART_LINK_CLICK],
  output: '#dl-event',
  consequence: () => {
    dlPush({
      event: 'genericEvent',
      eventname: 'sizing_guide_click'
    })
  }
})

addRule({
  id: 'dl/SIZE_CHART_LINK_VISIBLE',
  target: [filterEvents.SIZE_CHART_LINK_VISIBLE],
  output: '#dl-event',
  consequence: () => {
    dlPush({
      event: 'genericEvent',
      eventname: 'sizing_guide_view'
    })
  }
})
