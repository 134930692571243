import store from '../../store'
import * as a from './actions'

declare global {
  interface Window {
    __module_navigation_location_change: (
      location: Location,
      prev: Location | null
    ) => void
  }
}

if (typeof window !== 'undefined') {
  window.__module_navigation_location_change = function (
    location,
    prevLocation
  ) {
    const action = a.locationChanged(location, prevLocation)
    store.dispatch(action)
  }
}
