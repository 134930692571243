import useInView from 'hooks/useInView'
import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'

type Props = {
  iconSource?: string
}

export default function PromotionIcon(props: Props) {
  const [ref, refInVp] = useInView<any>(500, true)

  if (!props.iconSource) return null

  return (
    <Wrapper className="PromotionIcon" ref={ref}>
      {/*  eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state */}
      {refInVp && <img src={props.iconSource} alt="icon" />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: none;
  @media (min-width: ${theme.ms.MD}px) {
    display: block;
  }
  > img {
    width: 12rem;
  }
`
