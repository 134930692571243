import * as React from 'react'
import styled from 'styled-components'
import Warning from 'assets/warning.svg'
import Info from 'assets/info-icon.svg'
import Error from 'assets/error.svg'
import Close from 'assets/menu-close.svg'
import { useSnackbar } from 'modules/Snackbar'
import theme from 'theme'
import Container from 'theme/atoms/Container'
import useTranslations from 'hooks/useTranslations'
import { MessageKey } from 'modules/Snackbar/types'

export default function Snackbar() {
  const messages = useSnackbar()
  const t = useTranslations<'app-Snackbar'>()

  if (messages.data.length === 0) return null

  return (
    <Container>
      <Wrapper data-cy-ctx="app/Snackbar" className="Snackbar">
        {messages.data.map((msg, i) => {
          if (msg.type === 'Stock') return false
          return (
            <Item key={i} type={msg.type}>
              <div className="icon">
                {msg.type === 'Info' && <Info data-cy-state="info" />}
                {msg.type === 'Warning' && <Warning data-cy-state="warning" />}
                {msg.type === 'Error' && <Error data-cy-state="error" />}
              </div>
              <div className="label">
                {msg.customMessage && msg.customMessage}
                {!msg.customMessage && t(msg.messageKey as MessageKey)}
              </div>
              <div
                className="icon close"
                onClick={() => messages.removeMessage(msg)}
                data-cy-handle="close-icon"
              >
                <Close />
              </div>
            </Item>
          )
        })}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  z-index: ${theme.zIndex.app_snackbar};
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  padding: ${theme.spacing('xs')};

  @media (min-width: ${theme.ms.MD}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 450px;
    top: 1.25rem;
    align-items: center;
    left: inherit;
    ${theme.ty('r-base')}
    padding: ${theme.spacing('m')};
  }
  @media (min-width: ${theme.ms.XL}px) {
    right: calc(50vw - 680px);
  }
`

const Item = styled.div<{ type: 'Info' | 'Error' | 'Warning' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${theme.ms.MD}px) {
    min-width: 400px;
  }

  border: 2px solid ${theme.colors['info']};

  background: ${theme.colors['info-100']};
  ${(props) => {
    switch (props.type) {
      case 'Error':
        return `
    border-color: ${theme.colors['error']};;
    background: ${theme.colors['error-100']};`
      case 'Warning':
        return `
    
    border-color:${theme.colors['warning-100']};
    background: ${theme.colors['warning-100']};`
      default:
        return ``
    }
  }}

  padding: ${theme.spacing('xs')};
  ${theme.ty('r-s')}
  > .icon {
    width: 50px;
    padding-left: 20px;
    > svg {
      height: 1.5rem;
      width: 1.5rem;

      g {
        circle {
          fill: ${(p) =>
            p.type === 'Info'
              ? theme.colors.info
              : p.type === 'Error'
                ? theme.colors.error
                : theme.colors.warning};
        }
      }

      @media (min-width: ${theme.ms.MD}px) {
        height: 1.5rem;
        width: 1.75rem;
      }
    }
    &.close > svg {
      height: 1rem;
      width: 1rem;
    }
  }

  > .label {
    margin-left: ${theme.spacing('xs')};
    ${theme.ty('r-base')}
    flex: 1;
    > span {
      margin-right: ${theme.spacing('xs')};
      a {
        ${theme.ty('rc-base', 'bold')}
        text-decoration:underline;
      }
    }
  }
`
