import * as React from 'react'
import styled from 'styled-components'
import Container from 'theme/atoms/Container'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'
import PrepaymentIcon from 'assets/paymentIcons/prepayment.svg'
import InvoiceIcon from 'assets/paymentIcons/invoice.svg'
import CashOnDeliveryIcon from 'assets/paymentIcons/cash-on-delivery.svg'
import PaypalIcon from 'assets/paymentIcons/paypal-footer.svg'
import VisaIcon from 'assets/paymentIcons/visa.svg'
import MastercardIcon from 'assets/paymentIcons/mastercard.svg'
import IdealIcon from 'assets/paymentIcons/ideal.svg'
import SepaIcon from 'assets/paymentIcons/sepa.svg'
import DinersClubIcon from 'assets/paymentIcons/diners-club.svg'
import DiscoverIcon from 'assets/paymentIcons/discover.svg'
import ApplePayIcon from 'assets/paymentIcons/apple-pay.svg'
import GooglePayIcon from 'assets/paymentIcons/google-pay.svg'
import TwintIcon from 'assets/paymentIcons/twint.svg'

type Props = {
  headline: boolean
  iconList: string[]
  ['data-cy-state']: string
}

export default function PaymentIcons(props: Props) {
  const t = useTranslations<'app-Footer'>()

  const paymentsDict = {
    ['invoice']: (
      <div key="invoice-icon" className="payment-icon with-text">
        <InvoiceIcon />
        <div className="icon-text">{t('payment_invoice')}</div>
      </div>
    ),
    ['prepayment']: (
      <div key="prepayment-icon" className="payment-icon with-text">
        <PrepaymentIcon />
        <div className="icon-text">{t('payment_prepayment')}</div>
      </div>
    ),
    ['cash-on-delivery']: (
      <div key="cash-on-delivery-icon" className="payment-icon with-text">
        <CashOnDeliveryIcon />
        <div className="icon-text">{t('payment_cash_on_delivery')}</div>
      </div>
    ),
    ['paypal']: (
      <div key="paypal-icon" className="payment-icon">
        <PaypalIcon />
      </div>
    ),
    ['paypal-partial-payment']: (
      <div key="paypal-partial-payment-icon" className="payment-icon with-text">
        <PaypalIcon />
        <div className="icon-text paypal">
          {t('payment_paypal_partial_payment')}
        </div>
      </div>
    ),
    ['paypal-invoice']: (
      <div key="invoice-paypal-invoice" className="payment-icon with-text">
        <PaypalIcon />
        <div className="icon-text paypal">{t('payment_paypal_invoice')}</div>
      </div>
    ),
    ['visa']: (
      <div key="visa-icon" className="payment-icon">
        <VisaIcon />
      </div>
    ),
    ['mastercard']: (
      <div key="mastercard-icon" className="payment-icon">
        <MastercardIcon />
      </div>
    ),
    ['ideal']: (
      <div key="ideal-icon" className="payment-icon">
        <IdealIcon />
      </div>
    ),
    ['sepa']: (
      <div key="sepa-icon" className="payment-icon">
        <SepaIcon />
      </div>
    ),
    ['diners-club']: (
      <div key="diners-club-icon" className="payment-icon">
        <DinersClubIcon />
      </div>
    ),
    ['discover']: (
      <div key="discover-icon" className="payment-icon">
        <DiscoverIcon />
      </div>
    ),
    ['apple-pay']: (
      <div key="apple-pay-icon" className="payment-icon">
        <ApplePayIcon />
      </div>
    ),
    ['google-pay']: (
      <div key="google-pay-icon" className="payment-icon">
        <GooglePayIcon />
      </div>
    ),
    ['twint']: (
      <div key="twint-icon" className="payment-icon">
        <TwintIcon />
      </div>
    )
  }

  return (
    <Wrapper
      data-cy-ctx="molecules/PaymentIcons"
      data-cy-state={props['data-cy-state']}
      $bg={props.headline}
      className="PaymentIcons"
    >
      {props.headline && (
        <div className="headline" data-cy-state="show-headline">
          {t('footer_accepted_payment')}
        </div>
      )}
      <Container className="acceptedPaymentsContainer">
        {props.iconList.map((key) => {
          return paymentsDict[key]
        })}
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div<{ $bg?: boolean }>`
  ${(props) =>
    props.$bg &&
    `
  background: ${theme.colors.shade.b6};
  `};

  > .headline {
    display: flex;
    justify-content: center;
    ${theme._ty([22, 0, 28], theme.fontSpecial, '700')}
  }

  > .acceptedPaymentsContainer {
    flex-wrap: wrap;
    padding: 0px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${theme.spacing('ml')};
    > .payment-icon {
      width: 90px;
      height: 90px;
      margin: 0 ${theme.spacing('xs')};
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      overflow: hidden;

      &.with-text {
        margin-top: -${theme.spacing('s')};
        > .icon-text {
          text-transform: uppercase;
          color: ${theme.colors.shade.b2};
          ${theme.ty('r-xs', '700')}
          margin-top: -${theme.spacing('m')};
          &.paypal {
            color: #253b80;
          }
        }
      }
      > svg {
        width: 100%;
      }
    }
  }
`
