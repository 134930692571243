import { addRule } from 'redux-ruleset'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import getPageInfo from 'utils/getPageInfo'
import config from 'config'
import { getChannel } from 'utils/channel'

declare global {
  interface Window {
    _tfa: any[]
  }
}

addRule({
  id: 'taboola/PAGELOAD',
  target: LOCATION_CHANGED,
  output: '#dl-event',
  condition: () => config.modules.taboola !== undefined,
  consequence: (action) => {
    const path = action.payload.pathname
    const pageInfo = getPageInfo(path)

    if (typeof window !== 'undefined') {
      window._tfa = window._tfa || []
      window._tfa.push({
        notify: 'event',
        name: 'page_view',
        id: config.modules.taboola?.id,
        'item-url': pageInfo.path
      })
    }
  }
})

addRule({
  id: 'taboola/ADD_TO_CART',
  target: ['CART_ADD_ITEM', 'CART_UPDATE_ITEM'],
  output: '#dl-event',
  condition: () => config.modules.taboola !== undefined,
  consequence: (action) => {
    const channel = getChannel()

    const lastAddedItem = action.cart.items.find(
      (x) => x.cartItemId === action.cartItemId
    )

    const revenue =
      lastAddedItem?.quantity ||
      0 *
        (channel === 'b2b'
          ? lastAddedItem?.prices.packPriceGross || 0
          : lastAddedItem?.prices.packPriceNet || 0)

    if (typeof window !== 'undefined') {
      window._tfa = window._tfa || []
      window._tfa.push({
        notify: 'event',
        name: 'add_to_cart',
        id: config.modules.taboola?.id,
        currency: config.i18n.currency_ISO,
        revenue: revenue
      })
    }
  }
})

addRule({
  id: 'taboola/PURCHASE',
  target: 'checkout/PURCHASE',
  output: '#dl-event',
  condition: () => config.modules.taboola !== undefined,
  consequence: (action) => {
    let quantity = 0

    action.meta.order.lineItems.forEach((item) => {
      quantity += item.quantity
    })

    if (typeof window !== 'undefined') {
      window._tfa = window._tfa || []
      window._tfa.push({
        notify: 'event',
        name: 'make_purchase',
        id: config.modules.taboola?.id,
        revenue: action.meta.order.totalPrice,
        currency: config.i18n.currency_ISO,
        orderid: action.meta.order.id,
        quantity: quantity
      })
    }
  }
})
