import * as React from 'react'
import * as t from '../types'
import { dispatchEvent } from 'redux-ruleset'

export default function useObserver() {
  const observer = React.useRef({
    listeners: [] as ((evt: t.Event) => void)[],
    sendEvent: (evt: t.Event) => {
      dispatchEvent(evt)
      for (const fn of observer.current.listeners) fn(evt)
    },
    onEvent: (cb: (evt: t.Event) => void) => {
      observer.current.listeners.push(cb)
      return () => {
        observer.current.listeners = observer.current.listeners.filter(
          (fn) => fn !== cb
        )
      }
    }
  })

  return observer
}
