import * as t from './types'
import * as at from './const'
import * as actions from './actions'

export type State = {
  messages: t.Message[]
}

export const defaultState = {
  messages: []
}
export default function reducer(
  state: State = defaultState,
  action: actions.Action
): State {
  switch (action.type) {
    case at.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }
    case at.REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((i) => i !== action.payload)
      }
    case at.REMOVE_ALL:
      return {
        ...state,
        messages: []
      }
    default:
      return state
  }
}
