import * as a from '../actions'
import { State } from '../reducer'
import useConnect, { Config } from 'hooks/useConnect'

export type Input = {}

export type Output = {
  open: boolean
  setOpen: typeof a.setPDPSizeTableOpen
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/usePDPSizeTableOpen',
  createCacheKey: () => '',
  mapState: (state) => ({
    open: state.pdpSizeTableOpen
  }),
  mapDispatch: {
    setOpen: a.setPDPSizeTableOpen
  }
}

export default function usePDPSizeTableOpen(): Output {
  const input = {}
  const hook = useConnect<Input, Output, State, any>(input, config)
  return hook
}
