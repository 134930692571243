import * as React from 'react'
import useObserver from '../useAccount/_useObserver'
import lusiniFunctionsFetch from 'utils/lusiniFunctionsFetch'
import config from 'config'

export type AbandonedCartResponseMode =
  | 'NO_LOCATION'
  | 'LOCATION_NOT_VALID'
  | 'NO_MAIL'
  | 'MAIL_NOT_VALID'
  | 'MAIL_NOT_FOUND'
  | 'UNHANDLED_ERROR'
  | 'ERROR_500'

export type AbandonedCartCartUpdateResponse = {
  status: 'success' | 'failure'
  mode: 'ABANDONED_CART_UPDATED' | AbandonedCartResponseMode
}

export type AbandonedCartOrderSuccessResponse = {
  status: 'success' | 'failure'
  mode: 'ABANDONED_CART_ORDER_SUCCESS' | AbandonedCartResponseMode
}

const abandonedCartLocalStorageKey = 'abandonedCartDate'

/**
 * useAbandonedCart hook is used for data transfer to Emarsys via API for abandonedCart
 * @param observer: is used to listen to events from account
 */
export default function useAbandonedCart(
  observer: ReturnType<typeof useObserver>
) {
  React.useEffect(() => {
    let loggedIn = false
    let email = ''

    return observer.current.onEvent((evt) => {
      switch (evt.type) {
        /** Set the loggedIn and email variable on initial page load */
        case 'SET_INITIAL_CONTEXT':
          loggedIn = evt.context.loggedIn
          email = evt.context.email || ''
          break
        /** if cart is not empty and the date is different as today the emarsys "cartUpate" entry will be updated */
        case 'REGISTER':
        case 'LOGIN':
          loggedIn = true
          email = evt.context.email
          evt.cart.items.length > 0 &&
            isDifferentDateThanToday() &&
            cartUpdate(email)
          break
        /** clears localStorage entry (e.g. login to another customer account) */
        case 'LOGOUT':
          loggedIn = false
          email = ''
          localStorage.removeItem(abandonedCartLocalStorageKey)
          break
        case 'CART_ADD_ITEM':
          loggedIn && isDifferentDateThanToday() && cartUpdate(email)
          break
        /** if cart is empty the localStorage will be cleared and removes the emarsys "cartUpate" entry for cartUpdate
         * if cart is not empty and the date is different as today the emarsys "cartUpate" entry will be updated
         */
        case 'CART_REMOVE_ITEM':
          if (loggedIn && evt.cart.items.length === 0) {
            localStorage.removeItem(abandonedCartLocalStorageKey)
            cartUpdate(email, true)
          } else if (
            loggedIn &&
            evt.cart.items.length > 0 &&
            isDifferentDateThanToday()
          )
            cartUpdate(email)
          break
        /** if order succeeded the emarsys entry "orderSuccess" will be updated */
        case 'CREATE_ORDER':
          loggedIn && orderSuccess(email)
          break
      }
    })
  }, [])
}

export const cartUpdate = async (email: string, clearDate?: boolean) => {
  return await lusiniFunctionsFetch<AbandonedCartCartUpdateResponse>(
    'https://functions.lusini.com/.netlify/functions/abandonedCart-cartUpdate',
    {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        location: config.locale,
        clearDate: !!clearDate
      })
    }
  )
    .then((res) => {
      const status: AbandonedCartCartUpdateResponse['status'] = res.status
      if (status !== 'success') {
        localStorage.removeItem(abandonedCartLocalStorageKey)
        // eslint-disable-next-line no-console
        console.error('Abandoned_Cart_Error: ' + res)
      }
    })
    .catch((err) => {
      localStorage.removeItem(abandonedCartLocalStorageKey)
      throw new Error('Abandoned_Cart_Error: ' + err)
    })
}

export const orderSuccess = async (email: string) => {
  return await lusiniFunctionsFetch<AbandonedCartOrderSuccessResponse>(
    'https://functions.lusini.com/.netlify/functions/abandonedCart-orderSuccess',
    {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        location: config.locale
      })
    }
  )
    .then((res) => {
      const status: AbandonedCartOrderSuccessResponse['status'] = res.status
      if (status !== 'success') {
        // eslint-disable-next-line no-console
        console.error('Abandoned_Cart_Error: ' + res)
      }
    })
    .catch((err) => {
      throw new Error('Abandoned_Cart_Error: ' + err)
    })
}

/**
 * Checks if the abondedCart date in localStorage is different as today
 * then return true and update the date in localStorage
 * @params none
 * @returns boolean
 */
const isDifferentDateThanToday = () => {
  const date = new Date().toISOString().split('T')[0]
  const abandonedCartDate =
    localStorage.getItem(abandonedCartLocalStorageKey) ?? ''

  if (abandonedCartDate !== date) {
    localStorage.setItem(abandonedCartLocalStorageKey, date)
    return true
  }
  return false
}
