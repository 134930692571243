import useInView from 'hooks/useInView'
import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'

export default function BackgroundImages() {
  const [ref, refInVp] = useInView<any>(500, true)

  if (!refInVp) return <div ref={ref} />

  return (
    <Wrapper className="BackgroundImages">
      <img
        className="desktop-dish dish1"
        src="https://res.cloudinary.com/lusini/image/upload/v1652774815/application/newsletter/dish1.png"
        alt="dish1"
      />
      <img
        className="desktop-dish dish2"
        src="https://res.cloudinary.com/lusini/image/upload/v1652774816/application/newsletter/dish2.png"
        alt="dish2"
      />
      <img
        className="desktop-dish dish3"
        src="https://res.cloudinary.com/lusini/image/upload/v1652774868/application/newsletter/dish3.png"
        alt="dish3"
      />
      <img
        className="mobile-dish dish2-mobile"
        src="https://res.cloudinary.com/lusini/image/upload/v1652775112/application/newsletter/dish2-mobile.png"
        alt="dish2-mobile"
      />
      <img
        className="mobile-dish dish1-mobile"
        src="https://res.cloudinary.com/lusini/image/upload/v1652775112/application/newsletter/dish1-mobile.png"
        alt="dish1-mobile"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > img {
    position: absolute;
  }
  > .mobile-dish {
    display: block;
    @media (min-width: ${theme.ms.MD}px) {
      display: none;
    }
  }
  > .desktop-dish {
    display: none;
    @media (min-width: ${theme.ms.LG}px) {
      display: block;
    }
  }
  > .dish1 {
    left: 0;
    top: 0;
    width: 7.75rem;
    height: 15.5625rem;
  }
  > .dish2 {
    right: 0;
    top: 0;
    width: 14.3125rem;
    height: 11.8125rem;
  }
  > .dish3 {
    right: 0;
    bottom: 0;
    width: 9.25rem;
    height: 13.0625rem;
  }
  > .dish1-mobile {
    right: 1.5625rem;
    bottom: 0;
    width: 12.0625rem;
    height: 4rem;
  }
  > .dish2-mobile {
    right: 0;
    bottom: 0;
    width: 3.75rem;
    height: 6.5rem;
  }
`
