import * as React from 'react'
import useTranslations from 'hooks/useTranslations'
import styled from 'styled-components'
import theme from 'theme'
import { Gq } from '../../types'
import Button from 'theme/atoms/Button'
import Container from 'theme/atoms/Container'
import useLazyImgSrc from 'hooks/useLazyImgSrc'

export default function Catalogs(props: Omit<Gq, 'brandName'>) {
  const t = useTranslations<'app-Footer'>()
  const [ref, url] = useLazyImgSrc<any>(props.footer.catalogImage, 200)

  return (
    <Container>
      <Wrapper className="Catalogs">
        <div className="img-wrapper" ref={ref}>
          <img src={url} alt="catalogs" />
        </div>
        <div className="info-wrapper">
          {t('catalog_headline')}
          <Button
            className="catalog-button"
            to={props.footer.catalogLink}
            variation="secondary"
          >
            {t('catalog_button')}
          </Button>
        </div>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${theme.spacing('m')};

  @media (min-width: ${theme.ms.MD}px) {
    justify-content: center;
    flex-wrap: nowrap;
    margin-left: 0;
  }

  > .img-wrapper {
    max-height: 250px;
    margin: 0 ${theme.spacing('s')};

    > img {
      width: 100%;
    }

    @media (min-width: ${theme.ms.MD}px) {
      margin: 0 ${theme.spacing('m')};
      width: 480px;
    }
    @media (min-width: ${theme.ms.LG}px) {
      margin: 0;
      width: 700px;
    }
  }

  > .info-wrapper {
    margin-top: ${theme.spacing('s')};
    margin-bottom: ${theme.spacing('ml')};
    margin-left: ${theme.spacing('l')};

    @media (min-width: ${theme.ms.MD}px) {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      ${theme.ty('rc-2xl')}
    }

    @media (min-width: ${theme.ms.LG}px) {
      margin: 0 ${theme.spacing('xl')};
      ${theme.ty('rc-3xl')}
    }

    > .catalog-button {
      margin-top: ${theme.spacing('s')};
    }
  }
`
