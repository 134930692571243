import * as React from 'react'
import styled from 'styled-components'
import Logo from '../../../../../static/images/logo.svg'
import { navigate } from 'gatsby'
import theme from 'theme'

export default function Head() {
  return (
    <Wrapper className="Head" data-cy-ctx="app/Head">
      <img
        src={Logo}
        onClick={() => navigate('/')}
        data-cy-handle="back-to-shop"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  @media (min-width: ${theme.ms.MD}px) {
    margin-top: ${theme.spacing('l')};
  }

  > img {
    display: flex;
    height: 40px;
    cursor: pointer;

    @media (min-width: ${theme.ms.MD}px) {
      margin-top: -10px;
    }
  }
`
