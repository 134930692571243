import { dispatchEvent } from 'redux-ruleset'

export const OPEN: 'Header-SearchField/OPEN' = 'Header-SearchField/OPEN'
export const SEARCH: 'Header-SearchField/SEARCH' = 'Header-SearchField/SEARCH'
export const SEARCH_BUTTON_CLICK: 'ProductWidget/SEARCH_BUTTON_CLICK' =
  'ProductWidget/SEARCH_BUTTON_CLICK'
export const INPUT_TYPE_ENTER: 'ProductWidget/INPUT_TYPE_ENTER' =
  'ProductWidget/INPUT_TYPE_ENTER'
export const VIEW_SEARCH_RESULT_PDP: 'Header-SearchField/VIEW_SEARCH_RESULT_PDP' =
  'Header-SearchField/VIEW_SEARCH_RESULT_PDP'

export const open = () =>
  dispatchEvent({
    type: OPEN
  })

export const search = (query: string, itemLabel: string) =>
  dispatchEvent({
    type: SEARCH,
    meta: { itemLabel },
    payload: query
  })

export const viewSearchResultPDP = (
  search: string,
  url: string,
  pageType: string
) =>
  dispatchEvent({
    type: VIEW_SEARCH_RESULT_PDP,
    payload: { search, url, pageType }
  })

export const searchInputClick = (query: string) =>
  dispatchEvent({
    type: SEARCH_BUTTON_CLICK,
    meta: {},
    payload: query
  })

export const inputTypeEnter = (query: string) =>
  dispatchEvent({
    type: INPUT_TYPE_ENTER,
    meta: {},
    payload: query
  })

export type Open = ReturnType<typeof open>
export type Search = ReturnType<typeof search>
export type ViewSearchResultPDP = ReturnType<typeof viewSearchResultPDP>
export type SearchInputClick = ReturnType<typeof searchInputClick>
export type InputTypeEnter = ReturnType<typeof inputTypeEnter>

export type Event =
  | Open
  | Search
  | ViewSearchResultPDP
  | SearchInputClick
  | InputTypeEnter

declare global {
  interface RulesetDispatchEvents {
    'app/Header/SearchField': Event
  }
}
