import useOutsideClick from 'hooks/useOutsideClick'
import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import useWindowSize from 'hooks/useWindowSize'

type Props = {
  anchorElement: React.RefObject<HTMLElement> | null
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  hasBackdrop?: boolean
}

function Popover(props: Props) {
  const popoverRef = useOutsideClick(
    () => (!props.isOpen ? null : props.onClose()),
    props.anchorElement?.current?.getBoundingClientRect()
  )
  const { windowHeight, windowWidth } = useWindowSize()
  const [top, setTop] = React.useState(0)
  const [left, setLeft] = React.useState(0)

  React.useEffect(() => {
    if (typeof window === 'undefined') return
    setTop(
      (props.anchorElement?.current?.getBoundingClientRect().bottom ?? 0) +
        window.scrollY
    )
  }, [props.anchorElement?.current, props.isOpen, windowHeight, windowWidth])

  React.useEffect(() => {
    setLeft(props.anchorElement?.current?.getBoundingClientRect().left ?? 0)
  }, [props.anchorElement?.current, props.isOpen, windowHeight, windowWidth])

  return (
    <>
      {props.hasBackdrop && props.isOpen && (
        // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
        <Backdrop $top={top} $height={document.body.scrollHeight} />
      )}
      <Wrapper
        className="Popover"
        $top={top}
        $left={left}
        ref={popoverRef}
        $isOpen={props.isOpen}
      >
        <div className="popover-content">{props.children}</div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ $top: number; $left: number; $isOpen: boolean }>`
  display: ${(p) => (p.$isOpen ? 'block' : 'none')};
  position: absolute;
  top: ${(p) => p.$top}px;
  left: ${(p) => p.$left}px;
  z-index: ${theme.zIndex.app_header_navigation_popover_Wrapper};
  > .popover-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    background: ${theme.colors.white};
    padding: 8px 0px;
  }
`

const Backdrop = styled.div<{ $top: number; $height: number }>`
  position: absolute;
  top: ${(p) => p.$top}px;
  left: 0;
  z-index: ${theme.zIndex.app_header_navigation_popover_Backdrop};
  height: ${(p) => p.$height - p.$top}px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`

export default Popover
