import './rules'
import reducer, { State } from './reducer'
import { injectReducer } from 'store/rootReducer'
import store from 'store'
import { Action } from './actions'

injectReducer(store, 'productReviews', reducer)

declare global {
  interface RootState {
    productReviews: State
  }
  interface RootReducers {
    productReviews: typeof reducer
  }
  interface ModuleActions {
    productReviews: Action
  }
}

export { default as useProductReviews } from './hooks/useProductReviews'
export { default as useInitializer } from './hooks/useInitializer'
