/**
 * Returns the icon and link to be used in the contact section of the page.
 * @param type The type of contact to get the icon and link for
 * @param value The value of the contact to use in the link
 * @returns The icon and link to be used in the contact section of the page
 */

import React from 'react'

// Import the icons we want to use
import WhatsappSimpleIcon from '../assets/whatsapp-simple.svg'
import MailIcon from '../assets/mail.svg'
import PhoneIcon from '../assets/phone-call.svg'

// Define the type of the return value
type IconLink = {
  icon: React.ReactNode
  link: string
}

// Export the function
export default function getContactVersion(
  // Define the type of the input parameter
  type: 'phonenumber' | 'whatsapp' | 'email',
  value: string
): IconLink {
  // Define the variables we will use
  const result = { icon: null, link: null } as unknown as IconLink
  if (type === 'phonenumber') {
    // Set the icon
    result.icon = <PhoneIcon />
    // Set the link
    result.link = `tel:${value}`
  } else if (type === 'whatsapp') {
    result.icon = <WhatsappSimpleIcon />
    result.link = `https://wa.me/${value.replace(/[^0-9]/g, '')}`
  } else if (type === 'email') {
    result.icon = <MailIcon />
    result.link = `mailto:${value}`
  } else {
    throw new Error('Unknown type: ' + type)
  }

  // Return the icon and link
  return result
}
