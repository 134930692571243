import { dispatchEvent } from 'redux-ruleset'
export const OPEN_PRODUCT_REVIEWS: 'Rating/OPEN_PRODUCT_REVIEWS' =
  'Rating/OPEN_PRODUCT_REVIEWS'

export const openProductReviews = (source: string) =>
  dispatchEvent({
    type: 'Rating/OPEN_PRODUCT_REVIEWS' as const,
    meta: { source }
  })

export type OpenProductReviews = ReturnType<typeof openProductReviews>

export type Event = OpenProductReviews

declare global {
  interface RulesetDispatchEvents {
    'theme/molecules/Rating': Event
  }
}
