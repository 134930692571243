export type MS = keyof typeof ms

export const ms = {
  XS: 0,
  SM: 576, // Small devices (landscape phones, 576px and up)
  MD: 767, // Medium devices (tablets, 768px and up)
  LG: 992, // Large devices (desktops, 992px and up)
  XL: 1200 // X-Large devices (large desktops, 1200px and up)
}

export function getMediaSize(): MS {
  if (typeof window === 'undefined') {
    throw new Error('cannot access MediaSize on server')
  }

  const screenWidth = document.body.clientWidth

  let currentMs: MS = 'XS'

  for (const key of Object.keys(ms)) {
    if (screenWidth >= ms[key as MS]) {
      currentMs = key as MS
    }
  }

  return currentMs
}

export function isBiggerThan(size: MS, predicate = getMediaSize()) {
  return ms[size] > ms[predicate]
}

export function isBiggerThanOrEqual(size: MS, predicate = getMediaSize()) {
  return ms[size] >= ms[predicate]
}
