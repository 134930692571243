import * as React from 'react'
import theme from 'theme'
import styled from 'styled-components'
import useTranslations from 'hooks/useTranslations'

export default function BestShop() {
  const t = useTranslations<'app-Footer'>()

  return (
    <Wrapper className="BestShop">
      <div className="outer">
        <div className="inner">{t('best_shop_info')}</div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > .outer {
    padding: ${theme.spacing('xxs')};
    margin: 0 ${theme.spacing('m')};
    background: ${`linear-gradient(180deg, ${theme.colors.shade.primaryBrighter}, ${theme.colors.primary})`};
    color: ${theme.colors.primary};
    ${theme.ty('rc-2xl', 'bold')}
    ${theme.rounding('m')};

    @media (min-width: ${theme.ms.LG}px) {
      ${theme.ty('rc-3xl', 'bold')}
    }

    > .inner {
      background: #fff;
      padding: ${theme.spacing('m')};

      @media (min-width: ${theme.ms.MD}px) {
        padding: ${theme.spacing('m')} ${(theme._spacing.xl * 2) / 16}rem;
      }
    }
  }
`
