import { dispatchEvent } from 'redux-ruleset'

export const WIDGET_CLICK: 'Intersticial/WIDGET_CLICK' =
  'Intersticial/WIDGET_CLICK'
export const SCROLL_INTO_VIEW: 'Intersticial/SCROLL_INTO_VIEW' = `Intersticial/SCROLL_INTO_VIEW`

type trackingProps = {
  campaignID: string
  campaignName: string
  campaignCreative: string
  gridArea: string
}

export const widgetClick = (trackingProps: trackingProps) =>
  dispatchEvent({
    type: WIDGET_CLICK,
    payload: trackingProps
  })

export const scrollIntoView = (trackingProps: trackingProps) =>
  dispatchEvent({
    type: SCROLL_INTO_VIEW,
    payload: trackingProps
  })

export type ScrollIntoView = ReturnType<typeof scrollIntoView>
export type WidgetClick = ReturnType<typeof widgetClick>

export type Event = ScrollIntoView | WidgetClick

declare global {
  interface RulesetDispatchEvents {
    'molecules/Intersticial': Event
  }
}
