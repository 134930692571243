import 'features'
import * as React from 'react'
import StoryEvents from './StoryEvents'
import Breadcrumbs from 'theme/atoms/Breadcrumbs'
import Header from 'theme/app/Header'
import Footer from 'theme/app/Footer'
import Modal from 'theme/app/Modal'
import { Context as ContainerSizeContext } from 'hooks/useContainerSize'
import smoothscroll from 'smoothscroll-polyfill'
import { PageContext, PageContextProvider } from 'hooks/usePageContext'
import styled from 'styled-components'
import theme from 'theme'
import Snackbar from 'theme/app/Snackbar'
import B2xModal from 'theme/app/B2xModal'
//WDV-3313 NewsletterIntentLayer
import NewsletterIntentLayer from 'theme/app/NewsletterIntentLayer'
//WDV-3313 NewsletterIntentLayer

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

export type Props = {
  pageContext?: PageContext
  children: React.ReactNode
  isCheckout?: boolean
  breadcrumbs?: {
    label: string
    link?: string
  }[]
  isFetching?: boolean
  storyEvents?: Record<string, unknown>[]
  smallContainer?: boolean
  greyBackground?: boolean
  'data-cy-ctx'?: string
  dontRenderCartIcon?: boolean
}

export default function Layout(props: Props) {
  const containerSize = props.smallContainer ? 700 : 1300
  return (
    <PageContextProvider pageContext={props.pageContext || null}>
      <StoryEvents storyEvents={props.storyEvents}>
        <Snackbar />
        <B2xModal />
        <Header />
        {props.breadcrumbs && (
          // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
          <Breadcrumbs
            breadcrumbs={props.breadcrumbs}
            isFetching={props.isFetching || false}
          />
        )}
        <ContainerSizeContext.Provider value={containerSize}>
          <Wrapper
            $grey={props.greyBackground}
            data-cy-ctx={props['data-cy-ctx']}
          >
            {props.children}
          </Wrapper>
        </ContainerSizeContext.Provider>
        <Footer />
        <Modal />
        <NewsletterIntentLayer />
      </StoryEvents>
    </PageContextProvider>
  )
}

const Wrapper = styled.div<{ $grey?: boolean }>`
  ${(props) =>
    props.$grey &&
    `
    background: ${theme.colors.shade.b6};
    padding: 40px 0;
  `}
`
