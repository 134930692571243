import { LOCATION_CHANGED } from 'modules/navigation/const'
import { addRule } from 'redux-ruleset'
import getPageInfo from 'utils/getPageInfo'
import getHotjarStatus from 'utils/getHotjarStatus'
import config from 'config'
import { getConsentStatus } from 'utils/dy-hybrid/manager'
import Hotjar from '@hotjar/browser'

addRule({
  id: 'f-hotjar/INIT',
  target: LOCATION_CHANGED,
  output: '#hotjar-init',
  condition: () => config.features.hotjar || false,
  consequence: (action) => {
    const siteId = config.modules.hotjar.id
    const version = config.modules.hotjar.version

    // consentStatus checks if the user has accepted the cookies for marketing purposes
    const consentStatus = getConsentStatus()

    const pageType = getPageInfo(action.payload.pathname)
    const isHotjarEnables = getHotjarStatus(pageType.pageType)

    if (!isHotjarEnables && consentStatus !== 'ACCEPTED') return

    setTimeout(() => Hotjar.init(siteId, version), 500)
  }
})
