import * as at from './const'

export const locationChanged = (
  newLocation: Location,
  prevLocation: Location | null
) => ({
  type: at.LOCATION_CHANGED,
  meta: { prevLocation },
  payload: newLocation
})

export type LocationChanged = ReturnType<typeof locationChanged>

export type Action = LocationChanged
