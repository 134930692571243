import Component from './Snackbar'
import { graphql, useStaticQuery } from 'gatsby'
import { withTranslations } from 'hooks/useTranslations'

declare global {
  interface Snippets {
    'app-Snackbar': {
      product_not_found: void
      product_out_of_stock: void
      product_stock_reached: void
      invalid_uuid: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query app_Snackbar_translations {
      product_not_found: snippet(
        group: "app-Snackbar"
        name: "product_not_found"
      )
      product_out_of_stock: snippet(
        group: "app-Snackbar"
        name: "product_out_of_stock"
      )
      product_stock_reached: snippet(
        group: "app-Snackbar"
        name: "product_stock_reached"
      )
      invalid_uuid: snippet(group: "app-Snackbar", name: "invalid_uuid")
    }
  `)
)
