import * as React from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import config from 'config'
import useAccount from 'hooks/account/useAccount'
import styled, { css } from 'styled-components'

type Props = {
  to?: string | null
  children?: React.ReactNode
  className?: string
  onClick?: any
  ref?: any
  /** force link to open in new tab */
  blank?: boolean
  title?: string
  preventReloadOnSamePageOnClick?: boolean
  'data-cy-ctx'?: string
  'data-cy-handle'?: string
  'data-cy-collection'?: string
  'data-cy-state'?: string
}

function evaluateTo(to: string, channel: 'b2b' | 'b2c') {
  if (!config.features.b2cHomePage) {
    return to
  }
  if (to === '/') {
    const newTo = channel === 'b2c' ? '/b2c/' : '/'
    return newTo
  } else {
    return to
  }
}

export default React.forwardRef(function Link(props: Props, ref: any) {
  const { to, children, className, onClick, blank, ...rest } = props
  const account = useAccount()
  const [clicked, setClicked] = React.useState(false)

  if (!to)
    return (
      // eslint-disable-next-line @kaminrunde/firescout/onclick-needs-handle
      <span onClick={onClick} className={className} ref={ref} {...rest}>
        {children}
      </span>
    )
  const internal = to.startsWith('/') || to.startsWith('#')
  let handleClick = onClick

  /** during testing we implement navigate to check later if navigation happened */
  if (process.env.NODE_ENV === 'test' && to) {
    handleClick = (...args: any[]) => {
      navigate(to)
      if (onClick) onClick(...args)
    }
  }

  if (internal && !blank) {
    if (process.env.NODE_ENV === 'development') {
      // if(!to.endsWith('/')) {
      //   console.log('internal links must end with a trailing slash')
      //   return <a style={{color: 'green', background: 'blue'}}>{children}</a>
      // }
    }
    const linkTarget = evaluateTo(to, account.channel)

    return (
      // eslint-disable-next-line @kaminrunde/firescout/onclick-needs-handle
      <LinkEl
        $clicked={clicked}
        ref={ref}
        onClick={(e) => {
          setClicked(true)
          handleClick && handleClick(e)
          if (
            !props.preventReloadOnSamePageOnClick &&
            linkTarget ===
              window.location.pathname.replace(`/${config.locale}/`, '/')
          ) {
            e.preventDefault()
            location.reload()
            return
          }
        }}
        className={className}
        to={linkTarget}
        {...rest}
      >
        {children}
      </LinkEl>
    )
  } else {
    let url = evaluateTo(to, account.channel)
    if (internal && blank) {
      url = '/' + config.locale + to
    }
    if (process.env.NODE_ENV === 'development') {
      url = to
    }
    return (
      // @ts-ignore
      // eslint-disable-next-line @kaminrunde/firescout/onclick-needs-handle
      <LinkEl
        as="a"
        $clicked={clicked}
        href={url}
        ref={ref}
        onClick={(e) => {
          setClicked(true)
          handleClick && handleClick(e)
        }}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </LinkEl>
    )
  }
}) as React.ComponentType<Props>

const LinkEl = styled(GatsbyLink)<{ $clicked: boolean }>`
  @keyframes fadeOutAndIn {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ $clicked }) =>
    $clicked &&
    css`
      animation: fadeOutAndIn 0.1s ease-in-out;
    `}
`
