import config from 'config'
import createAlgoliaHelper from 'utils/createAlgoliaHelper'

export async function isValidSku(sku: string) {
  const helper = await createAlgoliaHelper(config.index.products, {
    disjunctiveFacets: ['sku']
  })
  helper.addDisjunctiveFacetRefinement('sku', sku)
  const result = await helper.searchOnce({ hitsPerPage: 1 })
  if (result.content.hits.length >= 1) return true
  else return false
}

export async function isValidContainerID(containerID: string) {
  const helper = await createAlgoliaHelper(config.index.products, {
    disjunctiveFacets: ['containerID']
  })
  helper.addDisjunctiveFacetRefinement('containerID', containerID)
  const result = await helper.searchOnce({ hitsPerPage: 1 })
  if (result.content.hits.length >= 1) return true
  else return false
}
