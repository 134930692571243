import { init } from 'modules/seriesListing/actions'
import { FETCH_SUCCESS, INIT } from 'modules/seriesListing/const'
import { addRule } from 'redux-ruleset'

addRule({
  id: 'feature/LISTING_STATE_TO_HISTORY',
  target: FETCH_SUCCESS,
  output: '#history-state',
  consequence: (action) => {
    const { page } = action.meta.filterValues

    window.history.replaceState({ ...history.state, page: page ? page : 0 }, '')
  }
})
/**
 * When the url contains a hash
 * And I init my listing state
 * Then I want to add the hashed filtervalues to the initializing
 */
addRule({
  id: 'feature/HYDRATE_CATEGORY_PAGINATION',
  target: INIT,
  output: INIT,
  position: 'INSTEAD',
  condition: () =>
    typeof window !== 'undefined' && Boolean(history.state?.page),
  consequence: (action) => {
    const newFilterValues = {
      ...action.payload,
      page: history.state?.page || 0
    }

    return init(action.meta.recordId, newFilterValues)
  }
})
