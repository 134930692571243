import * as React from 'react'
import styled from 'styled-components'
import Burgermenu from 'assets/burgermenu.svg'
import theme from 'theme'
import Popover from '../Popover'
import CategoryItem from './CategoryItem'
import useCategories, {
  CategoryItem as CategoryItemType
} from '../../useCategories'
import Button from 'theme/atoms/Button'
import * as evt from '../../FlyoutNavi/events'
import * as headerEvt from '../../events'
import useTranslations from 'hooks/useTranslations'

const MAX_ITEMS_PER_LEVEL = 14

export function hasChildren(node: CategoryItemType) {
  return Boolean(node.childrenCategory?.length) && node.categoryLevel !== 3
}

export default function CategorySelect() {
  const [isCategoryPopoverOpen, setIsCategoryPopoverOpen] =
    React.useState(false)
  const [showAllInLevel, setShowAllInLevel] = React.useState({
    '1': false,
    '2': false
  })
  const t = useTranslations<'app-Header'>()
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const {
    categories,
    currentLevel,
    addCategoryForLevel,
    clearCategoryIds,
    categoryByLevel
  } = useCategories()

  const handleCategorySelectorClick = () => {
    setIsCategoryPopoverOpen(!isCategoryPopoverOpen)
    setShowAllInLevel({ '1': false, '2': false })
    clearCategoryIds()
    headerEvt.burgerMenuClick()
  }

  const handleClose = () => {
    setIsCategoryPopoverOpen(false)
    setShowAllInLevel({ '1': false, '2': false })
    clearCategoryIds()
  }

  const createCategoryItem = React.useCallback(
    (node: CategoryItemType) => {
      return (
        <CategoryItem
          key={node.id}
          hasChildren={hasChildren(node)}
          to={hasChildren(node) ? null : node.urlPath}
          categoryName={node.label}
          isActive={
            node.id === categoryByLevel[node?.categoryLevel]?.id &&
            hasChildren(node)
          }
          title={node.label || ''}
          urlPath={node.urlPath}
          onClick={() => {
            if (hasChildren(node)) {
              evt.subCategoryClick(node.path.split(' > ')[-1] || '', node.path)
              addCategoryForLevel(node.categoryLevel, node)
              setShowAllInLevel({
                ...showAllInLevel,
                [`${node.categoryLevel + 1}`]: false
              })
            } else {
              evt.subCategoryClick(node.path.split(' > ')[-1] || '', node.path)
              handleClose()
            }
          }}
          data-cy-handle="select-category-item"
        />
      )
    },
    [categoryByLevel, showAllInLevel]
  )

  const renderItems = React.useCallback(
    (items: CategoryItemType[] | undefined, categoryLevel: 2 | 3) => {
      if (!items) return null
      const itemsToRender: React.ReactElement[] = []
      if (items.length > MAX_ITEMS_PER_LEVEL) {
        for (let index = 0; index < MAX_ITEMS_PER_LEVEL + 1; index++) {
          itemsToRender.push(createCategoryItem(items[index]))
        }
      } else {
        for (let index = 0; index < items.length; index++) {
          itemsToRender.push(createCategoryItem(items[index]))
        }
      }
      if (
        items.length > MAX_ITEMS_PER_LEVEL + 1 &&
        !showAllInLevel[categoryLevel]
      ) {
        itemsToRender.push(
          <div style={{ display: 'grid' }}>
            <Button
              className="show-more-button"
              variation="secondary"
              $fullWidth={true}
              title={t.asText(false, 'header_show_more_btn_label')}
              onClick={() =>
                setShowAllInLevel({
                  ...showAllInLevel,
                  [`${categoryLevel}`]: true
                })
              }
              data-cy-handle="show-more-btn"
            >
              {t('header_show_more_btn_label')}
            </Button>
          </div>
        )
      }
      if (items.length > MAX_ITEMS_PER_LEVEL && showAllInLevel[categoryLevel]) {
        for (
          let index = MAX_ITEMS_PER_LEVEL + 1;
          index < items.length;
          index++
        ) {
          itemsToRender.push(createCategoryItem(items[index]))
        }
      }
      return itemsToRender
    },
    [showAllInLevel, categoryByLevel]
  )

  return (
    <Wrapper className="CategorySelect">
      <button
        className="category-button"
        onClick={handleCategorySelectorClick}
        data-cy-handle="category-button"
        ref={buttonRef}
      >
        <Burgermenu />
        <span>{t('header_primary_btn_label')}</span>
      </button>
      <Popover
        anchorElement={buttonRef}
        isOpen={isCategoryPopoverOpen}
        onClose={() => {
          evt.closeNavigationClick(categoryByLevel[currentLevel]?.path ?? null)
          handleClose()
        }}
        hasBackdrop
      >
        <CategoriesPopover>
          {/* First category level */}
          <div
            data-cy-state={
              isCategoryPopoverOpen ? 'megadropdown-visible' : null
            }
          >
            {categories.map((node) => (
              <CategoryItem
                key={node.id}
                hasChildren={hasChildren(node)}
                to={hasChildren(node) ? null : node.urlPath}
                categoryName={node.label}
                title={node.label || ''}
                isActive={node.id === categoryByLevel[node.categoryLevel]?.id}
                urlPath={node.urlPath}
                onClick={() => {
                  evt.mainCategoryClick(node.label)
                  clearCategoryIds()
                  addCategoryForLevel(node.categoryLevel, node)
                  setShowAllInLevel({ '1': false, '2': false })
                }}
                data-cy-handle="select-category-item"
              />
            ))}
          </div>
          {/* Second category level */}
          {currentLevel > 0 && (
            <div data-cy-state="second-category-level-shown">
              <CategoryItem
                isBold={true}
                hasChildren={false}
                categoryName={t('header_main_category_label', {
                  CATEGORY: categoryByLevel[1]?.label ?? ''
                })}
                title={t.asText(false, 'header_main_category_label', {
                  CATEGORY: categoryByLevel[1]?.label ?? ''
                })}
                isActive={false}
                data-cy-handle="show-more-btn"
                to={categoryByLevel[1]?.urlPath}
                onClick={() =>
                  evt.headlineClick(
                    categoryByLevel[1]?.label ?? null,
                    categoryByLevel[1]?.path ?? null
                  )
                }
              />
              {renderItems(categoryByLevel[1]?.childrenCategory, 2)}
            </div>
          )}
          {/* Third category level */}
          {currentLevel > 1 && (
            <div data-cy-state="third-category-level-shown">
              <CategoryItem
                isBold={true}
                title={t.asText(false, 'header_main_category_label', {
                  CATEGORY: categoryByLevel[2]?.label ?? ''
                })}
                hasChildren={false}
                categoryName={t('header_main_category_label', {
                  CATEGORY: categoryByLevel[2]?.label ?? ''
                })}
                isActive={false}
                urlPath={categoryByLevel[2]?.urlPath}
                data-cy-handle="show-more-btn"
                to={categoryByLevel[2]?.urlPath}
                onClick={() =>
                  evt.headlineClick(
                    categoryByLevel[2]?.label ?? null,
                    categoryByLevel[2]?.path ?? null
                  )
                }
              />
              {renderItems(categoryByLevel[2]?.childrenCategory, 3)}
            </div>
          )}
        </CategoriesPopover>
      </Popover>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .category-button {
    display: none;
    @media (min-width: ${theme.ms.LG}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 188px;
      background: ${theme.colors.b0};
      ${theme.rounding('m')};
      color: ${theme.colors.white};
      ${theme.ty('rc-base', '700')};
      column-gap: ${theme.spacing('xs')};
      > svg g {
        stroke: ${theme.colors.white};
      }
    }
  }
`

const CategoriesPopover = styled.div`
  display: flex;
  flex-direction: row;
  > div > div > .show-more-button {
    margin: ${theme.spacing('s')};
    width: calc(100% - ${theme.spacing('s')} * 2);
  }
`
