import * as React from 'react'
import styled, { css } from 'styled-components'
import useNewsletterSubmit from './useNewsletterSubmit'
import theme from 'theme'
import NewsletterResponse from './NewsletterResponse'
import NewsletterForm from './NewsletterForm'

type Props = {
  labels: string[]
  title: string
  disclaimer: string
  hasPromotionIcon: boolean
}

export default function InfoContainer(props: Props) {
  const newsletterSubmit = useNewsletterSubmit()

  return (
    <Wrapper
      className="InfoContainer"
      $hasPromoIcon={props.hasPromotionIcon}
      $isSubmitting={newsletterSubmit.isSubmitting}
    >
      <div className="title">{props.title}</div>

      <NewsletterForm
        handleSubmit={newsletterSubmit.handleSubmit}
        response={newsletterSubmit.response}
        hasPromotionIcon={props.hasPromotionIcon}
        isSubmitting={newsletterSubmit.isSubmitting}
      />

      <NewsletterResponse status={newsletterSubmit.response} />

      {!newsletterSubmit.response && (
        <ul className="labels" data-cy-state="show-labels">
          {props.labels.map((label) => (
            <li key={label}>{label}</li>
          ))}
        </ul>
      )}

      {!newsletterSubmit.response && (
        <div
          className="disclaimer"
          data-cy-state="show-disclaimer"
          dangerouslySetInnerHTML={{ __html: props.disclaimer }}
        />
      )}
    </Wrapper>
  )
}

type WrapperProps = {
  $hasPromoIcon: boolean
  $isSubmitting: boolean
}

const Wrapper = styled.div<WrapperProps>`
  @media (min-width: ${theme.ms.MD}px) {
    max-width: ${(props) => (props.$hasPromoIcon ? `420px` : `640px`)};
  }

  display: flex;
  flex-direction: column;

  > .title {
    ${theme.ty('rc-2xl', 'bold')}
    text-align: left;
    margin-bottom: ${theme.spacing('s')};
    @media (min-width: ${theme.ms.LG}px) {
      ${theme.ty('rc-3xl', 'bold')}
    }

    @media (min-width: ${theme.ms.MD}px) {
      ${(props) =>
        !props.$hasPromoIcon &&
        css`
          text-align: center;
        `}
    }
  }

  > div {
    text-align: left;
    margin-bottom: ${theme.spacing('s')};
  }
  > ul.labels {
    display: flex;
    flex-direction: column;
    ${theme.ty('r-base')}
    @media (min-width: ${theme.ms.LG}px) {
      ${theme.ty('r-xl')}
    }
    margin-bottom: ${theme.spacing('xs')};

    > li {
      margin-bottom: ${theme.spacing('xxs')};
      padding-left: ${theme.spacing('m')};
      position: relative;

      @media (min-width: ${theme.ms.MD}px) {
        ${(props) =>
          !props.$hasPromoIcon &&
          css`
            display: block;
            text-align: center;
          `}
      }
    }
    > li:before {
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTUgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjcyMTEwMDcsMC4yMDU4OTk3MiBDMTQuMDcxMTAwNywwLjUxMDA3OTcyIDE0LjEwODMwMDcsMS4wNDAzOTk3MiAxMy44MDQxMDA3LDEuMzkwMzk5NzIgTDYuMzIyNjAwNzUsOS45OTkzOTk3MiBMMC4zNDgyODA3NDcsNS42ODY4OTk3MiBDLTAuMDI3NzI5MjUyOSw1LjQxNTQ5OTcyIC0wLjExMjUwOTI1Myw0Ljg5MDU5OTcyIDAuMTU4OTAwNzQ3LDQuNTE0NTk5NzIgQzAuNDMwMzIwNzQ3LDQuMTM4NTk5NzIgMC45NTUxNjA3NDcsNC4wNTM3OTk3MiAxLjMzMTE2MDc1LDQuMzI1MTk5NzIgTDYuMDYxNDAwNzUsNy43Mzk3OTk3MiBMMTIuNTM2NjAwNywwLjI4ODg4OTcyIEMxMi44NDA3MDA3LC0wLjA2MTEzMDI3OTcgMTMuMzcxMTAwNywtMC4wOTgyOTAyNzk3IDEzLjcyMTEwMDcsMC4yMDU4OTk3MiBaIiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
      width: ${theme.spacing('s')};
      height: ${theme.spacing('s')};
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      background-position: center center;
      margin-right: ${theme.spacing('xs')};
      margin-top: ${theme.spacing('xxs')};
      position: absolute;
      margin-left: -20px;
    }
  }

  > .disclaimer {
    display: block;
    ${theme.ty('r-xs', null, 'r-s')}
    @media (min-width: ${theme.ms.MD}px) {
      ${(props) =>
        !props.$hasPromoIcon &&
        css`
          text-align: center;
        `}
    }
  }
`
