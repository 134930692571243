import * as React from 'react'
import useAccount from '../useAccount'
import * as t from '../types'
import * as utils from '../utils'
import { GetConfigResponse } from '../response-types/get-config'

export type AddressRequireDict = Record<
  'billing' | 'shipping',
  Record<string, 'optional' | 'required'>
>

/**
 * @example
 * const requireDict = useAddressRequireDict()
 *
 * const billingValid = requireDict.isBillingValid(address)
 * const shippingValid = requireDict.isShippingValid(address)
 *
 * if(billingValid && shippingValid) navigate('/next/page')
 *
 * {requireDict.data.billing.lusiniVatId && <input />}
 */
export default function useAddressRequireDict() {
  const account = useAccount()
  const [dict, setDict] = React.useState<AddressRequireDict>(
    account._.stores.addressRequireDict || {
      billing: {},
      shipping: {}
    }
  )
  const [fetching, setFetching] = React.useState(
    !account._.stores.addressRequireDict
  )

  const fetch = async () => {
    setFetching(true)
    const promise =
      account._.stores.addressRequireDictP || fetchAddressRequireDict()
    account._.stores.addressRequireDictP = promise

    const result = await promise

    if (result.status === 200) {
      account._.stores.addressRequireDict = result.payload
      setDict(result.payload)
    }
    setFetching(false)
    delete account._.stores.addressListP
  }

  React.useEffect(() => {
    // if (Object.keys(dict).length) return
    fetch()

    return account._.onEvent((evt) => {
      switch (evt.type) {
        case 'SET_CHANNEL':
          fetch()
      }
    })
  }, [])

  return {
    data: dict,
    fetching,
    getErrors: (address: t.Address, type: 'billing' | 'shipping') => {
      if (!account.context || fetching)
        return {
          type: 'NOT_READY' as const,
          success: false
        }
      const errors: string[] = []
      for (const key in dict[type]) {
        if (dict[type][key] === 'optional') continue
        const value = address[key] ?? address.customFields[key]
        if (!value) errors.push(key)
      }

      return {
        type: 'READY' as const,
        success: errors.length === 0,
        errors: errors
      }
    },
    /**
     * always returns false when dictionary was not fetched yet or account was not loaded yet
     */
    isBillingValid: React.useCallback(
      (address: t.Address) => {
        if (!account.context) return true
        if (fetching) return true

        let billingValid = true

        for (const key in dict.billing) {
          if (dict.billing[key] === 'optional') continue
          const value = address[key] ?? address.customFields[key]
          if (!value) {
            billingValid = false
            break
          }
        }

        return billingValid
      },
      [account.context, fetching, dict]
    ),
    /**
     * always returns false when dictionary was not fetched yet or account was not loaded yet
     */
    isShippingValid: React.useCallback(
      (address: t.Address) => {
        if (!account.context) return true
        if (fetching) return true

        let shippingValid = true

        for (const key in dict.shipping) {
          if (dict.shipping[key] === 'optional') continue
          const value = address[key] ?? address.customFields[key]
          if (!value) {
            shippingValid = false
            break
          }
        }

        return shippingValid
      },
      [account.context, fetching, dict]
    )
  }
}

/** @firescoutMockFn account.fetchAddressRequireDict */
async function fetchAddressRequireDict(): t.Response<AddressRequireDict> {
  try {
    const [result, status] = await utils.fetchFromShopware<GetConfigResponse>({
      method: 'GET',
      url: '/account/register/config'
    })
    if (status >= 400) {
      throw new Error('FETCH_REGISTER_CONFIG_FAILED')
    }

    const config: AddressRequireDict = {
      billing: {},
      shipping: {}
    }

    for (const type of ['billing', 'shipping']) {
      let target =
        type === 'billing' ? result.billingAddress : result.shippingAddress

      target = { ...target, ...target.customFields }
      // @ts-expect-error
      delete target.customFields

      // hack for countryStateId
      if (target.countryStateId) {
        if (!target.countryStateId.required) {
          // @ts-expect-error
          delete target.countryStateId
        }
      }

      for (const key in target) {
        if (typeof target[key] === 'object' && target[key] !== null) {
          if (target[key].required) config[type][key] = 'required'
          else config[type][key] = 'optional'
        }
      }
    }

    return { status: 200, payload: config }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}
