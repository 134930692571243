import * as React from 'react'
import useTranslations from 'hooks/useTranslations'
import styled from 'styled-components'
import theme from 'theme'
import { Gq } from '../../../types'
import { useOptInStatus } from 'modules/ui'
import config from 'config'
import useInView from 'hooks/useInView'

//wdv-3004
declare global {
  interface Window {
    __loadTrustedShops: (
      variant: 'custom' | 'custom_reviews'
    ) => void | undefined
  }
}
import useLazyImgSrc from 'hooks/useLazyImgSrc'

export default function Content(props: Omit<Gq, 'brandName'>) {
  // There are 4 cases:
  // - do not show anything (if feature is disabled in config)
  // - only display Trusticon (if it is set in contenful)
  // - intergrate the script (if nothing is set in contentful and user accept all cookies)
  // - only display Trusticon (if nothing is set in contentful and user deny all cookies) -> but no icon is set in contentful
  const t = useTranslations<'app-Footer'>()
  // the optInStatus is set if the user accept all cookies or deny all cookies in the cookie banner (see modules/ui)
  // the optInStatus is set by trustedShop variable '__module_ui_opted_in' in the cookie (plugins/module-trustedshops/gatsby-ssr.js)
  const { optInStatus } = useOptInStatus()
  const [ref, isInView] = useInView<HTMLDivElement>(200, true)
  const [refTrustIcon, trustIconSrc] = useLazyImgSrc<any>(
    props.footer.trustIcon ?? '',
    200
  )
  const [refTrustIcon2, trustIconSrc2] = useLazyImgSrc<any>(
    props.footer.trustIcon2 ?? '',
    200
  )
  const [refPlaceholder, placeholderSrc] = useLazyImgSrc<any>(
    config.modules.trustShop.placeholderImg,
    200
  )
  React.useEffect(() => {
    if (!isInView) return
    window?.__loadTrustedShops && window.__loadTrustedShops('custom_reviews')
  }, [isInView])

  return (
    <Wrapper className="Trusted" ref={ref}>
      {config.features.trustShop && (
        <>
          <div className="footer-small-headline">
            {t('trust_icon_headline')}
          </div>
          {/*if trustIcon is set then display only the icon with the link otherwise check for the optInStatus*/}
          <div className="icons-wrapper">
            {props.footer.trustIcon ? (
              <div className="trustShop" data-cy-state="trust-icon-visible">
                {props.footer.trustIcon && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.footer.trustLink}
                    data-cy-state="trust-icon-link"
                    ref={refTrustIcon}
                  >
                    {' '}
                    <img src={trustIconSrc} />
                  </a>
                )}
              </div>
            ) : (
              <div className="images">
                {/*if optInStatus is true then display the trustShop-Script otherwise display the placeholder image with the link*/}
                {optInStatus ? (
                  <div
                    className="trustedshop-container teh-certificate"
                    id="trustedshop-badge"
                    data-cy-state="trust-script-container-exits"
                  />
                ) : (
                  <div className="placeholder-img">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={props.footer.trustLink}
                      ref={refPlaceholder}
                    >
                      <img
                        style={{ maxHeight: '6.25rem', maxWidth: '6.25rem' }}
                        src={placeholderSrc}
                      />
                    </a>
                  </div>
                )}
              </div>
            )}
            {props.footer.trustLink2 && props.footer.trustIcon2 && (
              <div className="trustShop" data-cy-state="trust-icon2-visible">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={props.footer.trustLink2}
                  ref={refTrustIcon2}
                >
                  <img src={trustIconSrc2} />
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .footer-small-headline {
    ${theme.ty('rc-s', '700')}
    margin-top: ${theme.spacing('m')};
    @media (min-width: ${theme.ms.LG}px) {
      ${theme.ty('rc-l', '700')}
      margin-top:0
    }
  }
  > .icons-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    @media (min-width: ${theme.ms.MD}px) {
      flex-direction: column;
    }

    > .images {
      margin-top: 0;
      width: 6rem;
      height: 6rem;
    }

    > #trustedshop-badge {
      margin-top: 0;
      height: 6.25rem;
      width: 9.6875rem;
    }
    > .trustShop {
      display: flex;
      width: 6.25rem;
      height: 6.25rem;

      @media (min-width: ${theme.ms.MD}px) {
        margin-top: 0;
      }
      > a svg {
        width: 5rem;
        height: 5rem;
      }
      > a img {
        width: 100%;
      }
    }
  }
`
