import { MessageKey } from './types'

export const getCertainErrors = (errors) => {
  if (!errors.length) return []

  const messageKeyMapping: Record<string, MessageKey> = {
    'product-not-found': 'product_not_found',
    'product-out-of-stock': 'product_out_of_stock',
    'product-stock-reached': 'product_stock_reached',
    invalid_uuid: 'invalid_uuid'
  }

  return errors
    .map((error) => {
      if (messageKeyMapping[error.messageKey]) {
        return {
          messageKey: messageKeyMapping[error.messageKey],
          message: error.message,
          quantity: error.quantity || 0
        }
      }

      return null
    })
    .filter(Boolean)
}
