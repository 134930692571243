import { defaultFilterValues } from '../const'
import * as t from '../types'

export function serialize(
  filterValues: t.FilterValues,
  category = '',
  baseFilterValues?: t.FilterValues
): { hash: string; hasFilterValue: boolean } {
  const {
    query,
    attributes,
    numericAttributes,
    page,
    flags,
    minPrice,
    maxPrice,
    index
  } = filterValues
  let hash = '#q='
  let hasFilterValue = false

  if (
    Object.values(attributes).flat().length > 0 ||
    Object.values(numericAttributes).flat().length > 0 ||
    query !== '' ||
    page ||
    minPrice ||
    maxPrice ||
    category ||
    flags.length > 0
  ) {
    if (query !== baseFilterValues?.query ?? '') {
      hash = `#q=${encodeURIComponent(query)}`
    }
  }

  if (minPrice || maxPrice) {
    const baseEqual =
      baseFilterValues?.minPrice === minPrice &&
      baseFilterValues?.maxPrice === maxPrice
    if (!baseEqual) {
      hash += `&price=${minPrice || ''}---${maxPrice || ''}`
    }
  } else if (baseFilterValues?.minPrice || baseFilterValues?.maxPrice) {
    hash += `&price=none`
  }

  if (index && index !== baseFilterValues?.index) {
    hash += `&sorting=${index}`
  }

  for (const attr in attributes) {
    const changedValues: string[] = []
    const baseValues = baseFilterValues?.attributes[attr] || []
    const currentValues = attributes[attr] || []
    for (const value of currentValues) {
      if (!baseValues.includes(value)) changedValues.push(value)
    }
    for (const value of baseValues) {
      if (!currentValues.includes(value)) changedValues.push('!' + value)
    }
    if (changedValues.length) {
      hash += `&${attr}=${changedValues.map(encodeURIComponent).join('---')}`
    }
  }

  for (const attr in numericAttributes) {
    if (numericAttributes[attr].min || numericAttributes[attr].max) {
      const baseValue = baseFilterValues?.numericAttributes[attr] || {
        min: null,
        max: null
      }
      const baseEqual =
        baseValue.min === numericAttributes[attr].min &&
        baseValue.max === numericAttributes[attr].max
      if (baseEqual) continue

      hash += `&${attr}=${encodeURIComponent(
        numericAttributes[attr].min ? numericAttributes[attr].min || 0 : ''
      )}---${encodeURIComponent(
        numericAttributes[attr].max ? numericAttributes[attr].max || 0 : ''
      )}`
    } else if (
      baseFilterValues?.numericAttributes[attr].min ||
      baseFilterValues?.numericAttributes[attr].max
    ) {
      hash += `&${attr}=none`
    }
  }

  if (flags.length > 0 || baseFilterValues?.flags?.length || 0 > 0) {
    const baseValues = baseFilterValues?.flags || []
    const changedValues = flags.filter((flag) => !baseValues.includes(flag))
    if (changedValues.length) {
      hash += `&_F=${changedValues.map(encodeURIComponent).join('---')}`
    }
  }

  if (category) {
    hash += `&category=${encodeURIComponent(category)}`
  }
  const regex = new RegExp('#q=([^&]*)$')
  hasFilterValue = hash !== '' && !regex.test(hash)
  if (page && page !== (baseFilterValues?.page ?? 0)) {
    hash += `&page=${page + 1}`
  }

  if (hash === '#q=') hash = ''

  return { hash, hasFilterValue }
}

export function deserialize(
  hash = '',
  attributes: t.AttributeDef[] = [],
  presetFilterValues?: t.FilterValues
): t.FilterValues {
  const newFilterValues = JSON.parse(
    JSON.stringify(presetFilterValues || defaultFilterValues)
  )

  const queryMatch = hash.match(/#q=([^&]*)/)
  if (queryMatch) newFilterValues.query = decodeURIComponent(queryMatch[1])

  const pageMatch = hash.match(/&page=([^&]*)/)
  if (pageMatch) newFilterValues.page = parseInt(pageMatch[1]) - 1

  const priceRegex = new RegExp(`&price=([^&]*)`)
  const priceMatch = hash.match(priceRegex)

  if (priceMatch) {
    if (priceMatch[1] === 'none') {
      newFilterValues.minPrice = null
      newFilterValues.maxPrice = null
    } else {
      const price = priceMatch[1].split('---')
      newFilterValues.minPrice = parseFloat(price[0])
      newFilterValues.maxPrice = parseFloat(price[1])
    }
  }

  const sortingRegex = new RegExp(`&sorting=([^&]*)`)
  const sortingMatch = hash.match(sortingRegex)

  if (sortingMatch) {
    newFilterValues.index = sortingMatch[1] as t.Index
  }

  for (const attr of attributes) {
    const regex = new RegExp(`&${attr.key}=([^&]*)`)
    const match = hash.match(regex)
    if (match) {
      if (attr.filtertype !== 'rangeslider') {
        const values = match[1].split('---').map(decodeURIComponent)
        for (const value of values) {
          if (value.startsWith('!')) {
            newFilterValues.attributes[attr.key] = newFilterValues.attributes[
              attr.key
            ].filter((v) => v !== value.slice(1))
          } else {
            newFilterValues.attributes[attr.key] = [
              ...(newFilterValues.attributes[attr.key] ?? []),
              value
            ]
          }
        }
      } else {
        if (match[1] === 'none') {
          newFilterValues.numericAttributes[attr.key] = {
            min: null,
            max: null
          }
          continue
        }
        const [min, max] = match[1].split('---')

        newFilterValues.numericAttributes[attr.key] = {
          min: min ? parseFloat(min) : null,
          max: max ? parseFloat(max) : null
        }
      }
    }
  }

  // flags
  {
    const regex = new RegExp(`&_F=([^&]*)`)
    const match = hash.match(regex)
    if (match) {
      const flags = match[1].split('---').map(decodeURIComponent)
      newFilterValues.flags = Array.from(
        new Set([...newFilterValues.flags, ...flags])
      )
    }
  }

  const categoryMatch = hash.match(/&category=([^&]*)/)
  if (categoryMatch) {
    newFilterValues.category = decodeURIComponent(categoryMatch[1])
  }

  return newFilterValues
}
