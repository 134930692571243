import { dispatchEvent } from 'redux-ruleset'
export const LISTING_CONTEXT_BRAND_NAME: 'PageLoad/LISTING_CONTEXT_BRAND_NAME' =
  'PageLoad/LISTING_CONTEXT_BRAND_NAME'
export const LISTING_PAGE_CONTEXT: 'PageLoad/LISTING_PAGE_CONTEXT' =
  'PageLoad/LISTING_PAGE_CONTEXT'

export const listingContextBrandName = (brandName: string) =>
  dispatchEvent({
    type: LISTING_CONTEXT_BRAND_NAME,
    meta: {},
    payload: brandName
  })

export const listingPageContext = (pageContext: {
  type: string
  dyPath: string
}) =>
  dispatchEvent({
    type: LISTING_PAGE_CONTEXT,
    meta: {},
    payload: pageContext
  })

export type ListingContextBrandName = ReturnType<typeof listingContextBrandName>
export type ListingPageContext = ReturnType<typeof listingPageContext>

export type Event = ListingContextBrandName | ListingPageContext

declare global {
  interface RulesetDispatchEvents {
    'pageLoad/ListingContextBrandName': Event
  }
}
