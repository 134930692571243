import { pageTypeInPath } from 'utils/defaultRegex'
import config from 'config'
import capitalizeFirstLetter from './capitalizeFirstLetter'

export default (path: string) => {
  if (pageTypeInPath(path, '$'))
    return { pageType: 'Home' as 'Home', pageName: 'Start' }
  if (pageTypeInPath(path, 'pdp'))
    return { pageType: 'Product' as 'Product', pageName: 'Productpage' }
  if (pageTypeInPath(path, 'category'))
    return {
      pageType: 'Category' as 'Category',
      pageName: path.split('/').slice(-2, -1).pop() || '',
      path: path.split('/').slice(3, -1) || []
    }

  if (pageTypeInPath(path, 'series'))
    return {
      pageType: 'Series' as 'Series',
      pageName: path.split('/').slice(-2, -1).pop() || '',
      path: path.split('/').slice(3, -1) || []
    }
  if (pageTypeInPath(path, 'search'))
    return {
      pageType: 'Search' as 'Search',
      pageName: 'Search',
      path: ['search']
    }
  if (pageTypeInPath(path, 'checkout'))
    return { pageType: 'Checkout' as 'Checkout', pageName: 'Checkout' }

  if (pageTypeInPath(path, 'helpandservice'))
    return {
      pageType: 'Service' as 'Service',
      pageName: path.split('/').pop() || ''
    }
  if (pageTypeInPath(path, 'cart'))
    return { pageType: 'Cart' as 'Cart', pageName: 'Cart' }
  if (pageTypeInPath(path, 't'))
    return {
      pageType: 'Tag' as 'Tag',
      pageName: path.split('/').slice(-2, -1).pop() || '',
      path:
        path
          .replace(config.locale + '/', '')
          .split('/')
          .slice(2, -1)
          .map((str) => capitalizeFirstLetter(str)) || []
    }

  if (pageTypeInPath(path, 'b2c') || pageTypeInPath(path, 'b2b'))
    return { pageType: 'Home' as 'Home', pageName: 'Start' }

  return {
    pageType: 'Other' as 'Other',
    pageName: ''
  }
}
