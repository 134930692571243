import * as React from 'react'
import styled from 'styled-components'
import EyeIcon from 'assets/eye.svg'
import EyeClosedIcon from 'assets/eye-closed.svg'
import theme from 'theme'

export type Props = {
  value: string
  className?: string
  type: 'email' | 'text' | 'password' | 'number'
  hideNumberArrows?: boolean
  disabled?: boolean
  onChange: (val: string) => void
  onFocus?: () => void
  onBlur?: () => void
  autoComplete?: string
  placeholder: string
  error: React.ReactElement | string | null
  'data-cy-state'?: string
  'data-cy-handle': string
  'data-cy-state-error'?: string
  inputRef?: React.Ref<HTMLInputElement>
}

export default function Input(props: Props) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  return (
    <Wrapper
      className={'Input ' + (props.className || '')}
      $hasError={!!props.error}
      $hideNumberArrows={!!props.hideNumberArrows}
      data-cy-state={props['data-cy-state']}
    >
      <div className="input-contain">
        <input
          disabled={props.disabled || false}
          type={showPassword ? 'text' : props.type}
          data-cy-handle={props['data-cy-handle']}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          // id="fname"
          // name="fname"
          autoComplete={props.autoComplete}
          value={props.value}
          ref={props.inputRef}
          // aria-labelledby="placeholder-fname"
        />
        <label
          className="placeholder-text"
          // htmlFor="fname"
          // id="placeholder-fname"
        >
          <div className="text">{props.placeholder}</div>
        </label>
      </div>
      {props.type === 'password' && (
        // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state, @kaminrunde/firescout/onclick-needs-handle
        <div
          className="password-toggle"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
            <EyeClosedIcon style={{ marginTop: '1px' }} />
          ) : (
            <EyeIcon />
          )}
        </div>
      )}

      {props.error && (
        <div className="error" data-cy-state={props['data-cy-state-error']}>
          {props.error}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $hasError: boolean; $hideNumberArrows: boolean }>`
  position: relative;
  .input-contain {
    position: relative;
    > input {
      height: 52px;
      width: 100%;
      border: 1px solid
        ${(p) =>
          p.$hasError ? theme.colors.accent.red : theme.colors.shade.b2};
      border-radius: 3px;
      padding-top: 12px;
      padding-left: 15px;
    }
    > input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
    ${(p) =>
      p.$hideNumberArrows
        ? `> input[type='number'] {
          -moz-appearance: textfield;
        }

        > input::-webkit-outer-spin-button,
        > input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }`
        : ''};

    > .placeholder-text {
      padding-left: 15px;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      > .text {
        ${theme.ty('r-base')}
        background-color: transparent;
        color: ${(p) =>
          p.$hasError ? theme.colors.accent.red : theme.colors.shade.b2};
        transform: translate(0);
        transition:
          transform 0.15s ease-out,
          font-size 0.15s ease-out,
          background-color 0.2s ease-out,
          color 0.15s ease-out;
      }
    }

    > input,
    .placeholder-text {
      ${theme.ty('r-base')}
    }
    > input:focus {
      outline: none;
      border-color: ${(p) =>
        p.$hasError ? theme.colors.accent.red : theme.colors.primary};
    }
    > :not(input[value='']) + .placeholder-text > .text {
      background-color: transparent;
      ${theme.ty('r-s')}
      color: ${(p) =>
        p.$hasError ? theme.colors.accent.red : theme.colors.shade.b2};
      transform: translate(0, -80%);
    }
    > input:focus + .placeholder-text > .text {
      background-color: transparent;
      ${theme.ty('r-s')}
      color: ${theme.colors.shade.b2};
      transform: translate(0, -80%);
      border-color: ${theme.colors.primary};
      color: ${(p) =>
        p.$hasError ? theme.colors.accent.red : theme.colors.primary};
    }
  }

  > .password-toggle {
    position: absolute;
    top: 14px;
    right: ${theme.spacing('s')};
    cursor: pointer;
    > svg {
      width: 25px;
      height: 25px;
      > path {
        fill: ${theme.colors.shade.b4};
      }
    }
  }

  > .error {
    margin-top: ${theme.spacing('xxs')};
    font-size: 12px;
    margin-left: ${theme.spacing('s')};
    color: ${theme.colors.accent.red};
  }
`
