import * as at from './const'
import { Action } from './actions'

export type State = {
  searchValue: string
  showFlyoutNavi: boolean
  sidebarCategory: string | null
  currentCategory: string | null
  modalContent: any
  optedIn: boolean
  locationChangeCounter: number
  newsletterIntentVisible: boolean
  registerNewsletterNL: boolean
  pdpFilterDrawerOpen: boolean
  pdpSizeTableOpen: boolean
}

export const defaultState: State = {
  showFlyoutNavi: false,
  searchValue: '',
  sidebarCategory: null,
  currentCategory: null,
  modalContent: null,
  optedIn: false,
  locationChangeCounter: 0,
  newsletterIntentVisible: false,
  registerNewsletterNL: false,
  pdpFilterDrawerOpen: false,
  pdpSizeTableOpen: false
}

export default function reducer(state: State = defaultState, action: Action) {
  switch (action.type) {
    case at.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload
      }
    case at.TOGGLE_NAVI:
      return {
        ...state,
        showFlyoutNavi: !state.showFlyoutNavi
      }
    case at.SET_SIDEBAR_CATEGORY:
      return {
        ...state,
        sidebarCategory: action.payload,
        showFlyoutNavi: true
      }
    case at.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload
      }

    case at.CLEAR:
      if (action.payload)
        return {
          ...defaultState,
          [action.payload]: state[action.payload],
          optedIn: state.optedIn,
          //WDV-3313 NewsletterIntentLayer
          locationChangeCounter: state.locationChangeCounter,
          newsletterNL: state.registerNewsletterNL
          //WDV-3313 NewsletterIntentLayer
        }

      return {
        ...defaultState
      }
    case at.SET_MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.payload
      }
    case at.SET_OPT_IN:
      return {
        ...state,
        optedIn: true
      }
    //WDV-3313 NewsletterIntentLayer

    case at.SET_INTENT_VISIBLE:
      return {
        ...state,
        newsletterIntentVisible: action.payload.visible
      }
    case at.INCREMENT_LOCATION_CHANGE_COUNTER:
      return {
        ...state,
        locationChangeCounter: state.locationChangeCounter + 1
      }
    case at.REGISTER_NEWSLETTER_NL:
      return {
        ...state,
        registerNewsletterNL: true
      }
    case at.SET_PDP_FILTER_DRAWER_OPEN: {
      return {
        ...state,
        pdpFilterDrawerOpen: action.payload,
        // Close the size table, if the filter drawer is opened. Otherwise leave its state unchanged.
        pdpSizeTableOpen: action.payload ? false : state.pdpSizeTableOpen
      }
    }
    case at.SET_PDP_SIZE_TABLE_OPEN: {
      return {
        ...state,
        pdpSizeTableOpen: action.payload,
        // Close the filter drawer, if the size table is opened. Otherwise leave its state unchanged.
        pdpFilterDrawerOpen: action.payload ? false : state.pdpFilterDrawerOpen
      }
    }
    //WDV-3313 NewsletterIntentLayer
    default:
      return state
  }
}
