import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import PromotionIcon from './PromotionIcon'
import InfoContainer from './InfoContainer'
import BackgroundImages from './BackgroundImages'

type Props = {
  footer: {
    newsletter: {
      labels: string[]
      title: string
      disclaimer: string
      iconSource: string
    }
  }
  'data-cy-state': string
}

export default function Newsletter(props: Props) {
  const { labels, disclaimer, title, iconSource } = props.footer.newsletter

  return (
    <Wrapper
      className="Newsletter"
      data-cy-state={props['data-cy-state']}
      data-cy-collection="Newsletter"
      id="newsletter-registration"
    >
      <div>
        <PromotionIcon iconSource={iconSource} />

        <InfoContainer
          disclaimer={disclaimer}
          hasPromotionIcon={!!iconSource}
          labels={labels}
          title={title}
        />

        <BackgroundImages />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;

  > div {
    max-width: 1300px;
    margin: ${theme.spacing('l')} ${theme.spacing('xs')};
    border-radius: 15px 15px 15px 0px;
    overflow: hidden;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: ${theme.spacing('m')};
    padding-bottom: ${theme.spacing('xl')};
    width: 100%;
    position: relative;

    @media (min-width: ${theme.ms.MD}px) {
      align-items: center;
      column-gap: ${theme.spacing('xl')};
      padding-bottom: ${theme.spacing('m')};
    }
  }
`
