import Personalization from 'theme/atoms/Personalization'
import * as t from './types'
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FetchContentResult } from 'utils/dy-hybrid/endpoints/fetchContent'
import theme from 'theme'
import { configurableBackgroundColors, configurableTextColors } from './const'

const mapDy =
  (prevProps?: t.Props) =>
  (config: FetchContentResult<t.DyResponse>): t.Props => {
    const content = config.content

    return {
      countdown: prevProps?.countdown || content.Countdown,
      prefix: prevProps?.prefix || content.Prefix,
      titel: prevProps?.titel || content.Titel,
      link: prevProps?.link || content.Link,
      buttonLink: prevProps?.buttonLink || content.ButtonLink,
      code: prevProps?.code || content.Code,
      buttonPrefix: prevProps?.buttonPrefix || content.ButtonPrefix,
      buttonText: prevProps?.buttonText || content.ButtonText,
      eecTracking: {
        campaignId: 'dy-notificationbar',
        name: config.context.experienceName,
        creative: config.context.variationName
      },
      textColor:
        content.TextColor || prevProps?.textColor || theme.colors.black,
      backgroundColor:
        content.BackgroundColor ||
        prevProps?.backgroundColor ||
        theme.colors.brand.lusini
    }
  }
export default function withDy(
  Component: React.JSXElementConstructor<t.Props>
) {
  return function Wrapper(props: t.Props) {
    const gq: t.Gq = useStaticQuery(graphql`
      query appNotificationBar {
        header {
          notificationBar
        }
      }
    `)
    if (
      !gq.header ||
      !gq.header.notificationBar ||
      gq.header.notificationBar === ''
    )
      return null
    return (
      <Personalization<t.Props, t.DyResponse>
        preventDySeenEvent
        processResponse={mapDy(props)}
        selector={gq.header.notificationBar}
        showDefaultDataByControlGroup={true}
        shape={[
          'object',
          {
            Countdown: 'string',
            Prefix: 'string',
            Titel: 'string',
            Link: ['optional', 'string'],
            ButtonLink: ['optional', 'string'],
            Code: ['optional', 'string'],
            ButtonPrefix: ['optional', 'string'],
            ButtonText: ['optional', 'string'],
            TextColor: ['optional', ['enum', configurableTextColors]],
            BackgroundColor: [
              'optional',
              ['enum', configurableBackgroundColors]
            ]
          }
        ]}
        initialData={{ ...props }}
        strategy="BLUR"
        render={(data) => <Component {...data} />}
      />
    )
  }
}
