import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'

type Props = {
  histogram: {
    label: number
    percent: number
    count: number
  }[]
}

export default function RatingHistogram(props: Props) {
  const { histogram } = props
  const t = useTranslations<'partials-ProductReviews'>()

  return (
    <Wrapper className="rating-histogram">
      {histogram
        .sort((a, b) => b.label - a.label)
        .map((item, index) => {
          return (
            <div className="rating-histogram-item" key={index}>
              <div className="rating-histogram-item__label">
                {item.label} {t('histogram_stars')}
              </div>
              <div className="rating-histogram-item__bar">
                <div
                  className="rating-histogram-item__bar-fill"
                  style={{ width: `${item.percent}%` }}
                ></div>
              </div>
              <div className="rating-histogram-item__count">
                {' '}
                {item.percent}% ({item.count})
              </div>
            </div>
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing('xs')};
  > .rating-histogram-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing('m')};
    > .rating-histogram-item__label {
      margin-right: 0.5rem;
    }
    > .rating-histogram-item__bar {
      flex: 3;
      height: 1.5rem;
      background-color: ${theme.colors.shade.primaryUltraBright};
      position: relative;
      > .rating-histogram-item__bar-fill {
        height: 100%;
        background-color: ${theme.colors.primary};
      }
    }
    > .rating-histogram-item__count {
      margin-left: 0.5rem;
      text-align: center;
      min-width: 4rem;
    }
  }
`
