declare global {
  interface Window {
    dataLayer: any[]
  }
}

export function onRouteUpdate() {
  setTimeout(() => {
    window.dataLayer.push({ event: 'gatsby-route-change' })
  }, 50)
}
