import { addRule } from 'redux-ruleset'
import * as productInfomationEvents from 'theme/templates/PDP/InformationBox/events'

addRule({
  id: 'feature/track-order-specimen',
  target: [productInfomationEvents.ORDER_SPECIMEN_CLICK],
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'sample_order_button'
    })
  }
})
