import { dispatchEvent } from 'redux-ruleset'

export const SIZE_CHART_LINK_VISIBLE: 'SizeChartLinkVisible/SIZE_CHART_LINK_VISIBLE' =
  'SizeChartLinkVisible/SIZE_CHART_LINK_VISIBLE'
export const SIZE_CHART_LINK_CLICK: 'SizeChartLinkClick/SIZE_CHART_LINK_CLICK' =
  'SizeChartLinkClick/SIZE_CHART_LINK_CLICK'

export const sizeChartLinkVisible = () =>
  dispatchEvent({
    type: SIZE_CHART_LINK_VISIBLE,
    meta: {}
  })
export const sizeChartLinkClick = () =>
  dispatchEvent({
    type: SIZE_CHART_LINK_CLICK,
    meta: {}
  })

export type SizeChartLinkClick = ReturnType<typeof sizeChartLinkClick>

export type SizeChartLinkVisible = ReturnType<typeof sizeChartLinkVisible>

export type Event = SizeChartLinkClick | SizeChartLinkVisible

declare global {
  interface RulesetDispatchEvents {
    'templates/PDP/FilterDrawer/Filter': Event
  }
}
