import { GatsbyBrowser } from 'gatsby'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location,
  prevLocation
}) => {
  if (typeof window !== 'undefined') {
    if (window.__module_navigation_location_change) {
      try {
        window.__module_navigation_location_change(location, prevLocation)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('navigation cb failed', e)
      }
    }
  }
}

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({
  location,
  prevLocation
}) => {
  if (typeof window !== 'undefined') {
    if (window.__module_navigation_location_pre_change) {
      try {
        window.__module_navigation_location_pre_change(location, prevLocation)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('pre-navigation cb failed', e)
      }
    }
  }
}

// Extend the Window interface to include the custom properties
declare global {
  interface Window {
    __module_navigation_location_change?: (
      location: Location,
      prevLocation: Location | null
    ) => void
    __module_navigation_location_pre_change: (
      location: Location,
      prevLocation: Location | null
    ) => void
  }
}
