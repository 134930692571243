import { dispatchEvent } from 'redux-ruleset'

export const PDP_3D_OPEN: 'Info3DModal/PDP_3D_OPEN' = 'Info3DModal/PDP_3D_OPEN'

export const PDP_3D_INFO: 'Info3DModal/PDP_3D_INFO' = 'Info3DModal/PDP_3D_INFO'

export const pdp3DOpen = (sku: string, title: string) =>
  dispatchEvent({
    type: PDP_3D_OPEN,
    meta: { title },
    payload: sku
  })

export const pdp3DInfo = (sku: string, title: string) =>
  dispatchEvent({
    type: PDP_3D_INFO,
    meta: { title },
    payload: sku
  })

export type Pdp3DOpen = ReturnType<typeof pdp3DOpen>
export type Pdp3DInfo = ReturnType<typeof pdp3DInfo>

export type Event = Pdp3DOpen | Pdp3DInfo

declare global {
  interface RulesetDispatchEvents {
    'templates/PDP/BuyBox/Info3DModal': Event
  }
}
