/* eslint-disable @kaminrunde/firescout/jsx-expression-needs-state */
import * as React from 'react'
import { Helmet } from 'react-helmet'
import config from 'config'
import * as t from 'modules/productDetail/types'

type Props = {
  title: string
  description: string
  type: 'profile' | 'website' | 'article' | 'blog'
  seoNoFollow?: boolean
  seoNoIndex?: boolean
  prevPath?: string
  nextPath?: string
  hrefLang?: {
    url: string
    hrefLang: string
  }[]
  location: {
    origin: string
    pathname: string
  }
  breadcrumbs?: {
    label: string
    link?: string
  }[]
  product?: Partial<t.Variant>
  // publishTime?: string
  // author?: string
  canonicalUrl?: string | false
  // prevPath?: string | false
  // nextPath?: string | false
  img?: string
  richSearchResultJsonLd?: string
}

export default function Seo(props: Props) {
  let ldJson
  if (props.breadcrumbs) {
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: []
    }
    props.breadcrumbs.map((item, i) => {
      ldJson.itemListElement.push({
        '@type': 'ListItem',
        position: i + 1,
        name: item.label,
        item: item.link
          ? config.baseUrl + '/' + config.locale + item.link
          : undefined
      })
    })
  }
  const removeHreflang =
    /** pages with noindex must not have a href-lang (https://github.com/eBusEmmos/lusini/issues/2361) */
    props.seoNoIndex ||
    /**
     * urls with non-self-referencing canonicals must not have
     * a href-lang (https://github.com/eBusEmmos/lusini/issues/2361)
     */
    (props.canonicalUrl &&
      !props.canonicalUrl.endsWith(props.location.pathname)) ||
    /** we can show hreflang */
    false

  return (
    <Helmet>
      <html lang={config.locale} />
      <title>{props.title}</title>
      {props.richSearchResultJsonLd && props.richSearchResultJsonLd.trim() && (
        <script type="application/ld+json">
          {props.richSearchResultJsonLd}
        </script>
      )}
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/images/favicon.svg"
      />
      {props.canonicalUrl && <link rel="canonical" href={props.canonicalUrl} />}
      <meta httpEquiv="content-language" content={config.locale} />
      <meta
        name="robots"
        content={`${props.seoNoFollow ? 'nofollow' : 'follow'},${
          props.seoNoIndex ? 'noindex' : 'index'
        }`}
      />
      {!removeHreflang &&
        props.hrefLang &&
        props.hrefLang.map((row) => (
          <link
            key={row.hrefLang}
            rel="alternate"
            href={row.url}
            hrefLang={row.hrefLang}
          />
        ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href="https://www.lusini.com/"
      />
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content="LUSINI" />
      <meta property="og:url" content={props.location.pathname} />
      <meta property="og:type" content={props.type} />
      <meta property="og:description" content={props.description} />
      <meta property="og:locale" content={config.locale} />
      <meta
        property="og:image"
        content={
          props.img ||
          'https://res.cloudinary.com/lusini/image/upload/v1618839582/lusini-meta-logo.jpg'
        }
      ></meta>
      {props.prevPath && <link rel="prev" href={props.prevPath} />}
      {props.nextPath && <link rel="next" href={props.nextPath} />}
      {props.breadcrumbs && (
        <script
          data-react-helmet="true"
          id="breadcrumbs"
          type="application/ld+json"
        >
          {JSON.stringify(ldJson)}
        </script>
      )}
    </Helmet>
  )
}
