import { dispatchEvent } from 'redux-ruleset'
export const NEWSLETTER_REGISTERED: 'NewsletterRegistrationForm/NEWSLETTER_REGISTERED' =
  'NewsletterRegistrationForm/NEWSLETTER_REGISTERED'

export const newsletterRegistered = () =>
  dispatchEvent({
    type: 'NewsletterRegistrationForm/NEWSLETTER_REGISTERED' as const,
    meta: {}
  })

export type OpenProductReviews = ReturnType<typeof newsletterRegistered>

export type Event = OpenProductReviews

declare global {
  interface RulesetDispatchEvents {
    'theme/organisms/NewsletterRegistrationForm': Event
  }
}
