import useWishlist from 'hooks/account/lists/useWishlist'
import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import HeartIcon from 'assets/heart.svg'
import theme from 'theme'
import usePrevValue from 'hooks/usePrevValue'
import useTranslations from 'hooks/useTranslations'
import { useSnackbar } from 'modules/Snackbar'

export default function WishlistIcon() {
  const wishlist = useWishlist()
  const t = useTranslations<'app-Header'>()
  const prevSize = usePrevValue(wishlist.sw6uuids.size)
  const prevLoading = usePrevValue(wishlist.isLoading)
  const $wishlistLink = React.useRef<HTMLAnchorElement>(null)

  const snackbar = useSnackbar()
  React.useEffect(() => {
    if (prevLoading) return
    if (!location.pathname.includes('account')) {
      if (prevSize > wishlist.sw6uuids.size) {
        const infoText = t('header_wishlisticon_remove_product', {
          WISHLIST: '-',
          __transformers: {
            WISHLIST: (_, vars) => (
              <Link to="/wishlist">{vars.WISHLIST_label}</Link>
            )
          }
        })
        snackbar.addMessage({
          type: 'Info',
          customMessage: infoText,
          productId: ''
        })
      }

      if (prevSize < wishlist.sw6uuids.size) {
        const infoText = t('header_wishlisticon_add_product', {
          WISHLIST: '-',
          __transformers: {
            WISHLIST: (_, vars) => (
              <Link to="/wishlist">{vars.WISHLIST_label}</Link>
            )
          }
        })
        snackbar.addMessage({
          type: 'Info',
          customMessage: infoText,
          productId: ''
        })
      }
    }
  }, [wishlist.sw6uuids])

  return (
    <Wrapper className="WishlistIcon">
      <Link
        ref={$wishlistLink}
        to="/wishlist"
        title={t.asText($wishlistLink, 'header_wishlist_a11y_label')}
        className="heart-icon-wrapper"
      >
        <HeartIcon />
        {Boolean(wishlist.sw6uuids.size) && (
          <span data-cy-state="show-wishlist-items-amount" className="badge">
            {wishlist.sw6uuids.size}
          </span>
        )}
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  text-decoration: none;
  display: block;

  &.desktop {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${theme.ms.LG}px) {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: flex;
    }
  }

  > .heart-icon-wrapper {
    display: block;
    margin-bottom: -5px;
    > svg {
      height: 1.5rem;
      width: 1.5rem;
      g {
        fill: ${theme.colors.primary};
      }

      @media (min-width: ${theme.ms.MD}px) {
        height: 1.75rem;
        width: 1.75rem;
      }
    }
    > .badge {
      display: block;
      box-sizing: border-box;
      position: absolute;
      right: -7px;
      top: -7px;
      width: 1.125rem;
      height: 1.125rem;
      cursor: pointer;
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
      border: 2px solid ${theme.colors.white};
      border-radius: 50%;
      ${theme.ty('rc-xs', null, 'rc-s')}
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: ${theme.ms.MD}px) {
        right: -10px;
        top: -10px;
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }
`
