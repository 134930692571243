import { dispatchEvent } from 'redux-ruleset'

export const invalidMail = () =>
  dispatchEvent({
    type: 'NewsletterIntentContent/INVALID_MAIL' as const,
    payload: {}
  })

declare global {
  interface RulesetDispatchEvents {
    'app/NewsletterIntentContent': ReturnType<typeof invalidMail>
  }
}
