import { addRule } from 'redux-ruleset'
import * as shareCartEvents from 'theme/molecules/account/CartSummary/ShareCart/events'

addRule({
  id: 'dl/SHARE_CART_OPEN_MODAL',

  target: [shareCartEvents.SHARE_CART_OPEN_MODAL],
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'share_shopping_cart',
      action: 'open'
    })
  }
})

addRule({
  id: 'dl/SHARE_CART_COPY_LINK',
  target: [shareCartEvents.SHARE_CART_COPY_LINK],
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'share_shopping_cart',
      action: 'copy_link'
    })
  }
})

addRule({
  id: 'dl/SHARE_CART_DOWNLOAD',
  target: [shareCartEvents.SHARE_CART_DOWNLOAD],
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'share_shopping_cart',
      action: 'download_cart'
    })
  }
})

addRule({
  id: 'dl/SHARE_CART_SEND_VIA_MAIL',
  target: [shareCartEvents.SHARE_CART_SEND_VIA_MAIL],
  output: '#dl-event',
  consequence: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'share_shopping_cart',
      action: 'send_via_email'
    })
  }
})
