import { addRule } from 'redux-ruleset'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'prepayment-clipboards/COPYTOCLIPBOARD',
  target: 'checkout/COPY_TO_CLIPBOARD',
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'advance_payment_copy',
      action: action.fieldName
    })
  }
})
