import * as React from 'react'
import styled, { css } from 'styled-components'
import InfoIcon from 'assets/info-icon-lusini.svg'
import TickIcon from 'assets/tick-icon.svg'
import useTranslations from 'hooks/useTranslations'
import { ResponseType } from '../useNewsletterSubmit'
import theme from 'theme'

type Props = {
  status: ResponseType | null
}

type Obj = Record<
  ResponseType,
  {
    icon: React.ReactElement
    headline: React.ReactElement
    info: React.ReactElement
  }
>

export default function NewsletterResponse(props: Props) {
  const t = useTranslations<'app-Footer'>()

  const obj: Obj = {
    already_optin: {
      icon: <InfoIcon />,
      headline: t('newsletter_response_already_optin_headline'),
      info: t('newsletter_response_already_optin_info')
    },
    failure: {
      icon: <InfoIcon />,
      headline: t('newsletter_response_failure_headline'),
      info: t('newsletter_response_failure_info')
    },
    empty_mail: {
      icon: <InfoIcon />,
      headline: t('newsletter_response_empty_mail_headline'),
      info: t('newsletter_response_empty_mail_info')
    },
    success: {
      icon: <TickIcon />,
      headline: t('newsletter_response_success_headline'),
      info: t('newsletter_response_success_info')
    },
    success_no_mail: {
      icon: <TickIcon />,
      headline: t('newsletter_response_success_no_mail_headline'),
      info: t('newsletter_response_success_no_mail_info')
    }
  }

  const data = obj[props.status || '']

  if (!data) return null

  return (
    <Wrapper className="NewsletterResponse" $status={props.status}>
      <div className="headline">
        {data.icon}
        {data.headline}
      </div>
      <div className="info">{data.info}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $status: ResponseType | null }>`
  position: relative;
  background-color: ${theme.colors.white};
  > .headline {
    > svg {
      margin-right: ${theme.spacing('xxs')};
      > path {
        fill: ${theme.colors.white};
      }
    }
    display: flex;
    padding: ${theme.spacing('xs')} ${theme.spacing('m')};
    ${theme.ty('r-base', '700')}
  }
  > .info {
    color: ${theme.colors.b0};
    padding: ${theme.spacing('m')};
    ${theme.ty('r-base')}
  }

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: -36px;
    left: 5%;
    border: solid transparent;
    border-width: 17px;
  }

  ${(props) =>
    props.$status === 'already_optin' &&
    css`
      border: 2px solid ${theme.colors.accent.blue};
      > .headline {
        color: ${theme.colors.white};
        background-color: ${theme.colors.accent.blue};
      }
      &::after {
        border-bottom-color: ${theme.colors.accent.blue};
      }
    `}

  ${(props) =>
    (props.$status === 'success' || props.$status === 'success_no_mail') &&
    css`
      border: 2px solid ${theme.colors.accent.green};
      > .headline {
        color: ${theme.colors.white};
        background-color: ${theme.colors.accent.green};
      }
      &::after {
        display: none;
      }
    `}

  ${(props) =>
    (props.$status === 'empty_mail' || props.$status === 'failure') &&
    css`
      border: 2px solid ${theme.colors.accent.red};
      > .headline {
        color: ${theme.colors.white};
        background-color: ${theme.colors.accent.red};
      }
      &::after {
        border-bottom-color: ${theme.colors.accent.red};
      }
    `}
`
