import { dispatchEvent } from 'redux-ruleset'

export const copyCodeToClipboard = (code: string) =>
  dispatchEvent({
    type: 'NotificationBar/COPY_CODE_TO_CLIPBOARD' as const,
    payload: { code }
  })

declare global {
  interface RulesetDispatchEvents {
    'app/NotificationBar': ReturnType<typeof copyCodeToClipboard>
  }
}
