import * as React from 'react'
import useTranslations from 'hooks/useTranslations'
import Link from 'theme/atoms/Link'
import styled from 'styled-components'
import theme from 'theme'
import Accordion from './Accordion'
import { Gq } from '../../types'
import Container from 'theme/atoms/Container'
import Trusted from './Trusted'
import Social from './Social'

export default function Content(props: Omit<Gq, 'brandName'>) {
  const t = useTranslations<'app-Footer'>()

  return (
    <div style={{ backgroundColor: `${theme.colors.shade.b6}` }}>
      <Container>
        <Wrapper className="Content">
          <div className="content">
            <div className="lists mobile">
              <Accordion
                label={t('footer_column_helpandservice')}
                data-cy-handle="accordion-toggle"
                data-cy-state="accordion-open"
              >
                <ul>
                  {props.footer.serviceLinks.map((service) => (
                    <li key={service.urlPath}>
                      <Link to={service.urlPath}>{service.title}</Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
              <Accordion
                label={t('footer_column_about')}
                data-cy-handle="accordion-toggle"
                data-cy-state="accordion-open"
              >
                <ul>
                  {props.footer.aboutLinks.map((service) => (
                    <li key={service.urlPath}>
                      <Link to={service.urlPath}>{service.title}</Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
              <Accordion
                label={t('footer_column_advantages')}
                data-cy-handle="accordion-toggle"
                data-cy-state="accordion-open"
              >
                <ul>
                  {props.footer.advantages.map((service, i) => (
                    <li key={i}>
                      <Link to={service.link}>{service.label}</Link>
                    </li>
                  ))}
                </ul>
              </Accordion>
            </div>
            <div className="lists desktop">
              <div>
                <div className="small-headline">
                  {t('footer_column_helpandservice')}
                </div>

                <ul>
                  {props.footer.serviceLinks.map((service) => (
                    <li key={service.urlPath}>
                      <Link to={service.urlPath}>{service.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <div className="small-headline">{t('footer_column_about')}</div>

                <ul>
                  {props.footer.aboutLinks.map((service) => (
                    <li key={service.urlPath}>
                      <Link to={service.urlPath}>{service.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="advantages">
                <div className="small-headline">
                  {t('footer_column_advantages')}
                </div>

                <ul>
                  {props.footer.advantages.map((service, i) => (
                    <li key={i}>
                      <Link to={service.link}>{service.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr className="md-divider" />
            <Social footer={props.footer} />
            <Trusted footer={props.footer} />
          </div>
        </Wrapper>
      </Container>
    </div>
  )
}

const Wrapper = styled.div`
  padding: ${theme.spacing('s')};

  @media (min-width: ${theme.ms.MD}px) {
    padding: ${theme.spacing('m')};
  }

  > .content {
    display: grid;

    > .md-divider {
      display: none;
      @media (min-width: ${theme.ms.MD}px) {
        display: block;
      }
      @media (min-width: ${theme.ms.LG}px) {
        display: none;
      }
    }

    @media (min-width: ${theme.ms.LG}px) {
      grid-template-columns: 3fr 1.5fr 0.5fr;
    }

    > .lists {
      &.mobile {
        @media (min-width: ${theme.ms.MD}px) {
          display: none;
        }
      }
      &.desktop {
        display: none;
        ${theme.ty('rc-s', '700')}
        @media (min-width: ${theme.ms.MD}px) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        }
        & div > div > .small-headline {
          @media (min-width: ${theme.ms.LG}px) {
            ${theme.ty('rc-l', '700')}
          }
        }
        & div > .small-headline {
          ${theme.ty('rc-s', '700')}
          @media (min-width: ${theme.ms.LG}px) {
            ${theme.ty('rc-l', '700')}
          }
        }
      }

      margin-bottom: ${theme.spacing('m')};
      @media (min-width: ${theme.ms.MD}px) {
        margin-bottom: 0;
      }

      li {
        ${theme._ty([13, 0.32, 18], theme.fontSpecial, '400')}
        margin-bottom: ${theme.spacing('xxs')};
        @media (min-width: ${theme.ms.XL}px) {
          margin-bottom: ${theme.spacing('xs')};
          ${theme._ty([16, 0.4, 24], theme.fontSpecial, '400')}
        }
        > a {
          ${theme._ty([13, 0.32, 18], theme.fontSpecial, '400')}
          margin-bottom: ${theme.spacing('xxs')};
          @media (min-width: ${theme.ms.XL}px) {
            margin-bottom: ${theme.spacing('xs')};
            ${theme._ty([16, 0.4, 24], theme.fontSpecial, '400')}
          }
        }
        > a {
          ${theme._ty([13, 0.32, 18], theme.fontSpecial, '400')}
          margin-bottom: ${theme.spacing('xxs')};
          @media (min-width: ${theme.ms.XL}px) {
            margin-bottom: ${theme.spacing('xs')};
            ${theme._ty([16, 0.4, 24], theme.fontSpecial, '400')}
          }
        }
      }

      > .advantages {
        > ul li {
        }
      }
      .brands {
        margin-top: 1.25rem;
        > .brands-list {
          display: flex;
          flex-wrap: wrap;

          > a {
            flex-grow: 1;
            width: 100%;
            ${theme._ty([13, 0.32, 24], theme.fontSpecial, '400')}
            margin-bottom: ${theme.spacing('xxs')};

            @media (min-width: ${theme.ms.MD}px) {
              width: 50%;
            }

            @media (min-width: ${theme.ms.XL}px) {
              margin-bottom: ${theme.spacing('xs')};
              ${theme._ty([16, 0.4, 24], theme.fontSpecial, '400')}
            }
          }
        }
      }
    }

    div.small-headline {
      ${theme._ty([16, 0, 22], theme.fontSpecial, '700')}
      margin-bottom: ${theme.spacing('xs')};
    }

    li {
      ${theme._ty([16, 0.4, 24], theme.fontSpecial, '400')}
      margin-bottom: ${theme.spacing('xs')};
    }
  }

  hr {
    border-bottom: 0;
    border-style: solid;
    color: ${theme.colors.shade.b4};
    width: 100%;
    margin-bottom: ${theme.spacing('m')};
  }
`
