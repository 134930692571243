export type Shape =
  | 'string'
  | 'number'
  | 'bool'
  | ['array', Shape]
  | ['object', Record<string, Shape>]
  | ['optional', Shape]
  | ['enum', readonly string[] | string[]]
  | 'url'

export function isValid(shape: Shape, data: any) {
  if (Array.isArray(shape)) {
    if (shape[0] === 'optional') {
      if (!data) return true
      return isValid(shape[1], data)
    } else if (shape[0] === 'array') {
      if (!Array.isArray(data)) return false
      for (const item of data) {
        if (!isValid(shape[1], item)) return false
      }
      return true
    } else if (shape[0] === 'enum') {
      return shape[1].includes(data)
    } else {
      if (Array.isArray(data) || data === null || typeof data !== 'object')
        return false
      for (const key in shape[1]) {
        if (!isValid(shape[1][key], data[key])) return false
      }
      return true
    }
  } else {
    switch (shape) {
      case 'url':
        return (
          typeof data === 'string' &&
          (data.startsWith('/') || /^https?:\/\//.test(data))
        )
      case 'bool':
        return typeof data === 'boolean'
      case 'number':
        return typeof data === 'number'
      case 'string':
        return typeof data === 'string'
      default:
        return false
    }
  }
}
