import { addRule } from 'redux-ruleset'
import config from 'config'
type DlEvent = any
const push = (evt: DlEvent) => {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(evt)
  }
}

const getCategoryPath = (categories) =>
  categories.lvl7?.[0].split('>').join('/') ||
  categories.lvl6?.[0].split('>').join('/') ||
  categories.lvl5?.[0].split('>').join('/') ||
  categories.lvl4?.[0].split('>').join('/') ||
  categories.lvl3?.[0].split('>').join('/') ||
  categories.lvl2?.[0].split('>').join('/') ||
  categories.lvl1?.[0].split('>').join('/') ||
  categories.lvl0?.[0].split('>').join('/') ||
  ''

const getMainCategoryPath = (categoryPath) => {
  const obj: Record<string, any> = {}
  const arr = categoryPath.split(' / ')
  // See https://jira.em-group.de/browse/WDV-3365
  // The first category is always named item_category since this is the requirement.
  obj.item_category = arr[0]
  for (let i = 1; i < arr.length; i++) {
    const catKey = 'item_category' + (i + 1)
    const catEntity = { [catKey]: arr[i] }
    Object.assign(obj, catEntity)
  }
  return obj
}

addRule({
  id: 'wishlist/AddToWishlist',
  target: 'WISHLIST_ADD',
  output: '#dl-event',
  consequence: (action) => {
    const { variant, listid, listname, item_list_id } = action.payload

    push({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: variant.sku,
            item_name: variant?.title,
            affiliation: '', //only in checkout-steps retrievable
            coupon: '', //only in checkout-steps retrievable
            currency: config.i18n.currency_ISO,
            discount: (variant?.prices.piecePseudoPriceNet > 0
              ? variant?.prices.piecePseudoPriceNet -
                variant?.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: listid, //assigend increamentaly for any product in the array
            item_brand: variant?.brand,
            ...getMainCategoryPath(getCategoryPath(variant?.categories)),
            item_list_id: item_list_id,
            item_list_name: listname,
            item_variant: variant,
            location_id: '', //nebulously defined
            price: variant.prices.packPriceNet,
            quantity: 1, // pack?  //not set unitl added to cart, or modified there
            delivery_date: 'availiable' //replace w/ actual date,
          }
        ]
      }
    })
  }
})

addRule({
  id: 'wishlist/RemoveFromWishlist',
  target: 'WISHLIST_REMOVE',
  output: '#dl-event',
  consequence: (action) => {
    const { variant } = action.payload

    push({
      event: 'remove_from_whishlist',
      item_id: variant.sku,
      item_name: variant?.title
    })
  }
})
