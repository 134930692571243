import { addRule } from 'redux-ruleset'
import { setChannel } from 'utils/channel'

addRule({
  id: 'dl/OVERLAY_B2B_B2C_SWITCH/OVERLAY_B2CB2B_CLICK',
  target: 'SET_CHANNEL',
  output: '#dl-event',
  consequence: (action) => {
    const eventname = action.dlEvent
    if (window.dataLayer) {
      if (eventname === 'overlay_b2c_click') {
        window.dataLayer.push({
          event: 'genericEvent',
          eventname: 'overlay_b2b2c_switch',
          customer_type: 'Privatkunden',
          category: 'Overlay B2B B2C Switch',
          action: 'B2C',
          label: '',
          value: 0,
          nonInteraction: 'true'
        })
      }
      if (eventname === 'overlay_b2b_click') {
        window.dataLayer.push({
          event: 'genericEvent',
          eventname: 'overlay_b2b2c_switch',
          customer_type: 'Firmenkunde',
          category: 'Overlay B2B B2C Switch',
          action: 'B2B',
          label: '',
          value: 0,
          nonInteraction: 'true'
        })
      }
    }
  }
})

addRule({
  id: 'feature/set_channel_on_login',
  target: 'LOGIN',
  output: '#dl-event',
  consequence: (action) => {
    setChannel(action.channel)
    window.dataLayer.push({
      event: 'genericEvent',
      eventname: 'set_channel_on_login',
      customer_type: action.channel === 'b2b' ? 'Firmenkunde' : 'Privatkunden',
      category: 'Login B2B B2C Switch',
      action: action.channel === 'b2b' ? 'B2B' : 'B2C',
      label: '',
      value: 0,
      nonInteraction: 'false'
    })
  }
})
