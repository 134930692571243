let inactivityTimer
import { dispatchEvent } from 'redux-ruleset'
import * as at from 'modules/ui/const'
import checkUrl from './checkUrl'

// Function to reset the timer
function resetInactivityTimer(timeout = 60) {
  // Clear the existing timer
  if (typeof window !== 'undefined') {
    clearTimeout(inactivityTimer)
    // Set a new timer for 60 seconds
    inactivityTimer = setTimeout(() => {
      // Action to take after 60 seconds of inactivity
      const isIntentLayerExecuted =
        localStorage.getItem('intentLayerExecuted') === 'true'
      const isBlacklisted = checkUrl(window.location.pathname)
      if (!isIntentLayerExecuted && !isBlacklisted)
        dispatchEvent({
          type: at.SET_INTENT_VISIBLE,
          payload: { visible: true, source: 'inactivity' }
        })
      // You can also add other actions here, such as redirecting or logging out the user
    }, timeout * 1000) // 60000 milliseconds = 60 seconds
  }
}

// Function to set up event listeners for user activity
export default function setupInactivityDetection(timeout = 60) {
  // List of events to consider as user activity
  const activityEvents = [
    'mousemove',
    'mousedown',
    'keydown',
    'touchstart',
    'scroll'
  ]

  // Add event listeners for each activity event
  activityEvents.forEach((event) => {
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem('intentLayerExecuted') !== 'true'
    )
      window.addEventListener(event, () => resetInactivityTimer(timeout))
  })

  // Initialize the timer when the script loads
  resetInactivityTimer()
}
