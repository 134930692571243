module.exports = [{
      plugin: require('../plugins/module-navigation/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/module-tagmanager/gatsby-browser.ts'),
      options: {"plugins":[],"id":"GTM-WGDM5TC","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lusini","short_name":"lusini","start_url":"/","background_color":"#D6A444","theme_color":"#D6A444","display":"minimal-ui","icon":"static/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5798d8e1cdbaaf38ff4a0bcf73ae1668"},
    },{
      plugin: require('../plugins/emg-styled-components/gatsby-browser.tsx'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
