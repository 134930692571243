import * as t from '../../types'
import * as utils from '../../utils'
import transformCart from './transformCart'
import { GetCartResponse } from 'hooks/account/response-types/get-cart'
import { GetShippingMethodsResponse } from 'hooks/account/response-types/get-shipping-methods'
import { PatchContextResponse } from 'hooks/account/response-types/patch-context'
import { MessageKey } from 'modules/Snackbar/types'
import { getCertainErrors } from 'modules/Snackbar/utils'

type AddItem = {
  type: 'product'
  referencedId: string
  quantity: number
  additionalInformation: {
    list_positon: number
    item_list_name: string
    item_list_id: string
  }
  config?: any
}

export const getEmptyCart = (): t.Cart => ({
  hash: 'empty',
  dy: {
    sessionID: '',
    userID: ''
  },
  promotionStatus: {
    added: false,
    excluded: false,
    notEligable: false
  },
  promotionItems: [],
  deliveryCost: 0,
  specialDelivery: false,
  deliveryReduction: 0,
  positionPrice: 0,
  tax: 0,
  taxRate: 0,
  totalPrice: 0,
  items: []
})

type Result = {
  cart: t.Cart
  shippingMethod: t.ShippingMethod
  errors: { message: string; messageKey: MessageKey; quantity?: number }[]
}

/** @firescoutMockFn account.fetchCart */
export const fetchCart = async (
  shippingMethod: t.ShippingMethod
): t.Response<Result> => {
  const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
    method: 'GET',
    url: '/checkout/cart'
  })
  if (status >= 400) {
    return { status: 500, payload: '' }
  }
  utils.updateToken(result.token)

  return {
    status: 200,
    payload: await preTransformCart(result, shippingMethod)
  }
}

/** @firescoutMockFn account.addItemToCart */
export const addItem = async (
  sw6Uuid: string,
  quantity: number,
  shippingMethod: t.ShippingMethod,
  config?: t.CustomTailorConfig,
  item_list_name = '',
  list_positon = 0,
  item_list_id = ''
): t.Response<Result> => {
  let item: AddItem = {
    type: 'product',
    referencedId: sw6Uuid,
    quantity,
    additionalInformation: {
      item_list_name,
      list_positon,
      item_list_id
    }
  }
  if (config) {
    item = { ...item, ...config }
  }
  const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
    method: 'POST',
    url: '/checkout/cart/line-item',
    body: {
      items: [item]
    }
  })

  if (status >= 400) {
    return { status: 500, payload: '' }
  }
  utils.updateToken(result.token)
  return {
    status: 200,
    payload: await preTransformCart(result, shippingMethod)
  }
}

/** @firescoutMockFn account.addItemToCartBatch */
export const addItemBatch = async (
  items: {
    sw6Uuid: string
    quantity: number
    type: 'product'
    config?: t.CustomTailorConfig
  }[],
  shippingMethod: t.ShippingMethod
): t.Response<Result> => {
  const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
    method: 'POST',
    url: '/checkout/cart/line-item',
    body: { items }
  })
  if (status >= 400) {
    return { status: 500, payload: '' }
  }
  utils.updateToken(result.token)
  return {
    status: 200,
    payload: await preTransformCart(result, shippingMethod)
  }
}

/** @firescoutMockFn account.addPromotion */
export const addPromotion = async (code: string): t.Response<t.Cart> => {
  try {
    const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
      method: 'POST',
      url: '/checkout/cart/line-item',
      body: {
        items: [
          {
            type: 'promotion',
            referencedId: code
          }
        ]
      }
    })
    if (result.errors['promotion-not-found']) {
      return { status: 400, payload: [] }
    }
    if (status >= 400) {
      throw new Error('ADD_PROMOTION_TO_CART_FAILED')
    }
    utils.updateToken(result.token)
    return { status: 200, payload: await transformCart(result) }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

/** @firescoutMockFn account.removeItemFromCart */
export const removeItem = async (
  cartItemId: string,
  shippingMethod: t.ShippingMethod
): t.Response<Result> => {
  try {
    const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
      method: 'POST',
      url: '/checkout/cart/line-item/delete',
      body: {
        ids: [cartItemId]
      }
    })
    if (status >= 400) {
      throw new Error('REMOVE_ITEM_FROM_CART_FAILED')
    }
    utils.updateToken(result.token)
    return {
      status: 200,
      payload: await preTransformCart(result, shippingMethod)
    }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

/** @firescoutMockFn account.removeItemFromCartBatch */
export const removeItemBatch = async (
  ids: string[],
  shippingMethod: t.ShippingMethod
): t.Response<Result> => {
  try {
    const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
      method: 'POST',
      url: '/checkout/cart/line-item/delete',
      body: { ids }
    })
    if (status >= 400) {
      throw new Error('BATCH_REMOVE_ITEM_FROM_CART_FAILED')
    }
    utils.updateToken(result.token)
    return {
      status: 200,
      payload: await preTransformCart(result, shippingMethod)
    }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

/** @firescoutMockFn account.updateItemInCart */
export const updateItem = async (
  cartItemId: string,
  quantity: number,
  shippingMethod: t.ShippingMethod
): t.Response<Result> => {
  try {
    const [result, status] = await utils.fetchFromShopware<GetCartResponse>({
      method: 'PATCH',
      url: '/checkout/cart/line-item',
      body: {
        items: [{ id: cartItemId, quantity }]
      }
    })
    if (status >= 400) {
      throw new Error('UPDATE_CART_ITEM_FAILED')
    }
    utils.updateToken(result.token)
    return {
      status: 200,
      payload: await preTransformCart(result, shippingMethod)
    }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

async function setShippingMethodId(id: string): t.Response<null> {
  try {
    const [, status] = await utils.fetchFromShopware<PatchContextResponse>({
      method: 'PATCH',
      url: '/context',
      body: {
        shippingMethodId: id
      }
    })

    if (status >= 400) {
      throw new Error('SET_SHIPPING_METHOD_ID_FAILED')
    }

    return { status: 200, payload: null }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

async function fetchShippingMethods(): t.Response<t.ShippingMethod[]> {
  try {
    const [result, status] =
      await utils.fetchFromShopware<GetShippingMethodsResponse>({
        method: 'POST',
        url: '/shipping-method?onlyAvailable=1',
        body: {
          page: 1,
          limit: 100
        }
      })

    if (status >= 400) {
      throw new Error('FETCH_SHIPPING_METHODS_FAILED')
    }

    return {
      status: 200,
      payload: result.elements.map((row) => ({
        id: row.id,
        name: row.translated.name
      }))
    }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

/**
 * revieves the shopware-cart. since shipping method can change when anything happens
 * to the cart we first need to fetch the available shipping methods (only get 1). when current
 * shipping method does not match current shipping method we need to update the
 * shipping method. then we need to refetch the cart
 * additionally we need to compare to cart-set shipping method since channel switches will update
 * shipping method silently on shopware
 */

async function preTransformCart(
  result: GetCartResponse,
  shippingMethod: t.ShippingMethod
): Promise<{
  cart: t.Cart
  shippingMethod: t.ShippingMethod
  errors: { message: string; messageKey: MessageKey; quantity?: number }[]
}> {
  const methods = await fetchShippingMethods()
  if (methods.status !== 200)
    throw new Error('could not fetch Shipping Methods')
  const avMethod = methods.payload[0] || null
  let needsUpdate = avMethod?.id !== shippingMethod.id
  const currentId = result.deliveries[0]?.shippingMethod.id ?? ''
  if (currentId !== shippingMethod.id) needsUpdate = true
  if (avMethod && needsUpdate) {
    const setResult = await setShippingMethodId(avMethod.id)
    if (setResult.status !== 200)
      throw new Error('could not set Shipping Method')
    const [newResult, status] = await utils.fetchFromShopware<GetCartResponse>({
      method: 'GET',
      url: '/checkout/cart'
    })
    if (status < 400) result = newResult
    else {
      throw new Error('FETCH_PRE_TRANSFORM_CART_FAILED')
    }
  }

  const cart = await transformCart(result)

  return {
    cart: cart,
    shippingMethod: avMethod || shippingMethod,
    errors: getCertainErrors(Object.values(result.errors))
  }
}
