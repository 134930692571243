import * as React from 'react'
import * as t from '../types'
import * as utils from '../utils'
import { AddressRequireDict } from '../lists/useAddressRequireDict'
import useChannel from './_useChannel'
import useObserver from './_useObserver'
import useCart from './cart/useCart'
import config from 'config'
import { GetContextResponse } from '../response-types/get-context'
import { PatchContextResponse } from '../response-types/patch-context'
import { GetPaymentMethodsResponse } from '../response-types/get-payment-methods'
import { mutate } from 'swr'
import { OutsideSalesRep } from 'theme/templates/checkout/Overview/useOutsideSalesRep'

export type Account = {
  /** initially null. will be set after the application mounts */
  context: null | t.Context
  /** b2b | b2c. defines the current customer group */
  channel: ReturnType<typeof useChannel>['channel']
  /** true when channel was finally initially */
  channelLoaded: boolean
  /** true while context was not fetched initially. will never turn true again */
  isFetching: boolean
  /** only true when user is logged in */
  loggedIn: boolean
  /** mark if the checkout was already visited */
  checkoutVisited: boolean
  setChannel: ReturnType<typeof useChannel>['setChannel']
  setCheckoutVisited: () => void
  logout: () => void
  createOrder: typeof utils.createOrder
  cart: ReturnType<typeof useCart>
  _: {
    stores: {
      addressList?: t.Address[]
      addressListP?: t.Response<t.Address[]>
      orderList?: t.Order[]
      orderListP?: t.Response<{
        orders: t.Order[]
        hasNextBatch: boolean
        page: number
      }>
      addressRequireDict?: AddressRequireDict
      addressRequireDictP?: t.Response<AddressRequireDict>
      cart?: t.Cart
      cartP?: t.Response<t.Cart>
      salutationList?: t.Salutation[]
      salutationListP?: t.Response<t.Salutation[]>
      countryList?: t.Country[]
      countryListP?: t.Response<t.Country[]>
      countryStateList?: t.CountryState[]
      countryStateListP?: t.Response<t.CountryState[]>
      paymentMethodList?: GetPaymentMethodsResponse
      paymentMethodListP?: Promise<GetPaymentMethodsResponse>
      availablePaymentMethodList?: t.PaymentMethod[]
      availablePaymentMethodListP?: t.Response<t.PaymentMethod[]>
      shippingMethodList?: t.ShippingMethod[]
      shippingMethodListP?: t.Response<t.ShippingMethod[]>
    }
    sendEvent: (evt: t.Event) => void
    onEvent: (cb: (evt: t.Event) => void) => () => void
    fetchContext: (channel?: t.Channel) => t.Response<t.Context>
  }
}

const NotImplemented = new Error(
  'not implemented. please use AccountProvider first'
)

const Context = React.createContext<Account>(
  // @ts-ignore
  {
    _: {
      stores: {},
      fetchContext: () => {
        throw NotImplemented
      },
      onEvent: () => {
        throw NotImplemented
      },
      sendEvent: () => {
        throw NotImplemented
      }
    },
    channel: 'b2b',
    context: null,
    createOrder: () => {
      throw NotImplemented
    },
    isFetching: false,
    loggedIn: false,
    logout: () => {
      throw NotImplemented
    },
    setChannel: () => {
      throw NotImplemented
    }
  }
)

// if (process.env.NODE_ENV === 'development') {
//   // @ts-ignore
//   window.Context = Context
// }

export function AccountProvider(props: { children: any }) {
  const [context, setContext] = React.useState<t.Context | null>(null)
  const [isFetching, setIsFetching] = React.useState(true)
  const observer = useObserver()
  const { channel, setChannel, channelLoaded } = useChannel(observer)
  const [checkoutVisited, setCheckoutVisited] = React.useState(false)
  const stores: React.MutableRefObject<Account['_']['stores']> = React.useRef(
    {}
  )
  const cart = useCart(observer, channel)
  const langFetched = React.useRef(false)

  if (config.modules.shopware.languageId) {
    React.useEffect(() => {
      if (!context) return
      if (langFetched.current) return
      if (context.languageId !== config.modules.shopware.languageId) {
        setLanguageId().then((result) => {
          langFetched.current = true
          if (result.status === 200) {
            setContext(result.payload)
          }
        })
      }
    }, [context])
  }

  /** when countryStateId is set in config, we need to set the default
   * countryStateId to show correct prices in cart when user is not logged in
   */
  React.useEffect(() => {
    if (!config.modules.shopware.initialCountryStateId) return
    if (!context) return
    if (context.countryStateSet) return
    setCountryStateId(config.modules.shopware.initialCountryStateId).then(
      (result) => {
        if (result.status === 200) setContext(result.payload)
      }
    )
  }, [context])

  React.useEffect(() => {
    fetchContext().then((result) => {
      if (result.status === 200) {
        setContext(result.payload)
        observer.current.sendEvent({
          type: 'SET_INITIAL_CONTEXT',
          context: result.payload,
          cart: cart.data
        })
      }
      setIsFetching(false)
    })

    return observer.current.onEvent((evt) => {
      switch (evt.type) {
        case 'SET_CHANNEL': {
          delete stores.current.addressRequireDict
          delete stores.current.addressRequireDictP
          break
        }
        case 'LOGIN':
        case 'REGISTER':
        case 'CHANGE_EMAIL':
        case 'CHANGE_PAYMENT_METHOD':
        case 'CHANGE_SHIPPING_METHOD':
        case 'CHANGE_CUSTOMER_DATA':
          setContext(evt.context)
          break
        case 'LOGOUT': {
          stores.current = {}
          break
        }
        case 'DELETE_ADDRESS':
        case 'CHANGE_ADDRESS': {
          delete stores.current.addressListP
          delete stores.current.addressList
          if (evt.type === 'CHANGE_ADDRESS') setContext(evt.context)
          break
        }
      }
    })
  }, [])

  const ctx: Account = {
    context: context,
    channel: channel,
    channelLoaded: channelLoaded,
    cart: cart,
    checkoutVisited: checkoutVisited,
    setCheckoutVisited: () => setCheckoutVisited(true),
    isFetching: isFetching,
    loggedIn: context?.loggedIn || false,
    setChannel: setChannel,
    createOrder: (
      paypalOrderId?: string,
      outsideSalesRepData?: OutsideSalesRep
    ) => {
      observer.current.sendEvent({ type: 'CREATE_ORDER' })
      return utils.createOrder(paypalOrderId, outsideSalesRepData)
    },
    logout: async () => {
      mutate(() => true, undefined, { revalidate: false })
      if (context?.loggedIn) {
        if (process.env.NODE_ENV === 'test') {
          await mockLogout()
        }
        utils.updateToken('')
        const result = await fetchContext(channel)

        if (result.status === 200) {
          setContext(result.payload)
          observer.current.sendEvent({ type: 'LOGOUT' })
        } else {
          location.reload()
        }
      }
    },
    _: {
      stores: stores.current,
      sendEvent: observer.current.sendEvent,
      onEvent: observer.current.onEvent,
      fetchContext: fetchContext
    }
  }

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>
}

/** @firescoutMockFn shopware.logout */
async function mockLogout() {
  null
}

export default function useAccount(): Account {
  /*  if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    return React.useContext(window.Context)
  } */
  return React.useContext(Context)
}

/** @firescoutMockFn account.fetchContext */
async function fetchContext(channel?: t.Channel): t.Response<t.Context> {
  try {
    const [result] = await utils.fetchFromShopware<GetContextResponse>({
      channel: channel,
      method: 'GET',
      url: '/context'
    })

    const langId = config.modules.shopware.languageId
    if (langId && langId !== result.salesChannel.languageId) {
      utils.fetchFromShopware<PatchContextResponse>({
        method: 'PATCH',
        url: '/context',
        body: { languageId: langId }
      })
    }
    if (langId && result.customer && langId !== result.customer.languageId) {
      utils.fetchFromShopware<PatchContextResponse>({
        method: 'POST',
        url: '/account/change-language',
        body: { languageId: langId }
      })
    }

    const ctx: t.Context = {
      languageId: langId || '',
      isGuest: result.customer?.guest || false,
      firstName: result.customer?.firstName || '',
      lastName: result.customer?.lastName || '',
      customerNumber: result.customer?.customerNumber || '',
      defaultBillingAddress: utils.createAddress(
        result.customer?.defaultBillingAddress
      ),
      activeBillingAddress: utils.createAddress(
        result.customer?.activeBillingAddress
      ),
      defaultShippingAddress: utils.createAddress(
        result.customer?.defaultShippingAddress
      ),
      activeShippingAddress: utils.createAddress(
        result.customer?.activeShippingAddress
      ),
      paymentMethodId: result.paymentMethod?.id || '',
      shippingMethodId: result.shippingMethod?.id || '',
      salutationId: result.customer?.salutationId || '',
      email: result.customer?.email || '',
      isIncomplete: false,
      loggedIn: Boolean(result.customer),
      defaultCountryId: result.salesChannel.countryId,
      countryStateSet: Boolean(result.shippingLocation.state),
      lusiniCustomerIsOutsideSalesRep:
        result.customer?.customFields?.lusiniCustomerIsOutsideSalesRep ?? false,
      lusiniCustomerCreditCheckAccepted:
        result.customer?.customFields?.lusiniCustomerCreditCheckAccepted ??
        false
    }

    if (ctx.firstName === 'INITIAL' || !ctx.firstName) {
      ctx.firstName = ''
      ctx.isIncomplete = true
    }
    if (ctx.lastName === 'INITIAL' || !ctx.lastName) {
      ctx.lastName = ''
      ctx.isIncomplete = true
    }
    if (
      ctx.salutationId === '6d0b33d96b5b400cbabc648c0a4c3f60' ||
      ctx.salutationId === 'ed643807c9f84cc8b50132ea3ccb1c3b' ||
      ctx.salutationId === ''
    ) {
      ctx.salutationId = ctx.defaultBillingAddress.salutationId
      ctx.isIncomplete = true
    }

    utils.updateToken(result.token)

    return { status: 200, payload: ctx }
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

async function setLanguageId() {
  await utils.fetchFromShopware<PatchContextResponse>({
    method: 'PATCH',
    url: '/context',
    body: {
      languageId: config.modules.shopware.languageId
    }
  })

  return fetchContext()
}

async function setCountryStateId(id: string) {
  await utils.fetchFromShopware<PatchContextResponse>({
    method: 'PATCH',
    url: '/context',
    body: {
      countryStateId: id
    }
  })

  return fetchContext()
}
