import { addRule } from 'redux-ruleset'
import {
  SET_FILTER_VALUE,
  SET_FILTER_VALUES,
  FETCH_SUCCESS,
  FETCH_FAILURE
} from 'modules/productDetail/const'
import { getDisplayVariant } from 'modules/productDetail/selectors'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import { setFilterValues } from 'modules/productDetail/actions'

function getSearchParamsForVariant(
  variant
): [searchParams: URLSearchParams, size: number] {
  const searchParams = new URLSearchParams(window.location.search)
  if (variant.isMainVariant) {
    searchParams.delete('sku')
  } else {
    searchParams.set('sku', variant.sku)
  }
  let size = searchParams.size
  if (!size) {
    size = [...searchParams.keys()].length
  }
  return [searchParams, size]
}

/**
 * When the display variant updates
 * Then we want to add the sku to the search params
 */
addRule({
  id: 'f-pdp/ADD_SKU_TO_SEARCH_PARAMS',
  target: [SET_FILTER_VALUE, SET_FILTER_VALUES],
  output: '#search-params',
  consequence: (_, { getState, addRule }) => {
    const state = getState()
    const variant = getDisplayVariant(state.productDetail)

    if (variant.isDummy) {
      addRule('waitForFetch')
      return
    }
    const [searchParams, searchParamsSize] = getSearchParamsForVariant(variant)
    window.history.replaceState(
      null,
      '',
      window.location.pathname +
        (searchParamsSize > 0 ? `?${searchParams.toString()}` : '')
    )
  },
  subRules: {
    waitForFetch: {
      target: [FETCH_SUCCESS, FETCH_FAILURE],
      output: '#search-params',
      addOnce: true,
      consequence: (_, { getState }) => {
        const state = getState()
        const variant = getDisplayVariant(state.productDetail)
        const [searchParams, searchParamsSize] =
          getSearchParamsForVariant(variant)
        window.history.replaceState(
          null,
          '',
          window.location.pathname +
            (searchParamsSize > 0 ? `?${searchParams.toString()}` : '')
        )
      }
    }
  }
})
/**
 * Given we visit the pdp
 * And the url contains an sku in the search params
 * When we set the initial filter values
 * Then we hydrate the sku
 */
addRule({
  id: 'f-pdp/HYDRATE_SKU_FROM_SEARCH_PARAMS',
  target: FETCH_SUCCESS,
  output: SET_FILTER_VALUES,
  addWhen: function* (next, { context }) {
    yield next(LOCATION_CHANGED, (action) => {
      const { pathname, search } = action.payload
      if (pathname.includes('/pdp/') && search.includes('sku=')) {
        const searchParams = new URLSearchParams(search)
        const sku = searchParams.get('sku')
        if (sku) {
          context.set('sku', sku)
          return true
        }
      }
      return false
    })
    return 'ADD_RULE'
  },
  addUntil: function* (next) {
    yield next(SET_FILTER_VALUES)
    yield next(SET_FILTER_VALUES)
    return 'RECREATE_RULE'
  },
  consequence: (action, { context }) => {
    const sku = context.get('sku')
    const variant = action.payload.find((variant) => variant.sku === sku)

    if (variant) {
      return setFilterValues({
        color: variant.variantData.color.label || null,
        size: variant.variantData.size.label || null,
        style: variant.variantData.style.label || null,
        variant: variant.variantData.variant.label || null
      })
    }

    return
  }
})

/**
 * Given we visit the pdp
 * And the url contains an sku in the hash
 * When we set the initial filter values
 * Then we hydrate the sku and remove the hash
 */
addRule({
  id: 'f-pdp/HYDRATE_SKU_FROM_HASH',
  target: FETCH_SUCCESS,
  output: SET_FILTER_VALUES,
  addWhen: function* (next, { context }) {
    yield next(LOCATION_CHANGED, (action) => {
      const { pathname, hash } = action.payload
      if (pathname.includes('/pdp/') && hash.includes('sku=')) {
        const skuMatch = hash.match(/sku=([^&]+)/)
        if (skuMatch) {
          context.set('sku', skuMatch[1])
          return true
        }
      }
      return false
    })
    return 'ADD_RULE'
  },
  addUntil: function* (next) {
    yield next(SET_FILTER_VALUES)
    yield next(SET_FILTER_VALUES)
    return 'RECREATE_RULE'
  },
  consequence: (action, { context }) => {
    const sku = context.get('sku')
    const variant = action.payload.find((variant) => variant.sku === sku)

    if (variant) {
      return setFilterValues({
        color: variant.variantData.color.label || null,
        size: variant.variantData.size.label || null,
        style: variant.variantData.style.label || null,
        variant: variant.variantData.variant.label || null
      })
    }
    // remove hash from url
    window.history.replaceState(
      null,
      '',
      window.location.pathname + window.location.search
    )
    return
  }
})
