import { dispatchEvent } from 'redux-ruleset'

export const MAIN_CATEGORY_CLICK: 'app/Header/Navigation/MAIN_CATEGORY_CLICK' =
  'app/Header/Navigation/MAIN_CATEGORY_CLICK'

export const BURGER_MENU_CLICK: 'app/Header/Navigation/BURGER_MENU_CLICK' =
  'app/Header/Navigation/BURGER_MENU_CLICK'

export const SHOW_MORE_CLICK: 'app/Header/Navigation/SHOW_MORE_CLICK' =
  'app/Header/Navigation/SHOW_MORE_CLICK'

export const BURGER_NAVI_CLICK: 'ProductWidget/BURGER_NAVI_CLICK' =
  'ProductWidget/BURGER_NAVI_CLICK'

export const LANGUAGE_SWITCH_CLICK: 'app/Header/Navigation/LANGUAGE_SWITCH_CLICK' =
  'app/Header/Navigation/LANGUAGE_SWITCH_CLICK'

export const burgerNaviClick = () =>
  dispatchEvent({ type: BURGER_NAVI_CLICK, meta: {}, payload: {} })

export const mainCategoryClick = (category: string | null) =>
  dispatchEvent({
    type: MAIN_CATEGORY_CLICK,
    meta: { category, path: category },
    payload: 'Main Category Click - Horizontal'
  })

export const burgerMenuClick = () =>
  dispatchEvent({
    type: BURGER_MENU_CLICK,
    meta: { category: '', path: '' },
    payload: 'Burger Menu Click'
  })

export const showMoreMenuClick = () =>
  dispatchEvent({
    type: SHOW_MORE_CLICK,
    meta: { category: '', path: '' },
    payload: 'Show More Click'
  })

export const languageSwitchClick = (to: string) =>
  dispatchEvent({
    type: LANGUAGE_SWITCH_CLICK,
    meta: {},
    payload: {
      to
    }
  })

export type MainCategoryClick = ReturnType<typeof mainCategoryClick>
export type BurgerMenuClick = ReturnType<typeof burgerMenuClick>
export type BurgerNaviClick = ReturnType<typeof burgerNaviClick>
export type ShowMoreMenuClick = ReturnType<typeof showMoreMenuClick>
export type LanguageSwitchClick = ReturnType<typeof languageSwitchClick>
export type Event =
  | MainCategoryClick
  | BurgerMenuClick
  | ShowMoreMenuClick
  | BurgerNaviClick
  | LanguageSwitchClick

declare global {
  interface RulesetDispatchEvents {
    'app/Header/Navigation': Event
    'search/OnSubmit': Event
  }
}
