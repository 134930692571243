import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import config from 'config'
import { navigate } from 'gatsby'
import SwitchItem from './SwitchItem'
import Button from 'theme/atoms/Button'
import { languageSwitchClick } from '../../events'
import useTranslations from 'hooks/useTranslations'
import CloseIcon from 'assets/close.svg'
import GlobeRegular from 'assets/globe-regular.svg'

export default function LanguageSwitch() {
  const [showTooltip, setShowTooltip] = React.useState(false)
  const [activeLang, setActiveLang] = React.useState(
    config.langShop?.current.locale
  )
  const [selectedLang, setSelectedLang] = React.useState(
    config.langShop?.current.label
  )

  const [selectedUrl, setSelectedUrl] = React.useState('/')
  const t = useTranslations<'app-Header'>()

  const handleOnSaved = () => {
    setShowTooltip(false)
    if (activeLang === config.langShop?.current.locale) return
    languageSwitchClick(selectedLang || config.langShop?.current.label || '')
    navigate(selectedUrl)
  }

  const handleOnCancel = () => {
    setShowTooltip(false)
  }

  return (
    <Wrapper className="LanguageSwitch">
      <div
        className="circle"
        data-cy-handle="language-switch-icon"
        onClick={() => setShowTooltip(!showTooltip)}
      >
        <GlobeRegular />
      </div>
      {showTooltip && (
        <div className="lang-tooltip" data-cy-state="show-lang-modal">
          <div className="close">
            <span
              data-cy-handle="lang-switch-close"
              onClick={() => setShowTooltip(false)}
            >
              <CloseIcon />
            </span>
          </div>
          <div className="tooltip-title">{t('language_switch_title')}</div>

          {config.langShop?.alternate.map((lang) => (
            <div key={lang.label} className="lang-switch">
              <SwitchItem
                data-cy-handle="lang-select"
                onClick={() => {
                  setActiveLang(lang.locale)
                  setSelectedLang(lang.label)
                  setSelectedUrl(lang.url)
                }}
                active={lang.locale === activeLang}
                label={lang.label}
              />
            </div>
          ))}
          <div className="actions">
            <Button
              variation="secondary"
              $fullWidth
              onClick={handleOnCancel}
              data-cy-handle="cancel-lang-switch"
            >
              {t('language_switch_cancel')}
            </Button>
            <Button
              variation="primary"
              data-cy-handle="save-lang-switch"
              $fullWidth
              onClick={() => handleOnSaved()}
            >
              {t('language_switch_save')}
            </Button>
          </div>
        </div>
      )}
      {showTooltip && (
        <div
          className="tooltip-header"
          data-cy-state="show-modal-indicator"
        ></div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  position: relative;
  gap: ${theme.spacing('s')};
  height: 2.125rem;
  width: 3rem;
  display: flex;
  justify-content: center;

  > .circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > svg {
      width: 1.5rem;
      height: 1.5rem;

      @media (min-width: ${theme.ms.MD}px) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }

  > .tooltip-header {
    height: 1.5625rem;
    width: 1.5625rem;
    position: absolute;
    top: 2.625rem;
    left: 0.4375rem;
    background-color: ${theme.colors.white};
    border-top: 1px solid ${theme.colors.primary};
    border-right: 1px solid ${theme.colors.primary};
    z-index: ${theme.zIndex.app_header_navigation_popover_Wrapper};
    transform: rotate(-45deg);
    @media (min-width: ${theme.ms.MD}px) {
      top: 3.5rem;
      left: 0.625rem;
    }
  }
  > .lang-tooltip {
    position: absolute;
    top: 3.375rem;
    right: -10rem;
    background: ${theme.colors.white};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    padding: ${theme.spacing('s')};
    z-index: ${theme.zIndex.app_header_navigation_popover_Wrapper};
    min-width: 18.75rem;

    max-width: 98vw;
    border: 1px solid ${theme.colors.primary};
    > .actions {
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing('s')};
      margin-top: ${theme.spacing('s')};
      > .Button {
        padding: ${theme.spacing('xs')} ${theme.spacing('ml')};
        @media (min-width: ${theme.ms.MD}px) {
          padding: ${theme.spacing('xs')} ${theme.spacing('l')};
        }
      }
    }
    > .close {
      display: flex;
      justify-content: flex-end;
    }
    > .tooltip-title {
      ${theme.ty('rc-2xl', '700', 'rc-3xl')}
      margin-top: -${theme.spacing('m')};
      margin-bottom: ${theme.spacing('m')};
      padding-right: ${theme.spacing('m')};
    }
    @media (min-width: ${theme.ms.MD}px) {
      top: 4.25rem;
      right: -5rem;
      width: 26.25rem;
    }
  }
`
