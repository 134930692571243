import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Tooltip from './Tooltip'
import Link from 'theme/atoms/Link'
import Container from '../../../atoms/Container'
import ArrowLeft from 'assets/arrow-left-white.svg'
import useTranslations from 'hooks/useTranslations'

export default function TopLine() {
  const t = useTranslations<'app-CheckoutHeader'>()
  return (
    <Wrapper data-cy-ctx="app/TopLine">
      <Container>
        <div>
          <ArrowLeft />
          <Link to="/">{t('checkout_back_to_shop')}</Link>
        </div>
        <Tooltip />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${theme.colors.primary};

  @media (max-width: ${theme.ms.MD}px) {
    display: none;
  }

  > .Container {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      color: ${theme.colors.white};

      > svg {
        width: 12px;
        height: 12px;
      }

      > a {
        color: ${theme.colors.white};
        ${theme._ty([14, 0, 24], theme.fontSpecial, '700')};
      }
    }
  }
`
1
