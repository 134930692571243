import { dispatchEvent } from 'redux-ruleset'
import { Event } from 'hooks/account/types'
import { setChannel } from 'utils/channel'

const B2C_KEY = 'b2c'
const B2B_KEY = 'b2b'

function isB2XParamActive(paramValue: string | null): boolean {
  try {
    if (paramValue === null) {
      return false
    }
    const val = JSON.parse(paramValue)
    return !!val
  } catch (error) {
    return false
  }
}

// [WDV-2501] check for "b2b/b2c"-indicators within the url for marketing-campaigns
if (typeof window !== 'undefined') {
  const searchParams = new URL(window.location.href).searchParams
  if (
    searchParams.has(B2C_KEY) &&
    isB2XParamActive(searchParams.get(B2C_KEY))
  ) {
    setChannel('b2c')
    dispatchEvent<Event>({
      type: 'SET_CHANNEL',
      channel: 'b2c',
      dlEvent: 'b2b2cFromURL'
    })
  }
  if (
    searchParams.has(B2B_KEY) &&
    isB2XParamActive(searchParams.get(B2B_KEY))
  ) {
    setChannel('b2b')
    dispatchEvent<Event>({
      type: 'SET_CHANNEL',
      channel: 'b2b',
      dlEvent: 'b2b2cFromURL'
    })
  }
}
