import * as React from 'react'
import useTranslations from 'hooks/useTranslations'
import Link from 'theme/atoms/Link'
import styled from 'styled-components'
import theme from 'theme'
import { Gq } from '../../../types'
import ContactVersion from 'utils/contactVersion'
import YoutubeIcon from 'assets/youtube.svg'
import FacebookIcon from 'assets/fb.svg'
import InstagramIcon from 'assets/instagram.svg'
import WhatsappIcon from 'assets/whatsapp.svg'
import PinterestIcon from 'assets/pinterest.svg'
import LinkedinIcon from 'assets/linkedin.svg'
import XingIcon from 'assets/xing.svg'
import CountrySwitch from './CountrySwitch'

const socialDict = {
  youtube: <YoutubeIcon />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  whatsapp: <WhatsappIcon />,
  pinterest: <PinterestIcon />,
  linkedin: <LinkedinIcon />,
  xing: <XingIcon />
}

export default function Content(props: Omit<Gq, 'brandName'>) {
  const t = useTranslations<'app-Footer'>()
  const $fbLink = React.useRef<HTMLAnchorElement>(null)
  const $ytLink = React.useRef<HTMLAnchorElement>(null)
  const $instaLink = React.useRef<HTMLAnchorElement>(null)
  const $whatsappLink = React.useRef<HTMLAnchorElement>(null)
  const $pinterestLink = React.useRef<HTMLAnchorElement>(null)
  const $linkedinLink = React.useRef<HTMLAnchorElement>(null)
  const $xingLink = React.useRef<HTMLAnchorElement>(null)

  return (
    <Wrapper className="Social">
      <div className="social">
        <div className="small-headline">{t('social_icons_headline')}</div>

        <div className="icons">
          {props.footer.facebook && (
            <Link
              ref={$fbLink}
              title={t.asText($fbLink, 'app_footer_social_facebook_a11y_label')}
              data-cy-state="facebook-visible"
              key={props.footer.facebook}
              to={props.footer.facebook}
            >
              {socialDict['facebook']}
            </Link>
          )}
          {props.footer.youtube && (
            <Link
              ref={$ytLink}
              title={t.asText($ytLink, 'app_footer_social_youtube_a11y_label')}
              data-cy-state="youtube-visible"
              key={props.footer.youtube}
              to={props.footer.youtube}
            >
              {socialDict['youtube']}
            </Link>
          )}
          {props.footer.instagram && (
            <Link
              ref={$instaLink}
              title={t.asText(
                $instaLink,
                'app_footer_social_instagram_a11y_label'
              )}
              data-cy-state="instagram-visible"
              key={props.footer.instagram}
              to={props.footer.instagram}
            >
              {socialDict['instagram']}
            </Link>
          )}
          {props.footer.whatsapp && (
            <Link
              ref={$whatsappLink}
              title={t.asText(
                $whatsappLink,
                'app_footer_social_whatsapp_a11y_label'
              )}
              data-cy-state="whatsapp-visible"
              key={props.footer.whatsapp}
              to={props.footer.whatsapp}
            >
              {socialDict['whatsapp']}
            </Link>
          )}
          {props.footer.pinterest && (
            <Link
              ref={$pinterestLink}
              title={t.asText(
                $pinterestLink,
                'app_footer_social_pinterest_a11y_label'
              )}
              data-cy-state="pinterest-visible"
              key={props.footer.pinterest}
              to={props.footer.pinterest}
            >
              {socialDict['pinterest']}
            </Link>
          )}

          {props.footer.linkedin && (
            <Link
              ref={$linkedinLink}
              title={t.asText(
                $linkedinLink,
                'app_footer_social_linkedin_a11y_label'
              )}
              data-cy-state="linkedin-visible"
              key={props.footer.linkedin}
              to={props.footer.linkedin}
            >
              {socialDict['linkedin']}
            </Link>
          )}
          {props.footer.xing && (
            <Link
              ref={$xingLink}
              title={t.asText($xingLink, 'app_footer_social_xing_a11y_label')}
              data-cy-state="xing-visible"
              key={props.footer.xing}
              to={props.footer.xing}
            >
              {socialDict['xing']}
            </Link>
          )}
        </div>
      </div>
      {props.footer.contactInfo.length >= 1 && (
        <div className="order-info" data-cy-state="contact-visible">
          <div className="small-headline">
            {t('app_footer_contact_hotline')}
          </div>

          {props.footer.contactInfo
            .filter((contact) => contact.value && contact.info && contact.title)
            .map((contact, i) => {
              return (
                <div key={contact.title + i} className="contact-info">
                  <div className="small-headline">{contact.title}</div>

                  <div className="info-contact">
                    <Link
                      className="link-contact"
                      to={ContactVersion(contact.type, contact.value).link}
                    >
                      {ContactVersion(contact.type, contact.value).icon}
                      <span>{contact.value}</span>
                    </Link>
                    <div className="opening-contact">{contact.info}</div>
                  </div>
                </div>
              )
            })}
        </div>
      )}
      <CountrySwitch />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.ms.MD}px) {
    flex-direction: row;
  }

  @media (min-width: ${theme.ms.LG}px) {
    flex-direction: column;
  }

  > div.small-headline {
    ${theme.ty('rc-s', '700')}

    @media (min-width: ${theme.ms.LG}px) {
      ${theme.ty('rc-l', '700')}
    }
  }
  > .social {
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: ${theme.spacing('s')};
    @media (min-width: ${theme.ms.MD}px) {
      margin-bottom: 0;
    }

    > div.small-headline {
      ${theme.ty('rc-s', '700')}
      @media (min-width: ${theme.ms.LG}px) {
        ${theme.ty('rc-l', '700')}
      }
    }

    > .icons {
      margin-top: ${theme.spacing('s')};

      > a {
        > svg {
          margin-right: ${theme.spacing('xs')};
          width: 35.84px;
          height: 35.84px;
          @media (min-width: ${theme.ms.MD}px) {
            width: 44.8px;
            height: 44.8px;
          }
        }
      }
    }
  }

  > .order-info {
    margin: 0 0 ${theme.spacing('ml')} 0;

    @media (min-width: ${theme.ms.MD}px) {
      max-width: 400px;
    }

    @media (min-width: ${theme.ms.LG}px) {
      margin: ${theme.spacing('ml')} 0;
    }

    div.small-headline {
      ${theme.ty('rc-s', '700')}
      @media (min-width: ${theme.ms.LG}px) {
        ${theme.ty('rc-l', '700')}
      }
    }
    > div.contact-info {
      flex-grow: 2;
      flex-basis: 0;
      margin-top: ${theme.spacing('xs')};

      > div {
        flex-direction: row;
      }
      div.small-headline {
        margin-bottom: ${theme.spacing('xxs')};
        ${theme.ty('rc-s', '700')}
        @media (min-width: ${theme.ms.LG}px) {
          ${theme.ty('rc-l', '700')}
        }
      }
      > .info-contact {
        > a.link-contact {
          display: flex;
          margin-right: ${theme.spacing('s')};
          > span {
            ${theme.ty('rc-base', '700')}
          }
          > svg {
            margin-right: 12px;
            width: 18px;

            path {
              fill: ${theme.colors.b0};
            }
          }
        }
        > div.opening-contact {
          margin-top: 3px;
          margin-left: ${theme.spacing('ml')};
          ${theme.ty('rc-base')}
        }
      }
    }
  }
`
