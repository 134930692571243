import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import config from 'config'
import useTranslations from 'hooks/useTranslations'
import AtFlag from 'assets/countryFlags/AT.svg'
import BeFlag from 'assets/countryFlags/BE.svg'
import ChFlag from 'assets/countryFlags/CH.svg'
import DeFlag from 'assets/countryFlags/DE.svg'
import DkFlag from 'assets/countryFlags/DK.svg'
import EsFlag from 'assets/countryFlags/ES.svg'
import FrFlag from 'assets/countryFlags/FR.svg'
import HrFlag from 'assets/countryFlags/HR.svg'
import ItFlag from 'assets/countryFlags/IT.svg'
import LiFlag from 'assets/countryFlags/LI.svg'
import LuFlag from 'assets/countryFlags/LU.svg'
import NlFlag from 'assets/countryFlags/NL.svg'
import NoFlag from 'assets/countryFlags/NO.svg'
import PtFlag from 'assets/countryFlags/PT.svg'
import SeFlag from 'assets/countryFlags/SE.svg'
import WwFlag from 'assets/countryFlags/WW.svg'
import ArrowDown from 'assets/arrow-down.svg'
import ArrowUp from 'assets/arrow-up.svg'
import useOutsideClick from 'hooks/useOutsideClick'
import * as evt from './events'

type LocalObject = {
  locale: string
  targetLocale: string
  snippet:
    | 'country_switch_at'
    | 'country_switch_be_lu'
    | 'country_switch_ch_de'
    | 'country_switch_ch_fr'
    | 'country_switch_ch_li'
    | 'country_switch_de'
    | 'country_switch_dk'
    | 'country_switch_es'
    | 'country_switch_be_fr'
    | 'country_switch_fr'
    | 'country_switch_hr'
    | 'country_switch_it'
    | 'country_switch_nl'
    | 'country_switch_be_nl'
    | 'country_switch_no'
    | 'country_switch_pt'
    | 'country_switch_se'
    | 'country_switch_int'
  flagComponent: React.FunctionComponent<any>
}

const locales: LocalObject[] = [
  {
    locale: 'de-at',
    targetLocale: 'de-at',
    snippet: 'country_switch_at',
    flagComponent: AtFlag
  },
  {
    locale: 'be-lu',
    targetLocale: 'fr-be',
    snippet: 'country_switch_be_lu',
    flagComponent: LuFlag
  },
  {
    locale: 'de-ch',
    targetLocale: 'de-ch',
    snippet: 'country_switch_ch_de',
    flagComponent: ChFlag
  },
  {
    locale: 'fr-ch',
    targetLocale: 'fr-ch',
    snippet: 'country_switch_ch_fr',
    flagComponent: ChFlag
  },
  {
    locale: 'li-ch',
    targetLocale: 'de-ch',
    snippet: 'country_switch_ch_li',
    flagComponent: LiFlag
  },
  {
    locale: 'de-de',
    targetLocale: 'de-de',
    snippet: 'country_switch_de',
    flagComponent: DeFlag
  },
  {
    locale: 'da-dk',
    targetLocale: 'da-dk',
    snippet: 'country_switch_dk',
    flagComponent: DkFlag
  },
  {
    locale: 'es-es',
    targetLocale: 'es-es',
    snippet: 'country_switch_es',
    flagComponent: EsFlag
  },
  {
    locale: 'fr-be',
    targetLocale: 'fr-be',
    snippet: 'country_switch_be_fr',
    flagComponent: BeFlag
  },
  {
    locale: 'fr-fr',
    targetLocale: 'fr-fr',
    snippet: 'country_switch_fr',
    flagComponent: FrFlag
  },
  {
    locale: 'hr-hr',
    targetLocale: 'hr-hr',
    snippet: 'country_switch_hr',
    flagComponent: HrFlag
  },
  {
    locale: 'it-it',
    targetLocale: 'it-it',
    snippet: 'country_switch_it',
    flagComponent: ItFlag
  },
  {
    locale: 'nl-nl',
    targetLocale: 'nl-nl',
    snippet: 'country_switch_nl',
    flagComponent: NlFlag
  },
  {
    locale: 'nl-be',
    targetLocale: 'nl-be',
    snippet: 'country_switch_be_nl',
    flagComponent: BeFlag
  },
  {
    locale: 'nb-no',
    targetLocale: 'nb-no',
    snippet: 'country_switch_no',
    flagComponent: NoFlag
  },
  {
    locale: 'pt-pt',
    targetLocale: 'pt-pt',
    snippet: 'country_switch_pt',
    flagComponent: PtFlag
  },
  {
    locale: 'sv-se',
    targetLocale: 'sv-se',
    snippet: 'country_switch_se',
    flagComponent: SeFlag
  },
  {
    locale: 'en',
    targetLocale: 'en',
    snippet: 'country_switch_int',
    flagComponent: WwFlag
  }
]

export default function CountrySwitch() {
  const t = useTranslations<'app-Footer'>()
  const [open, setOpen] = React.useState(false)
  const outsideClickRef = useOutsideClick(() => {
    setOpen(false)
  })

  const switchCountry = (targetLocale: string) => {
    evt.switchCountryButtonClick(targetLocale.replace('-', '_'))
    document.location.href = `${config.baseUrl}/${targetLocale}/`
  }

  return (
    <Wrapper ref={outsideClickRef} $isOpen={open} className="CountrySwitch">
      <div
        className="selected-country"
        data-cy-handle="country-switch-toggle"
        onClick={() => setOpen(!open)}
      >
        {locales
          .filter((locale) => locale.locale == config.locale)
          .map((locale) => {
            return (
              <div
                onClick={() => setOpen(open)}
                key={locale.locale}
                data-cy-handle="country-switcher"
              >
                <LocaleElement>
                  <locale.flagComponent />
                  <span>{t(locale.snippet)}</span>
                </LocaleElement>
              </div>
            )
          })}

        {/* eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state */}
        {open ? <ArrowUp /> : <ArrowDown />}
      </div>
      {open && (
        <div className="country-dropdown" data-cy-state="country-dropdown-open">
          {locales.map((locale) => {
            if (locale.locale !== config.locale) {
              return (
                <div
                  onClick={() => switchCountry(locale.targetLocale)}
                  key={locale.locale}
                  data-cy-handle="country-switcher"
                >
                  <LocaleElement>
                    <locale.flagComponent />
                    <span>{t(locale.snippet)}</span>
                  </LocaleElement>
                </div>
              )
            }
            return null
          })}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isOpen: boolean }>`
  position: relative;

  > .selected-country {
    display: flex;
    padding: ${theme.spacing('xs')} ${theme.spacing('s')};
    background-color: ${(props) =>
      props.$isOpen
        ? theme.colors.shade.primaryUltraBright
        : theme.colors.white};

    border: 1px solid ${theme.colors.shade.b5};
    border-top: ${(props) => props.$isOpen && `0px`};
    ${theme.rounding('s')};

    border-top-left-radius: ${(props) => props.$isOpen && `0px`};
    border-top-right-radius: ${(props) => props.$isOpen && `0px`};
    max-height: 2.625rem;

    > div {
      width: 95%;
    }
  }
  > .country-dropdown {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 100%;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.shade.b5};
    border-bottom: 0px;
    ${theme.rounding('s')};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    > div {
      padding: ${theme.spacing('xs')} ${theme.spacing('s')};
      display: flex;
      width: 100%;
      border-bottom: 1px solid ${theme.colors.shade.b5};

      &:hover {
        cursor: pointer;
        background-color: ${theme.colors.shade.primaryUltraBright};
      }
    }
  }
`

const LocaleElement = styled.div`
  display: flex;
  cursor: pointer;
  > svg {
    width: 28px;
  }
  > span {
    margin-left: ${theme.spacing('xs')};
  }
`
