import * as React from 'react'

type Ctx = { selector: string; trackClick: () => void; decisionId: string }
const Context = React.createContext<Ctx>({
  selector: '',
  trackClick: () => null,
  decisionId: ''
})

type Props = {
  children: any
  selector: string
  trackClick: () => void
  decisionId: string
}

export default function usePersonalization() {
  return React.useContext(Context)
}

export function PersonalizationProvider(props: Props) {
  const ctx: Ctx = {
    selector: props.selector,
    trackClick: props.trackClick,
    decisionId: props.decisionId
  }
  return <Context.Provider value={ctx}>{props.children}</Context.Provider>
}
