import * as React from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import Link from './Link'
import TailSpin from 'assets/tail-spin.svg'
import CartIcon from 'assets/cart.svg'
import LoginIcon from 'assets/login.svg'
import ArrowRightIcon from 'assets/arrow-button-right.svg'
import ArrowLeftIcon from 'assets/arrow-left.svg'
import DeleteIcon from 'assets/delete.svg'
import Disabled from 'assets/disabled.svg'
import LinkIcon from 'assets/link.svg'
import WhatsAppIcon from 'assets/whatsapp.svg'
import EmailIcon from 'assets/mail.svg'

type Variation =
  | 'primary'
  | 'secondary'
  | 'primary_s'
  | 'secondary_s'
  | 'cart'
  | 'cart_disabled'
  | 'cart_disabled_pdp'
  | 'special'
  | 'special_s'
  | 'language_s'
  | 'language_active'
  | 'delete'
  | 'text'
  | 'plain_text'
  | 'cart_channel_restriction'

export type Icon =
  | 'login'
  | 'cart'
  | 'arrow-right'
  | 'delete'
  | 'disabled'
  | 'arrow-left'
  | 'link'
  | 'whatsapp'
  | 'e-mail'

type Props = {
  children?: React.ReactNode
  className?: string
  variation: Variation
  icon?: Icon | React.ReactElement
  label?: string
  $iconPositionRight?: boolean
  to?: string
  $fullWidth?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ref?: any
  submit?: boolean
  'data-cy-handle'?: string
  'data-cy-state'?: string
  submitable?: boolean
  isSubmitting?: boolean
  isSubmittingState?: string
  disabled?: boolean
  title?: string
}

export default React.forwardRef(function Button(props: Props, ref: any) {
  const {
    className,
    variation,
    to,
    children,
    submitable,
    isSubmitting,
    label,
    icon,
    ...rest
  } = props

  const getIcon = () => {
    if (submitable && isSubmitting) {
      return <TailSpin size="small" data-cy-state={props['data-cy-state']} />
    }
    if (icon && typeof icon !== 'string') return icon
    switch (icon) {
      case 'cart':
        return <CartIcon className="icon-cart" />
      case 'login':
        return <LoginIcon className="icon-login" />
      case 'arrow-right':
        return <ArrowRightIcon className="icon-arrow-right" />
      case 'arrow-left':
        return <ArrowLeftIcon className="icon-arrow-left" />
      case 'delete':
        return <DeleteIcon className="icon-delete" />
      case 'disabled':
        return <Disabled className="icon-disabled" />
      case 'link':
        return (
          <div className="icon-link-wrapper">
            <LinkIcon className="icon-link" />
          </div>
        )
      case 'whatsapp':
        return (
          <div className="icon-whatsapp-wrapper">
            <WhatsAppIcon className="icon-whatsapp" />
          </div>
        )
      case 'e-mail':
        return (
          <div className="icon-email-wrapper">
            {' '}
            <EmailIcon className="icon-email" />{' '}
          </div>
        )
      default: {
        if (submitable) return <div className="icon-placeholder" />
        else return null
      }
    }
  }

  if (to) {
    return (
      <Wrapper
        as={Link}
        ref={ref}
        data-cy-handle={props['data-cy-handle']}
        className={`Button ${variation} ${className ? className : ''}`}
        to={to}
        {...rest}
      >
        {getIcon()}
        {label || children}
      </Wrapper>
    )
  }

  return (
    <Wrapper
      disabled={props.disabled}
      type={props.submit ? 'submit' : undefined}
      className={`Button ${variation} ${className ? className : ''}`}
      ref={ref}
      data-cy-handle={props['data-cy-handle']}
      $isSubmitting={!!isSubmitting}
      {...rest}
      onClick={(e) => !isSubmitting && props.onClick && props.onClick(e)}
    >
      {getIcon()}
      {label || children}
    </Wrapper>
  )
}) as React.ComponentType<Props>

const Wrapper = styled.button<{
  $iconPositionRight?: boolean
  $fullWidth?: boolean
  $isSubmitting?: boolean
}>`
  ${theme.ty('rc-base', '700')};
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row;
  gap: 10px;

  > svg {
    width: 1rem;
    height: 1rem;

    &.icon-login > path {
      fill: white;
    }

    &.icon-arrow-right {
      width: 0.75rem;
      #arrow-button-right {
        stroke: ${theme.colors.white};
      }
    }

    &.icon-arrow-left {
      width: 0.4rem;
      padding-right: 5px;
    }

    &.icon-delete > path {
      fill: white;
    }
  }

  > .icon-whatsapp-wrapper {
    width: 1.5rem;
    height: 1.6rem;
    overflow: hidden;
    > svg {
      width: 2.8rem;
      height: 2.8rem;
      transform: translate(-25%, -23%);
    }
  }

  > .icon-link-wrapper {
    width: 1.5rem;
    height: 1.6rem;
    overflow: hidden;
    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  > .icon-email-wrapper {
    width: 1.5rem;
    height: 1.6rem;
    overflow: hidden;
    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  > .icon-placeholder {
    width: 1rem;
    height: 1rem;
  }

  ${(p) =>
    p.$iconPositionRight &&
    css`
      flex-direction: row-reverse;
    `}

  ${(p) =>
    p.$isSubmitting &&
    css`
      cursor: progress;
    `}

  ${(p) =>
    p.$fullWidth &&
    css`
      width: 100%;
    `}

  &:focus,
  &:active {
    outline: none;
  }

  &.primary,
  &.secondary,
  &.special,
  &.text {
    padding: ${theme.spacing('xs')} ${theme.spacing('l')};
    ${theme.rounding('m')};
  }

  &.primary {
    background: ${theme.colors.b0};
    color: ${theme.colors.white};
    border-color: ${theme.colors.b0};
  }

  &.secondary {
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.shade.b3};
    color: ${theme.colors.shade.b2};
  }

  &.primary_s,
  &.secondary_s,
  &.special_s {
    padding: ${theme.spacing('xxs')} ${theme.spacing('m')};
    ${theme.rounding('s')};
    border: 1px solid ${theme.colors.shade.b2};
  }

  &.primary_s {
    background: ${theme.colors.shade.b2};
    color: ${theme.colors.white};
  }

  &.secondary_s {
    background: ${theme.colors.white};
    color: ${theme.colors.shade.b2};
    ${theme.rounding('m')};
  }

  &.cart,
  &.cart_disabled {
    background: ${theme.colors.b0};
    color: ${theme.colors.white};
    border-color: ${theme.colors.b0};
    padding: ${theme.spacing('xs')} ${theme.spacing('xs')};
    ${theme.rounding('m')};
    &.cart_disabled {
      background: ${theme.colors.white};
      color: ${theme.colors.shade.b2};
      border: 1px solid ${theme.colors.shade.b2};
    }
  }
  &.cart_disabled_pdp {
    cursor: not-allowed;
    background: ${theme.colors.shade.b6};
    color: ${theme.colors.shade.b3};
    border: none;
    padding: ${theme.spacing('xs')} ${theme.spacing('xs')};
    ${theme.rounding('m')};
  }

  &.cart_channel_restriction {
    background: ${theme.colors.shade.b6};
    color: ${theme.colors.shade.b3};
    border: none;
    cursor: not-allowed;
    ${theme.rounding('m')};
    padding: ${theme.spacing('xs')} ${theme.spacing('xs')};
  }

  &.special {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-color: ${theme.colors.primary};
  }
  &.special_s {
    background: ${theme.colors.white};
    color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }
  &.delete {
    background: ${theme.colors.accent.red};
    color: ${theme.colors.white};
    border-color: ${theme.colors.accent.red};
    padding: ${theme.spacing('xs')} ${theme.spacing('l')};
    border-radius: ${theme.spacing('xxs')};
  }
  &.language_s {
    padding: ${theme.spacing('m')} ${theme.spacing('l')};
    ${theme.ty('rc-l')};
    background: ${theme.colors.shade.primaryUltraBright};
    color: ${theme.colors.b0};
    border-color: ${theme.colors.shade.primaryUltraBright};
    border-radius: ${theme.spacing('xxs')};
    max-width: 12.5rem;
  }
  &.language_active {
    padding: ${theme.spacing('m')} ${theme.spacing('l')};
    ${theme.ty('rc-l', '700')};
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-color: ${theme.colors.primary};
    border-radius: ${theme.spacing('xxs')};
    max-width: 12.5rem;
    cursor: default;
  }
  &.checkout-submit {
    padding: ${theme.spacing('s')} ${theme.spacing('l')};
  }
`
