import * as React from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import StarRating from 'theme/atoms/StarRating'
import { useReviews } from 'modules/ui'
import * as evt from './events'
import config from 'config'

export type Props = {
  containerID: string
  ratingAverage?: number
  ratingCount?: number
  amountText?: string
  clickable?: boolean
  evtSource?: string
}
export default function Rating(props: Props) {
  const reviews = useReviews()
  if (!props.ratingAverage || !props.ratingCount) return null

  const handleClick = () => {
    if (props.containerID && props.clickable) {
      reviews.showReviews(props.containerID)
      if (props.evtSource) evt.openProductReviews(props.evtSource)
    }
  }

  return (
    <Wrapper
      className="Rating"
      data-cy-ctx="molecules/Rating"
      onClick={() => handleClick()}
    >
      <StarRating ratingAverage={props.ratingAverage} />
      <div className="rating-average">{props.ratingAverage}</div>
      <RatingCount $isClickable={!!props.clickable}>
        {props.amountText || `(${props.ratingCount})`}
      </RatingCount>
    </Wrapper>
  )
}

const RatingCount = styled.div<{ $isClickable: boolean }>`
  ${(props) => props.$isClickable && 'text-decoration: underline;'}
`

const Wrapper = styled.div`
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing('xxs')};
  ${theme.ty('r-s')};
  color: ${theme.colors.shade.b2};
  display: flex;
  cursor: pointer;
`
