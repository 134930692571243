import * as at from './const'
import * as t from './types'
import { Action } from './actions'

export type State = Record<
  string,
  {
    data: {
      reviews: t.Review[]
      histogram: t.Bar[]
    }
    isFetching: boolean
    fetchError: null | string
  }
>

export const defaultState = {
  data: {
    reviews: [],
    histogram: []
  },
  isFetching: false,
  fetchError: null
}

const getHistogram = (reviews: t.Review[]): t.Bar[] => {
  const histogram: t.Bar[] = []

  const histogramDict: Record<number, { count: number; percent: number }> = {}
  for (let i = 1; i < 6; i++) {
    histogramDict[i] = { count: 0, percent: 0 }
  }
  for (const review of reviews) histogramDict[review.rating].count++

  const total = reviews.length
  for (let i = 1; i < 6; i++) {
    histogramDict[i].percent = Math.round(
      (histogramDict[i].count / total) * 100
    )
    histogram.push({
      label: i,
      count: histogramDict[i].count,
      percent: histogramDict[i].percent
    })
  }
  return histogram
}

export default function reducer(state: State = {}, action: Action) {
  switch (action.type) {
    case at.INIT: {
      return { ...state }
    }
    case at.FETCH_REQUEST:
      return {
        ...state,
        [action.meta.recordId]: {
          ...state[action.meta.recordId],
          data: {
            reviews: [],
            histogram: []
          },
          isFetching: true,
          fetchError: null
        }
      }
    case at.FETCH_SUCCESS:
      return {
        ...state,
        [action.meta.recordId]: {
          ...state[action.meta.recordId],
          isFetching: false,
          data: {
            reviews: action.payload,
            histogram: getHistogram(action.payload)
          }
        }
      }
    case at.FETCH_FAILURE:
      return {
        ...state,
        [action.meta.recordId]: {
          ...state[action.meta.recordId],
          isFetching: false,
          fetchError: action.payload
        }
      }

    default:
      return state
  }
}
