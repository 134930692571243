import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import StarRating from 'theme/atoms/StarRating'
import DateIcon from 'assets/date.svg'
import LocationIcon from 'assets/location.svg'
import ChatIcon from 'assets/chat.svg'
import useTranslations from 'hooks/useTranslations'
import * as t from 'modules/productReviews/types'
import { I18nLocale } from 'config/config.type'
import TickIcon from 'assets/checkmark.svg'
import config from 'config'
import useReview from './useReview'

type Props = {
  review: t.Review
  images?: string[]
}

type LocaleWithUnderscore<S = I18nLocale> =
  S extends `${infer prefix}-${infer suffix}`
    ? `${prefix}_${suffix}`
    : S extends 'en'
      ? 'en'
      : never

export default function Review(props: Props) {
  const date = new Date(
    props.review.createdAt.replace(' ', 'T')
  ).toLocaleDateString(config.i18n.locale)
  const t = useTranslations<'partials-ProductReviews'>()
  const review = useReview(props.review)
  const skuTitle = props.review.skuData[config.i18n.locale]
    ? props.review.skuData[config.i18n.locale].subtitle
    : ''

  const reviewerName =
    props.review.reviewer.name && props.review.reviewer.company
      ? `${props.review.reviewer.name}, ${props.review.reviewer.company}`
      : props.review.reviewer.company
        ? props.review.reviewer.company
        : props.review.reviewer.name || ''

  const locationEnding = props.review.originalReview.locale.replace(
    '-',
    '_'
  ) as LocaleWithUnderscore
  return (
    <Wrapper className="review-container">
      <div className="star-rating-wrapper">
        <StarRating ratingAverage={props.review.rating} />
        {props.review.verified && (
          <div data-cy-state="show-verified" className="verified">
            <TickIcon />
            {t('verified_purchase')}
          </div>
        )}
      </div>
      <div className="reviewer">
        {reviewerName ? reviewerName : t('verified_buyer')}
      </div>
      <div className="date-location">
        <span>
          <DateIcon />
          {date}
        </span>
        <span>
          <LocationIcon />
          {props.review.originalReview.locale &&
            t(`product_reviews_location_${locationEnding}`)}
        </span>
      </div>
      {skuTitle && (
        <div data-cy-state="show-article-subtitle" className="article-subtitle">
          {skuTitle}
        </div>
      )}
      <div className="comment">
        <div className="title">{review.title}</div>
        <div className="comment-text">{review.comment}</div>
      </div>
      {props.review.originalReply && props.review.originalReply.comment && (
        <div data-cy-state="show-service-reply" className="reply">
          <div className="title">
            {' '}
            <ChatIcon /> <span>{t('service_reply')}</span>
          </div>
          <div className="comment-text">{review.reply}</div>
        </div>
      )}
      {review.showTranslationBtn && (
        <div
          data-cy-state="show-toggle-btn"
          data-cy-handle="toggle-translation"
          className="translate"
          onClick={() => review.toggleTranslation()}
        >
          {!review.translate
            ? t('product_reviews_show_translation')
            : t('product_reviews_show_in_original_language')}
        </div>
      )}
      <hr className="divider" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${theme.ty('r-s')}
  > .divider {
    margin: ${theme.spacing('m')} 0;
    border: 0.5px solid ${theme.colors.shade.primaryUltraBright};
  }
  > .translate {
    ${theme.ty('r-s', '700')}
    margin-top: ${theme.spacing('s')};
    text-decoration: underline;
    cursor: pointer;
  }
  > .star-rating-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing('xxs')};
    > .verified {
      padding: 0.25rem 0.5rem;
      display: flex;
      gap: ${theme.spacing('xxs')};
      background-color: #e5f0ec;
      color: ${theme.colors.accent.green};
      > svg {
        fill: ${theme.colors.accent.green};
        ${theme.ty('rc-s', '700')}
        max-height: 1rem;
        > g > path {
          fill: ${theme.colors.accent.green};
        }
      }
      ${theme.ty('rc-s')}
    }
  }
  > .reviewer {
    margin-bottom: ${theme.spacing('xxs')};
    ${theme.ty('r-s')}
    color: ${theme.colors.shade.b2};
  }
  > .date-location {
    ${theme.ty('r-s')}
    color: ${theme.colors.shade.b2};
    margin-bottom: ${theme.spacing('xxs')};
    display: flex;
    > span {
      display: flex;
      align-items: center;
      margin-right: ${theme.spacing('s')};
      > svg {
        margin-right: ${theme.spacing('xxs')};
      }
    }
  }
  > .article-subtitle {
    margin-bottom: ${theme.spacing('xxs')};
    ${theme.ty('r-s')}
    color: ${theme.colors.shade.b2};
  }
  > .comment {
    margin-top: ${theme.spacing('xxs')};
    > .title {
      margin-bottom: ${theme.spacing('xxs')};
      ${theme.ty('rc-base', '700')}
    }
    > .comment-text {
      ${theme.ty('r-base')}
    }
  }
  > .reply {
    margin-top: ${theme.spacing('xs')};
    background-color: ${theme.colors.shade.primaryUltraBright};
    padding: ${theme.spacing('s')};
    > .title {
      display: flex;
      margin-bottom: ${theme.spacing('xs')};
      align-items: center;
      > svg {
        margin-right: ${theme.spacing('xxs')};
      }
      ${theme.ty('rc-base', '700')}
    }
    > .comment-text {
      ${theme.ty('r-base')}
    }
  }
`
