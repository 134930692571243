import { addRule } from 'redux-ruleset'
import config from 'config'

addRule({
  id: 'userlike/LOAD_CHAT',
  target: '*',
  output: '#script-add',
  addOnce: true,
  delay: 3000,
  condition: () =>
    config.features.userLikeWidgetUrl
      ? config.features.userLikeWidgetUrl.length > 0
      : false,
  consequence: () => {
    if (typeof document !== 'undefined' && config.features.userLikeWidgetUrl) {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.async = true

      scriptElement.src = config.features.userLikeWidgetUrl

      document.body.appendChild(scriptElement)
    }
  }
})
