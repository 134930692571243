import { navigate } from 'gatsby'
import { dropState, FetchSuccess } from 'modules/listing/actions'
import { addRule } from 'redux-ruleset'
import { Shape, isValid } from 'utils/shape'
import { push as dlPushFn } from 'features/tracking/datalayer'
import getPageInfo from 'utils/getPageInfo'

const isAlgoliaEvent = (schema: Shape) => (action: FetchSuccess) => {
  return action.payload.userData.some((data) => isValid(schema, data))
}

/**
 * When the SEARCH_SUCCESS action has a userData (from algolia-rule)
 * And the userData contains a REDIRECT_TO_URL event
 * Then we want to navigate to the given URL
 */
addRule({
  id: 'feature/ALGOLIA_REDIRECT',
  target: 'listing/FETCH_SUCCESS',
  output: ['#navigate', 'listing/DROP_STATE'],
  position: 'INSTEAD',
  weight: 1,
  condition: isAlgoliaEvent([
    'object',
    {
      type: ['enum', ['REDIRECT_TO_URL']],
      url: 'url'
    }
  ]),
  consequence: (action, { addRule }) => {
    const redirect = action.payload.userData.find(
      (data) => data.type === 'REDIRECT_TO_URL'
    )!
    navigate(redirect.url, { replace: true })
    addRule('dropState', { recordId: action.meta.recordId })
    dlPushFn({
      event: 'internalSearch',
      eventname: 'view_search_result_redirection',
      search_keyword: action.meta.filterValues.query,
      search_initiator_url: window.location.href,
      search_initiator: getPageInfo(window.location.pathname).pageType
    })
  },
  subRules: {
    /**
     * we need to clear search state to reset to normal state. otherwise when user searches for the exact term twice
     * a blank page will be shown because the search did not change
     */
    dropState: {
      target: 'navigation/LOCATION_CHANGED',
      output: 'listing/DROP_STATE',
      addOnce: true,
      consequence: (_, { context }) => dropState(context.get('recordId'))
    }
  }
})
