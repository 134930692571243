import { dispatchEvent } from 'redux-ruleset'

export const SHARE_CART_OPEN_MODAL: 'ShareCart/SHARE_CART_OPEN_MODAL' =
  'ShareCart/SHARE_CART_OPEN_MODAL'
export const SHARE_CART_COPY_LINK: 'ShareCart/SHARE_CART_COPY_LINK' =
  'ShareCart/SHARE_CART_COPY_LINK'
export const SHARE_CART_DOWNLOAD: 'ShareCart/SHARE_CART_DOWNLOAD' =
  'ShareCart/SHARE_CART_DOWNLOAD'
export const SHARE_CART_SEND_VIA_MAIL: 'ShareCart/SHARE_CART_SEND_VIA_MAIL' =
  'ShareCart/SHARE_CART_SEND_VIA_MAIL'

export const shareCartOpenModal = () =>
  dispatchEvent({
    type: SHARE_CART_OPEN_MODAL,
    meta: {}
  })

export const shareCartCopyLink = () =>
  dispatchEvent({
    type: SHARE_CART_COPY_LINK,
    meta: {}
  })

export const shareCartDownload = () =>
  dispatchEvent({
    type: SHARE_CART_DOWNLOAD,
    meta: {}
  })

export const shareCartSendViaEmail = () =>
  dispatchEvent({
    type: SHARE_CART_SEND_VIA_MAIL,
    meta: {}
  })

export type ShareCartOpenModal = ReturnType<typeof shareCartOpenModal>
export type ShareCartCopyLink = ReturnType<typeof shareCartCopyLink>
export type ShareCartDownload = ReturnType<typeof shareCartDownload>
export type ShareCartSendViaEmail = ReturnType<typeof shareCartSendViaEmail>

export type Event =
  | ShareCartOpenModal
  | ShareCartCopyLink
  | ShareCartDownload
  | ShareCartSendViaEmail

declare global {
  interface RulesetDispatchEvents {
    'molecules/account/CartSummary/ShareCart': Event
  }
}
