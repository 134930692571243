import Component from './CheckoutHeader'
import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'app-CheckoutHeader': {
      checkout_header_contact: void
      checkout_step_account: void
      checkout_step_address: void
      checkout_step_payment: void
      checkout_step_order: void
      checkout_back_to_shop: void
      infotext_service_info: void
      trust_icon_headline: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query app_CheckoutHeader_translations {
      checkout_header_contact: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_header_contact"
      )
      checkout_step_account: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_step_account"
      )
      checkout_step_address: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_step_address"
      )
      checkout_step_payment: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_step_payment"
      )
      checkout_step_order: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_step_order"
      )
      checkout_back_to_shop: snippet(
        group: "app-CheckoutHeader"
        name: "checkout_back_to_shop"
      )
      trust_icon_headline: snippet(
        group: "app-Footer"
        name: "trust_icon_headline"
      )
    }
  `)
)
