import { addRule } from 'redux-ruleset'
import { init } from 'modules/listing/actions'
import { FETCH_SUCCESS, INIT, SET_CATEGORY } from 'modules/listing/const'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import { serialize, deserialize } from 'modules/listing/utils/query-string'

/**
 * When the listing state updates
 * Then I want to track the filtervalues in the url
 */
addRule({
  id: 'feature/LISTING_STATE_TO_URL',
  target: FETCH_SUCCESS,
  output: '#url-hash',
  weight: 1,
  condition: () => !window.location.href.includes('one-to-one-bundle'),
  addWhen: function* (next, { context }) {
    // hydrate initial category
    yield next(LOCATION_CHANGED, () => {
      const hash = window.location.hash
      const categoryMatch = hash.match(/&category=([^&]*)/)
      if (categoryMatch)
        context.set('category', decodeURIComponent(categoryMatch[1]))
      return true
    })
    return 'ADD_RULE'
  },
  addUntil: function* (next, { context }) {
    // track virtual categories
    yield next([SET_CATEGORY, INIT], (action) => {
      if (action.type === INIT) context.set('category', null)
      else context.set('category', action.payload)
    })

    return 'REAPPLY_ADD_UNTIL'
  },
  consequence: (action, { context, getState }) => {
    const baseFilterValues =
      getState().listing[action.meta.recordId]?.resetFilterValues
    if (!baseFilterValues) return

    const hash = serialize(
      action.meta.filterValues,
      context.get('category'),
      baseFilterValues
    ).hash

    window.history.replaceState(
      null,
      '',
      window.location.pathname + window.location.search + hash
    )
  }
})

/**
 * When the url contains a hash
 * And I init my listing state
 * Then I want to add the hashed filtervalues to the initializing
 */
addRule({
  id: 'feature/HYDRATE_LISTING_STATE',
  target: INIT,
  output: INIT,
  position: 'INSTEAD',
  weight: 9,
  condition: () => Boolean(window.location.hash),
  consequence: (action) => {
    const newFilterValues = deserialize(
      window.location.hash,
      action.meta.attributes,
      action.payload
    )

    return init(
      action.meta.recordId,
      newFilterValues,
      action.meta.attributes,
      action.meta.resetFilterValues
    )
  }
})

/**
 * Given the user is on the listing route
 * When the user triggers the history back-button
 * We re-initialize the url to invalidate the listing
 */
addRule({
  id: 'feature/INIT_LISTING_ON_POPSTATE',
  target: INIT,
  output: [INIT, '#listener'],
  concurrency: 'ONCE',
  addWhen: function* (next) {
    yield next(LOCATION_CHANGED, (action) =>
      action.payload.pathname.includes('/category/')
    )
    return 'ADD_RULE'
  },
  addUntil: function* (next) {
    yield next(
      LOCATION_CHANGED,
      (action) => !action.payload.pathname.includes('/category/')
    )
    return 'RECREATE_RULE'
  },
  consequence: (action, { dispatch }) => {
    const l = () => {
      dispatch(action)
    }

    window.addEventListener('popstate', l)
    return () => window.removeEventListener('popstate', l)
  }
})
