import * as t from './types'

export const ADD_MESSAGE: 'snackbar/ADD_MESSAGE' = 'snackbar/ADD_MESSAGE'
export const REMOVE_MESSAGE: 'snackbar/REMOVE_MESSAGE' =
  'snackbar/REMOVE_MESSAGE'
export const REMOVE_ALL: 'snackbar/REMOVE_ALL' = 'snackbar/REMOVE_ALL'

export const defaultState: t.State = {
  messages: []
}
