import { addRule } from 'redux-ruleset'
import * as filterEvents from 'theme/molecules/ListingFilter/FilterDrawer/events'
import { push as dlPush } from 'features/tracking/datalayer'
import { FETCH_SUCCESS } from 'modules/listing/const'
import getPageInfo from 'utils/getPageInfo'

addRule({
  id: 'dl/PROMINENT_FILTER_CLICK',
  target: filterEvents.PROMINENT_FILTER_OPEN,
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'filtermenu_open',
      filter_category: action.payload,
      category: 'Filter Navigation',
      action: 'filtermenu_open',
      label: action.payload,
      value: 0,
      nonInteraction: 'true'
    })
  }
})
addRule({
  id: 'dl/FILTER_DRAWER_CLOSE',
  target: filterEvents.FILTER_DRAWER_CLOSE,
  output: '#dl-event',
  consequence: (action) => {
    if (action.payload)
      dlPush({
        event: 'genericEvent',
        eventname: 'filtermenu_close',
        filter_closing: action.payload,
        category: 'Filter Navigation',
        action: 'filtermenu_close',
        label: action.payload,
        value: 0,
        nonInteraction: 'true'
      })
  }
})
addRule({
  id: 'dl/FILTER_VALUE_CLICK',
  target: filterEvents.FILTER_VALUE_CLICK,
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'filtername_click',
      filter_category: action.payload.filterName,
      filter_value: action.payload.filterValue,
      category: 'Filter Navigation',
      action: 'filtername_click',
      label: action.payload.filterName + '-' + action.payload.filterValue,
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/FILTER_VALUE_REMOVE',
  target: filterEvents.FILTER_VALUE_REMOVE,
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'filter_remove',
      filter_category: action.payload.filterName,
      filter_value: action.payload.filterValue,
      category: 'Filter Navigation',
      action: 'filter_remove',
      label: action.payload.filterName + '-' + action.payload.filterValue,
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/FILTER_RESET',
  target: filterEvents.FILTER_RESET,
  output: '#dl-event',
  consequence: () => {
    dlPush({
      event: 'genericEvent',
      eventname: 'filter_reset',
      category: 'Filter Navigation',
      action: 'Filter Reset',
      label: '',
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/FILTER_CLICK',
  target: filterEvents.FILTER_CLICK,
  output: '#dl-event',

  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'filtername_click',
      filter_category: action.payload,
      category: 'Filter Navigation',
      action: 'filtername_click',
      label: action.payload,
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/FILTER_CHANGE',
  target: FETCH_SUCCESS,
  addWhen: function* (next) {
    yield next([
      filterEvents.FILTER_VALUE_CLICK,
      filterEvents.FILTER_VALUE_REMOVE,
      filterEvents.FILTER_RESET
    ])
    return 'ADD_RULE'
  },
  onExecute: 'RECREATE_RULE',
  output: '#dl-event',
  consequence: (action) => {
    const filters = Object.keys(action.meta.filterValues.attributes).filter(
      (key) => action.meta.filterValues.attributes[key].length > 0
    )

    const attr = Object.keys(action.meta.filterValues.numericAttributes).filter(
      (key) =>
        action.meta.filterValues.numericAttributes[key].min !== null ||
        action.meta.filterValues.numericAttributes[key].max !== null
    )
    attr.map((val) => filters.push(val))

    if (
      action.meta.filterValues.minPrice !== null ||
      action.meta.filterValues.maxPrice !== null
    ) {
      filters.push('PRICE')
    }

    dlPush({
      event: 'genericEvent',
      eventname: 'productCount',
      category: 'productCount',
      action: `Category: ${action.meta.filterValues.category}`,
      label: action.payload.nbHits,
      value: action.payload.nbHits,
      nonInteraction: 'false',
      productCount: action.payload.nbHits,
      filterActive: filters.length > 0,
      filters: filters.join(',')
    })
  }
})

addRule({
  id: 'dl/PREFETCH_LISTING',
  target: 'navigation/LOCATION_CHANGED',
  output: '#dl-event',
  condition: (action) => {
    return getPageInfo(action.payload.pathname).pageType === 'Category'
  },
  consequence: (_, { getState }) => {
    const state = getState()

    dlPush({
      event: 'genericEvent',
      eventname: 'productCount',
      category: 'productCount',
      action: `Category: ${
        state.listing.category?.filterValues.category || ''
      }`,
      label: state.listing.category?.nbHits || 0,
      value: state.listing.category?.nbHits || 0,
      nonInteraction: 'false',
      productCount: state.listing.category?.nbHits || 0,
      filterActive: false,
      filters: ''
    })
  }
})
