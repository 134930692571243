import * as t from '../types'
import { State } from '../reducer'
import * as s from '../selectors'
import * as a from '../actions'
import useConnect, { Config } from 'hooks/useConnect'

export type Input = Record<string, never>

export type Output = {
  data: t.Message[]
  addMessage: typeof a.addMessage
  removeMessage: typeof a.removeMessage
}

const config: Config<Input, Output, State, object> = {
  moduleKey: 'snackbar',
  name: 'snackbar/useMessages',
  createCacheKey: () => '',
  mapState: (state) => ({
    data: s.getMessages(state)
  }),
  mapDispatch: {
    addMessage: a.addMessage,
    removeMessage: a.removeMessage
  }
}

export default function useSnackbar(): Output {
  const input = {}
  const hook = useConnect(input, config)
  return hook
}
