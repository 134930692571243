import { addRule } from 'redux-ruleset'
import { OPEN_PRODUCT_REVIEWS } from 'theme/molecules/Rating/events'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'dl/PRODUCT_REVIEWS_OPEN',
  target: [OPEN_PRODUCT_REVIEWS],
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'product_reviews_open',
      position: action.meta.source
    })
  }
})
