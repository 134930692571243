import { addRule } from 'redux-ruleset'
import * as at from './const'
import * as a from './actions'

addRule({
  id: 'snackbar/REMOVE_MESSAGE_AFTER_TIME',
  target: at.ADD_MESSAGE,
  output: at.REMOVE_MESSAGE,
  delay: 3000,
  consequence: (action) => a.removeMessage(action.payload)
})
