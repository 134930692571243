import theme from 'theme'

export const configurableTextColors = [
  theme.colors.black,
  theme.colors.white
] as const
export const configurableBackgroundColors = [
  theme.colors.accent.red,
  theme.colors.brand.vega,
  theme.colors.brand.erwinm,
  theme.colors.brand.pulsiva_gray,
  theme.colors.brand.jobeline,
  theme.colors.green_50,
  theme.colors.blue,
  theme.colors.blue_50,
  theme.colors.yellow,
  theme.colors.dark_gray,
  theme.colors.brand.lusini
] as const
