import B2xModal from './B2xModal'
import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'app-B2xModal': {
      b2xModal_headline: void
      b2xModal_label_b2b: void
      b2xModal_label_b2c: void
      b2xModal_label_submit_mobile: void
      b2xModal_label_submit_b2b: void
      b2xModal_label_submit_b2c: void
      b2xModal_text_info_b2c: void
      b2xModal_text_info_b2b: void
      b2xModal_bg_a11y_label: void
    }
  }
}

const withFooterTranslations = (Component: any) =>
  withTranslations(Component, () =>
    useStaticQuery(graphql`
      query app_B2xModal_translations {
        b2xModal_bg_a11y_label: snippet(
          group: "app-B2xModal"
          name: "b2xModal_bg_a11y_label"
        )
        b2xModal_headline: snippet(
          group: "app-B2xModal"
          name: "b2xModal_headline"
        )

        b2xModal_label_b2b: snippet(
          group: "app-B2xModal"
          name: "b2xModal_label_b2b"
        )

        b2xModal_label_b2c: snippet(
          group: "app-B2xModal"
          name: "b2xModal_label_b2c"
        )

        b2xModal_label_submit_mobile: snippet(
          group: "app-B2xModal"
          name: "b2xModal_label_submit_mobile"
        )

        b2xModal_label_submit_b2c: snippet(
          group: "app-B2xModal"
          name: "b2xModal_label_submit_b2c"
        )

        b2xModal_label_submit_b2b: snippet(
          group: "app-B2xModal"
          name: "b2xModal_label_submit_b2b"
        )

        b2xModal_text_info_b2b: snippet(
          group: "app-B2xModal"
          name: "b2xModal_text_info_b2b"
        )

        b2xModal_text_info_b2c: snippet(
          group: "app-B2xModal"
          name: "b2xModal_text_info_b2c"
        )
      }
    `)
  )
export default withFooterTranslations(B2xModal)
