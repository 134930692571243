import Component from './Header'
import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'app-Header': {
      b2x_selection_private: void
      b2x_selection_company: void
      b2x_dropdown_private: { TAX: any }
      b2x_dropdown_company: { TAX: any }
      iconList_bookmarks: void
      iconList_contact: void
      iconList_account: void
      iconList_cart: void
      header_primary_btn_label: void
      header_show_more_btn_label: void
      header_main_category_label: {
        CATEGORY: string
      }
      header_search_input_placeholder: void
      header_wishlisticon_add_product: {
        WISHLIST: any
      }
      header_wishlisticon_remove_product: {
        WISHLIST: any
      }
      language_switch_title: void
      language_switch_cancel: void
      language_switch_save: void
      header_logo_a11y_label: void
      header_flyout_logo_a11y_label: void
      header_wishlist_a11y_label: void
      header_search_input_a11y_title: void
      header_clear_search_button_a11y_title: void
      header_search_button_a11y_title: void
      seo_title: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query app_Header_translations {
      header_search_button_a11y_title: snippet(
        group: "app-Header"
        name: "header_search_button_a11y_title"
      )
      header_clear_search_button_a11y_title: snippet(
        group: "app-Header"
        name: "header_clear_search_button_a11y_title"
      )
      header_search_input_a11y_title: snippet(
        group: "app-Header"
        name: "header_search_input_a11y_title"
      )
      header_wishlist_a11y_label: snippet(
        group: "app-Header"
        name: "header_wishlist_a11y_label"
      )
      header_flyout_logo_a11y_label: snippet(
        group: "app-Header"
        name: "header_flyout_logo_a11y_label"
      )
      header_logo_a11y_label: snippet(
        group: "app-Header"
        name: "header_logo_a11y_label"
      )
      header_main_category_label: snippet(
        group: "app-Header"
        name: "header_main_category_label"
      )
      header_show_more_btn_label: snippet(
        group: "app-Header"
        name: "header_show_more_btn_label"
      )
      header_primary_btn_label: snippet(
        group: "app-Header"
        name: "header_primary_btn_label"
      )
      b2x_selection_private: snippet(
        group: "app-Header"
        name: "b2x_selection_private"
      )
      b2x_selection_company: snippet(
        group: "app-Header"
        name: "b2x_selection_company"
      )
      b2x_dropdown_private: snippet(
        group: "app-Header"
        name: "b2x_dropdown_private"
      )
      b2x_dropdown_company: snippet(
        group: "app-Header"
        name: "b2x_dropdown_company"
      )
      iconList_bookmarks: snippet(
        group: "app-Header"
        name: "iconList_bookmarks"
      )
      iconList_contact: snippet(group: "app-Header", name: "iconList_contact")
      iconList_account: snippet(group: "app-Header", name: "iconList_account")
      iconList_cart: snippet(group: "app-Header", name: "iconList_cart")
      header_search_input_placeholder: snippet(
        group: "app-Header"
        name: "header_search_input_placeholder"
      )
      header_wishlisticon_add_product: snippet(
        group: "app-Header"
        name: "header_wishlisticon_add_product"
      )
      header_wishlisticon_remove_product: snippet(
        group: "app-Header"
        name: "header_wishlisticon_remove_product"
      )
      language_switch_title: snippet(
        group: "app-Header"
        name: "language_switch_title"
      )
      language_switch_cancel: snippet(
        group: "app-Header"
        name: "language_switch_cancel"
      )
      language_switch_save: snippet(
        group: "app-Header"
        name: "language_switch_save"
      )
      seo_title: snippet(group: "app-Header", name: "seo_title")
    }
  `)
)
