import * as t from './types'
import { State, defaultState } from './reducer'

export const getState = (
  state: State,
  recordId: string
): {
  reviews: t.Review[]
  histogram: t.Bar[]
} => {
  const inner = state[recordId]
  if (!inner) return defaultState.data
  return inner.data
}
