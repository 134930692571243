import * as React from 'react'
import GlobalStyle from './GlobalStyle'
import Partials from './Partials'
import { ErrorBoundary } from './ErrorBoundary'
import { AccountProvider } from 'hooks/account/useAccount'
import useDYScrollEvents from 'hooks/useDYScrollEvents'

export default function GlobalLayout(props: { children: any }) {
  useDYScrollEvents()

  return (
    <ErrorBoundary accountProviderBoundary>
      <AccountProvider>
        <ErrorBoundary>
          <GlobalStyle />
          {props.children}
          <Partials />
        </ErrorBoundary>
      </AccountProvider>
    </ErrorBoundary>
  )
}
