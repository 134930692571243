import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Button from 'theme/atoms/Button'
import useAccount from 'hooks/account/useAccount/useAccount'
import useTranslations from 'hooks/useTranslations'
import * as events from './events'
import config from 'config'
import { b2xManager } from 'utils/channel'

export default function B2xModal() {
  // Do not render the modal, if the b2c feature is disabled
  if (!config.features.b2c) return null

  const { setChannel } = useAccount()
  const t = useTranslations<'app-B2xModal'>()
  const b2bImage =
    'https://res.cloudinary.com/lusini/image/upload/f_auto/v1700555456/application/b2b-b2c-switch/iccwzexmxe1zuuuiwpyd'
  const b2cImage =
    'https://res.cloudinary.com/lusini/image/upload/f_auto/v1700555454/application/b2b-b2c-switch/tt5tgvqiwob8hn0xejrf'

  const $background = React.useRef<HTMLImageElement>(null)

  /**
   * send the view event
   */
  React.useEffect(() => {
    events.view()
  }, [])

  return (
    <Wrapper className="B2xModal" data-cy-ctx="app/B2xModal">
      <div className="modal">
        <img
          ref={$background}
          alt={t.asText($background, 'b2xModal_bg_a11y_label')}
          className="modal_background_image"
          src="https://res.cloudinary.com/lusini/image/upload/f_auto,w_800,h_800/v1712302994/application/2000X2000v2"
          loading="lazy"
        ></img>
        <div className="title">{t('b2xModal_headline')}</div>
        <div className="options">
          <div
            className="option"
            data-cy-handle="b2b-option"
            onClick={() => {
              setChannel('b2b', 'overlay_b2b_click')
              events.close()
            }}
          >
            <img src={b2bImage} alt="b2b" />
            <div className="content">
              <div className="title">{t('b2xModal_label_b2b')}</div>
              <div className="label">{t('b2xModal_text_info_b2b')}</div>
              <Button className="desktop" variation="cart">
                {t('b2xModal_label_submit_b2b')}
              </Button>
              <Button className="mobile" variation="cart">
                {t('b2xModal_label_submit_mobile')}
              </Button>
            </div>
          </div>
          <div
            className="option"
            data-cy-handle="b2c-option"
            onClick={() => {
              setChannel('b2c', 'overlay_b2c_click')
              events.close()
            }}
          >
            <img src={b2cImage} alt="b2c" />
            <div className="content">
              <div className="title">{t('b2xModal_label_b2c')}</div>
              <div className="label">{t('b2xModal_text_info_b2c')}</div>
              <Button className="desktop" variation="cart">
                {t('b2xModal_label_submit_b2c')}
              </Button>
              <Button className="mobile" variation="cart">
                {t('b2xModal_label_submit_mobile')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndex.theme_app_b2xmodal_Overlay};
  background: rgba(0, 0, 0, 0.5);

  /** when channel was set the hideB2xPopup class will be set */
  ${b2xManager.getCssRule()}

  > .modal {
    position: absolute;
    left: calc(100vw / 2);
    top: calc(100vh / 2);
    width: calc(100vw - ${theme.spacing('m')});
    max-width: 820px;
    transform: translate(-50%, -50%);
    padding: ${theme.spacing('m')};
    background: ${theme.colors.shade.b6};

    @media (min-width: ${theme.ms.LG}px) {
      padding: ${theme.spacing('l')};
      padding-top: ${theme.spacing('m')};
    }

    > .modal_background_image {
      position: absolute; /* Positions the image on top of the div */
      top: 0; /* Aligns the top of the image with the top of the div */
      left: 0; /* Aligns the left of the image with the left of the div */
      width: 100%; /* Stretches the image to cover the entire width of the div */
      height: 100%; /* Stretches the image to cover the entire height of the div */
      z-index: -1; /* Ensures the image is behind the content */
    }

    > .title {
      ${theme.ty('rc-2xl', 'bold', 'rc-3xl')}
      margin-bottom: ${theme.spacing('m')};

      @media (min-width: ${theme.ms.LG}px) {
        margin-bottom: ${theme.spacing('l')};
      }
    }

    > .options {
      display: flex;
      gap: ${theme.spacing('m')};

      @media (min-width: ${theme.ms.LG}px) {
        gap: ${theme.spacing('l')};
      }

      > .option {
        background: ${theme.colors.white};
        flex: 1;
        cursor: pointer;

        > img {
          width: 100%;
        }
        > .content {
          padding: ${theme.spacing('m')};

          > .title {
            ${theme.ty('rc-base', 'bold', 'rc-2xl')}
            margin-bottom: ${theme.spacing('xxs')};
          }

          > .label {
            ${theme.ty('rc-s', null, 'rc-base')};
            margin-bottom: ${theme.spacing('m')};
          }

          > .desktop {
            display: none;
          }

          @media (min-width: ${theme.ms.MD}px) {
            > .desktop {
              display: block;
            }
            > .mobile {
              display: none;
            }
          }
        }
      }
    }
  }
`
