import * as a from '../actions'
import { State } from '../reducer'
import useConnect, { Config } from 'hooks/useConnect'

export type Input = {}

export type Output = {
  optInStatus: boolean
  setOptIn: typeof a.setOptIn
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'ui',
  name: 'ui/useOptInStatus',
  createCacheKey: () => '',
  mapState: (state) => ({
    optInStatus: state.optedIn
  }),
  mapDispatch: {
    setOptIn: a.setOptIn
  }
}

export default function useOptInStatus(): Output {
  const input: Input = {}
  const hook: Output = useConnect(input, config)
  return hook
}
