import * as React from 'react'
import * as t from '../types'
import useObserver from './_useObserver'
import * as channelModule from 'utils/channel'

export type DlEvent =
  | 'overlay_b2c_click'
  | 'overlay_b2b_click'
  | 'navigation_b2b2c_switch'
  | 'overlay_b2c_pdp_click'
  | 'overlay_b2b_pdp_click'
  | 'overlay_b2b2c_x_click'
  | 'cart_b2x_switch'
  | 'implicit'
  | 'paypal_express_b2c_click'
  | 'paypal_express_b2b_click'

export default function useChannel(observer: ReturnType<typeof useObserver>) {
  const [channel, _setChannel] = React.useState('b2b' as t.Channel)
  const [channelLoaded, setChannelLoaded] = React.useState(false)

  const setChannel = (channel: t.Channel, dlEvent: DlEvent) => {
    _setChannel(channel)
    channelModule.setChannel(channel)

    observer.current.sendEvent({ type: 'SET_CHANNEL', channel, dlEvent })
  }

  React.useEffect(() => {
    _setChannel(channelModule.getChannel())
    setChannelLoaded(true)
  }, [])

  React.useEffect(() => {
    return observer.current.onEvent((evt) => {
      switch (evt.type) {
        case 'LOGIN':
        case 'REGISTER': {
          if (evt.channel !== channel) setChannel(evt.channel, 'implicit')
        }
      }
    })
  }, [channel])

  return { setChannel, channel, channelLoaded }
}
