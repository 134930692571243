import React from 'react'
export default function useWindowSize(): {
  windowWidth: number
  windowHeight: number
} {
  if (typeof window === 'undefined') return { windowWidth: 0, windowHeight: 0 }

  const [windowSize, setWindowSize] = React.useState({
    windowWidth: window.innerWidth ?? 0,
    windowHeight: window.innerHeight ?? 0
  })

  React.useEffect(() => {
    const listener = () => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      })
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  return windowSize
}
