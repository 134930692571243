import * as React from 'react'
import { useProductReviews } from 'modules/productReviews'
import styled from 'styled-components'
import Drawer from 'theme/atoms/Drawer'
import useDelayedFn from 'hooks/useDelayedFn'
import CloseIcon from 'assets/menu-close.svg'
import theme from 'theme'
import StarRating from 'theme/atoms/StarRating'
import RatingHistogram from './RatingHistogram'
import Review from './Review'
import withTranslations from './withTranslations'
import useTranslations from 'hooks/useTranslations'
import Spinner from 'theme/atoms/Spinner'

type Props = {
  remove: () => void
  containerID: string
}

export function ProductReviews(props: Props) {
  const productReviews = useProductReviews(props.containerID)
  const [showReviews, setShowReviews] = React.useState(false)
  const [isClosing, delayedClose] = useDelayedFn(300, props.remove)
  const t = useTranslations<'partials-ProductReviews'>()

  const handleClose = () => {
    delayedClose()
  }

  const averageRating = React.useMemo(() => {
    return (
      productReviews.data.histogram.reduce(
        (acc, curr) => acc + curr.label * curr.count,
        0
      ) / productReviews.data.reviews.length
    )
  }, [productReviews.data.histogram])

  React.useEffect(() => {
    if (productReviews.data.reviews.length) {
      setShowReviews(true)
    }
  }, [productReviews.data.reviews.length])

  if (!showReviews)
    return (
      <Drawer
        onClose={handleClose}
        maxWidth="27.5rem"
        data-cy-handle-overlay="overlay-ref-click"
        data-cy-handle-close-icon="close-icon"
        data-cy-ctx="partials/ProductReviews"
        customHeader={true}
        isClosing={isClosing}
        visible={showReviews}
      >
        <Wrapper>
          <div className="flyout-productreviews-top">
            <div className="label">{t('customer_reviews')}</div>
            <div
              className="close"
              data-cy-handle="close-icon"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="spinner-wrapper" data-cy-state="show-spinner">
            <Spinner size="big" />
          </div>
        </Wrapper>
      </Drawer>
    )
  if (productReviews.data.reviews.length == 0) {
    return null
  }
  return (
    <Drawer
      onClose={handleClose}
      maxWidth="27.5rem"
      data-cy-handle-overlay="overlay-ref-click"
      data-cy-handle-close-icon="close-icon"
      data-cy-ctx="partials/ProductReviews"
      customHeader={true}
      isClosing={isClosing}
      visible={showReviews}
    >
      <Wrapper>
        <div className="flyout-productreviews-top">
          <div className="label">{t('customer_reviews')}</div>
          <div
            className="close"
            data-cy-handle="close-icon"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="star-rating-wrapper">
          <span className="rating-average">
            {Math.round(averageRating * 100) / 100}
          </span>

          <StarRating ratingAverage={averageRating} />
          <span>
            {t('total_ratings_text', {
              count: productReviews.data.reviews.length
            })}
          </span>
        </div>

        <RatingHistogram histogram={productReviews.data.histogram} />
        <div className="review-container">
          {productReviews.data.reviews.map((review) => (
            <Review review={review} key={review.objectID} />
          ))}
        </div>
      </Wrapper>
    </Drawer>
  )
}
export default withTranslations(ProductReviews)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing('ml')};

  > .star-rating-wrapper {
    margin-bottom: ${theme.spacing('ml')};
    display: flex;
    gap: ${theme.spacing('xxs')};
    flex-wrap: wrap;
    > .rating-average {
      ${theme.ty('rc-l', 'bold')}
      color: ${theme.colors.b0};
    }
  }
  > .spinner-wrapper {
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing('ml')};
  }
  > .flyout-productreviews-top {
    border: 0px;
    margin: 0;
    display: flex;
    flex-direction: row;
    min-height: 4.5rem;

    > .label {
      display: flex;
      ${theme.ty('rc-2xl')}
      text-transform: none;
      flex: 1;
      color: ${theme.colors.b0};
      margin-top: ${theme.spacing('ml')};
    }

    > .close {
      margin: ${theme.spacing('ml')};
      margin-right: ${theme.spacing('xs')};
      height: 1.25rem;
      cursor: pointer;

      > svg {
        font-size: 2rem;
        #menu-close {
          stroke: ${theme.colors.b0};
        }
        width: 1.25rem;
      }
    }
  }
`
