import * as React from 'react'
import { dispatchEvent } from 'redux-ruleset'
import createThrottle from 'utils/createThrottle'

export type Event =
  | { type: 'SCROLL25'; payload: 'Scrolldepth25' }
  | { type: 'SCROLL50'; payload: 'Scrolldepth50' }
  | { type: 'SCROLL75'; payload: 'Scrolldepth75' }

declare global {
  interface RulesetDispatchEvents {
    'globalLayout/useScroll': Event
  }
}

export default function useDYScrollEvents() {
  React.useEffect(() => {
    const throttle = createThrottle(2000)
    const cb = throttle(() => {
      const range = 20
      const scrollPosition = Math.round(
        (window.scrollY /
          (document.documentElement.scrollHeight -
            document.documentElement.clientHeight)) *
          range
      )
      if (scrollPosition === 5) {
        dispatchEvent({ type: 'SCROLL25', payload: 'Scrolldepth25' })
      }
      if (scrollPosition === 10) {
        dispatchEvent({ type: 'SCROLL50', payload: 'Scrolldepth50' })
      }
      if (scrollPosition === 15) {
        dispatchEvent({ type: 'SCROLL75', payload: 'Scrolldepth75' })
      }
    })
    window.addEventListener('scroll', cb)
    return () => window.removeEventListener('scroll', cb)
  }, [])
}
