import * as t from '../types'
import createAlgoliaHelper from 'utils/createAlgoliaHelper'
import config from 'config'

/** @firescoutMockFn productReviews.fetch */
export const fetch = async (containerID: string): Promise<t.api.Fetch> => {
  const helper = await createAlgoliaHelper(config.index.productReviews || '', {
    facets: ['containerID'],
    distinct: 0,
    attributesToRetrieve: [
      'containerID',
      'createdAt',
      'images',
      'locale',
      'originalReply',
      'originalReview',
      'rating',
      'reply',
      'review',
      'reviewer',
      'sku',
      'verified',
      'objectID',
      'skuData'
    ]
  })
  helper.addFacetRefinement('containerID', containerID)
  const result = await helper.searchOnce({ hitsPerPage: 100 })
  const getFormatedLocale = (locale: string) =>
    locale === 'en_GB' ? 'en' : locale.replace('_', '-')

  const mapKeys = (skuData) =>
    Object.keys(skuData).reduce((acc, k) => {
      acc[getFormatedLocale(k)] = skuData[k]
      return acc
    }, {})

  const reviewsResult = result.content.hits.map((hit) => ({
    ...hit,
    locale: getFormatedLocale(hit.locale),
    originalReview: {
      ...hit.originalReview,
      locale: getFormatedLocale(hit.originalReview.locale)
    },
    originalReply: {
      ...hit.originalReply,
      locale: getFormatedLocale(hit.originalReply.locale)
    },
    skuData: mapKeys(hit.skuData)
  }))

  return reviewsResult.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}
