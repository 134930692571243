import * as React from 'react'
import * as api from './api'
import { CartItem } from '../../types'
import * as t from '../../types'
import useObserver from '../_useObserver'
import { isItemChannelRestricted } from 'hooks/account/utils'
import useAbandonedCart from 'hooks/account/cart/useAbandonedCart'
import { useSnackbar } from 'modules/Snackbar'
import { logError } from 'utils/buzdev'

/**
 * @firescoutMockFn cart.useCart
 * manages the whole cart. can add, remove and update cart items
 * as long as the cart was not initially fetched the prop "setup" is false
 */
export default function useCart(
  observer: ReturnType<typeof useObserver>,
  channel: 'b2b' | 'b2c'
) {
  useAbandonedCart(observer)
  const [cart, setCart] = React.useState(api.getEmptyCart())
  const [updating, setUpdating] = React.useState(false)
  const [setup, setSetup] = React.useState(false)
  const shippingMethod = React.useRef({ id: '', name: '...' })
  const preventCartFetchWhenPaypalIsActive = React.useRef(false)
  const snackbar = useSnackbar()

  /**
   * fetch the cart data and updates shipping method
   */
  const fetch = async () => {
    const result = await api.fetchCart(shippingMethod.current)

    if (result.status === 200) {
      if (!setup) {
        observer.current.sendEvent({
          type: 'SET_INITIAL_CART',
          cart: result.payload.cart
        })
      }
      shippingMethod.current = result.payload.shippingMethod

      setCart(result.payload.cart)
    }
    setSetup(true)
  }

  /**
   * initally fetch cart
   */
  React.useEffect(() => {
    if (setup) return
    fetch()
  }, [])

  /**
   * react to account-events
   */
  React.useEffect(() => {
    return observer.current.onEvent((evt) => {
      switch (evt.type) {
        case 'LOGOUT':
          setCart(api.getEmptyCart())
          break
        case 'SET_CHANNEL':
        case 'CHANGE_SHIPPING_METHOD':
        case 'CHANGE_PAYMENT_METHOD':
        case 'CHANGE_ADDRESS':
          if (!preventCartFetchWhenPaypalIsActive.current) {
            fetch()
          }
          break
        case 'LOGIN': {
          /** only fetch when channel did not change. otherwise wait for set_channel event */
          if (evt.channel === channel) fetch()
          break
        }
        case 'PROMOTION_CODE_ADD':
        case 'CART_ADD_ITEM':
        case 'CART_ADD_ITEM_BATCH':
        case 'CART_UPDATE_ITEM':
        case 'CART_REMOVE_ITEM':
        case 'CART_ITEM_INCREASE_QUANTITY':
        case 'CART_ITEM_DECREASE_QUANTITY':
        case 'CART_REMOVE_ITEM_BATCH': {
          setCart(evt.cart)
        }
      }
    })
  }, [])

  /**
   * fire initally and then wait for 300ms. as long as quantity changes within 300ms
   * we start timeout again. when no click happens after 300ms we send request one last time
   * when the item gets increased during the fetch we cancel the fetch result and wait another 300ms
   */
  const debouncedUpdate = React.useMemo(() => {
    let timeoutId: false | NodeJS.Timeout = false
    let fetchId = 0

    if (process.env.NODE_ENV === 'test') {
      return async (itemId: string, quantity: number) => {
        setUpdating(true)
        const result = await api.updateItem(
          itemId,
          quantity,
          shippingMethod.current
        )

        if (result.status === 200) {
          shippingMethod.current = result.payload.shippingMethod

          observer.current.sendEvent({
            type: 'CART_UPDATE_ITEM',
            cart: result.payload.cart,
            cartItemId: itemId
          })
        }
        setUpdating(false)
      }
    }

    return async function (itemId: string, quantity: number) {
      const fId = (fetchId = fetchId + 1)
      setUpdating(true)
      if (timeoutId) clearTimeout(timeoutId)

      timeoutId = setTimeout(async () => {
        /** cancel call when other call is active currently */
        if (fId !== fetchId) return
        const result = await api.updateItem(
          itemId,
          quantity,
          shippingMethod.current
        )
        /** cancel call when other call is active currently */
        if (fId !== fetchId) return

        if (result.status === 200) {
          shippingMethod.current = result.payload.shippingMethod
          if (
            result.payload.errors !== undefined &&
            result.payload.errors.length > 0
          )
            snackbar.addMessage({
              messageKey: result.payload.errors[0].messageKey,
              type: 'Stock',
              productId: itemId,
              quantity: result.payload.errors[0].quantity
            })
          observer.current.sendEvent({
            type: 'CART_UPDATE_ITEM',
            cart: result.payload.cart,
            cartItemId: itemId
          })
        }
        setUpdating(false)
      }, 300)
    }
  }, [])

  const hook = {
    /**
     * this is a ugly hack to prevent cart fetch during paypal process in the overview page.
     * when user clicks on submit order button, we first save the addresses. then we trigger "create-order"
     * at the same time cart wants to update because address changed, wich causes a bug on shopware that prevents
     * the cart deletion (get-cart request creates a new cart after cart was deleted from create-order request)
     */
    preventCartFetchWhenPaypalIsActive,
    shippingMethod: shippingMethod.current,
    data: cart,

    /**
     * when we interact with the cart this flag will be set to true. Listends to
     * the following interactions:
     * - addItem
     * - removeItem
     * - updateItem
     */
    updating,
    /** initially true. when first cart-fetch resolves setup will be false. Use
     * this flag to show a loading-spinner while we do not know if we have items
     * in the cart or not
     */
    setup,
    /** adds item to cart. use the sw6Uuid as the item-id */

    addItem: async (
      sw6Uuid: string,
      quantity: number,
      additionalInformation = {
        item_list_name: '',
        list_position: 0,
        item_list_id: ''
      },
      config?: t.CustomTailorConfig
    ) => {
      setUpdating(true)
      const result = await api.addItem(
        sw6Uuid,
        quantity,
        shippingMethod.current,
        config,
        additionalInformation.item_list_name,
        additionalInformation.list_position,
        additionalInformation.item_list_id
      )

      if (result.status === 200) {
        const cart = result.payload.cart
        shippingMethod.current = result.payload.shippingMethod
        if (
          result.payload.errors !== undefined &&
          result.payload.errors.length > 0
        ) {
          snackbar.addMessage({
            messageKey: result.payload.errors[0].messageKey,
            type: 'Error',
            productId:
              result.payload.cart.items.find((item) => item.sw6Uuid === sw6Uuid)
                ?.cartItemId || ''
          })
          logError({
            type: 'fetch-from-shopware',
            msg: result.payload.errors[0].messageKey,
            stack: '',
            loc: window.location.href,
            ua: navigator.userAgent,
            xhr: {
              url: '/store-api/checkout/cart/line-item',
              method: 'POST',
              status: result.status,
              result: result.payload.errors[0].message
            }
          })
        } else {
          observer.current.sendEvent({
            type: 'CART_ADD_ITEM',
            cart: result.payload.cart,
            cartItemId: cart.items[cart.items.length - 1].cartItemId
          })
        }
      } else {
        snackbar.addMessage({
          messageKey: 'invalid_uuid',
          type: 'Error',
          productId: ''
        })
      }

      setUpdating(false)
    },
    /** adds a batch of items to the cart. use the sw6Uuid as the item-ids */
    addItemBatch: async (
      cartItems: {
        sw6Uuid: string
        quantity: number
        type: 'product'
        config?: t.CustomTailorConfig
      }[]
    ) => {
      setUpdating(true)
      const result = await api.addItemBatch(cartItems, shippingMethod.current)

      if (result.status === 200) {
        shippingMethod.current = result.payload.shippingMethod
        if (
          result.payload.errors !== undefined &&
          result.payload.errors.length > 0
        ) {
          snackbar.addMessage({
            messageKey: result.payload.errors[0].messageKey,
            type: 'Error',
            productId: ''
          })
          logError({
            type: 'fetch-from-shopware',
            msg: result.payload.errors[0].messageKey,
            stack: '',
            loc: window.location.href,
            ua: navigator.userAgent,
            xhr: {
              url: '/store-api/checkout/cart/line-item',
              method: 'PATCH',
              status: result.status,
              result: result.payload.errors[0].message
            }
          })
        } else {
          observer.current.sendEvent({
            type: 'CART_ADD_ITEM_BATCH',
            cart: result.payload.cart,
            cartItemIds: []
          })
        }
      } else {
        snackbar.addMessage({
          messageKey: 'invalid_uuid',
          type: 'Error',
          productId: ''
        })
      }
      setUpdating(false)
    },
    /** optomisticly removes item from cart.
     * when request fails the item will be added to the cart again
     */
    removeItem: async (cartItemId: string) => {
      // optimistic update
      const currentCart = cart
      const item = cart.items.find((item) => item.cartItemId === cartItemId)
      setCart({
        ...currentCart,
        items: currentCart.items.filter(
          (item) => item.cartItemId !== cartItemId
        )
      })

      // api update
      setUpdating(true)
      const result = await api.removeItem(cartItemId, shippingMethod.current)

      if (result.status === 200) {
        shippingMethod.current = result.payload.shippingMethod
        observer.current.sendEvent({
          type: 'CART_REMOVE_ITEM',
          cart: result.payload.cart,
          item: item as CartItem
        })
      } else {
        setCart(currentCart)
      }

      setUpdating(false)
    },

    /** optomisticly removes all commited items from cart.
     * when request fails the items will be added to the cart again
     * @param keepCart if true, the cart will not be emptied before fetching the new cart
     */
    removeItemBatch: async (cartItemIds: string[], keepCart = false) => {
      // optimistic update
      const currentCart = cart
      if (!keepCart) {
        setCart({
          ...currentCart,
          items: []
        })
      }
      setUpdating(true)
      const result = await api.removeItemBatch(
        cartItemIds,
        shippingMethod.current
      )
      if (result.status === 200) {
        shippingMethod.current = result.payload.shippingMethod
        observer.current.sendEvent({
          type: 'CART_REMOVE_ITEM_BATCH',
          cart: result.payload.cart
        })
      } else {
        setCart(currentCart)
      }

      setUpdating(false)
    },
    /**
     * removes all items from the cart that are not available in the current channel
     */
    removeRestrictedItems: async () => {
      const restrictedItems = cart.items.filter((item) =>
        isItemChannelRestricted(item, channel)
      )
      await hook.removeItemBatch(
        restrictedItems.map((item) => item.cartItemId),
        true
      )
    },
    /** obtimisticly changes the quantity of a cart item.
     * when request fails the original quantity will be restored again.
     * when quantity is set to 0 "removeItem" will be called instead
     */
    updateItem: async (cartItemId: string, quantity: number) => {
      if (quantity === 0) return hook.removeItem(cartItemId)
      // optimistic update
      const currentCart = cart
      const index = currentCart.items.findIndex(
        (item) => item.cartItemId === cartItemId
      )

      if (index !== -1) {
        const difference = quantity - currentCart.items[index].quantity
        const newCart = {
          ...currentCart,
          items: [
            ...currentCart.items.slice(0, index),
            {
              ...currentCart.items[index],
              quantity: quantity
            },
            ...currentCart.items.slice(index + 1)
          ]
        }

        setCart(newCart)
        if (difference > 0) {
          observer.current.sendEvent({
            type: 'CART_ITEM_INCREASE_QUANTITY',
            cart: newCart,
            cartItemId,
            quantity: difference
          })
        } else {
          observer.current.sendEvent({
            type: 'CART_ITEM_DECREASE_QUANTITY',
            cart: newCart,
            item: currentCart.items[index],
            quantity: difference * -1
          })
        }
      }
      // api update
      debouncedUpdate(cartItemId, quantity)
    }
  }

  return hook
}
