import { Product } from 'modules/listing/types'
import { dispatchEvent } from 'redux-ruleset'

export const SKU_WIDGET_CLICK: 'ProductWidgetSeries/WIDGET_CLICK' =
  'ProductWidgetSeries/WIDGET_CLICK'

export const Sku_Widget_ADD_TO_CART: 'ProductWidgetSeries/Sku_Widget_ADD_TO_CART' =
  'ProductWidgetSeries/Sku_Widget_ADD_TO_CART'
export const SCROLL_INTO_VIEW: 'ProductWidgetSeries/SCROLL_INTO_VIEW' = `ProductWidgetSeries/SCROLL_INTO_VIEW`

export const skuWidgetClick = (
  variant: Product,
  listname: string,
  listPosition: number,
  listid: string
) =>
  dispatchEvent({
    type: SKU_WIDGET_CLICK,
    meta: { listname, listPosition, listid },
    payload: variant
  })

export const scrollIntoView = (
  variant: Product,
  listname: string,
  listPosition: number,
  listid: string
) =>
  dispatchEvent({
    type: SCROLL_INTO_VIEW,
    meta: { listname, listPosition, listid },
    payload: variant
  })

export const skuWidgetAddToCart = (
  variant: Product,
  listname: string,
  listPosition: number,
  listid: string
) =>
  dispatchEvent({
    type: Sku_Widget_ADD_TO_CART,
    meta: { listname, listPosition, listid },
    payload: variant
  })
export type SkuWidgetClick = ReturnType<typeof skuWidgetClick>
export type SkuWidgetAddToCart = ReturnType<typeof skuWidgetAddToCart>
export type ScrollIntoView = ReturnType<typeof scrollIntoView>

export type Event = SkuWidgetClick | SkuWidgetAddToCart | ScrollIntoView

declare global {
  interface RulesetDispatchEvents {
    'themes/Series/SkuList/SkuWidget': Event
  }
}
