import config from 'config'
import { PageContext } from 'hooks/usePageContext'
import manager from '../manager'
import { API_KEY, createBody, dyFetch } from '../utils'

export type FetchContentResult<T> = {
  content: T
  skus: { sku: string; slotId: string }[]
  context: {
    decisionId: string
    campaignId: string
    campaignName: string
    experienceId: string
    experienceName: string
    variationId: string
    variationName: string
  }
}

/** @firescoutMockFn dy.fetchContent */
export default async function fetchContent<T>(props: {
  selector: string
  path?: string
  pageContext: PageContext
}): Promise<FetchContentResult<T>> {
  return manager.getContext(
    async (ctx) => {
      const body = createBody(ctx)
      /** add request data */
      body.options = {
        isImplicitPageview: false,
        returnAnalyticsMetadata: true,
        isImplicitClientData: ctx.consentStatus === 'ACCEPTED',
        recsProductData: { skusOnly: true }
      }
      body.selector = {
        names: [props.selector]
      }
      body.context.page = {
        type: props.pageContext?.type.toUpperCase() || 'OTHER',
        location:
          window.location.href || config.baseUrl + '/' + config.locale + '/',
        locale: config.i18n.locale.replace('-', '_'),
        data: createContextData(props.pageContext)
      }

      if (previewToken) {
        body.selector.preview = {
          ids: [previewToken]
        }
      }

      /** fetch */
      const content = await dyFetch(
        'https://direct.dy-api.eu/v2/serve/user/choose',
        {
          method: 'POST',
          headers: {
            'DY-API-Key': API_KEY,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      )

      /** transform result */
      if (content.choices.length > 0) {
        if (content.choices[0].variations.length > 0) {
          const choice = content.choices[0] || {}
          const variation = choice.variations[0]
          const payload = variation.payload || {}
          // if (!variation.payload.data.custom)
          //   variation.payload.data = { custom: variation.payload.data }
          // const data = variation.payload.data
          let data = payload
          if (payload.data) data = payload.data

          return {
            content: data.custom || data,
            skus: (data.slots || []).map((row) => ({
              sku: row.sku,
              slotId: row.slotId
            })),
            context: {
              decisionId: choice.decisionId,
              ...variation.analyticsMetadata
            }
          }
        } else {
          return {
            content: null,
            skus: [],
            context: {
              decisionId: content.choices[0].decisionId,
              campaignId: null,
              campaignName: null,
              experienceId: null,
              experienceName: null,
              variationId: null,
              variationName: null
            }
          }
        }
      }

      return { ...content }
    },
    (ctx) =>
      ctx._dyid_server &&
      ctx._dyjsession &&
      ctx.consentStatus !== 'UNKNOWN' &&
      ctx.pageViewSend &&
      ctx.isChannelSet
  )
}

function createContextData(pageContext: PageContext) {
  if (pageContext?.type === 'Cart') return pageContext.skus
  if (pageContext?.type === 'Category') return pageContext.dyPath.split('|')
  if (pageContext?.type === 'Series') return pageContext.path.split(' > ')
  if (pageContext?.type === 'Product') return [pageContext.sku]
  return []
}

// content can be previewed: https://jira.em-group.de/browse/WDV-3209
const previewToken =
  typeof window === 'undefined'
    ? ''
    : new URLSearchParams(location.search).get('dyApiPreview') || ''
