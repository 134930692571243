export default function createThrottle(time: number) {
  let cb: any
  let pause = false

  if (process.env.NODE_ENV === 'test') {
    return <T extends (...args: any[]) => any>(callback: T) =>
      (...args: any[]) => {
        callback(...args)
      }
  }

  return <T extends (...args: any[]) => any>(callback: T) =>
    (...args: any[]) => {
      cb = callback
      if (pause) return
      pause = true
      setTimeout(() => {
        cb(...args)
        pause = false
      }, time)
    }
}
