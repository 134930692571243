import * as React from 'react'
import Error from 'theme/atoms/Error'
import { logError } from 'utils/buzdev'

export class ErrorBoundary extends React.Component<
  { children: any; accountProviderBoundary?: boolean },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo) {
    logError({
      type: 'ErrorBoundary',
      loc: window.location.href,
      msg: error.toString(),
      stack: error.stack ?? '',
      ua: navigator.userAgent
    })

    console.error(error, errorInfo)
  }

  componentDidUpdate(previousProps) {
    if (previousProps.children !== this.props.children)
      this.setState({ hasError: false })
  }

  render() {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname === '/error/' &&
      this.state.hasError
    ) {
      return <h1>Something went super wrong</h1>
    }

    if (this.state.hasError) {
      const isCheckout = /checkout/.exec(window.location.pathname)
      if (isCheckout) {
        return <Error location={window.location} checkoutLayout />
      } else {
        return this.props.accountProviderBoundary ? (
          <Error location={window.location} renderWithoutCart />
        ) : (
          <Error location={window.location} />
        )
      }
    }
    return this.props.children
  }
}
