// These are the user facing error messages.
// We do not allow arbitrary strings to be displayed to the user on purpose.
// All user facing error messages must be defined here.

// TODO: Localizing these messages would be nice, but probably overkill for a first simple version. Overriding them from the config shouldn't be that hard though...

/**
 * Error messages for use with PublicError.
 * Each error message can contain placeholders for dynamic values. The PublicError type will enforce that values for all placeholders are provided.
 * Single quotes are used here on purpose. Please do not change them to backticks.
 */
export const ErrorMessages = {
  test_error:
    'Dies ist eine Test Fehlermeldung. Folgende Variable sollte ersetzt werden. ${test_variable_1} ${test_variable_2}',
  unknown_error: 'Es ist ein unbekannter Fehler aufgetreten.',
  // help and service page
  help_and_service_page_missing_slug:
    'In der Help und Service Seite mit der ID ${service_page_id} fehlt ein Slug. Bitte ergänzen Sie diesen unter https://app.contentful.com/spaces/aza65graowyr/entries/${service_page_id}?focusedField=slug',
  // landing page
  landing_page_missing_slug:
    'In der Landing Page mit der ID ${landing_page_id} fehlt ein Slug. Bitte ergänzen Sie diesen unter https://app.contentful.com/spaces/aza65graowyr/entries/${landing_page_id}?focusedField=slug',
  // magazine article
  magazine_article_missing_slug:
    'Im Magazin Artikel mit der ID ${magazine_article_id} fehlt ein Slug. Bitte ergänzen Sie diesen unter https://app.contentful.com/spaces/aza65graowyr/entries/${magazine_article_id}?focusedField=slug',
  magazine_article_missing_teaser_image:
    'Im Magazin Artikel mit der ID ${magazine_article_id} fehlt ein Teaser Image. Bitte ergänzen Sie dieses unter https://app.contentful.com/spaces/aza65graowyr/entries/${magazine_article_id}?focusedField=teaserImageUrl',
  category_injected_teaser_missing_teaser_image:
    'In der Category Story in Kategorie mit dem Namen ${category_id} fehlt ein Teaser Image in einem injected teaser mit der Kampagnen ID ${campaign_id}. Bitte ergänzen Sie dieses.'
} as const

/**
 * Union type of all error message keys. Mainly used in the PublicError type.
 */
export type ErrorMessageKey = keyof typeof ErrorMessages

// Type to extract variable names from the template strings
// Example: The string 'a: ${a}, b: ${b}' would return the type 'a' | 'b'
type ExtractVariablesFromErrorMessage<S extends string> =
  // Recursively deconstruct the string to create a new union type with the found variable strings.
  // The recursion terminates with the return of never, which does not alter the union type.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  S extends `${infer _Start}\${${infer Var}}${infer Rest}`
    ? Var | ExtractVariablesFromErrorMessage<Rest>
    : never

// Get a union type for each error message that contains the variables in the string.
type ExtractedVariablesForEachErrorMessage = {
  [K in keyof typeof ErrorMessages]: ExtractVariablesFromErrorMessage<
    (typeof ErrorMessages)[K]
  >
}

// Given an error message, this type will return the union type of variables for that message.
type ExtractExpectedErrorMessageVariables<T extends ErrorMessageKey> =
  ExtractedVariablesForEachErrorMessage[T]

// Given an error message, this type will return an object type that contains the expected variables for that message.
type ExpectedErrorMessageVariables<T extends ErrorMessageKey> = Record<
  ExtractExpectedErrorMessageVariables<T>,
  string
>

// Construct the final type that dynamically expects the variables contained in the specified error message (T) to be present in the variables parameter.

/**
 * Error type for posting public errors. These will be sent to normal users (non developers). For example in MS-Teams.
 *
 * @template T - The type of the error message key.
 *
 * @property {T} errorCode - The specific error code.
 * @property {ExpectedErrorMessageVariables<T>} variables - The variables associated with the error message.
 * @property {'ms-teams'} [target] - The target platform for the error, currently only 'ms-teams' is supported.
 * @property {any} [metadata] - Optional metadata for future use.
 */
export type PublicError<T extends ErrorMessageKey> = {
  errorCode: T
  variables: ExpectedErrorMessageVariables<T>
  target?: 'ms-teams'
  metadata?
}

/**
 * Formats a public error message by replacing placeholders with provided variables.
 *
 * @template T - The type of the error message key.
 * @param {PublicError<T>} error - The public error object containing the error code and variables.
 * @returns {string} The formatted error message.
 * @throws {Error} If the error code is unsupported or if not all placeholders are replaced.
 */
export function formatPublicError<T extends ErrorMessageKey>(
  error: PublicError<T>
): string {
  if (!ErrorMessages[error.errorCode]) {
    throw new Error('error.errorCode unsupported value')
  }

  let formattedErrorMessage: string = ErrorMessages[error.errorCode]
  for (const key in error.variables) {
    formattedErrorMessage = formattedErrorMessage.replace(
      new RegExp(`\\$\\{${key}\\}`, 'g'),
      error.variables[key]
    )
  }

  // Check for unreplaced placeholders
  if (/\$\{[^\}]+\}/.test(formattedErrorMessage)) {
    throw new Error(
      'Not all variables were properly replaced in the error message: ' +
        formattedErrorMessage
    )
  }

  return formattedErrorMessage
}

/**
 * Logs a public error using the provided reporter.
 * It will use reporter.panicOnBuild to log the error, so the build will fail.
 *
 * @template T - The type of the error message key.
 * @param {PublicError<T>} error - The public error object to be logged.
 * @param reporter - The reporter object used to log the error.
 * @throws {Error} If the error code is unsupported or if the target is unsupported.
 *
 * @remarks
 * - If the error object does not have a target, it defaults to 'ms-teams'.
 * - The error object is cloned and modified before logging.
 * - The error message is formatted and included in the cloned error object.
 * - The cloned error object is serialized and logged using the reporter's `panicOnBuild` method.
 */
export function throwPublicError<T extends ErrorMessageKey>(
  error: PublicError<T>
) {
  if (!ErrorMessages[error.errorCode]) {
    throw new Error('error.errorCode unsupported value')
  }

  if (error.target && error.target !== 'ms-teams') {
    throw new Error('error.target unsupported value')
  }

  // We want to change the error object, so we clone it first.
  const clonedError = { ...error }

  if (!error.target) {
    clonedError.target = 'ms-teams'
  }

  const formattedErrorMessage = formatPublicError(clonedError)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(clonedError as any).message = formattedErrorMessage

  const serializedError = JSON.stringify(clonedError)
  console.error('<publicerror>' + serializedError + '</publicerror>')

  throw error
}

export function throwPrivateError(error: Error) {
  const serializedError = JSON.stringify({
    message: error.message,
    name: error.name,
    stack: error.stack
  })
  console.error('<privateerror>' + serializedError + '</privateerror>')
  throw error
}
