import { dispatchEvent } from 'redux-ruleset'

export const ADDRESS_COMPLETION: 'account/ADDRESS_COMPLETION' =
  'account/ADDRESS_COMPLETION'

export const accountAddressCompletion = (type: string) =>
  dispatchEvent({
    type: ADDRESS_COMPLETION,
    meta: {},
    payload: { type }
  })

export type AccountAddressCompletion = ReturnType<
  typeof accountAddressCompletion
>

export type Event = AccountAddressCompletion

declare global {
  interface RulesetDispatchEvents {
    'account/AddressCompletion': Event
  }
}
