import calculateDeliveryInformation from 'utils/calculateDeliveryDate'
import * as t from '../types'

export const filterHirarchie: t.FilterKey[] = [
  'color',
  'size',
  'style',
  'variant'
]

export default function calculateFilterOptions(
  variants: t.Variant[],
  filterValues: t.FilterValues,
  filterKey: t.FilterKey
): t.FilterOption[] {
  const labels = new Set<string>()

  const hirarchieIndex = filterHirarchie.indexOf(filterKey)

  /**
   * hold the sort number for each label
   */
  const sortNumbers: Record<string, number> = {}

  /**
   * check if a label is selectable (sellable)
   */
  const selectableLabels: Record<string, boolean> = {}

  const labelImages: Record<string, t.Variant['images']['imageWeb'][0]> = {}

  /**
   * based on hirarchie we exclude the variant from options when variant does not
   * exist in the hirarchical combination. e.g we exclude a size when no variant with
   * current color exists with this size. we exclude a style when no variant with current
   * color and current size exits with this style
   */
  const shouldExclude = (variant: t.Variant) => {
    if (hirarchieIndex === 0) return false
    // this loop only iterates over filterOptions hirarchical above the current filterKey
    for (let i = 1; i < filterHirarchie.length - 1; i++) {
      if (hirarchieIndex >= i) {
        // this loop checks every hirarchical step from down to top and excludes this option
        for (let j = hirarchieIndex - 1; j >= 0; j--) {
          const fLabel = filterValues[filterHirarchie[j]]
          const label = variant.variantData[filterHirarchie[j]].label

          if (label && label !== fLabel) return true
        }
      }
    }
    return false
  }

  // get unique filter labels
  for (const variant of variants) {
    const label = variant.variantData[filterKey].label
    if (shouldExclude(variant)) continue
    if (!label) continue
    const deliveryInfo = calculateDeliveryInformation(variant, 1)
    labels.add(label)

    const sortNumber =
      (variant.attributes.SKU_SORT_NUMBER?.values?.[0]?.value as number) || null

    if (sortNumber !== null) {
      if (!sortNumbers[label]) sortNumbers[label] = sortNumber
      else if (sortNumber < sortNumbers[label]) sortNumbers[label] = sortNumber
    }

    /** at least one variant is sellable with other filters hirarchical below */
    if (deliveryInfo.isBuyable) selectableLabels[label] = true

    if (!labelImages[label]) labelImages[label] = variant.images.imageWeb[0]
  }

  /** fallback when no SKU_SORTNUMBER exists */
  if (Object.keys(sortNumbers).length === 0)
    return Array.from(labels).map((label) => ({
      image: labelImages[label],
      label,
      selectable: Boolean(selectableLabels[label])
    }))

  return Array.from(labels)
    .sort((prev, next) => {
      if (!sortNumbers[prev]) return 1
      if (!sortNumbers[next]) return -1
      return sortNumbers[prev] - sortNumbers[next] || -1
    })
    .map((label) => ({
      image: labelImages[label],
      label,
      selectable: Boolean(selectableLabels[label])
    }))
}
