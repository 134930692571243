import * as React from 'react'
import styled from 'styled-components'
import Drawer from 'theme/atoms/Drawer'
import { useFlyoutNavi } from 'modules/ui'
import Logo from '../../../../../static/images/logo.svg'
import * as evt from './events'
import useCategories from '../useCategories'
import theme from 'theme'
import CloseIcon from 'assets/menu-close.svg'
import CategoryItem from '../Navigation/CategorySelect/CategoryItem'
import { hasChildren } from '../Navigation/CategorySelect/CategorySelect'
import Back from 'assets/back.svg'
import Button from 'theme/atoms/Button'
import useStaticNavi from '../useStaticNavi'
import Link from 'theme/atoms/Link'
import useTranslations from 'hooks/useTranslations'
import B2XDropdown from '../B2XDropdown/B2XDropdown'

export default function FlyoutNavi() {
  const navi = useFlyoutNavi()
  const {
    categories,
    currentLevel,
    addCategoryForLevel,
    clearCategoryIds,
    categoryByLevel,
    removeCategoryForLevel
  } = useCategories()
  const sidebarNavi = useStaticNavi()
  const [activeSecondLevel, setActiveSecondLevel] = React.useState('')
  const t = useTranslations<'app-Header'>()
  const $logo = React.useRef<HTMLImageElement>(null)

  return (
    <Wrapper
      data-cy-collection="FlyoutNavi"
      data-cy-state={navi.visible ? 'visible' : undefined}
      $visible={navi.visible}
    >
      <Drawer
        fromLeft
        visible={navi.visible}
        maxWidth="28.4375rem"
        onClose={() => {
          evt.closeNavigationClick(navi.sidebarCategory)
          clearCategoryIds()
          navi.toggle()
        }}
        data-cy-handle-overlay={'overlay'}
        data-cy-handle-close-icon={'close-icon'}
        customHeader={true}
        className="drawer"
      >
        <CategoryContainer $visible={currentLevel === 0}>
          <CustomHeader>
            <div
              className="header-content"
              data-cy-state={currentLevel === 0 ? 'k0-open' : null}
            >
              <img
                ref={$logo}
                src={Logo}
                alt={t.asText($logo, 'header_flyout_logo_a11y_label')}
              />
              <B2XDropdown />
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  evt.closeNavigationClick(navi.sidebarCategory)
                  clearCategoryIds()
                  navi.toggle()
                }}
                data-cy-handle="close-icon"
              />
            </div>
          </CustomHeader>
          <Content className="content" $isVisible={true}>
            {categories.map((node) => (
              <CategoryItem
                hasFullWidth
                key={node.id}
                title={node.label}
                hasChildren={hasChildren(node)}
                to={hasChildren(node) ? null : node.urlPath}
                urlPath={node.urlPath}
                categoryName={node.label}
                isActive={node.id === categoryByLevel[node.categoryLevel]?.id}
                onClick={() => {
                  evt.mainCategoryClick(node.label)
                  clearCategoryIds()
                  addCategoryForLevel(node.categoryLevel, node)
                  setActiveSecondLevel(node.id)
                }}
                data-cy-handle="select-category-item"
              />
            ))}
            <Devider className="divider" />
            {sidebarNavi.map((node) => (
              <Row
                className="h3"
                to={node.link}
                key={node.label}
                selected={false}
                data-cy-handle="customizable-navigation-item"
                onClick={() => evt.customizedNavigationClick(node.label)}
                title={node.label}
              >
                <span>{node.label}</span>
              </Row>
            ))}
          </Content>
        </CategoryContainer>

        <CategoryContainer $visible={currentLevel === 1}>
          <CustomHeader>
            <div
              className="header-content-levels"
              data-cy-state={currentLevel === 1 ? 'k1-open' : null}
            >
              <Back
                onClick={() => {
                  clearCategoryIds()
                  evt.backToParentMenu(
                    categoryByLevel[currentLevel]?.path?.split(' > ')[-1] || '',
                    categoryByLevel[currentLevel]?.path ?? ''
                  )
                }}
                data-cy-handle="back-to-k1"
              />
              <span className="header-label">{categoryByLevel[1]?.label}</span>
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  evt.closeNavigationClick(navi.sidebarCategory)
                  clearCategoryIds()
                  navi.toggle()
                }}
                data-cy-handle="close-icon"
              />
            </div>
          </CustomHeader>
          {categories.map((parent) => (
            <Content
              key={parent.id}
              className="content"
              $isVisible={parent?.id === activeSecondLevel}
            >
              <CategoryItem
                isBold
                hasFullWidth
                title={t.asText(false, 'header_main_category_label', {
                  CATEGORY: parent.label ?? ''
                })}
                hasChildren={false}
                categoryName={t('header_main_category_label', {
                  CATEGORY: parent.label ?? ''
                })}
                urlPath={parent.urlPath}
                isActive={false}
                data-cy-handle="show-more-btn"
                onClick={() =>
                  evt.headlineClick(parent.label ?? '', parent.path ?? '')
                }
                to={parent.urlPath}
              />
              {parent.childrenCategory?.map((node) => (
                <CategoryItem
                  hasFullWidth
                  key={node.id}
                  hasChildren={hasChildren(node)}
                  to={hasChildren(node) ? null : node.urlPath}
                  categoryName={node.label}
                  title={node.label}
                  urlPath={node.urlPath}
                  isActive={node.id === categoryByLevel[node.categoryLevel]?.id}
                  onClick={() => {
                    evt.subCategoryClick(node.label, node.path)
                    if (hasChildren(node)) {
                      addCategoryForLevel(node.categoryLevel, node)
                    }
                  }}
                  data-cy-handle="select-category-item"
                />
              ))}
              <Button
                variation="primary"
                data-cy-handle={'cat-overview-btn'}
                icon="arrow-right"
                $iconPositionRight
                title={parent.label}
                onClick={() => {
                  evt.showAllButtonClick(parent.label ?? '', parent.path ?? '')
                }}
                to={parent.urlPath}
              >
                {parent.label}
              </Button>
            </Content>
          ))}
        </CategoryContainer>

        <CategoryContainer $visible={currentLevel === 2}>
          <CustomHeader>
            <div
              className="header-content-levels"
              data-cy-state={currentLevel === 2 ? 'k2-open' : null}
            >
              <Back
                onClick={() => {
                  evt.backToParentMenu(
                    categoryByLevel[currentLevel]?.path?.split(' > ')[-1] || '',
                    categoryByLevel[currentLevel]?.path ?? ''
                  )
                  removeCategoryForLevel(2)
                }}
                data-cy-handle="back-to-k2"
              />
              <span className="header-label">{categoryByLevel[2]?.label}</span>
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  evt.closeNavigationClick(navi.sidebarCategory)
                  clearCategoryIds()
                  navi.toggle()
                }}
                data-cy-handle="close-icon"
              />
            </div>
          </CustomHeader>
          <Content className="content" $isVisible={true}>
            <CategoryItem
              isBold
              hasFullWidth
              title={t.asText(false, 'header_main_category_label', {
                CATEGORY: categoryByLevel[2]?.label ?? ''
              })}
              hasChildren={false}
              categoryName={t('header_main_category_label', {
                CATEGORY: categoryByLevel[2]?.label ?? ''
              })}
              isActive={false}
              data-cy-handle="show-more-btn"
              onClick={() =>
                evt.headlineClick(
                  categoryByLevel[2]?.label ?? '',
                  categoryByLevel[2]?.path ?? ''
                )
              }
              to={categoryByLevel[2]?.urlPath}
            />
            {categoryByLevel[2]?.childrenCategory?.map((node) => (
              <CategoryItem
                hasFullWidth
                key={node.id}
                title={node.label}
                hasChildren={hasChildren(node)}
                to={hasChildren(node) ? null : node.urlPath}
                categoryName={node.label}
                isActive={node.id === categoryByLevel[node.categoryLevel]?.id}
                onClick={() => {
                  evt.subCategoryClick(node.label, node.path)
                  clearCategoryIds()
                  addCategoryForLevel(node.categoryLevel, node)
                }}
                data-cy-handle="select-category-item"
              />
            ))}
            <Button
              variation="primary"
              data-cy-handle={'cat-overview-btn'}
              icon="arrow-right"
              $iconPositionRight
              title={categoryByLevel[2]?.label}
              onClick={() => {
                evt.showAllButtonClick(
                  categoryByLevel[2]?.label ?? '',
                  categoryByLevel[2]?.path ?? ''
                )
              }}
              to={categoryByLevel[2]?.urlPath}
            >
              {categoryByLevel[2]?.label}
            </Button>
          </Content>
        </CategoryContainer>
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'block' : 'none')};
`
const CategoryContainer = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'block' : 'none')};
`

const CustomHeader = styled.div`
  padding: ${theme.spacing('m')};
  > .header-content {
    display: grid;
    grid-template-columns: 1fr auto auto;
    justify-content: end;
    border-bottom: 1px solid ${theme.colors.shade.b5};
    padding-bottom: ${theme.spacing('m')};
  }
  > .header-content-levels {
    display: grid;
    place-items: center;
    grid-template-columns: auto 1fr auto;
    border-bottom: 1px solid ${theme.colors.shade.b5};
    padding-bottom: ${theme.spacing('m')};

    > .header-label {
      ${theme.ty('rc-base', '700')}
    }
  }
  > .header-content > img {
    height: 24px;
    align-self: center;
  }

  > .header-content > .close-icon {
    height: 24px;
    place-self: center;
    margin-left: ${theme.spacing('s')};
    cursor: pointer;
  }
`
const Content = styled.div<{ $isVisible }>`
  display: ${(props) => (props.$isVisible ? 'block' : 'none')};
  > .CategoryItem {
    ${theme.ty('rc-base')};
  }
  > .Button {
    margin: ${theme.spacing('m')};
  }
`
const Row = styled(Link)<{ selected: boolean }>`
  @media (min-with: ${theme.ms.MD}) {
    display: none;
  }
  ${theme.ty('rc-base')};
  display: block;
  padding-top: ${theme.spacing('xs')};
  padding-bottom: ${theme.spacing('s')};
  margin-left: ${theme.spacing('s')};
  cursor: pointer;
`
const Devider = styled.div`
  @media (min-with: ${theme.ms.MD}) {
    display: none;
  }
  border-top: 1px solid ${theme.colors.shade.b5};

  height: ${theme.spacing('m')};
  margin-top: ${theme.spacing('s')};
  margin-left: ${theme.spacing('s')};
  margin-right: ${theme.spacing('s')};
`
