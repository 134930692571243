import { dispatchEvent } from 'redux-ruleset'

export const OPEN: 'PDP-FilterDrawer/OPEN' = 'PDP-FilterDrawer/OPEN'
export const CLOSE: 'PDP-FilterDrawer/CLOSE' = 'PDP-FilterDrawer/CLOSE'

export const open = () =>
  dispatchEvent({
    type: OPEN
  })

export const close = (type: string) =>
  dispatchEvent({
    type: CLOSE,
    payload: type
  })

export type Open = ReturnType<typeof open>
export type Close = ReturnType<typeof close>

export type Event = Open | Close

declare global {
  interface RulesetDispatchEvents {
    'PDP/FilterDrawer': Event
  }
}
