import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Container from 'theme/atoms/Container'
import Burgermenu from 'assets/burgermenu.svg'
import Logo from '../../../../static/images/logo.svg'
import Link from 'theme/atoms/Link'
import SearchField from './SearchField'
import IconList from './IconList'
import Navigation from './Navigation/Navigation'
import { useFlyoutNavi } from 'modules/ui'
import * as evt from './events'
import FlyoutNavi from './FlyoutNavi'
import NotificationBar from '../NotificationBar'
import config from 'config'
import useTranslations from 'hooks/useTranslations'

export default function Header() {
  const navi = useFlyoutNavi()
  const t = useTranslations<'app-Header'>()
  const $logo = React.useRef<HTMLImageElement>(null)

  return (
    <div data-cy-ctx="app/Header">
      {config.features.notificationBar && (
        <NotificationBar data-cy-state="notificationBar-shown" />
      )}
      <Container>
        <Wrapper>
          <header>
            <div
              className="menu"
              onClick={() => {
                navi.toggle()
                evt.burgerNaviClick()
              }}
              data-cy-handle="mobile-burger-menu"
            >
              <Burgermenu />
            </div>
            <Link to="/" className="logo">
              <img
                src={Logo}
                alt={t.asText($logo, 'header_logo_a11y_label')}
                title={t.asText(false, 'seo_title')}
              />
            </Link>
            <SearchField />
            <IconList />
          </header>
          <Navigation />
        </Wrapper>
      </Container>
      <FlyoutNavi />
    </div>
  )
}

const Wrapper = styled.header`
  > header {
    display: grid;
    grid:
      'logo       logo        IconList'
      'menu SearchField SearchField'
      / auto 1fr auto;
    gap: ${theme.spacing('s')};
    padding: ${theme.spacing('m')} 0;
    border-bottom: 1px solid ${theme.colors.shade.b5};

    @media (min-width: ${theme.ms.LG}px) {
      grid:
        'logo . SearchField . IconList'
        / 1fr 1fr 4fr 1fr auto;
      gap: ${theme.spacing('s')};
    }

    > .menu {
      grid-area: menu;
      height: 2.5rem;
      width: 2.5rem;
      background: ${theme.colors.b0};
      border-radius: 5px;
      ${theme.flex('center', 'center')};
      cursor: pointer;

      > svg g {
        stroke: ${theme.colors.white};
      }

      @media (min-width: ${theme.ms.LG}px) {
        display: none;
      }
    }

    > .logo {
      grid-area: logo;
      align-self: center;
      > img {
        max-height: 40px;
        /* on Safari the width on mobile is 100% if we do not set a max-with, which causes a incorrect alignment */
        max-width: 101px;
        @media (min-width: ${theme.ms.MD}px) {
          min-width: 144px;
        }
      }

      @media (min-width: ${theme.ms.MD}px) {
        align-self: auto;
      }
    }

    > .SearchField {
      grid-area: SearchField;
    }

    > .IconList {
      grid-area: IconList;
    }
  }

  > .Navigation {
    width: 100%;
    @media (min-width: ${theme.ms.MD}px) {
      height: 64px;
    }
  }
`
