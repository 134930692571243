import config from 'config'
import { addRule } from 'redux-ruleset'

type BuzError = {
  type: string
  msg: string
  stack: string
  loc: string
  ua: string
  xhr?: {
    url: string
    method: string
    status: number
    result: string
  }
}

const history: string[] = []

addRule({
  id: 'buzdev',
  target: '*',
  output: '#listener',
  consequence: (action: RootAction | ValueOf<RulesetDispatchEvents>) => {
    if (!action) return
    if (action.type.includes('SCROLL_INTO_VIEW')) return
    history.unshift(action.type)
    if (history.length > 50) history.pop()
  }
})

export function logError(error: BuzError) {
  if (process.env.NODE_ENV !== 'production') return
  const url = config.modules.buzdev.errorUrl
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...error, history })
  })
}
