import { addRule } from 'redux-ruleset'
import { push as dlPush } from 'features/tracking/datalayer'
import { NEWSLETTER_REGISTERED } from 'theme/organisms/NewsletterRegistrationForm/events'

addRule({
  id: 'dl/NEWSLETTER_REGISTERED',
  target: [NEWSLETTER_REGISTERED],
  output: '#dl-event',
  consequence: () => {
    dlPush({
      event: 'genericEvent',
      eventname: 'newsletter_soi',
      category: 'Engagement',
      action: 'Newsletter',
      label: 'Single Opt In',
      value: 0,
      nonInteraction: 'false'
    })
  }
})
