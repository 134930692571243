import * as React from 'react'
import useConnect, { Config } from 'hooks/useConnect'
import { State } from '../reducer'
import * as a from '../actions'

type Input = {
  containerID: string
}

type Output = {
  isFetching: boolean
  _init: typeof a.init
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'productReviews',
  name: 'productReviews/useInit',
  createCacheKey: () => '',
  mapState: () => ({}),
  mapDispatch: {
    _init: a.init
  }
}

export default function useInitializer(containerID: string): Output {
  const input: Input = { containerID }
  const hook: Output = useConnect(input, config)

  React.useEffect(() => {
    hook._init(containerID)
  }, [containerID])

  return hook
}
