import * as React from 'react'
import styled from 'styled-components'
import useInView from 'hooks/useInView'
import ArrowLeftIcon from 'assets/arrow-button-left.svg'
import ArrowRightIcon from 'assets/arrow-button-right.svg'

import * as t from './types'
import * as evt from './events'
import ProductWidget from '../ProductWidget'
import PositionIndicator from './PositionIndicator'
import theme from 'theme'
import useProducts from './hooks/useProducts'
import useArrowNavigation from 'hooks/useSliderArrowNavigation'
import useTranslations from 'hooks/useTranslations'

export default function ProductSlider(props: t.Props) {
  const [ref, refInVp] = useInView<HTMLDivElement>(100, true)
  const arrowNav = useArrowNavigation(0.8)
  const products = useProducts(props, arrowNav.lastItemReached)
  const loadingOffset = products.allItemsLoaded ? 1 : 400
  const layoutSize = props.layoutSize ? props.layoutSize : 'normal'
  const list_id =
    props.listid || typeof window !== 'undefined' ? window.location.href : ''
  const translations = useTranslations<'molecules-ProductSlider'>()
  const $prevButton = React.useRef<HTMLButtonElement>(null)
  const $nextButton = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    if (refInVp) evt.present(props.listname, products.data)
  }, [refInVp])

  // no products found
  if (products.allItemsLoaded && products.data.length === 0) {
    return (
      <div data-cy-ctx="molecules/ProductSlider" data-cy-state="no-products" />
    )
  }

  return (
    <Wrapper
      data-cy-ctx="molecules/ProductSlider"
      className="ProductSlider"
      ref={ref}
      $visible={products.data.length > 0 ? true : false}
      $layoutSize={layoutSize}
      data-cy-state={props['data-cy-state']}
    >
      {props.title && Boolean(products.data.length) && (
        <h2 data-cy-state="has-title">{props.title}</h2>
      )}
      <div className="innerSlider">
        <div className="products" ref={arrowNav.productListRef}>
          <Indicator ref={arrowNav.firstItemRef} $width={1} />
          {products.data.slice(0, props.maxProducts).map((product, i) => (
            <div className="widget-wrapper" key={product.containerID}>
              <ProductWidget
                product={product}
                listname={props.listname}
                listid={list_id}
                listPosition={i + 1}
                layoutSize={props.layoutSize}
              />
            </div>
          ))}
          <Indicator ref={arrowNav.lastItemRef} $width={loadingOffset} />
        </div>
        {!arrowNav.firstItemReached && (
          <button
            data-cy-handle="prev-button"
            data-cy-state="has-prev-button"
            title={translations.asText(
              $prevButton,
              'molecules_productslider_a11y_prev_button'
            )}
            className="prevButton"
            onClick={() => arrowNav.handleSlide('left')}
          >
            <ArrowLeftIcon />
          </button>
        )}
        {!products.allItemsLoaded || !arrowNav.lastItemReached ? (
          <button
            data-cy-handle="next-button"
            data-cy-state="has-next-button"
            title={translations.asText(
              $nextButton,
              'molecules_productslider_a11y_next_button'
            )}
            className="nextButton"
            onClick={() => arrowNav.handleSlide('right')}
          >
            <ArrowRightIcon />
          </button>
        ) : null}
      </div>
      {!props.hideScrollbar && (
        <PositionIndicator
          data-cy-state="show-position-indicator"
          componentRef={arrowNav.productListRef}
          maxSteps={
            products.allItemsLoaded ? products.data.length : props.maxProducts
          }
          offset={loadingOffset}
          displayedSteps={products.data.length}
        />
      )}
    </Wrapper>
  )
}

export function ProductSliderLoading() {
  return <div style={{ height: 340 }} />
}

const Wrapper = styled.div<{ $visible: boolean; $layoutSize: string }>`
  width: 100%;
  max-width: var(--container-size);
  position: relative;
  ${(props) => !props.$visible && `display:none;`}

  > h2 {
    color: ${theme.colors.b0};
    margin-top: ${theme.spacing('m')};
    ${theme.ty('rc-2xl')}
  }

  .innerSlider {
    position: relative;
    margin-top: ${theme.spacing('ml')};
    > .products {
      display: flex;
      width: 100%;
      max-width: 1260px;
      overflow-x: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      > .widget-wrapper {
        ${(props) =>
          props.$layoutSize === 'normal' &&
          `
            margin-right: ${theme.spacing('m')};
            min-width: 180px;
            max-width: 180px;
            @media (min-width: ${theme.ms.MD}px) {
              min-width: 200px;
              max-width: 200px;
            }
            @media (min-width: ${theme.ms.LG}px) {
              min-width: 220px;
              max-width: 220px;
            }
         `}
        ${(props) =>
          props.$layoutSize === 'small' &&
          `
            margin-right: ${theme.spacing('s')};
            min-width: 165px;
            max-width: 165px;
            
         `}
        > * {
          width: 100%;
        }
      }
    }

    > button {
      width: 3.125rem;
      height: 3.125rem;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      box-shadow: 0 0.1875rem 0.375rem 0rem rgba(0, 0, 0, 0.1);
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;

      background: ${theme.colors.white};

      &.prevButton {
        left: ${theme.spacing('xs')};
      }

      &.nextButton {
        right: ${theme.spacing('xs')};
      }
    }
  }
`

const Indicator = styled.div<{ $width: number }>`
  width: ${(p) => p.$width + 'px'};
  min-width: ${(p) => p.$width + 'px'};
  height: 0.0625rem;
`
