export type Entry = {
  label: string
  type: 'search' | 'category'
  urlPath: string
}

const loadData = () =>
  JSON.parse(localStorage.getItem('historic-queries') || '[]')
const saveData = (data: Entry[]) =>
  localStorage.setItem('historic-queries', JSON.stringify(data))

let dataCache: null | Entry[] = null
const getData = (): Entry[] => {
  if (dataCache) return dataCache
  dataCache = loadData()
  return dataCache as Entry[]
}

export function add(entry: Entry) {
  let data = getData()
  data.unshift(entry)
  const used = new Set<string>()
  data = data
    .filter((row) => {
      if (used.has(row.label)) return false
      used.add(row.label)
      return true
    })
    .slice(0, 40)
  dataCache = data
  saveData(data)
}

export function remove(label: string) {
  dataCache = getData().filter((row) => row.label !== label)
  saveData(dataCache)
}

export function query(query: string) {
  return getData().filter((row) =>
    row.label.toLowerCase().includes(query.toLowerCase())
  )
}
