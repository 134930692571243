import manager from '../manager'
import * as t from '../types'
import { API_KEY, createBody, dyFetch } from '../utils'

export default function reportPageVisit(evt: t.PageVisitEvent) {
  /**
   * when consent status changed we must update the context since we do not
   * have an event to listen for.
   */
  const consentStatus = manager.getConsentStatus()
  if (consentStatus !== manager._unsaveGetContext().consentStatus) {
    manager.updateContext(() => ({ consentStatus }))
  }

  return manager.getContext(
    async (ctx) => {
      const body = createBody(ctx)
      if (ctx.consentStatus === 'ACCEPTED') {
        if (!ctx._dy_cs_gcg) {
          body.selector = {
            names: ['global_control_group']
          }
        }
      }

      /** add event */
      body.context.page = evt
      body.options = {
        isImplicitPageview: true,
        isImplicitClientData: ctx.consentStatus === 'ACCEPTED',
        recsProductData: { skusOnly: true }
      }

      const result: any = await dyFetch(
        'https://direct.dy-api.eu/v2/serve/user/choose',
        {
          method: 'POST',
          headers: { 'DY-API-Key': API_KEY },
          body: JSON.stringify(body)
        }
      )

      /**
       * dy sends us the id and session as a response
       */
      const _dyid_server: string | null =
        result.cookies?.find((item) => item.name == '_dyid_server')?.value ||
        null
      const _dyjsession: string | null =
        result.cookies?.find((item) => item.name == '_dyjsession')?.value ||
        null
      let _dy_cs_gcg: string | null = ctx._dy_cs_gcg || null
      if (
        !_dy_cs_gcg &&
        result.choices &&
        result.choices[0] &&
        result.choices[0].variations &&
        result.choices[0].variations[0] &&
        result.choices[0].variations[0].id
      ) {
        _dy_cs_gcg =
          result.choices[0].variations[0].payload.data._dy_cs_gcg || null
      }

      /**
       * when we have no id/session or the session changes we want tu update out context
       */
      if (_dyid_server && _dyjsession) {
        if (
          ctx._dyid_server !== _dyid_server ||
          ctx._dyjsession !== _dyjsession ||
          ctx._dy_cs_gcg !== _dy_cs_gcg
        ) {
          manager.updateContext(() => ({
            _dyid_server: _dyid_server,
            _dyjsession: _dyjsession,
            _dy_cs_gcg: _dy_cs_gcg
          }))
        }
      }

      manager.updateContext(() => ({
        pageViewSend: true
      }))

      return result
    },
    (ctx) => ctx.consentStatus !== 'UNKNOWN' && ctx.isChannelSet
  )
}
