import config from '../config'
import { css } from 'styled-components'

export const channelCookieName = config.locale.split('-').pop() + '-channel'

export const isChannelB2B = () =>
  typeof window === 'undefined'
    ? false
    : document.cookie.includes(`${channelCookieName}=b2b`)

export const isChannelB2C = () =>
  typeof window === 'undefined'
    ? false
    : document.cookie.includes(`${channelCookieName}=b2c`)

/** will return "b2b" on server */
export const getChannel = () =>
  isChannelB2C() ? ('b2c' as const) : ('b2b' as const)

/** defines whether the channel cookie exists */
export const isChannelSet = () =>
  typeof window === 'undefined'
    ? false
    : document.cookie.includes(channelCookieName)

export const setChannel = (channel: 'b2b' | 'b2c') => {
  if (typeof document === 'undefined') return

  document.cookie = `${channelCookieName}=${channel};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT`
  document.body.className = document.body.className.replace(/b2b|b2c/, '')
  document.body.classList.add(channel)
  b2xManager.hidePopup()
}

/**
 * [WDV-3138]
 * manages the appearance of the b2x popup
 * the popup is shown by a css-rule because we want to be able to add it to the static html
 * and not have to wait for the javascript to load
 * this is important because we realize the LCP inside the popup and want to show it as soon as possible
 */
export const b2xManager = {
  getHidePopupFnString: () => `document.body.classList.add('hideB2xPopup')`,
  hidePopup: () => document.body.classList.add('hideB2xPopup'),
  getCssRule: () => css`
    body.hideB2xPopup & {
      display: none;
    }
  `
}
