import { dispatchEvent } from 'redux-ruleset'

export const view = () =>
  dispatchEvent({
    type: 'B2xModal/VIEW' as const
  })

export const close = () =>
  dispatchEvent({
    type: 'B2xModal/CLOSE' as const
  })

declare global {
  interface RulesetDispatchEvents {
    'app/B2xModal': ReturnType<typeof view> | ReturnType<typeof close>
  }
}
