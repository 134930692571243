import config from 'config'

type PageType =
  | 'Home'
  | 'Product'
  | 'Category'
  | 'Series'
  | 'Search'
  | 'Checkout'
  | 'Service'
  | 'Cart'
  | 'Tag'
  | 'Other'

export default (pageType: PageType) => {
  switch (pageType) {
    case 'Home':
      return !!config.modules.hotjar.homepageActive
    case 'Product':
      return !!config.modules.hotjar.pdpActive
    case 'Category':
      return !!config.modules.hotjar.categoryActive
    case 'Series':
      return !!config.modules.hotjar.seriesActive
    case 'Checkout':
      return !!config.modules.hotjar.checkoutActive
    case 'Service':
      return !!config.modules.hotjar.selfServiceActive
    case 'Cart':
      return !!config.modules.hotjar.cartActive
    case 'Tag':
      return !!config.modules.hotjar.tagCategoryActive
    default:
      return false
  }
}
