import * as React from 'react'
import styled from 'styled-components'
import StarsEmpty from 'assets/stars-empty.svg'
import StarsFull from 'assets/stars-full.svg'

type Props = {
  ratingAverage: number
}

export default function StarRating(props: Props) {
  const { ratingAverage } = props

  return (
    <Wrapper
      className="StarRating"
      $averagePercentage={(ratingAverage / 5) * 100}
      title={`${ratingAverage} / 5`}
    >
      <div className="empty-stars">
        <StarsEmpty />
      </div>
      <div className="full-stars">
        <StarsFull />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $averagePercentage: number }>`
  width: 5.625rem;
  position: relative;
  height: 1.1563rem;
  .empty-stars {
    position: absolute;
    height: 100%;
    > svg {
      width: 5.625rem;
    }
  }
  .full-stars {
    position: absolute;
    height: 100%;
    width: ${(props) => {
      return `${props.$averagePercentage}%`
    }};
    overflow: hidden;
    > svg {
      width: 5.625rem;
    }
  }
`
