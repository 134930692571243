import { addRule } from 'redux-ruleset'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'dl/OPEN_PRODUCT_SIZE_TABLE',
  target: 'ui/SET_PDP_SIZE_TABLE_OPEN',
  output: '#dl-event',
  consequence: (action) => {
    // Only fire the event, if the size table is opened. Not when it is closed.
    if (action.payload) {
      dlPush({
        event: 'genericEvent',
        eventname: 'groessentabelle_cta',
        category: 'engagement',
        action: 'click',
        label: 'Größentabelle CTA Klick - ' + action.meta.context,
        value: 0,
        nonInteraction: false
      })
    }
  }
})
