import * as React from 'react'
import whitePixel from 'utils/whitePixel'
import LazyImg from 'theme/atoms/LazyImg'
import genProductImageUrl from 'utils/genProductImageUrl'
type Props = React.HTMLAttributes<HTMLImageElement> & {
  img: {
    url: string
    classes: Array<'ASSET_FS' | 'ASSETS_M' | 'EMG' | string>
  }
  size: 'l' | 'm' | 's' | 'xs'
  alt: string
  base64?: string
}

export default function ProductImage({
  img,
  size,
  alt,
  base64,
  ...props
}: Props) {
  if (base64 === undefined) base64 = whitePixel

  // if whitepixel is used by e.g. dummy products, use base64 instead
  const isWhitePixel = img?.url === whitePixel

  const imgUrl = isWhitePixel ? base64 : genProductImageUrl(img, size)
  const imgUrlWithScale = isWhitePixel
    ? base64
    : genProductImageUrl(img, size, '2')

  return (
    <LazyImg
      className="ProductImage"
      src={imgUrl}
      alt={alt}
      offset={100}
      base64={base64}
      srcSet={`${imgUrl},                     
      ${imgUrlWithScale} 2x`}
      {...props}
    />
  )
}
