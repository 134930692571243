import * as React from 'react'
import styled from 'styled-components'
import { useOptInStatus } from 'modules/ui'
import config from 'config'
import { graphql, useStaticQuery } from 'gatsby'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'

type Gq = {
  footer: {
    trustIcon: string
    trustLink: string
  }
}

export default function Trusted() {
  const gq: Gq = useStaticQuery(graphql`
    query trustHeader {
      footer {
        trustIcon
        trustLink
      }
    }
  `)

  const {
    footer: { trustIcon, trustLink }
  } = gq
  const { optInStatus } = useOptInStatus()
  const t = useTranslations<'app-Footer'>()

  React.useEffect(() => {
    window?.__loadTrustedShops && window.__loadTrustedShops('custom')
  }, [])

  return (
    <Wrapper className="Trusted" data-cy-ctx="app/Trusted">
      {config.features.trustShop && (
        <div className="inner-wrapper" data-cy-state="trust-icon-visible">
          {trustIcon ? (
            <div className="trustShop" data-cy-state="trust-icon-visible">
              {trustIcon && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={trustLink}
                  data-cy-state="trust-icon-link"
                >
                  {' '}
                  <img src={trustIcon} />
                </a>
              )}
            </div>
          ) : (
            <div className="images">
              {optInStatus ? (
                <div
                  data-cy-state="trust-script-container-exits"
                  className="trustedshop-container teh-certificate"
                  id="trustedshop-badge"
                />
              ) : (
                <div className="logo">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={trustLink}
                    data-cy-handle="trust-icon-btn"
                  >
                    <img src={config.modules.trustShop.placeholderImg} />
                  </a>
                </div>
              )}
            </div>
          )}
          <div className="text">{t('trust_icon_headline')}</div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 25px;

  @media (min-width: ${theme.ms.MD}px) {
    margin-top: 10px;
  }

  > .inner-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    > .trustShop {
      > a {
        > img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    > .images {
      > .logo {
        width: 2.5rem;
        height: 2.5rem;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > .text {
      ${theme.ty('rc-s', '700')}
      max-width: 3.75rem;
      text-align: left;
    }
  }
`
