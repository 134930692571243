import { addRule } from 'redux-ruleset'
import config from 'config'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import {
  FETCH_SUCCESS as PDP_FETCH_SUCCESS,
  SET_FILTER_VALUE as PDP_SET_FILTER_VALUE
} from 'modules/productDetail/const'
import { getDisplayVariant } from 'modules/productDetail/selectors'
import calculateDeliveryDate from 'utils/calculateDeliveryDate'
import * as ProductWidget from 'theme/molecules/ProductWidget/events'
import * as MainNavigation from 'theme/app/Header/events'
import * as DrawerNavigation from 'theme/app/Header/FlyoutNavi/events'
import getCookieByName from 'utils/getCookieByName'
import * as EecTracking from 'theme/atoms/EecTracking'
import { LISTING_CONTEXT_BRAND_NAME } from 'theme/templates/Category/events'
import getPageInfo from 'utils/getPageInfo'
import * as SearchField from 'theme/app/Header/SearchField/events'
import * as Intersticial from 'theme/molecules/ProductList/Intersticial/events'
import * as ProductWidgetSeries from 'theme/molecules/ProductWidgetSeries/events'
import * as Pdp3D from 'theme/templates/PDP/Gallery/ImageGallery/Open3DView/events'
import store from 'store'
import * as pdpActions from 'modules/productDetail/actions'
import manager, * as hybridManager from 'utils/dy-hybrid/manager'
import { isItemChannelRestricted } from 'hooks/account/utils'
import theme from 'theme'
import { isLusiniPluginEnabled } from '../utils'
import { LANGUAGE_SWITCH_CLICK } from 'theme/app/Header/events'
import { getChannel, isChannelSet } from 'utils/channel'

type DlEvent = any

type BufferContext = {
  id: string
  path: string[]
}

declare global {
  interface Window {
    dataLayer: any
    abMode: 'a' | 'b' // CSS-A/B
  }
}
export const pushUA = (evt: DlEvent) => {
  if (!isLusiniPluginEnabled && window.dataLayer) window.dataLayer.push(evt)
}
export const push = (evt: DlEvent) => {
  if (!isLusiniPluginEnabled && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(evt)
  }
}
const bufferCacheUA: Record<string, any> = {}
const bufferedPushUA = (ctx: BufferContext, evt: DlEvent) => {
  const getTarget = (evt: any, path: string[]): Array<any> => {
    const [item, ...rest] = path
    if (item) return getTarget(evt[item], rest)
    else return evt
  }
  const flush = () => {
    push(bufferCacheUA[ctx.id])
    delete bufferCacheUA[ctx.id]
    window.removeEventListener('beforeunload', flush)
  }
  // very first event
  if (!bufferCacheUA[ctx.id]) {
    bufferCacheUA[ctx.id] = evt
    setTimeout(flush, 3000)
    window.addEventListener('beforeunload', flush)
    return
  }
  getTarget(bufferCacheUA[ctx.id], ctx.path).push(...getTarget(evt, ctx.path))
}
const bufferCache: Record<string, any> = {}

const bufferedPush = (ctx: BufferContext, evt: DlEvent) => {
  const getTarget = (evt: any, path: string[]): Array<any> => {
    const [item, ...rest] = path
    if (item) return getTarget(evt[item], rest)
    else return evt
  }
  const flush = () => {
    push(bufferCache[ctx.id])
    delete bufferCache[ctx.id]
    window.removeEventListener('beforeunload', flush)
  }
  // very first event
  if (!bufferCache[ctx.id]) {
    bufferCache[ctx.id] = evt
    setTimeout(flush, 3000)
    window.addEventListener('beforeunload', flush)
    return
  }
  getTarget(bufferCache[ctx.id], ctx.path).push(...getTarget(evt, ctx.path))
}

const getCategoryPath = (categories) =>
  categories.lvl7?.[0].split('>').join('/') ||
  categories.lvl6?.[0].split('>').join('/') ||
  categories.lvl5?.[0].split('>').join('/') ||
  categories.lvl4?.[0].split('>').join('/') ||
  categories.lvl3?.[0].split('>').join('/') ||
  categories.lvl2?.[0].split('>').join('/') ||
  categories.lvl1?.[0].split('>').join('/') ||
  categories.lvl0?.[0].split('>').join('/') ||
  ''

const getMainCategoryPath = (categoryPath) => {
  const obj: Record<string, any> = {}
  const arr = categoryPath.split(' / ')
  // See https://jira.em-group.de/browse/WDV-3365
  // The first category is always named item_category since this is the requirement.
  obj.item_category = arr[0]
  for (let i = 1; i < arr.length; i++) {
    const catKey = 'item_category' + (i + 1)
    const catEntity = { [catKey]: arr[i] }
    Object.assign(obj, catEntity)
  }
  return obj
}

// init datalayer
if (typeof window !== 'undefined') {
  const windowWidth = window.innerWidth
  const asset =
    windowWidth > theme.ms.LG
      ? 'Desktop'
      : windowWidth > theme.ms.MD
        ? 'Tablet'
        : 'Mobile'
  pushUA({
    originalLocation: document.location.href
  })
  pushUA({
    asset: asset,
    property: config.shopName,
    domain: window.location.host,
    language: config.i18n.locale
  })
}

addRule({
  id: 'dl/DY_SEEN',
  target: 'Personalization/SCROLL_INTO_VIEW',
  output: '#dl-event',
  condition: (action) => !action.payload.preventDySeenEvent,
  consequence: async () => {
    manager.getContext(
      () => {
        if (manager.isControlGroup()) return
        pushUA({
          event: 'genericEvent',
          eventname: 'dy_seen'
        })
      },
      (ctx) => ctx.pageViewSend
    )
  }
})

addRule({
  id: 'dl/PAGELOAD',
  target: LOCATION_CHANGED,
  output: '#dl-event',
  consequence: (action, { addRule }) => {
    const path = action.payload.pathname

    const pageInfo = getPageInfo(path)
    const channel = isChannelSet() ? getChannel() : 'undecided'

    const abBranchName =
      (window.abMode === 'b' &&
        config.abTests &&
        Object.keys(config.abTests).join(' | ')) ||
      'main'
    const userData = JSON.parse(
      decodeURIComponent(getCookieByName('datalayerCustomer') || '{}')
    )

    delete userData.branch
    delete userData.client
    delete userData.sessionId

    pushUA({
      cid: userData?.user || ''
    })

    const pageLoadObject = {
      event: 'pageLoad',
      abMode: window.abMode, // CSS-A/B
      abBranch: '-', // Branch-A/B
      branchName: process.env.GATSBY_BRANCH_NAME || '-',
      abTestVariantName: abBranchName, // CSS-A/B-only
      customer: {
        ...userData,
        isB2C: channel === 'undecided' ? channel : channel === 'b2c'
      },
      content: {
        pageType: pageInfo.pageType,
        pageName: pageInfo.pageName
      }
      // TODO: new cart logic
      // WHY? please check if this is really necessary! first page-load will always have empty cart
      // cart: state.cart.data.items.map((item) => ({
      //   sku: item.sku,
      //   amount: item.quantity,
      // })),
    }
    if (
      pageInfo.pageType === 'Series' ||
      pageInfo.pageType === 'Product' ||
      pageInfo.pageType === 'Category'
    ) {
      addRule('waitForLoadContextBrand', { pageLoadObject })
    } else {
      pushUA(pageLoadObject)
    }
  },
  subRules: {
    waitForLoadContextBrand: {
      target: [
        'templates/Series/MOUNT',
        PDP_FETCH_SUCCESS,
        LISTING_CONTEXT_BRAND_NAME
      ],
      output: '#dl-event',
      addOnce: true,
      consequence: (action, { context, getState }) => {
        const state = getState()

        const pageLoadObject = context.get('pageLoadObject')

        let ContextObject
        switch (action.type) {
          case 'templates/Series/MOUNT': {
            ContextObject = {
              brand: action.container.brand
            }
            break
          }
          case PDP_FETCH_SUCCESS: {
            ContextObject = {
              brand: state.productDetail.data[0]?.brand
            }
            pageLoadObject.content.isSeriesProduct =
              (state.productDetail.data[0]?.series ?? []).length > 0
                ? 'yes'
                : 'no'
            break
          }
          case LISTING_CONTEXT_BRAND_NAME: {
            if (action.payload !== '')
              ContextObject = {
                brand: action.payload
              }
            break
          }
        }

        const pageLoad = {
          ...pageLoadObject,
          context: ContextObject
        }

        pushUA(pageLoad)
      }
    }
  }
})

addRule({
  id: 'dl/PRODUCT_DETAIL_PAGE',
  target: [PDP_FETCH_SUCCESS, PDP_SET_FILTER_VALUE],
  output: '#dl-event',
  addUntil: function* (next, { context }) {
    yield next(
      [ProductWidget.WIDGET_CLICK, ProductWidgetSeries.SKU_WIDGET_CLICK],
      (action) => {
        context.set('listname', action.meta.listname)
        context.set('listPosition', action.meta.listPosition)
        context.set('listid', action.meta.listid)
      }
    )
    return 'RECREATE_RULE'
  },
  consequence: (_, { getState, context }) => {
    const state = getState()
    const variant = getDisplayVariant(state.productDetail)
    const dlActionField =
      context.get('listname') || getCategoryPath(variant.categories) || 'PDP'
    const listPosition = context.get('listPosition') || 0
    const listid = context.get('listid') || ''
    const deliveryDate = calculateDeliveryDate(variant, 1).snippet
    const originalLocation = listid || document.location.href
    store.dispatch(
      pdpActions.setCustomFields({
        itemListName: dlActionField,
        productPosition: listPosition,
        itemListId: listid
      })
    )

    pushUA({
      event: 'detailPage',
      ecommerce: {
        detail: {
          actionField: { list: dlActionField, action: 'detail' },
          products: [
            {
              name: variant.title,
              id: variant.sku,
              price: variant.prices.packPriceNet,
              brand: variant.brand,
              category: getCategoryPath(variant.categories),
              variant: Object.keys(variant.variantData)
                .filter((filterKey) => variant.variantData[filterKey])
                .map(
                  (filterKey) =>
                    `${filterKey}: ${variant.variantData[filterKey].label}`
                )
                .join(', '),
              deliveryDate: deliveryDate,
              dimension1: deliveryDate
            }
          ]
        }
      }
    })

    // https://jira.em-group.de/browse/WDV-2429
    let availability = ''
    if (isItemChannelRestricted(variant, 'b2b')) availability = 'Only B2C'
    if (isItemChannelRestricted(variant, 'b2c')) availability = 'Only B2B'

    push({
      event: 'view_item',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: variant.sku,
            item_name: variant.title,
            affiliation: '', //only in checkout-steps retrievable
            coupon: '', //only in checkout-steps retrievable
            currency: config.i18n.currency_ISO, //pack?prices
            discount: (variant.prices.piecePseudoPriceNet > 0
              ? variant.prices.piecePseudoPriceNet -
                variant.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: listPosition || 0, //assigend increamentaly for any product in the array
            item_brand: variant.brand,
            ...getMainCategoryPath(getCategoryPath(variant.categories)),
            item_list_id: originalLocation,
            item_list_name: dlActionField,
            item_variant: Object.keys(variant.variantData)
              .filter((filterKey) => variant.variantData[filterKey])
              .map(
                (filterKey) =>
                  `${filterKey}: ${variant.variantData[filterKey].label}`
              )
              .join(', '),
            location_id: '', //nebulously defined
            price: variant.prices.packPriceNet,
            quantity: '',
            delivery_date: deliveryDate, //replace w/ actual date,
            availability: availability,
            is_series_product: (variant.series ?? []).length > 0 ? 'yes' : 'no',
            product_review_stars: variant.productReviews?.productReviewAvg
              ? variant.productReviews.productReviewAvg.toString()
              : 'NA',
            product_review_count:
              variant.productReviews?.productReviewCount || 0,
            product_review_text_count:
              variant.productReviews?.productReviewTextCount || 0
          }
        ]
      }
    })
  }
})

// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/SHOW_CHANNEL_RESTRICTION_POPUP',
  target: 'BuyBox/SHOW_CHANNEL_RESTRICTION_POPUP',
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'pdp_notforb2c_warning',
      item_id: action.meta.sku,
      item_name: action.meta.name
    })
  }
})

// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/PDP_NOT_FOR_B2C_WARNING',
  target: 'BuyBox/CONTINUE_AS_B2B_CLICK',
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'pdp_notforb2c_continueasb2b',
      item_id: action.meta.sku,
      item_name: action.meta.name
    })
  }
})

// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/SHOW_CHANNEL_RESTRICTED_MODAL',
  target: 'cart/SHOW_CHANNEL_RESTRICTED_MODAL',
  output: '#dl-event',
  consequence: (action) => {
    if (action.meta.loggedIn) {
      pushUA({
        event: 'cart_notforb2c_warning_afterlogin',
        item_id: action.meta.sku,
        item_name: action.meta.title
      })
    } else {
      pushUA({
        event: 'pdp_notforb2c_continueasb2b',
        item_id: action.meta.sku,
        item_name: action.meta.title
      })
    }
  }
})

// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/CART_REMOVE_RESTRICTED_ITEMS_CLICK',
  target: 'cart/REMOVE_RESTRICTED_ITEMS_CLICK',
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'cart_notforb2c_remove_item',
      item_id: action.meta.sku,
      item_name: action.meta.title
    })
  }
})

// https://jira.em-group.de/browse/WDV-3055
addRule({
  id: 'dl/LANGUAGE_SWITCH_CLICK',
  target: LANGUAGE_SWITCH_CLICK,
  output: '#dl-event',
  consequence: (action) => {
    push({
      event: 'genericEvent',
      eventname: 'language_switch',
      category: 'language_switch',
      action: action.payload.to.toUpperCase(),
      label: '',
      value: 0,
      nonInteraction: 'false'
    })
  }
})
// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/CART_CONTACT_SUPPORT_CLICK',
  target: 'cart/CONTACT_SUPPORT_CLICK',
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'cart_notforb2c_contactsupport'
    })
  }
})

// https://jira.em-group.de/browse/WDV-2380
addRule({
  id: 'dl/CART_SWITCH_CHANNEL_BY_CHANNEL_MODAL',
  target: 'cart/SWITCH_CHANNEL_BY_CHANNEL_MODAL',
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'cart_notforb2c_continueasb2b'
    })
  }
})

addRule({
  id: 'dl/removeFromCart',
  target: `CART_REMOVE_ITEM`,
  output: '#dl-event',
  consequence: (action) => {
    const item = action.item
    if (!item) return
    pushUA({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              name: item.title,
              id: item.sku,
              price: item.prices.packPriceNet,
              brand: item.brand,
              category: getCategoryPath(item.categories),
              variant: Object.keys(item)
                .filter((filterKey) => item[filterKey])
                .map((filterKey) => `${filterKey}: ${item[filterKey].label}`)
                .join(', '),

              quantity: item.quantity
            }
          ]
        }
      }
    })
  }
})

addRule({
  id: 'dl/ADD_TO_CART',
  target: 'CART_ADD_ITEM',
  output: '#dl-event',
  addUntil: function* (next, { context }) {
    yield next(
      [
        ProductWidgetSeries.Sku_Widget_ADD_TO_CART,
        ProductWidgetSeries.SKU_WIDGET_CLICK,
        ProductWidget.WIDGET_CLICK
      ],
      (action) => {
        context.set('variantData', '')
        if (action.payload.variantData) {
          const item_variant = Object.keys(action.payload?.variantData)
            .filter((filterKey) => action.payload.variantData[filterKey])
            .map(
              (filterKey) =>
                `${filterKey}: ${action.payload.variantData[filterKey].label}`
            )
            .join(', ')
          context.set('variantData', item_variant)
        }

        context.set('listname', action.meta.listname)
        context.set('listPosition', action.meta.listPosition)
        context.set('listid', action.meta.listid)
      }
    )
    return 'RECREATE_RULE'
  },

  consequence: (action, { context }) => {
    const addedVariantData = action.cart.items.find(
      (item) => item.cartItemId === action.cartItemId
    )
    const variant = context.get('variantData') || ''
    const listname =
      context.get('listname') ||
      getCategoryPath(addedVariantData?.categories) ||
      'PDP'
    const listPosition = context.get('listPosition') || 0

    const originalLocation = document.location.href
    const listid = context.get('listid') || originalLocation

    if (!addedVariantData) return
    const deliveryDate = calculateDeliveryDate(addedVariantData, 1).snippet

    pushUA({
      event: 'addToCart',
      ecommerce: {
        currencyCode: config.i18n.currency_ISO,
        add: {
          products: [
            {
              name: addedVariantData.title,
              id: addedVariantData.sku,
              price: addedVariantData.prices.packPriceNet,
              brand: addedVariantData.brand,
              category: getCategoryPath(addedVariantData.categories),
              variant: Object.keys(addedVariantData)
                .filter((filterKey) => addedVariantData[filterKey])
                .map(
                  (filterKey) =>
                    `${filterKey}: ${addedVariantData[filterKey].label}`
                )
                .join(', '),
              deliveryDate: deliveryDate,
              dimension1: deliveryDate,
              quantity: addedVariantData.quantity
            }
          ],
          actionField: {
            list: 'Productpage',
            action: 'add'
          }
        }
      }
    })

    push({
      event: 'add_to_cart',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: addedVariantData.sku,
            item_name: addedVariantData?.title,
            affiliation: '', //only in checkout-steps retrievable
            coupon: '', //only in checkout-steps retrievable
            currency: config.i18n.currency_ISO,
            discount: (addedVariantData?.prices.piecePseudoPriceNet > 0
              ? addedVariantData?.prices.piecePseudoPriceNet -
                addedVariantData?.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: listPosition, //assigend increamentaly for any product in the array
            item_brand: addedVariantData?.brand,
            ...getMainCategoryPath(
              getCategoryPath(addedVariantData?.categories)
            ),
            item_list_id: listid,
            item_list_name: listname,
            item_variant: variant,
            location_id: '', //nebulously defined
            price: addedVariantData.prices.packPriceNet,
            quantity: addedVariantData.quantity, // pack?  //not set unitl added to cart, or modified there
            delivery_date: deliveryDate //replace w/ actual date,
          }
        ]
      }
    })
  }
})

addRule({
  id: 'dl/PRODUCT_WIDGET_CLICK',
  target: [ProductWidget.WIDGET_CLICK, ProductWidgetSeries.SKU_WIDGET_CLICK],
  output: '#dl-event',
  consequence: (action) => {
    const variant = action.payload
    const originalLocation = document.location.href

    pushUA({
      event: 'productClick', //inital click from shop
      ecommerce: {
        click: {
          actionField: {
            list: action.meta.listname,
            action: 'click'
          },
          products: [
            {
              id: variant.sku,
              name: variant.title,
              brand: variant.brand,
              price: variant.prices.packPriceNet,
              category: getCategoryPath(variant.categories),
              position: action.meta.listPosition
            }
          ]
        }
      }
    })
    push({
      event: 'select_item',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: variant.sku,
            item_name: variant.title,
            affiliation: '', //only in checkout-steps retrievable
            coupon: '', //only in checkout-steps retrievable
            currency: config.i18n.currency_ISO,
            discount: (variant.prices.piecePseudoPriceNet > 0
              ? variant.prices.piecePseudoPriceNet -
                variant.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: action.meta.listPosition || 0, //assigend increamentaly for any product in the array
            item_brand: variant.brand,
            ...getMainCategoryPath(getCategoryPath(variant.categories)),
            item_list_id: action.meta.listid || originalLocation,
            item_list_name:
              action.meta.listname ||
              getCategoryPath(action.payload.categories),
            item_variant: Object.keys(variant.variantData)
              .filter((filterKey) => variant.variantData[filterKey])
              .map(
                (filterKey) =>
                  `${filterKey}: ${variant.variantData[filterKey].label}`
              )
              .join(', '),
            location_id: '', //nebulously defined
            price: variant.prices.packPriceNet,
            quantity: 1,
            delivery_date: calculateDeliveryDate(action.payload, 1).snippet //replace w/ actual date,
          }
        ]
      }
    })
  }
})

addRule({
  id: 'dl/PRODUCT_WIDGET_COLOR_CLICK',
  target: ProductWidget.COLOR_CLICK,
  output: '#dl-event',
  consequence: (action) => {
    const { listname, listPosition, displayVariant } = action.meta
    const originalLocation = document.location.href
    pushUA({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: {
            list: listname,
            action: 'click'
          },
          products: [
            {
              id: displayVariant.sku,
              name: displayVariant.title,
              brand: displayVariant.brand,
              price: displayVariant.prices.packPriceNet,
              category: getCategoryPath(displayVariant.categories),
              position: listPosition
            }
          ]
        }
      }
    })
    push({
      event: 'select_item',
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: displayVariant.sku,
            item_name: displayVariant.title,
            affiliation: '', //only in checkout-steps retrievable
            coupon: '', //only in checkout-steps retrievable
            currency: config.i18n.currency_ISO,
            discount: (displayVariant.prices.piecePseudoPriceNet > 0
              ? displayVariant.prices.piecePseudoPriceNet -
                displayVariant.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: listPosition || 0,
            item_brand: displayVariant.brand,
            ...getMainCategoryPath(getCategoryPath(displayVariant.categories)),
            item_list_id: originalLocation,
            item_list_name:
              listname || getCategoryPath(displayVariant.categories),
            item_variant: Object.keys(displayVariant.variantData)
              .filter((filterKey) => displayVariant.variantData[filterKey])
              .map(
                (filterKey) =>
                  `${filterKey}: ${displayVariant.variantData[filterKey].label}`
              )
              .join(', '), //nebulously defined
            location_id: '', // nebulously defined
            price: displayVariant.prices.packPriceNet,
            quantity: '', // set at DetailPage of Poduct => pack?
            delivery_date: calculateDeliveryDate(displayVariant, 1).snippet
          }
        ]
      }
    })
  }
})

//TO CHECK
//eec_impressoin - view_item_list
addRule({
  id: 'dl/EEC_PRODUCT_IMPRESSION',
  target: [
    ProductWidget.SCROLL_INTO_VIEW,
    ProductWidgetSeries.SCROLL_INTO_VIEW
  ],
  output: '#dl-event',
  consequence: (action) => {
    const bufferContext = {
      id: 'dl/EEC_PRODUCT_IMPRESSION',
      path: ['ecommerce', 'items']
    }
    const originalLocation = document.location.href
    const bufferContextUA = {
      id: 'dl/EEC_PRODUCT_IMPRESSION',
      path: ['ecommerce', 'impressions']
    }
    bufferedPushUA(bufferContextUA, {
      event: 'eec_impressions',
      ecommerce: {
        currencyCode: config.i18n.currency_ISO,
        impressions: [
          {
            id: action.payload.sku,
            name: action.payload.title,
            category: getCategoryPath(action.payload.categories),
            brand: action.payload.brand,
            list: action.meta.listname,
            price: String(action.payload.prices.piecePriceNet),
            position: action.meta.listPosition
          }
        ]
      }
    })

    // https://jira.em-group.de/browse/WDV-2429
    let availability = ''
    if (isItemChannelRestricted(action.payload, 'b2b'))
      availability = 'Only B2C'
    if (isItemChannelRestricted(action.payload, 'b2c'))
      availability = 'Only B2B'

    bufferedPush(bufferContext, {
      event: `view_item_list`,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: [
          {
            item_id: action.payload.sku,
            item_name: action.payload.title,
            affiliation: '', //set in checkout
            coupon: '', //set in checkout
            currency: config.i18n.currency_ISO,
            discount: (action.payload.prices.piecePseudoPriceNet > 0
              ? action.payload.prices.piecePseudoPriceNet -
                action.payload.prices.piecePriceNet
              : 0
            ).toFixed(2),
            index: action.meta.listPosition || 0, //indexed increamentaly
            item_brand: action.payload.brand,
            ...getMainCategoryPath(getCategoryPath(action.payload.categories)),
            item_list_id: originalLocation,
            item_list_name: action.meta.listname,
            item_variant: '', //nebulously defined
            location_id: '', // nebulously defined
            price: action.payload.prices.piecePriceNet,
            quantity: '', //pack?
            delivery_date: '',
            availability: availability,
            product_review_stars: action.payload.productReviews
              ?.productReviewAvg
              ? action.payload.productReviews.productReviewAvg.toString()
              : 'NA',
            product_review_count:
              action.payload.productReviews?.productReviewCount || 0,
            product_review_text_count:
              action.payload.productReviews?.productReviewTextCount || 0
          }
        ]
      }
    })
  }
})

addRule({
  id: 'dl/BUYBOX_CUSTOMIZABLE_BTN_CLICK',
  target: 'BuyBox/CUSTOMIZABLE_BTN_CLICK',
  output: '#dl-event',
  consequence: (action) => {
    const { productName, categoryPath, sku } = action.meta

    pushUA({
      event: 'genericEvent',
      eventname: 'personalization_cta',
      interaction: 'click',
      element: 'CTA Button Jetzt personalisieren',
      category: 'Engagement',
      action: 'click',
      label: 'Jetzt personalisieren CTA Click',
      value: 0,
      nonInteraction: 'false',
      meta: `${categoryPath}||${productName}||${sku}`
    })
  }
})

addRule({
  id: 'dl/EEC_ORGANISM_CLICK',
  target: EecTracking.CLICK,
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id:
                action.meta.eecTracking?.campaignId || action.payload.gridarea,
              name: action.meta.eecTracking?.name || action.payload.gridarea,
              creative: action.meta.eecTracking?.creative || '',
              position: action.payload.path + ' | ' + action.payload.gridarea
            }
          ]
        }
      }
    })
    pushUA({
      event: 'select_promotion',
      promotion_name: action.meta.eecTracking?.name || action.payload.gridarea,
      promotion_id:
        action.meta.eecTracking?.campaignId || action.payload.gridarea,
      promotion_creative: action.meta.eecTracking?.creative || '',
      promotion_position: action.payload.path + ' | ' + action.payload.gridarea,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: []
      }
    })
    pushUA({
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions: [
            {
              id:
                action.meta.eecTracking?.campaignId || action.payload.gridarea,
              name: action.meta.eecTracking?.name || action.payload.gridarea,
              creative: action.meta.eecTracking?.creative || '',
              position: action.payload.path + ' | ' + action.payload.gridarea
            }
          ]
        }
      }
    })
    pushUA({
      event: 'view_promotion',
      promotion_name: action.meta.eecTracking?.name || action.payload.gridarea,
      promotion_id:
        action.meta.eecTracking?.campaignId || action.payload.gridarea,
      promotion_creative: action.meta.eecTracking?.creative || '',
      promotion_position: action.payload.path + ' | ' + action.payload.gridarea,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: []
      }
    })
  }
})

addRule({
  id: 'dl/DY_CHOOSE_INIT',
  target: 'Personalization/INIT',
  output: '#dy-event',
  consequence: (action) => {
    const dyObject = {
      event: 'dynamicyield_metadata',
      eventname: 'dynamicyield_metadata',
      dy_campaignId: action.payload.campaignId,
      dy_campaignName: action.payload.campaignName,
      dy_experienceId: action.payload.experienceId,
      dy_experienceName: action.payload.experienceName,
      dy_variationId: action.payload.variationId,
      dy_variationName: action.payload.variationName
    }
    if (hybridManager.getConsentStatus() === 'ACCEPTED') {
      const _dy_cs_gcg = localStorage.getItem('_dy_cs_gcg') || null
      if (_dy_cs_gcg) {
        dyObject['_dy_cs_gcg'] = _dy_cs_gcg
      }
    }
    pushUA(dyObject)
  }
})

addRule({
  id: 'dl/EEC_ORGANISM_SCROLL_INTO_VIEW',
  target: EecTracking.SCROLL_INTO_VIEW,
  output: '#dl-event',
  consequence: (action) => {
    const bufferContext = {
      id: 'dl/EEC_ORGANISM_SCROLL_INTO_VIEW',
      path: ['ecommerce', 'items']
    }
    const bufferContextUA = {
      id: 'dl/EEC_ORGANISM_SCROLL_INTO_VIEW',
      path: ['ecommerce', 'promoView', 'promotions']
    }
    bufferedPushUA(bufferContextUA, {
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions: [
            {
              id:
                action.meta.eecTracking?.campaignId || action.payload.gridarea,
              name: action.meta.eecTracking?.name || action.payload.gridarea,
              creative: action.meta.eecTracking?.creative || '',
              position: action.payload.path + ' | ' + action.payload.gridarea
            }
          ]
        }
      }
    })
    bufferedPush(bufferContext, {
      event: 'view_promotion',
      promotion_name: action.meta.eecTracking?.name || action.payload.gridarea,
      promotion_id:
        action.meta.eecTracking?.campaignId || action.payload.gridarea,
      promotion_creative: action.meta.eecTracking?.creative || '',
      promotion_position: action.payload.path + ' | ' + action.payload.gridarea,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: []
      }
    })
  }
})

addRule({
  id: 'dl/MAIN_NAVIGATION_CLICK',
  target: [
    MainNavigation.MAIN_CATEGORY_CLICK,
    MainNavigation.BURGER_MENU_CLICK,
    MainNavigation.SHOW_MORE_CLICK,
    DrawerNavigation.MAIN_CATEGORY_CLICK,
    DrawerNavigation.SUB_CATEGORY_CLICK
  ],
  output: '#dl-event',
  consequence: (action) => {
    const area = action.payload
    const { category, path } = action.meta

    pushUA({
      event: 'genericEvent',
      eventname: 'navigation_click',
      main_navigation_area: area,
      main_navigation_categorypath: path || category,
      category: 'Main Navigation',
      action: area,
      label: path || category, //label for the item_list_name
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/SHOW_ALL_PRODUCTS',
  target: [
    DrawerNavigation.HEADLINE_CLICK,
    DrawerNavigation.SHOW_ALL_BUTTON_CLICK
  ],
  output: '#dl-event',
  consequence: (action) => {
    const label =
      action.type === DrawerNavigation.HEADLINE_CLICK
        ? 'Als Untertitel'
        : 'OffCanvas-Menu - am Ende'
    pushUA({
      event: 'genericEvent',
      eventname: 'back_to_top',
      category: 'Engagement',
      action: 'Alles anzeigen',
      label: label,
      value: 0,
      nonInteraction: 'false'
    })
  }
})
addRule({
  id: 'dl/BACK_TO_PARENT_MENU',
  target: [DrawerNavigation.BACK_TO_PARENT_MENU],
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'genericEvent',
      eventname: 'back_to_top',
      category: 'Engagement',
      action: 'Zurueck',
      label: 'Zurueck',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/CUSTOMIZED_NAVIGATION_CLICK',
  target: [DrawerNavigation.CUSTOMIZED_NAVIGATION_CLICK],
  output: '#dl-event',
  consequence: (action) => {
    const label = action.payload

    pushUA({
      event: 'genericEvent',
      eventname: 'navigation_click',
      main_navigation_area: 'Customized Navigation Click',
      main_navigation_categorypath: label,
      category: 'Main Navigation',
      action: 'Customized Navigation Click',
      label: label,
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/CLOSE_NAVIGATION_CLICK',
  target: DrawerNavigation.CLOSE_NAVIGATION_CLICK,
  output: '#dl-event',
  consequence: (action) => {
    const categoryPath = action.payload

    pushUA({
      event: 'genericEvent',
      eventname: 'navigation_click',
      main_navigation_area: 'Main Navigation',
      main_navigation_categorypath: categoryPath || 'Home',
      category: 'Main Navigation',
      action: 'Drawer - X Click',
      label: categoryPath || 'Home',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/NAVIGATION_B2B_B2C_SWITCH',
  target: 'SET_CHANNEL',
  output: '#dl-event',
  consequence: (action) => {
    const customerType = action.channel
    const eventname = action.dlEvent
    if (eventname === 'navigation_b2b2c_switch') {
      pushUA({
        event: 'genericEvent',
        eventname: eventname,
        customer_type: customerType,
        category: 'Navigation B2B B2C Switch',
        action: customerType,
        label: '',
        value: 0,
        nonInteraction: 'true'
      })
    }
  }
})

//TO CHECK
//bufferedPush
//injected Teaser view - view_protmotion
addRule({
  id: 'dl/SEARCH_FIELD_OPEN',
  target: SearchField.OPEN,
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'genericEvent',
      eventname: 'autosuggest',
      category: 'autosuggest',
      action: 'suggest',
      label: 'Keine Eingabe',
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/SEARCH_FIELD_SEARCH',
  target: SearchField.SEARCH,
  output: '#dl-event',
  consequence: (action) => {
    let label = 'Keine Eingabe'
    if (action.payload.length > 0 && action.payload.length < 3)
      label = '< 3 Zeichen'
    if (action.payload.length >= 3) label = 'Ganzer Begriff'
    pushUA({
      event: 'genericEvent',
      eventname: 'autosuggest',
      category: 'autosuggest',
      action: 'click',
      label: label,
      value: 0,
      nonInteraction: 'false'
    })
  }
})

addRule({
  id: 'dl/INJECTED_TEASER_SCROLL_INTO_VIEW',
  target: Intersticial.SCROLL_INTO_VIEW,
  output: '#dl-event',
  consequence: (action) => {
    const bufferContext = {
      id: 'dl/EEC_ORGANISM_SCROLL_INTO_VIEW',
      path: ['ecommerce', 'items']
    }
    const bufferContextUA = {
      id: 'dl/EEC_ORGANISM_SCROLL_INTO_VIEW',
      path: ['ecommerce', 'promoView', 'promotions']
    }
    bufferedPushUA(bufferContextUA, {
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions: [
            {
              id: action.payload.campaignID,
              name: action.payload.campaignName,
              creative: action.payload.campaignCreative,
              position: action.payload.gridArea
            }
          ]
        }
      }
    })
    bufferedPush(bufferContext, {
      event: 'view_promotion',
      promotion_name: action.payload.campaignName,
      promotion_id: action.payload.campaignID,
      promotion_creative: action.payload.campaignCreative || '',
      promotion_position: action.payload.gridArea,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: []
      }
    })
  }
})

addRule({
  id: 'dl/INJECTED_TEASER_CLICK',
  target: Intersticial.WIDGET_CLICK,
  output: '#dl-event',
  consequence: (action) => {
    const items = []
    pushUA({
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: action.payload.campaignID,
              name: action.payload.campaignName,
              creative: action.payload.campaignCreative,
              position: action.payload.gridArea
            }
          ]
        }
      }
    })
    push({
      event: 'select_promotion',
      promotion_name: action.payload.campaignName,
      promotion_id: action.payload.campaignID,
      promotion_creative: action.payload.campaignCreative || '',
      promotion_position: action.payload.gridArea,
      ecommerce: {
        currency: config.i18n.currency_ISO,
        items: items
      }
    })
  }
})

addRule({
  id: 'dl/B2X_MODAL_VIEW',
  target: 'B2xModal/VIEW',
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'genericEvent',
      eventname: 'overlay_b2b2c_show',
      category: 'Overlay B2B B2C Switch',
      action: 'Show',
      label: '',
      value: 0,
      nonInteraction: 'true'
    })
  }
})

addRule({
  id: 'dl/PDP_3D_OPEN',
  target: Pdp3D.PDP_3D_OPEN,
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'pdp_3d_open',
      item_id: action.payload,
      item_name: action.meta.title
    })
  }
})

addRule({
  id: 'dl/PDP_3D_INFO',
  target: Pdp3D.PDP_3D_INFO,
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'pdp_3d_info_overlay',
      item_id: action.payload,
      item_name: action.meta.title
    })
  }
})

addRule({
  id: 'dl/PAYPAL_PAYMENT_APPROVED',
  target: 'CartSummary/PaypalExpress/PAYMENT_APPROVED',
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'genericEvent',
      eventname: 'cart_paypal_express_approved'
    })
  }
})

addRule({
  id: 'dl/PAYPAL_EXPRESS_CLICK',
  target: 'CartSummary/PaypalExpress/BUTTON_CLICK',
  output: '#dl-event',
  consequence: () => {
    pushUA({
      event: 'genericEvent',
      eventname: 'cart_paypal_express_click'
    })
  }
})

addRule({
  id: 'dl/CREATE_ORDER_CLICK',
  target: 'Overview/BUTTON_CLICK',
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'genericEvent',
      eventname: 'checkout_bestellen_click',
      zahlart: action.paymentMethod
    })
  }
})

addRule({
  id: 'dl/TRACK_PAYPAL_EXPRESS_ORDER_SUCCESS',
  target: 'checkout/ORDER_SUCCESS',
  output: '#dl-event',
  condition: (action) => action.isPaypalExpress,
  consequence: (action) => {
    pushUA({
      event: 'genericEvent',
      eventname: 'purchase_paypal_express',
      orderId: action.orderId
    })
  }
})

addRule({
  id: 'dl/TRACK_SIDE_NAVIGATION_CLICK',
  target: 'listingCategoryTree/SIDE_NAVIGATION_CLICK',
  output: '#dl-event',
  consequence: (action) => {
    pushUA({
      event: 'genericEvent',
      eventname: 'side_navigation_click',
      category: 'Engagement',
      action: 'side_navigation_click',
      label: action.payload.label,
      click_text: action.payload.label,
      value: 0,
      nonInteraction: 'false'
    })
  }
})
