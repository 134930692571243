import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Close from 'assets/close.svg'
import { useStaticQuery, graphql } from 'gatsby'
import PhoneIcon from 'assets/phone-call.svg'
import ContactVersion from 'utils/contactVersion'
import Link from 'theme/atoms/Link'
import useOutsideClick from 'hooks/useOutsideClick'
import useTranslations from 'hooks/useTranslations'

export type Gq = {
  header: {
    contactInfo: {
      title: string
      value: string
      info: string
      type: 'phonenumber' | 'whatsapp' | 'email'
    }[]
  }
}

export default function Tooltip() {
  const t = useTranslations<'app-CheckoutHeader'>()
  const [showTooltip, setShowTooltip] = React.useState(false)
  const outsideClickRef = useOutsideClick(() => {
    setShowTooltip(false)
  })

  const gq: Gq = useStaticQuery(graphql`
    query appheaderCheckout {
      header {
        contactInfo {
          title
          value
          info
          type
        }
      }
    }
  `)
  if (gq?.header.contactInfo.length === 0) return null

  return (
    <Wrapper ref={outsideClickRef}>
      <div
        className="menu"
        onClick={() => setShowTooltip(!showTooltip)}
        data-cy-handle="contact-icon"
      >
        <PhoneIcon />
        {t('checkout_header_contact')}
      </div>
      {showTooltip && (
        <div className="tooltip" data-cy-state="show-tooltip">
          <div>
            <button
              className="close-btn"
              onClick={() => setShowTooltip(false)}
              data-cy-handle="close-tooltip-btn"
            >
              <Close />
            </button>
          </div>
          {gq.header.contactInfo
            .filter((contact) => contact.value && contact.info && contact.title)
            .map((contact, i) => {
              return (
                <div key={contact.title + i} className="contact-wrapper">
                  <h3>{contact.title}</h3>
                  <div className="contact">
                    <Link to={ContactVersion(contact.type, contact.value).link}>
                      {ContactVersion(contact.type, contact.value).icon}
                      {contact.value}
                    </Link>
                    <div className="time">{contact.info}</div>
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  ${theme._ty([14, 0.35, 14], theme.fontSpecial, '400')};
  color: ${theme.colors.white};

  > .menu {
    display: flex;
    align-items: center;
    height: 100%;
    ${theme._ty([14, 0.35, 0], '"Roboto Condensed", sans-serif', '700')};

    svg {
      margin-right: ${theme.spacing('xs')};
      height: 20px;
    }
  }

  > .tooltip {
    display: flex;
    flex-wrap: wrap;
    visibility: visible;
    opacity: 1;
    width: 300px;
    background-color: #fff;
    border: #7d7971 1px solid;
    color: #000;
    ${theme.ty('r-s')};
    font-weight: 400;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: ${theme.zIndex.theme_app_checkoutheader_topline_tooltip};
    top: 50px;
    margin-left: 0;
    transition: opacity 0.3s;
    transform: translate(-55%, 0%);
    transform: unset;
    right: 0;
    margin-left: -64px;
    @media (min-width: ${theme.ms.MD}px) {
      transform: unset;
      right: 0;
      margin-left: -64px;
    }

    > div {
      width: 100%;

      > .close-btn {
        float: right;

        > svg {
          width: 20psx;
        }
      }
    }

    > .contact-wrapper {
      text-align: left;
      margin-left: ${theme.spacing('xs')};
      margin-bottom: ${theme.spacing('xs')};

      > .contact {
        margin-top: ${theme.spacing('xs')};
        ${theme.ty('rc-base', '400')}

        > a {
          margin-right: ${theme.spacing('s')};

          > svg {
            margin-right: ${theme.spacing('xs')};
            width: 18px;

            path {
              fill: ${theme.colors.b0};
            }
          }
        }

        > .time {
          ${theme.ty('r-s')}
          margin-left: ${theme.spacing('ml')};
          color: ${theme.colors.shade.b3};
        }
      }
    }
  }

  .tooltip::after,
  .tooltip::before {
    content: '';
    bottom: 100%;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    top: -30px;
    left: 50%;
    margin-left: -16px;
    border: solid transparent;
  }

  .tooltip::after {
    border-width: 16px;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: ${theme.colors.white};
    margin-left: 84px;
    @media (min-width: ${theme.ms.MD}px) {
      margin-left: 84px;
    }
  }

  .tooltip::before {
    border-width: 15px;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #7d7971;

    margin-left: 85px;
    @media (min-width: ${theme.ms.MD}px) {
      margin-left: 85px;
    }
  }
`
