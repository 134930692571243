import * as React from 'react'
import withDy from './withDy'
import * as t from './types'
import styled from 'styled-components'
import theme from 'theme'
import Link from 'theme/atoms/Link'
import Button from 'theme/atoms/Button'
import LinkArrow from 'assets/arrow-button-right.svg'
import DropdownIcon from 'assets/arrow-down.svg'
import { copyCodeToClipboard } from './events'
import EecTracking from 'theme/atoms/EecTracking'
import usePersonalization from 'hooks/usePersonalization'

export default withDy(function NotificationBar(props: t.Props) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const personalization = usePersonalization()
  const handleButtonClick = () => {
    setDropdownOpen(!dropdownOpen)
  }

  if (!props)
    return (
      <Wrapper
        $dropdownOpen={dropdownOpen}
        $backgroundColor={theme.colors.brand.lusini}
        $textColor={theme.colors.black}
      />
    )

  return (
    <EecTracking config={props.eecTracking} gridArea="Notification-Bar">
      <Wrapper
        data-cy-ctx="app/NotificationBar"
        $dropdownOpen={dropdownOpen}
        $backgroundColor={props.backgroundColor || theme.colors.brand.lusini}
        $textColor={props.textColor || theme.colors.black}
      >
        <div className="topline">
          {props.link && (
            <Link
              data-cy-handle="link"
              className="link"
              data-cy-state="show-link-variant"
              to={props.link}
              onClick={() => personalization.trackClick()}
            >
              <div className="text">
                {props.prefix && (
                  <div className="prefix element" data-cy-state="show-prefix">
                    <p>{props.prefix}</p>
                  </div>
                )}
                {props.countdown && (
                  <div
                    className="countdown element"
                    data-cy-state="show-countdown"
                  >
                    <p>{props.countdown}</p>
                  </div>
                )}

                <div className="suffix element">
                  <p>{props.titel}</p>
                  <LinkArrow className="icon" />
                </div>
              </div>
            </Link>
          )}{' '}
          {!props.link && (
            <div className="link" data-cy-state="show-dropdown-variant">
              <div className="text">
                {props.prefix && (
                  <div className="prefix element" data-cy-state="show-prefix">
                    <p>{props.prefix}</p>
                  </div>
                )}
                {props.countdown && (
                  <div
                    className="countdown element"
                    data-cy-state="show-countdown"
                  >
                    <p>{props.countdown}</p>
                  </div>
                )}
                <div className="suffix element">
                  <p>
                    <span>{props.titel}</span>
                    {props.buttonPrefix && (
                      <span
                        className="dropdown-button icon"
                        data-cy-state="dropdown"
                        data-cy-handle="dropdown-button"
                        onClick={handleButtonClick}
                      >
                        <DropdownIcon />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <Dropdown $open={dropdownOpen}>
          <div className="content-wrapper">
            <div className="button-prefix">{props.buttonPrefix}</div>
            {props.code && (
              <Button
                variation="primary"
                data-cy-state="button"
                data-cy-handle="copy-button"
                onClick={() => {
                  navigator.clipboard.writeText(props.code || '')
                  copyCodeToClipboard(props.code || '')
                }}
              >
                {props.buttonText}
              </Button>
            )}
            {props.buttonText && !props.code && (
              <Button
                variation="primary"
                data-cy-state="button"
                data-cy-handle="copy-button"
                to={props.buttonLink}
              >
                {props.buttonText}
              </Button>
            )}
          </div>
        </Dropdown>
      </Wrapper>
    </EecTracking>
  )
})

const Wrapper = styled.div<{
  $dropdownOpen: boolean
  $backgroundColor: string
  $textColor: string
}>`
  > .topline {
    min-height: 37px;
    @media (min-width: ${theme.ms.LG}px) {
      min-height: 42px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(topline) => topline.$backgroundColor};
    color: ${(topline) => topline.$textColor};

    > .link > .text {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing('xxs')};
      justify-content: center;
      padding: ${theme.spacing('xs')} 0;
      color: ${(topline) => topline.$textColor};
      > .element {
        width: fit-content;
        justify-content: center;
        display: flex;
        > p {
          margin: 0;
        }
      }
      > .prefix {
        ${theme.ty('rc-s', '400', 'rc-base')}
        color: ${(topline) => topline.$textColor};
      }
      > .countdown {
        ${theme.ty('rc-s', '700', 'rc-base')}
        color: ${(topline) => topline.$textColor};
      }
      > .suffix {
        ${theme.ty('rc-s', '400', 'rc-base')}
        color: ${(topline) => topline.$textColor};
        > p {
          display: flex;
          gap: ${theme.spacing('xs')};
          > .dropdown-button {
            ${(p) => p.$dropdownOpen && 'rotate: 180deg;'}
          }
        }
        > .icon {
          height: 100%;
          display: flex;
          align-items: center;
          width: 12px;
          cursor: pointer;
          margin-left: ${theme.spacing('xxs')};
          g {
            stroke: ${(topline) => topline.$textColor};
          }
        }
      }
    }
  }
`

const Dropdown = styled.div<{ $open: boolean }>`
  > .content-wrapper {
    gap: ${theme.spacing('m')};
    display: ${(props) => (props.$open ? 'flex' : 'none')};
    background-color: ${theme.colors.shade.primaryUltraBright};
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing('xs')} 0;
    > .button-prefix {
      ${theme.ty('rc-s', '700', 'rc-base')}
    }
  }
`
