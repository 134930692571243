import * as React from 'react'
import styled from 'styled-components'
import TopLine from './TopLine'
import Container from 'theme/atoms/Container'
import Head from './Head/index'
import Steps from './Steps'
import theme from 'theme'
import Trusted from './Trusted'

type Props = {
  activeStep?: 'account' | 'address' | 'payment' | 'buy'
}

export default function CheckoutHeader(props: Props) {
  const { activeStep } = props

  return (
    <Wrapper data-ctx="app/CheckoutHeader">
      <TopLine />
      <Container className="container">
        <Layout>
          <div className="head">
            <Head />
          </div>
          <div className="trusted">
            <Trusted />
          </div>
          <div className="steps">
            <Steps activeStep={activeStep} />
          </div>
        </Layout>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (min-width: ${theme.ms.MD}px) {
    margin-bottom: ${theme.spacing('s')};
  }
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas:
    'head . trusted'
    'steps steps steps';

  @media (min-width: ${theme.ms.MD}px) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'head steps trusted';
    grid-column-gap: ${theme.spacing('xxl')};
  }

  > .head {
    display: grid;
    place-items: center;
    grid-area: head;
    > .Head {
      @media (min-width: ${theme.ms.MD}px) {
        margin: unset;
      }
    }
  }

  > .steps {
    @media (min-width: ${theme.ms.MD}px) {
      display: flex;
      justify-content: center;
      margin-top: ${theme.spacing('s')};
    }
    grid-area: steps;
    text-align: center;

    > .Steps {
    }
  }

  > .trusted {
    display: grid;
    place-items: center;
    grid-area: trusted;

    > .Trusted {
      margin: ${theme.spacing('s')} 0 0 0;
      @media (min-width: ${theme.ms.MD}px) {
        margin: unset;
      }
    }
  }
`
