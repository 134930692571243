import Component from './Flags'
import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'molecules-Flags': {
      new: void
      discount: void
      sale: void
      series: void
      onlyB2B: void
      onlyB2C: void
      flag_3d: void
      secondChoice: void
    }
  }
}

export default withTranslations(Component, () =>
  useStaticQuery(graphql`
    query molecules_Flags_translations {
      new: snippet(group: "molecules-Flags", name: "new")
      discount: snippet(group: "molecules-Flags", name: "discount")
      sale: snippet(group: "molecules-Flags", name: "sale")
      series: snippet(group: "molecules-Flags", name: "series")
      onlyB2B: snippet(group: "molecules-Flags", name: "onlyB2B")
      onlyB2C: snippet(group: "molecules-Flags", name: "onlyB2C")
      flag_3d: snippet(group: "molecules-Flags", name: "flag_3d")
      secondChoice: snippet(group: "molecules-Flags", name: "secondChoice")
    }
  `)
)
