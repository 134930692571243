import * as at from './const'
import * as t from './types'

export const addMessage = (message: t.Message) => ({
  type: at.ADD_MESSAGE,
  payload: message
})

export const removeMessage = (message: t.Message) => ({
  type: at.REMOVE_MESSAGE,
  payload: message
})

export const removeAll = () => ({
  type: at.REMOVE_ALL
})

export type AddMessage = ReturnType<typeof addMessage>
export type RemoveMessage = ReturnType<typeof removeMessage>
export type RemoveAll = ReturnType<typeof removeAll>

export type Action = AddMessage | RemoveMessage | RemoveAll
