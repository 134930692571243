import { addRule } from 'redux-ruleset'
import { isChannelSet } from 'utils/channel'

declare global {
  interface Window {
    /** shows the onetrust cookie layer */
    addOnetrustScript?: () => void
  }
}

/**
 * we want to show the cookie popup
 * - when the b2x popup was already shown
 * - when the b2x popup was clicked
 */

if (typeof window !== 'undefined') {
  const addOnetrustScript =
    window.addOnetrustScript ??
    (() => {
      // noop
    })

  if (isChannelSet()) addOnetrustScript()
  else
    addRule({
      id: 'feature/LOAD_COOKIE_SCRIPT',
      target: 'B2xModal/CLOSE',
      output: '#script-add',
      addOnce: true,
      consequence: () => addOnetrustScript()
    })
}
