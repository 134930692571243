import { addRule } from 'redux-ruleset'
import * as a from './actions'
import * as at from './const'
import * as api from './utils/api'

/**
 * Should be Triggered, when the ProductReview Component is Mounted
 */

addRule({
  id: 'productReviews/TRIGGER_FETCH',
  target: at.INIT,
  output: at.FETCH_REQUEST,
  consequence: (action) => a.fetchRequest(action.meta.recordId)
})

addRule({
  id: 'productReviews/FETCH',
  target: at.FETCH_REQUEST,
  output: [at.FETCH_SUCCESS, at.FETCH_FAILURE],
  concurrency: 'FIRST',
  consequence: (action) => {
    return api.fetch(action.meta.recordId).then(
      (result) => {
        return a.fetchSuccess(action.meta.recordId, result)
      },
      (error) => a.fetchFailure(action.meta.recordId, error.toString())
    )
  }
})

addRule({
  id: 'productReviews/PREVENT_FETCH',
  target: at.FETCH_REQUEST,
  output: at.FETCH_SUCCESS,
  position: 'INSTEAD',
  condition: (action, { getState }) => {
    const state = getState()
    return (
      !!state.productReviews[action.meta.recordId] &&
      state.productReviews[action.meta.recordId].fetchError === null
    )
  },
  consequence: (action, { getState }: any) => {
    return a.fetchSuccess(
      action.meta.recordId,
      getState().productReviews[action.meta.recordId].data.reviews
    )
  }
})
