import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import B2XDropdown from '../B2XDropdown/B2XDropdown'
import CategorySelect from './CategorySelect/CategorySelect'

import * as evt from 'theme/app/Header/FlyoutNavi/events'
import Link from 'theme/atoms/Link'
import useStaticNavi from '../useStaticNavi'

export type NavigationQuery = {
  navigation: {
    sidebar: { label: string; link: string }[]
  }
}

function Navigation() {
  const sidebarNav = useStaticNavi()

  return (
    <Wrapper className="Navigation">
      <CategorySelect />
      <div className="items">
        {sidebarNav.map((item) => (
          <Item
            to={item.link}
            className="item"
            key={item.label}
            data-cy-handle="highlight-navigation-item"
            onClick={() => {
              evt.customizedNavigationClick(item.label)
            }}
          >
            {item.label}
          </Item>
        ))}
        <div className="spacer" />
      </div>
      <div className="b2x-dropdown">
        <B2XDropdown />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  display: flex;
  gap: ${theme.spacing('ml')};
  border-top: 1px solid ${theme.colors.shade.b5};
  @media (min-width: ${theme.ms.LG}px) {
    gap: ${theme.spacing('ml')};
  }
  justify-content: start;
  align-items: center;

  > .b2x-dropdown {
    display: none;
    margin-left: auto;
  }
  @media (min-width: ${theme.ms.MD}px) {
    > .b2x-dropdown {
      display: block;
    }
  }

  > .items {
    display: none;
    column-gap: ${theme.spacing('m')};
    :after {
      content: '';
      position: absolute;
      right: 0;
      height: 50px;
      width: 50px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        #ffffff 51.56%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    @media (min-width: ${theme.ms.MD}px) {
      display: flex;
    }
    @media (min-width: ${theme.ms.LG}px) {
      column-gap: ${theme.spacing('ml')};
      &::after {
        display: none;
      }
    }
    flex-direction: row;
    height: 50px;
    overflow-x: scroll;

    /* Hide scrollbars */
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    > .spacer {
      flex: 0 0 auto;
      width: ${theme.spacing('m')};
    }
  }
  > .B2XToggle {
    display: none;
    @media (min-width: ${theme.ms.LG}px) {
      display: block;
    }
  }
`

const Item = styled(Link)`
  display: flex;
  ${theme.ty('rc-s', null, 'rc-base')}
  ${(p) => p.to === '/t/sale/' && `color: ${theme.colors.accent.red};`}
    place-items: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
`

export default Navigation
