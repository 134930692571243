import * as at from './const'
import * as t from './types'

export const init = (recordId: string) => {
  return {
    type: at.INIT,
    meta: { recordId }
  }
}

export const fetchRequest = (recordId: string) => ({
  type: at.FETCH_REQUEST,
  meta: { recordId }
})

export const fetchSuccess = (recordId: string, result: t.api.Fetch) => ({
  type: at.FETCH_SUCCESS,
  meta: { recordId },
  payload: result
})

export const fetchFailure = (recordId: string, err: string) => ({
  type: at.FETCH_FAILURE,
  meta: { recordId },
  payload: err
})

export type Init = ReturnType<typeof init>
export type FetchRequest = ReturnType<typeof fetchRequest>
export type FetchSuccess = ReturnType<typeof fetchSuccess>
export type FetchFailure = ReturnType<typeof fetchFailure>

export type Action = Init | FetchRequest | FetchSuccess | FetchFailure
