import * as t from '../types'
import useConnect, { Config } from 'hooks/useConnect'
import { State } from '../reducer'
import * as a from '../actions'
import * as s from '../selectors'

type Input = {
  containerID: string
}

type Output = {
  data: {
    reviews: t.Review[]
    histogram: t.Bar[]
  }
  isFetching: boolean
  fetch: typeof a.fetchRequest
}

const config: Config<Input, Output, State, Record<string, unknown>> = {
  moduleKey: 'productReviews',
  name: 'productReviews/useProductReviews',
  createCacheKey: (input) => input.containerID,
  mapState: (state, input) => ({
    data: s.getState(state, input.containerID)
  }),
  mapDispatch: {
    fetch: a.fetchRequest
  }
}

export default function useProductReviews(containerID: string): Output {
  const input: Input = { containerID }
  const hook: Output = useConnect(input, config)
  return hook
}
