import 'features'
import * as React from 'react'
import styled from 'styled-components'
import Header from 'theme/app/CheckoutHeader'
import { CheckoutFooter as Footer } from 'theme/app/Footer'
import StoryEvents from './StoryEvents'
import { Context as ContainerSizeContext } from 'hooks/useContainerSize'
import smoothscroll from 'smoothscroll-polyfill'
import theme from 'theme'
import { PageContext, PageContextProvider } from 'hooks/usePageContext'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

type Props = {
  activeStep?: 'account' | 'address' | 'payment' | 'buy'
  children: React.ReactNode
  isFetching?: boolean
  storyEvents?: Record<string, unknown>[]
  smallContainer?: boolean
  pageContext?: PageContext
}

export default function CheckoutLayout(props: Props) {
  const containerSize = props.smallContainer ? 700 : 1300
  return (
    <PageContextProvider
      pageContext={props.pageContext || { type: 'Other', ready: true }}
    >
      <StoryEvents storyEvents={props.storyEvents}>
        <Wrapper className="CheckoutLayout">
          <Header activeStep={props.activeStep} />
          <ContainerSizeContext.Provider value={containerSize}>
            <main>{props.children}</main>
          </ContainerSizeContext.Provider>
          <Footer />
        </Wrapper>
      </StoryEvents>
    </PageContextProvider>
  )
}

const Wrapper = styled.div`
  > main {
    background: ${theme.colors.shade.b6};
    padding: 40px 0;
    min-height: calc(100vh - 31rem);
  }
`
