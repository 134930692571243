import manager from '../manager'
import * as t from '../types'
import { API_KEY, createBody, dyFetch } from '../utils'

export default function reportEventWithConsent(evt: t.ReportEvent) {
  return manager.getContext(
    (ctx) => {
      const body = createBody(ctx)

      /** add event */
      body.events = [evt]

      return dyFetch('https://direct-collect.dy-api.eu/v2/collect/user/event', {
        method: 'POST',
        headers: { 'DY-API-Key': API_KEY },
        body: JSON.stringify(body)
      })
    },
    (ctx) =>
      ctx._dyid_server &&
      ctx._dyjsession &&
      ctx.consentStatus === 'ACCEPTED' &&
      ctx.pageViewSend &&
      ctx.isChannelSet
  )
}
