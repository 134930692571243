import * as React from 'react'
import styled, { css } from 'styled-components'
import { Suggestion } from '../useSuggestions'
import Close from 'assets/menu-close.svg'
import Search from 'assets/search.svg'
import ArrowLeft from 'assets/arrow-button-left.svg'
import History from 'assets/history.svg'
import theme from 'theme'

type Props = {
  item: Suggestion
  active: boolean
  onItemClick: (item: Suggestion) => void
  onRemoveClick: (item: Suggestion) => void
  onSelectItem: (item: Suggestion) => void
}

export default function SuggestionItem(props: Props) {
  return (
    <Wrapper
      className="Suggestion"
      data-cy-collection="Suggestion"
      $active={props.active}
      key={props.item.label}
      onClick={() => props.onItemClick(props.item)}
    >
      {!props.item.historic && (
        <div className="icon arrow" data-cy-state="has-search-icon">
          <Search />
        </div>
      )}
      {props.item.historic && (
        <div className="icon history" data-cy-state="has-historic-icon">
          <History />
        </div>
      )}
      <span>{props.item.label}</span>
      {!props.item.historic && (
        <div
          className="icon nav"
          data-cy-handle="select-icon"
          data-cy-state="has-select-icon"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onSelectItem(props.item)
          }}
        >
          <ArrowLeft />
        </div>
      )}
      {props.item.historic && (
        <div
          className="icon close"
          data-cy-handle="remove-icon"
          data-cy-state="has-remove-icon"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onRemoveClick(props.item)
          }}
        >
          <Close />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $active: boolean }>`
  padding: 8px 0;

  cursor: pointer;
  display: flex;
  ${(props) =>
    props.$active &&
    css`
      background: rgba(214, 164, 68, 0.3);
    `}

  &:hover {
    background: rgba(214, 164, 68, 0.3);
  }

  > span {
    flex: 1;
    ${theme.ty('r-s')}
  }

  > .icon {
    width: 60px;
    height: 10px;
    display: flex;
    justify-content: center;

    &.arrow {
      margin-top: 2px;
      height: 14px;
      padding-left: 18px;
      box-sizing: border-box;
      g {
        stroke: ${theme.colors.primary};
      }
    }

    &.history {
      margin-top: -3px;
      height: 14px;
      padding-left: 16px;
      box-sizing: border-box;
      g {
        stroke: ${theme.colors.primary};
      }
    }

    &.nav {
      transform: rotate(45deg);
      width: 16px;
      margin-top: 1px;
      margin-right: 19px;
      g {
        stroke: grey;
      }
    }

    &.close {
      width: 19px;
      margin-top: 5px;
      margin-right: 22px;
      g {
        stroke: grey;
      }
    }
  }
`
