import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'
import CheckIcon from 'assets/tick-icon.svg'
const { white, primary } = theme.colors

type Props = {
  activeStep?: 'account' | 'address' | 'payment' | 'buy'
}

export default function CheckoutHeader(props: Props) {
  const t = useTranslations<'app-CheckoutHeader'>()
  const { activeStep } = props

  if (activeStep) {
    return (
      <Wrapper $active={activeStep} data-cy-ctx="app/Steps" className="Steps">
        <div className="step">
          <div className="circle account">
            {activeStep !== 'account' ? (
              <CheckIcon data-cy-state="show-check-icon" />
            ) : (
              1
            )}
          </div>
          <div className={'text' + (activeStep === 'account' ? ' bold' : '')}>
            {t('checkout_step_account')}
          </div>
        </div>
        <div className="spacer"></div>
        <div className="step">
          <div className="circle address">
            {activeStep !== 'address' && activeStep !== 'account' ? (
              <CheckIcon data-cy-state="show-check-icon" />
            ) : (
              2
            )}
          </div>
          <div className={'text' + (activeStep === 'address' ? ' bold' : '')}>
            {t('checkout_step_address')}
          </div>
        </div>
        <div className="spacer"></div>
        <div className="step">
          <div className="circle payment">
            {activeStep !== 'payment' &&
            activeStep !== 'address' &&
            activeStep !== 'account' ? (
              <CheckIcon data-cy-state="show-check-icon" />
            ) : (
              3
            )}
          </div>
          <div className={'text' + (activeStep === 'payment' ? ' bold' : '')}>
            {t('checkout_step_payment')}
          </div>
        </div>
        <div className="spacer"></div>
        <div className="step">
          <div className="circle order">4</div>
          <div className={'text' + (activeStep === 'buy' ? ' bold' : '')}>
            {t('checkout_step_order')}
          </div>
        </div>
      </Wrapper>
    )
  }
  return <></>
}

const Wrapper = styled.div<{ $active: Props['activeStep'] }>`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing('m')};
  margin-bottom: ${theme.spacing('m')};

  @media (min-width: ${theme.ms.MD}px) {
    max-width: 640px;
    flex: 1;
  }

  > .step {
    margin: 0 auto;

    > .circle {
      ${theme._ty([10, 0, 14], theme.font, '400')};
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 0 auto;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 1px solid ${primary};

      svg {
        width: 10px;
      }
    }

    > .account {
      background-color: ${(p) => (p.$active ? primary : white)};
      color: ${(p) => (p.$active ? white : primary)};
    }

    > .address {
      background-color: ${(p) =>
        p.$active === 'address' ||
        p.$active === 'payment' ||
        p.$active === 'buy'
          ? primary
          : white};
      color: ${(p) =>
        p.$active === 'address' ||
        p.$active === 'payment' ||
        p.$active === 'buy'
          ? white
          : primary};
    }

    > .payment {
      background-color: ${(p) =>
        p.$active === 'payment' || p.$active === 'buy' ? primary : white};
      color: ${(p) =>
        p.$active === 'payment' || p.$active === 'buy' ? white : primary};
    }

    > .order {
      background-color: ${(p) => (p.$active === 'buy' ? primary : white)};
      color: ${(p) => (p.$active === 'buy' ? white : primary)};
    }

    > .text {
      ${(p) => (p.$active ? primary : white)};
      ${theme._ty([10, 0, 14], theme.fontSpecial, '400')};
      text-align: center;
      margin-top: ${theme.spacing('xxs')};

      @media (min-width: ${theme.ms.MD}px) {
        ${theme._ty([13, 0, 19], theme.fontSpecial, '400')};
      }
    }

    > .bold {
      ${theme._ty([10, 0, 14], theme.fontSpecial, '700')};

      @media (min-width: ${theme.ms.MD}px) {
        ${theme._ty([13, 0, 19], theme.fontSpecial, '700')};
      }
    }
  }

  > .spacer {
    margin-top: -15px;
    background-color: ${primary};
    height: 1px;
    width: 15%;
  }
`
