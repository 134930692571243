export const SET_SEARCH_VALUE: 'ui/SET_SEARCH_VALUE' = 'ui/SET_SEARCH_VALUE'
export const TOGGLE_NAVI: 'ui/TOGGLE_NAVI' = 'ui/TOGGLE_NAVI'
export const SET_SIDEBAR_CATEGORY: 'ui/SET_SIDEBAR_CATEGORY' =
  'ui/SET_SIDEBAR_CATEGORY'
export const SET_CURRENT_CATEGORY: 'ui/SET_CURRENT_CATEGORY' =
  'ui/SET_CURRENT_CATEGORY'
export const CLEAR: 'ui/CLEAR' = 'ui/CLEAR'
export const SET_MODAL_CONTENT: 'ui/SET_MODAL_CONTENT' = 'ui/SET_MODAL_CONTENT'
export const SET_OPT_IN: 'ui/SET_OPT_IN' = 'ui/SET_OPT_IN'
export const SHOW_REVIEWS: 'ui/SHOW_REVIEWS' = 'ui/SHOW_REVIEWS'
//WDV-3313 NewsletterIntentLayer
export const INCREMENT_LOCATION_CHANGE_COUNTER: 'ui/INCREMENT_LOCATION_CHANGE_COUNTER' =
  'ui/INCREMENT_LOCATION_CHANGE_COUNTER'
export const SET_INTENT_VISIBLE: 'ui/SET_INTENT_VISIBLE' =
  'ui/SET_INTENT_VISIBLE'

export const SET_PDP_FILTER_DRAWER_OPEN: 'ui/SET_PDP_FILTER_DRAWER_OPEN' =
  'ui/SET_PDP_FILTER_DRAWER_OPEN'

export const SET_PDP_SIZE_TABLE_OPEN: 'ui/SET_PDP_SIZE_TABLE_OPEN' =
  'ui/SET_PDP_SIZE_TABLE_OPEN'

export const REGISTER_NEWSLETTER_NL: 'ui/RegisterNewsletterNL' =
  'ui/RegisterNewsletterNL'
//WDV-3313 NewsletterIntentLayer
