import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Link from 'theme/atoms/Link'

type Props = {
  className: string
  counter?: number
  label: any
  icon: any
  /** acts as click-popover */
  children?: any
  to?: string
  'data-cy-state'?: string
}

export default function IconTemplate(props: Props) {
  return (
    <Wrapper
      className={props.className}
      as={props.to ? Link : undefined}
      to={props.to}
      data-cy-state={props['data-cy-state']}
    >
      <div className="icon-wrapper">
        {props.icon}
        {Boolean(props.counter) && (
          <div className="counter" data-cy-state="has-counter">
            {props.counter}
          </div>
        )}
      </div>
      <span className="label">{props.label}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${theme.flex('center', 'center', 'column')}
  cursor: pointer;
  position: relative;

  @media (min-width: ${theme.ms.MD}px) {
    padding: 0 ${theme.spacing('xs')};
  }

  > .icon-wrapper {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    ${theme.flex('center', 'center')}

    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    path {
      fill: ${theme.colors.primary};
    }

    @media (min-width: ${theme.ms.MD}px) {
      width: 3rem;
      height: 2.125rem;
      > svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    > .counter {
      position: absolute;
      right: 2px;
      top: 2px;
      width: 1.125rem;
      height: 1.125rem;
      ${theme.flex('center', 'center')};
      background: ${theme.colors.primary};
      border-radius: 50%;
      border: 2px solid ${theme.colors.white};
      ${theme.ty('rc-xs', null, 'rc-s')};
      color: ${theme.colors.white};

      @media (min-width: ${theme.ms.MD}px) {
        right: -1px;
        top: -5px;
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }

  > .label {
    display: none;
    @media (min-width: ${theme.ms.MD}px) {
      display: block;
      ${theme.ty('rc-xs')}
    }
  }

  > .popover {
    width: 200px;
    height: 200px;
    background: steelblue;
    border: 1px solid black;
    position: absolute;
    top: 0;
    right: 0;
  }
`
