import { createGlobalStyle } from 'styled-components'
import './Layout.css'
import { ms } from 'utils/media-size'

export const theme = {
  ty: getTypographyNamed,
  spacing: spacing,
  rounding: rounding,
  ms: ms,
  font: '"Roboto", sans-serif' as '"Roboto", sans-serif',
  fontSpecial:
    '"Roboto Condensed", sans-serif' as '"Roboto Condensed", sans-serif',
  zIndex: {
    theme_molecules_account_addressformfields_addresssuggestions: 1,
    theme_molecules_account_addressformfields_fields_addresssuggestions: 1,
    theme_molecules_listingfilter_filterdrawer_priceslider_track: 1,
    theme_molecules_listingfilter_filterdrawer_rangeslider_track: 1,
    theme_molecules_productwidget: 1,
    theme_organisms_raffleregistrationform: 1,
    theme_organisms_newsletterregistrationform: 1,
    theme_templates_pdp_gallery_thumbnailbar_Nextbutton: 1,
    theme_molecules_listingfilter_filterdrawer_rangeslider_handle_Second: 3,
    theme_templates_pdp_filterdrawer: 3,
    theme_molecules_account_cartsummary: 3,
    app_modal: 4,
    app_snackbar: 4,
    theme_app_b2xmodal_Overlay: 4,
    theme_atoms_drawer_Overlay: 4,
    app_header_search_field: 4,
    theme_molecules_account_cartsummary_paypalexpress_paypalbutton: 4,
    theme_molecules_listingfilter_filterdrawer_priceslider_handle_second: 4,
    theme_molecules_listingfilter_sortingdropdown: 4,
    theme_templates_pdp_gallery_gallerylightbox: 4,
    app_header_navigation_popover_Backdrop: 4,
    app_header_navigation_popover_Wrapper: 4,
    app_header_b2xdropdown_dropdown_content: 4,
    theme_molecules_account_cartsummary_sharecart_Modal: 5,
    theme_atoms_drawer_Content: 5,
    theme_molecules_account_cartsummary_sharecart_errormodal_wrapper: 5,
    theme_app_b2xmodal_Modal: 5,
    theme_molecules_account_modaltemplate_Close: 5,
    theme_templates_account_personal_popups_popuptemplate_Overlay: 5,
    theme_templates_account_personal_popups_popuptemplate_Content: 5,
    theme_molecules_account_modaltemplate_Blur: 5,
    theme_molecules_listingfilter_filterdrawer_priceslider_handle_first: 6,
    theme_molecules_listingfilter_filterdrawer_rangeslider_handle_First: 6,
    theme_app_checkoutheader_topline_tooltip: 6,
    theme_atoms_input_dropdown: 8,
    theme_templates_pdp_buybox_buywrapper_channelpopup: 8,
    app_header_icon_list_wishlist_icon: 10,
    theme_molecules_account_cartsummary_deliveryinfomodal: 10,
    theme_templates_checkout_overview_purchasebox_purchasebutton_purchasebox: 10,
    theme_molecules_account_cartsummary_deliveryinfomodal_Box: 11,
    theme_molecules_account_modaltemplate_Overlay: 11,
    theme_molecules_account_cartsummary_sharecart_errormodal_modal: 50,
    theme_molecules_account_modaltemplate_OuterBox: 101, // paypal-express button has z-index 100
    theme_molecules_deliverybypartnermodal_deliveryByPartnerModal: 101,
    theme_molecules_account_cartsummary_paypalexpress: 200, // override paypal z-index: ;
    theme_molecules_account_cartsummary_paypalexpress_paypalbutton_Overlay: 200
  },
  colors: {
    green_50: '#006644' as const,
    blue: '#73C4EF' as const,
    blue_50: '#0747A6' as const,
    yellow: '#FDC100' as const,
    dark_gray: '#62676C' as const,
    black: '#000000' as const,
    primary: '#D6A444' as const,
    b0: '#2B271F' as const,
    white: '#ffffff' as const,
    warning: '#FF8B00' as const,
    'warning-0': '#331C00' as const,
    'warning-100': '#FFF3E5' as const,
    info: '#0747A6' as const,
    'info-0': '##010E21' as const,
    'info-100': '#E6EDF6' as const,
    error: '#A92200' as const,
    'error-0': '#2B0909' as const,
    'error-100': '#F6E9E5' as const,
    brand: {
      vega: '#004884' as const,
      erwinm: '#0075bf' as const,
      jobeline: '#000000' as const,
      pulsiva: '#000000' as const,
      lusini: '#d6a444' as const,
      pulsiva_red: '#E30000' as const,
      pulsiva_gray: '#B2B1B1' as const
    },
    shade: {
      b1: '#464134' as const,
      b2: '#625D52' as const,
      b3: '#7D7971' as const,
      b4: '#9A9790' as const,
      b5: '#D1CFCC' as const,
      b6: '#ECEBEA' as const,
      b7: '#F9F9F8' as const,
      primaryBrighter: '#EDD179' as const,
      primaryUltraBright: '#FAF5EC' as const
    },
    accent: {
      info: '#E6EDF6' as const,
      green: '#006644' as const,
      pink: '#E72F81' as const,
      purple: '#B94090' as const,
      yellowGreen: '#BBD033' as const,
      red: '#A92200' as const,
      blue: '#0747A6' as const
    },
    decorationColor: {
      lusiniBlue: '#73C4EF' as const,
      lusiniPurple: '#AC2786' as const
    }
  },
  /**
   *
   * @param font [font-size, letter-spacing, line-height]
   * @param family 'Roboto' | 'Roboto Condensed'
   * @param weight 400 | 700 | bold
   */
  _ty: typography,

  flex: flex,
  _spacing: {
    xxs: 5 as 5,
    xs: 10 as 10,
    s: 15 as 15,
    m: 20 as 20,
    ml: 30 as 30,
    l: 40 as 40,
    xl: 60 as 60,
    xxl: 80 as 80
  },
  _rounding: {
    s: 3 as 3,
    m: 5 as 5,
    l: 8 as 8
  }
}

type Rounding = 's' | 'm' | 'l'
/**
 *
 * Sets the border-radius in css
 * - s: 3px
 * - m: 5px
 * - l: 8px
 */
function rounding(value: Rounding) {
  return `border-radius: ${theme._rounding[value] / 16}rem;`
}

type TypographyNames =
  | 'rc-xs'
  | 'rc-s'
  | 'rc-base'
  | 'rc-l'
  | 'rc-xl'
  | 'rc-2xl'
  | 'rc-3xl'
  | 'rc-4xl'
  | 'r-xs'
  | 'r-s'
  | 'r-base'
  | 'r-l'
  | 'r-2xl'
  | 'r-xl'

function flex(
  x: 'left' | 'center' | 'right' = 'left',
  y: 'top' | 'center' | 'bottom' = 'top',
  orientation: 'column' | 'row' = 'row'
) {
  const tx = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center'
  }
  const ty = {
    top: 'flex-start',
    bottom: 'flex-end',
    center: 'center'
  }

  let content = `display: flex;`
  content += `align-items: ${orientation === 'row' ? ty[y] : tx[x]};`
  content += `justify-content: ${orientation === 'row' ? tx[x] : ty[y]};`

  if (orientation === 'column') content += 'flex-direction: column;'

  return content
}

/**
 *
 * @param font [font-size, letter-spacing, line-height]
 * @param family 'Roboto' | 'Roboto Condensed'
 * @param weight 400 | 700 | bold
 */
function typography(
  font: [number, number, number],
  family?: typeof theme.font | typeof theme.fontSpecial,
  weight?: '300' | '400' | '700'
) {
  const [size, spacing, height] = font

  let result =
    `font-size: ${size / 16}rem;` +
    `letter-spacing: ${spacing / 16}rem;` +
    `line-height: ${height / 16}rem;`

  if (family) result += `font-family: ${family};`
  if (weight) result += `font-weight: ${weight};`

  return result
}

const typographyNamed: Record<TypographyNames, string> = {
  'r-xs': typography([10, 0, 14], theme.font, '400'),
  'r-s': typography([13, 0, 18], theme.font, '400'),
  'r-base': typography([16, 0, 24], theme.font, '400'),
  'r-l': typography([18, 0, 24], theme.font, '400'),
  'r-2xl': typography([22, 0, 32], theme.font, '700'),
  'r-xl': typography([20, 0, 28], theme.font, '400'),

  'rc-xs': typography([10, 0, 14], theme.fontSpecial, '400'),
  'rc-s': typography([13, 0, 17], theme.fontSpecial, '400'),
  'rc-base': typography([16, 0, 22], theme.fontSpecial, '400'),
  'rc-l': typography([18, 0, 22], theme.fontSpecial, '400'),
  'rc-xl': typography([20, 0, 24], theme.fontSpecial, '400'),
  'rc-2xl': typography([22, 0, 28], theme.fontSpecial, '400'),
  'rc-3xl': typography([28, 0, 36], theme.fontSpecial, '400'),
  'rc-4xl': typography([38, 0, 48], theme.fontSpecial, '400')
}

/**
 * - 'r-xs': typography([10, 0, 14], theme.font, '400'),
 * - 'r-s': typography([13, 0, 18], theme.font, '400'),
 * - 'r-base': typography([16, 0, 24], theme.font, '400'),
 * - 'r-l': typography([18, 0, 24], theme.font, '400'),
 * - 'r-2xl': typography([22, 0, 32], theme.font, '700'),
 * - 'r-xl': typography([20, 0, 28], theme.font, '400'),

 * - 'rc-xs': typography([10, 0, 14], theme.fontSpecial, '400'),
 * - 'rc-s': typography([13, 0, 17], theme.fontSpecial, '400'),
 * - 'rc-base': typography([16, 0, 22], theme.fontSpecial, '400'),
 * - 'rc-l': typography([18, 0, 22], theme.fontSpecial, '400'),
 * - 'rc-xl': typography([20, 0, 24], theme.fontSpecial, '400'),
 * - 'rc-2xl': typography([22, 0, 28], theme.fontSpecial, '400'),
 * - 'rc-3xl': typography([28, 0, 36], theme.fontSpecial, '400'),
 * - 'rc-4xl': typography([38, 0, 48], theme.fontSpecial, '400'),
 */
function getTypographyNamed(
  name: TypographyNames,
  weight?: null | '300' | '400' | '700' | 'bold',
  mdName?: TypographyNames
) {
  if (mdName) {
    let s = typographyNamed[name]
    let sMD = typographyNamed[mdName]
    if (weight) {
      s += `font-weight:${weight};`
      sMD += `font-weight:${weight};`
    }
    return s + `@media(min-width:${theme.ms.MD}px){${sMD}}`
  }
  if (weight) return typographyNamed[name] + `font-weight:${weight};`
  return typographyNamed[name]
}

typography.named = getTypographyNamed

/**
 * Spacings
 */
type Spacing = 'xxs' | 'xs' | 's' | 'm' | 'ml' | 'l' | 'xl' | 'xxl'

/**
 * - xxs: 5px
 * - xs: 10px
 * - s: 15px,
 * - m: 20px,
 * - ml: 30px,
 * - l: 40px,
 * - xl: 60px,
 * - xxl: 80px,
 */
function spacing(spacing: Spacing) {
  return `${theme._spacing[spacing] / 16}rem`
}

export default createGlobalStyle`
  :root {
    --spacing-xxs: ${theme.spacing('xxs')};
    --spacing-xs: ${theme.spacing('xs')};
    --spacing-s: ${theme.spacing('s')};
    --spacing-m: ${theme.spacing('m')};
    --spacing-ml: ${theme.spacing('ml')};
    --spacing-l: ${theme.spacing('l')};
    --spacing-xl: ${theme.spacing('xl')};
    --spacing-xxl: ${theme.spacing('xxl')};
  }

  html, body {
    font-family: ${theme.font};
    color: ${theme.colors.b0};
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-y: overlay;
    overflow-x: hidden; 
  }

  input {
    box-sizing: border-box;
  }

  // scrolllock class for drawer
  body.scrolllock {
    overflow: hidden;
  }


  div {
    box-sizing: border-box;
  }

  input {
    color: ${theme.colors.b0};
  }

  svg {
    color: ${theme.colors.b0};
    width: 1rem;
  }

  a {
    color: ${theme.colors.b0};
    text-decoration: none;
  }

  img {
    margin: 0;
    display: block;
  }

  button {
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    color:${theme.colors.b0};
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
  }

  .debug {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(120,0,0,0.3);
    }
  }
`
