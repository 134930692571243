import * as React from 'react'
import Personalization from 'theme/atoms/Personalization'
import fetchBySku from 'utils/productListFetcher/bySku'
import ProductSlider, {
  ProductSliderLoading
} from 'theme/molecules/ProductSlider'

type DYResponse = {
  title: string
  skus: { sku: string; slotId: string }[]
  decisionId: string
}

type Props = {
  dySelector: string
  title: string
  // TODO: better naming for this prop
  gridArea: string
  layoutSize?: string
}

export default function DyProductSlider(props: Props) {
  if (!props.dySelector) return null
  return (
    <Personalization<DYResponse, { title: string }>
      strategy="NO_CACHE"
      // wdv-1162-home-recommendation-slot1
      selector={props.dySelector}
      autoTrack={true}
      loading={<ProductSliderLoading />}
      shape={[
        'object',
        {
          title: 'string'
        }
      ]}
      processResponse={(dyResponse) => ({
        skus: dyResponse.skus,
        title: dyResponse.content.title,
        decisionId: dyResponse.context.decisionId
      })}
      preventClickTracking
      render={(data) => (
        <ProductSlider
          maxProducts={data.skus.length}
          fetchFn={(page) =>
            fetchBySku(
              data.skus.map((row) => row.sku),
              10
            )(page).then((result) => {
              const dataDict: Record<string, any> = {}
              for (const product of result.data) {
                dataDict[product.sku] = product
              }

              return {
                ...result,
                data: data.skus
                  .map(({ sku, slotId }) => {
                    if (dataDict[sku]) {
                      const product = dataDict[sku]
                      return {
                        ...product,
                        dyContext: {
                          decisionId: data.decisionId,
                          slotId: slotId || ''
                        }
                      }
                    } else {
                      return null
                    }
                  })
                  .filter(Boolean)
              }
            })
          }
          title={data.title || props.title}
          listname={'Story: ' + props.gridArea}
          layoutSize={props.layoutSize || 'normal'}
        />
      )}
    />
  )
}
