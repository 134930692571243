import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

type Output = {
  categories: CategoryItem[]
  currentLevel: number
  addCategoryForLevel: (level: number, item: CategoryItem) => void
  removeCategoryForLevel: (level: number) => void
  clearCategoryIds: () => void
  categoryByLevel: {
    0: CategoryItem | null
    1: CategoryItem | null
    2: CategoryItem | null
  }
}

type BrandCategoryItem = {
  id: string
  tag: string
  label: string
  urlPath: string
}

export type CategoryItem = {
  id: string
  urlPath: string
  path: string
  label: string
  categoryLevel: number
  childrenCategory?: CategoryItem[]
  objectID?: string
}

export type QueryResponse = {
  categories: { nodes: CategoryItem[] }
  brandTagCategories: { nodes: BrandCategoryItem[] }
  navigation: {
    sidebar: { label: string; link: string }[]
  }
}

type Actions = AddLevelAction | ClearAction | RemoveLevelAction

type AddLevelAction = {
  type: 'add-category-id'
  payload: {
    level: number
    item: CategoryItem
  }
}
type ClearAction = {
  type: 'clear'
}

type RemoveLevelAction = {
  type: 'remove-category-id'
  payload: {
    level: number
  }
}

function reducer(state, action: Actions) {
  switch (action.type) {
    case 'add-category-id': {
      const s = { ...state }
      s[`${action.payload.level}`] = action.payload.item
      return s
    }
    case 'remove-category-id': {
      const s = { ...state }
      s[`${action.payload.level}`] = null
      return s
    }
    case 'clear':
      return { '0': null, '1': null, '2': null }
    default:
      throw new Error()
  }
}

function useCategories(): Output {
  const [currentLevel, setCurrentLevel] = React.useState<number>(0)
  const [categoryByLevel, dispatch] = React.useReducer(reducer, {
    0: null,
    1: null,
    2: null
  })

  const addCategoryForLevel = React.useCallback(
    (level: number, item: CategoryItem) => {
      setCurrentLevel(level)
      dispatch({ type: 'add-category-id', payload: { level, item } })
    },
    []
  )

  const removeCategoryForLevel = React.useCallback((level: number) => {
    setCurrentLevel(level - 1)
    dispatch({ type: 'remove-category-id', payload: { level } })
  }, [])

  const clearCategoryIds = React.useCallback(() => {
    setCurrentLevel(0)
    dispatch({ type: 'clear' })
  }, [])

  const gq: QueryResponse = useStaticQuery(graphql`
    query HeaderCategorySelect {
      categories: allCategory(
        filter: { categoryLevel: { eq: 1 } }
        sort: { position: ASC }
      ) {
        nodes {
          id
          label
          path
          urlPath
          categoryLevel
          objectID
          childrenCategory {
            id
            label
            path
            urlPath
            categoryLevel
            childrenCategory {
              id
              label
              path
              urlPath
              categoryLevel
              childrenCategory {
                id
                label
                path
                urlPath
                categoryLevel
              }
            }
          }
        }
      }
      brandTagCategories: allTagCategory(
        filter: { isBrand: { eq: true }, active: { eq: true } }
        sort: { sidebarIndex: ASC }
      ) {
        nodes {
          id
          tag
          label: title
          urlPath
        }
      }
    }
  `)

  const categories = React.useMemo(() => {
    const allCats = gq.categories.nodes.map((category) => {
      if (category.objectID === 'Marken') {
        return {
          ...category,
          childrenCategory: [
            ...(category?.childrenCategory ?? []),
            ...gq.brandTagCategories.nodes.map((brand) => {
              return {
                id: brand.id,
                label: brand.label,
                urlPath: brand.urlPath,
                path: '',
                categoryLevel: 2
              }
            })
          ]
        }
      }
      return category
    })
    return allCats
  }, [])

  return {
    categories: categories,
    currentLevel,
    addCategoryForLevel,
    clearCategoryIds,
    categoryByLevel,
    removeCategoryForLevel
  }
}

export default useCategories
