import * as React from 'react'
import useTranslations from 'hooks/useTranslations'
import Link from 'theme/atoms/Link'
import styled from 'styled-components'
import theme from 'theme'
import { Gq } from '../types'

export default function Copyright(props: Omit<Gq, 'brandName'>) {
  const t = useTranslations<'app-Footer'>()

  return (
    <Wrapper className="Copyright">
      <div className="outer">
        <div className="inner">
          <div className="list-wrapper">
            {props.footer.legal.map((service) => (
              <Link
                key={service.urlPath}
                className="infolink"
                to={service.urlPath}
                blank
              >
                {service.title}
              </Link>
            ))}
          </div>
          <span>{t('copyright')}</span>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${theme.ms.MD}px) {
    height: 3.125rem;
    padding: 0 ${theme.spacing('m')};
  }

  > .outer {
    padding: ${theme.spacing('xs')} 0;
    width: 1300px;
    ${theme.ty('r-base')}
    color: ${theme.colors.shade.b3};

    > .inner {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: ${theme.colors.b0};
      ${theme._ty([13, 0.5, 16], theme.font, '400')}
      margin-left: 1.5625rem;

      @media (min-width: ${theme.ms.LG}px) {
        flex-direction: row;
        margin-left: 0;
        ${theme._ty([16, 0.5, 15], theme.font, '400')}
      }

      > .list-wrapper {
        margin-bottom: ${theme.spacing('xs')};

        @media (min-width: ${theme.ms.MD}px) {
          margin-bottom: 0;
        }

        .infolink {
          color: ${theme.colors.b0};
          margin-bottom: ${theme.spacing('xs')};
          margin-right: 0;
          margin-left: 1.5625rem;

          @media (min-width: ${theme.ms.MD}px) {
            margin-bottom: 0;
          }

          @media (min-width: ${theme.ms.MD}px) {
            margin-right: ${theme.spacing('xs')};
            margin-left: 0;
          }

          &:first-child {
            margin-right: 0;
            margin-left: 0px;
            @media (min-width: ${theme.ms.MD}px) {
              margin-right: ${theme.spacing('m')};
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`
