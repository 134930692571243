import useSWR from 'swr/immutable'
import * as utils from '../utils'
import { GetWishlistResponse } from '../response-types/get-wishlist'
import { WishlistAddResponse } from '../response-types/wishlist-add'
import useAccount from '../useAccount/useAccount'
import * as React from 'react'
import { Product } from 'modules/listing/types'
import config from 'config'

export default function useWishlist() {
  const account = useAccount()
  const [info, setInfo] = React.useState<'add' | 'remove' | null>(null)

  /** list of all skus */
  const {
    data: sw6uuids,
    error,
    mutate
  } = useSWR(
    account.isFetching ? null : ['wishlist/skus', account.loggedIn],
    () => fetchWishlistSw6Uuids(account.loggedIn, account.context?.isGuest)
  )

  const isLoadingSw6uuids = !sw6uuids && !error

  const sw6uuidSet = React.useMemo(() => new Set(sw6uuids), [sw6uuids])

  const result = {
    isLoading: isLoadingSw6uuids,
    sw6uuids: sw6uuidSet,
    info,
    setInfo,
    toggleSku: (
      sw6uuid: string,
      payload: {
        variant: Product
        listid: number
        listname: string
        item_list_id: string
      }
    ) =>
      sw6uuidSet.has(sw6uuid)
        ? result.removeSku(sw6uuid, payload)
        : result.addSku(sw6uuid, payload),
    addSku: (
      sw6uuid: string,
      payload: {
        variant: Product
        listid: number
        listname: string
        item_list_id: string
      }
    ) => {
      account._.sendEvent({
        type: 'WISHLIST_ADD',
        payload: payload
      })
      return mutate(
        addSku(sw6uuid, account.loggedIn, account.context?.isGuest || false),
        {
          optimisticData: (sw6uuids = []) => [...sw6uuids, sw6uuid]
        }
      )
    },
    removeSku: (
      sw6uuid: string,
      payload: {
        variant: Product
        listid: number
        listname: string
        item_list_id: string
      }
    ) => {
      account._.sendEvent({
        type: 'WISHLIST_REMOVE',
        payload: payload
      })

      return mutate(
        removeSku(sw6uuid, account.loggedIn, account.context?.isGuest || false),
        {
          optimisticData: (sw6uuids = []) =>
            sw6uuids.filter((s) => s !== sw6uuid)
        }
      )
    }
  }

  return result
}

const tokenKey = `${config.locale.split('-').pop()}:wishlist-items`

async function fetchWishlistSw6Uuids(
  loggedIn: boolean,
  isGuest = false
): Promise<string[]> {
  const items = JSON.parse(localStorage.getItem(tokenKey) ?? '[]') as string[]
  if (!loggedIn || isGuest) return items

  /** when local wishlist exists we merge the items with remote wishlist */
  if (items.length) {
    const [result, status] = await utils.fetchFromShopware<WishlistAddResponse>(
      {
        method: 'POST',
        url: '/customer/wishlist/merge',
        body: {
          productIds: items
        }
      }
    )

    if (status !== 200 || !result.success)
      throw new Error('could not merge wishlist')
    else localStorage.removeItem(tokenKey)
  }
  const [result, status] = await utils.fetchFromShopware<GetWishlistResponse>({
    method: 'POST',
    url: '/customer/wishlist',
    body: {
      page: 1,
      limit: 100
    }
  })

  /** when initial wishlist fetch has no result (404), an empty wishlist will be created  */
  if (
    status === 404 &&
    result.errors?.[0].code === 'CHECKOUT__WISHLIST_NOT_FOUND'
  ) {
    return await utils
      .fetchFromShopware<WishlistAddResponse>({
        method: 'POST',
        url: '/customer/wishlist/merge',
        body: {
          productIds: ['00000000000000000000000000000000']
        }
      })
      .then(() => [])
  }
  return result.products.elements.map((el) => el.id)
}

async function addSku(
  sw6uuid: string,
  loggedIn: boolean,
  isGuest: boolean
): Promise<string[]> {
  if (!loggedIn || isGuest) {
    const items = JSON.parse(localStorage.getItem(tokenKey) ?? '[]') as string[]
    items.push(sw6uuid)
    localStorage.setItem(tokenKey, JSON.stringify(items))
    return items
  }
  const [result] = await utils.fetchFromShopware<WishlistAddResponse>({
    method: 'POST',
    url: `/customer/wishlist/add/${sw6uuid}`,
    body: {}
  })

  if (!result.success) throw new Error('could not add to wishlist')

  return fetchWishlistSw6Uuids(loggedIn, isGuest)
}

async function removeSku(
  sw6uuid: string,
  loggedIn: boolean,
  isGuest: boolean
): Promise<string[]> {
  if (!loggedIn || isGuest) {
    const items = JSON.parse(localStorage.getItem(tokenKey) ?? '[]') as string[]
    const filtered = items.filter((item) => item !== sw6uuid)
    localStorage.setItem(tokenKey, JSON.stringify(filtered))
    return filtered
  }
  const [result] = await utils.fetchFromShopware<WishlistAddResponse>({
    method: 'DELETE',
    url: `/customer/wishlist/delete/${sw6uuid}`,
    body: {}
  })

  if (!result.success) throw new Error('could not delete from wishlist')

  return fetchWishlistSw6Uuids(loggedIn, isGuest)
}
